import JwtAuthService from "../apiConfig/ApiConfig";
import { toast } from "react-toastify";
import {
  ISSUE_LIST_REQUEST,
  ISSUE_LIST_SUCCESS,
  ISSUE_LIST_ERROR,
  ADD_ISSUE_REQUEST,
  ADD_ISSUE_SUCCESS,
  ADD_ISSUE_ERROR,
  ISSUE_DETAILS_REQUEST,
  ISSUE_DETAILS_SUCCESS,
  ISSUE_DETAILS_ERROR,
  GET_ISSUE_FORMDATA_REQUEST,
  GET_ISSUE_FORMDATA_SUCCESS,
  GET_ISSUE_FORMDATA_ERROR,
  EDIT_ISSUE_REQUEST,
  EDIT_ISSUE_SUCCESS,
  EDIT_ISSUE_ERROR,
  ISSUE_DELETE_REQUEST,
  ISSUE_DELETE_SUCCESS,
  ISSUE_DELETE_ERROR,
  ADD_ISSUE_IMAGE_REQUEST,
  ADD_ISSUE_IMAGE_SUCCESS,
  ADD_ISSUE_IMAGE_ERROR,
  ISSUE_ARCHIVED_REQUEST,
  ISSUE_ARCHIVED_SUCCESS,
  ISSUE_ARCHIVED_ERROR,
  ISSUE_COUNT_REQUEST,
  ISSUE_COUNT_SUCCESS,
  ISSUE_COUNT_ERROR,
  ISSUE_CATEGORY_REQUEST,
  ISSUE_CATEGORY_SUCCESS,
  ISSUE_CATEGORY_ERROR,
  LINECHART_ISSUE_REQUEST,
  LINECHART_ISSUE_SUCCESS,
  LINECHART_ISSUE_ERROR,
  ISSUE_CREATED_REQUEST,
  ISSUE_CREATED_SUCCESS,
  ISSUE_CREATED_ERROR,
  ISSUE_AVERAGE_REQUEST,
  ISSUE_AVERAGE_SUCCESS,
  ISSUE_AVERAGE_ERROR,
  ADD_ISSUE_MEDIA_ERROR,
  ADD_ISSUE_MEDIA_SUCCESS,
  ADD_ISSUE_MEDIA_REQUEST,
  ALL_ISSUE_LIST_REQUEST,
  ALL_ISSUE_LIST_SUCCESS,
  ALL_ISSUE_LIST_ERROR,
  CATEGORY_LIST_ISSUE_REQUEST,
  CATEGORY_LIST_ISSUE_SUCCESS,
  CATEGORY_LIST_ISSUE_ERROR,
} from "../types/Types";

export const IssueList = () => ({
  type: ISSUE_LIST_REQUEST,
});

export const IssueListSuccess = (companyid) => ({
  type: ISSUE_LIST_SUCCESS,
  payload: companyid,
});

export const IssueListFailed = (error) => ({
  type: ISSUE_LIST_ERROR,
  payload: error,
});

export const IssueListApi = (data) => (dispatch) => {
  try {
    dispatch(IssueList(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(IssueListSuccess(data));
      } else {
        dispatch(IssueListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(IssueListFailed(error));
  }
};

export const AllIssueList = () => ({
  type: ALL_ISSUE_LIST_REQUEST,
});

export const AllIssueListSuccess = (companyid) => ({
  type: ALL_ISSUE_LIST_SUCCESS,
  payload: companyid,
});

export const AllIssueListFailed = (error) => ({
  type: ALL_ISSUE_LIST_ERROR,
  payload: error,
});

export const AllIssueListApi = (data) => (dispatch) => {
  try {
    dispatch(AllIssueList(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AllIssueListSuccess(data));
      } else {
        dispatch(AllIssueListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AllIssueListFailed(error));
  }
};


export const CategoryListRequest = () => ({
  type: CATEGORY_LIST_ISSUE_REQUEST,
});
export const CategoryListSuccess = (list) => ({
  type: CATEGORY_LIST_ISSUE_SUCCESS,
  payload: list,
});
export const CategoryListFailed = (error) => ({
  type: CATEGORY_LIST_ISSUE_ERROR,
  payload: error,
});

export const CategoryListApi = (data) => (dispatch) => {
  try {
    dispatch(CategoryListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(CategoryListSuccess(response));
      } else {
        dispatch(CategoryListFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CategoryListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const ArchivedIssueListRequest = () => ({
  type: ISSUE_ARCHIVED_REQUEST,
});

export const ArchivedIssueListSuccess = (companyid) => ({
  type: ISSUE_ARCHIVED_SUCCESS,
  payload: companyid,
});

export const ArchivedIssueListFailed = (error) => ({
  type: ISSUE_ARCHIVED_ERROR,
  payload: error,
});

export const ArchivedIssueListApi = (data) => (dispatch) => {
  try {
    dispatch(ArchivedIssueListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ArchivedIssueListSuccess(data));
      } else {
        dispatch(IssueListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ArchivedIssueListFailed(error));
  }
};

export const AddIssue = (companyid) => ({
  type: ADD_ISSUE_REQUEST,
  payload: companyid,
});

export const AddIssueSuccess = (companyid) => ({
  type: ADD_ISSUE_SUCCESS,
  payload: companyid,
});

export const AddIssueFailed = (error) => ({
  type: ADD_ISSUE_ERROR,
  payload: error,
});

export const AddIssueApi = (data) => (dispatch) => {
  try {
    dispatch(AddIssue(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddIssueSuccess(data));
      } else {
        dispatch(AddIssueFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddIssueFailed(error));
  }
};

export const AddIssueImage = (companyid) => ({
  type: ADD_ISSUE_IMAGE_REQUEST,
  payload: companyid,
});

export const AddIssueImageSuccess = (companyid) => ({
  type: ADD_ISSUE_IMAGE_SUCCESS,
  payload: companyid,
});

export const AddIssueImageFailed = (error) => ({
  type: ADD_ISSUE_IMAGE_ERROR,
  payload: error,
});

//Media API Code Here
export const AddIssueMedia = (companyid) => ({
  type: ADD_ISSUE_MEDIA_REQUEST,
  payload: companyid,
});

export const AddIssueMediaSuccess = (companyid) => ({
  type: ADD_ISSUE_MEDIA_SUCCESS,
  payload: companyid,
});

export const AddIssueMediaFailed = (error) => ({
  type: ADD_ISSUE_MEDIA_ERROR,
  payload: error,
});

//Code ended

export const AddIssueImageApi = (data) => (dispatch) => {
  try {
    dispatch(AddIssueImage(data));
    JwtAuthService.BinaryImageApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddIssueImageSuccess(data));
      } else {
        dispatch(AddIssueImageFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddIssueImageFailed(error));
  }
};
//Media API HERE
export const AddIssueMediaApi = (data) => (dispatch) => {
  try {
    dispatch(AddIssueMedia(data));
    JwtAuthService.BinaryImageApiService(data).then((response) => {
      // dont change this
      if (response?.status == 200) {
        const data = response;
        dispatch(AddIssueMediaSuccess(data));
      } else {
        dispatch(AddIssueMediaFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddIssueMediaFailed(error));
  }
};
//Media Code ended
export const GetIssueData = (companyid) => ({
  type: GET_ISSUE_FORMDATA_REQUEST,
  payload: companyid,
});

export const GetIssueDataSuccess = (companyid) => ({
  type: GET_ISSUE_FORMDATA_SUCCESS,
  payload: companyid,
});

export const GetIssueDataFailed = (error) => ({
  type: GET_ISSUE_FORMDATA_ERROR,
  payload: error,
});

export const GetIssueDataApi = (data) => (dispatch) => {
  try {
    dispatch(GetIssueData(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(GetIssueDataSuccess(data));
      } else {
        dispatch(GetIssueDataFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(GetIssueDataFailed(error));
  }
};

export const EditIssue = (companyid) => ({
  type: EDIT_ISSUE_REQUEST,
  payload: companyid,
});

export const EditIssueSuccess = (companyid) => ({
  type: EDIT_ISSUE_SUCCESS,
  payload: companyid,
});

export const EditIssueFailed = (error) => ({
  type: EDIT_ISSUE_ERROR,
  payload: error,
});

export const EditIssueApi = (data) => (dispatch) => {
  try {
    dispatch(EditIssue(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(EditIssueSuccess(data));
      } else {
        dispatch(EditIssueFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(EditIssueFailed(error));
  }
};

export const IssueDetails = (data) => ({
  type: ISSUE_DETAILS_REQUEST,
  payload: data,
});

export const IssueDetailsSuccess = (data) => ({
  type: ISSUE_DETAILS_SUCCESS,
  payload: data,
});

export const IssueDetailsFailed = (error) => ({
  type: ISSUE_DETAILS_ERROR,
  payload: error,
});

export const IssueDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(IssueDetails(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(IssueDetailsSuccess(data));
      } else {
        dispatch(IssueDetailsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(IssueDetailsFailed(error));
  }
};

export const DeleteIssue = (companyid) => ({
  type: ISSUE_DELETE_REQUEST,
  payload: companyid,
});

export const DeleteIssueSuccess = (companyid) => ({
  type: ISSUE_DELETE_SUCCESS,
  payload: companyid,
});

export const DeleteIssueFailed = (error) => ({
  type: ISSUE_DELETE_ERROR,
  payload: error,
});

export const DeleteIssueApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteIssue(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(DeleteIssueSuccess(data));
      } else {
        dispatch(DeleteIssueFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteIssueFailed(error));
  }
};

export const IssueCount = (companyid) => ({
  type: ISSUE_COUNT_REQUEST,
  payload: companyid,
});

export const IssueCountSuccess = (companyid) => ({
  type: ISSUE_COUNT_SUCCESS,
  payload: companyid,
});

export const IssueCountFailed = (error) => ({
  type: ISSUE_COUNT_ERROR,
  payload: error,
});

export const IssueCountApi = (data) => (dispatch) => {
  try {
    dispatch(IssueCount(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(IssueCountSuccess(data));
      } else {
        dispatch(IssueCountFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(IssueCountFailed(error));
  }
};

export const MostIssueCategory = (companyid) => ({
  type: ISSUE_CATEGORY_REQUEST,
  payload: companyid,
});

export const MostIssueCategorySuccess = (companyid) => ({
  type: ISSUE_CATEGORY_SUCCESS,
  payload: companyid,
});

export const MostIssueCategoryFailed = (error) => ({
  type: ISSUE_CATEGORY_ERROR,
  payload: error,
});

export const MostIssueCategoryApi = (data) => (dispatch) => {
  try {
    dispatch(MostIssueCategory(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(MostIssueCategorySuccess(data));
      } else {
        dispatch(MostIssueCategoryFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(MostIssueCategoryFailed(error));
  }
};

export const MostIssueCreatedRequest = (companyid) => ({
  type: ISSUE_CREATED_REQUEST,
  payload: companyid,
});

export const MostIssueCreatedSuccess = (companyid) => ({
  type: ISSUE_CREATED_SUCCESS,
  payload: companyid,
});

export const MostIssueCreatedFailed = (error) => ({
  type: ISSUE_CREATED_ERROR,
  payload: error,
});

export const MostIssueCreatedApi = (data) => (dispatch) => {
  try {
    dispatch(MostIssueCreatedRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(MostIssueCreatedSuccess(data));
      } else {
        dispatch(MostIssueCreatedFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(MostIssueCreatedFailed(error));
  }
};

export const LinechartIssueRequest = (companyid) => ({
  type: LINECHART_ISSUE_REQUEST,
  payload: companyid,
});

export const LinechartIssueSuccess = (companyid) => ({
  type: LINECHART_ISSUE_SUCCESS,
  payload: companyid,
});

export const LinechartIssueFailed = (error) => ({
  type: LINECHART_ISSUE_ERROR,
  payload: error,
});

export const LinechartIssueApi = (data) => (dispatch) => {
  try {
    dispatch(LinechartIssueRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(LinechartIssueSuccess(data));
      } else {
        dispatch(LinechartIssueFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(LinechartIssueFailed(error));
  }
};

export const IssuesAverageRequest = (companyid) => ({
  type: ISSUE_AVERAGE_REQUEST,
  payload: companyid,
});

export const IssuesAverageSuccess = (companyid) => ({
  type: ISSUE_AVERAGE_SUCCESS,
  payload: companyid,
});

export const IssuesAverageFailed = (error) => ({
  type: ISSUE_AVERAGE_ERROR,
  payload: error,
});

export const IssuesAverageApi = (data) => (dispatch) => {
  try {
    dispatch(IssuesAverageRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(IssuesAverageSuccess(data));
      } else {
        dispatch(IssuesAverageFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(IssuesAverageFailed(error));
  }
};
