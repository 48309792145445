/** @format */

import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateFormHead from "./createFormTabs/CreateFormHead";
import RuleSection from "./createFormTabs/RuleSection";
import RulesSelectionQustion from "./createFormTabs/RulesSelectionQustion";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { AllFormEditStoresUpdateSuccess } from "../../redux/actions/AllformStore";
import {
  CreateFormApi,
  ViewAllFormListApi,
  EditAllFormListApi,
  EditAllFormActionHandler,
  CategoryListApi,
} from "../../redux/actions/Checklist";
import { allFormQustionEditData } from "../../redux/actions/AllformStore";
import { ToastContainer } from "react-toastify";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import {
  allFormData,
  allFormsUpdateData,
} from "../../redux/actions/AllformStore";
import { SettingsOutlined } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { UserSubRoleApi, UserInspectionApi } from "../../redux/actions/User";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const EditAddAllForm = () => {
  let titleNames = localStorage.getItem("titleName");
  let validarData = localStorage.getItem("validatorData");
  let paredValidatorData = JSON.parse(validarData);
  const reportsData = localStorage.getItem("selectedReports");
  const parseReports = reportsData ? JSON.parse(reportsData) : [];
  const inspectionNames = localStorage.getItem("inspectionName");
  const selectedSku = localStorage.getItem("selectedSku");
  const selfieRequired = localStorage.getItem("isSelfieRequired")
    ? true
    : false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const textAreaRef = useRef(null);
  const [questionData, setQuestionData] = useState([]);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [rulesData, setRulesData] = useState([]);
  const [listData, setListData] = useState({});
  const [indexHover, setIndexHover] = useState(0);
  const [selectedReport, setSelectedReport] = useState(parseReports);
  const [selectedInspecCat, setSelectedInspecCat] = useState();
  const [reportSelect, setReportSelect] = useState([]);
  const classes = useStyles();
  const [isSelfieRequired, setIsSelfieRequired] = useState(selfieRequired);
  const [categoryData, setCategoryData] = useState([]);

  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [title, setTitle] = useState(titleNames);
  const [inspectionCategory, setInspection] = useState(inspectionNames);
  const [sku, setSku] = useState(selectedSku);
  const userId = location.state;
  const createQuestion = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.allform &&
      state.checklist.allform.data &&
      state.checklist.allform.data.data &&
      state.checklist.allform.data.data.forms
  );
  const addAllformData = useSelector(
    (state) => state && state.allForm && state.allForm.allForm
  );
  const addQustionEditData = useSelector(
    (state) => state && state.allForm && state.allForm.addqustionedit
  );
  const categoryListData = useSelector(
    (state) => state.checklist && state.checklist.category && state.checklist.category.data && state.checklist.category.data.data || []
  );
  // const addallformData = useSelector(state => state);
  const dataList = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.viewFormList &&
      state.checklist.viewFormList.data &&
      state.checklist.viewFormList.data.data
  );
  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );
  const inspectionList = useSelector(
    (state) =>
      state.user &&
      state.user.inspection &&
      state.user.inspection.data &&
      state.user.inspection.data.data &&
      state.user.inspection.data.data
  );
  const isAllSelectedReport =
    reportSelect.length > 0 && selectedReport.length === reportSelect.length;

  // useEffect(() => {
  //   handleViewList();
  // }, []);

  useEffect(() => {
    handleViewList();
  }, []);

  useEffect(() => {
    //  if(addQustionEditData){
    //   localStorage.setItem("questions", JSON.stringify(addQustionEditData));
    //  }
    const questionDatas = localStorage.getItem("questions");
    if (questionDatas) {
      const newQuest = JSON.parse(questionDatas);
      if (newQuest) {
        dispatch(allFormQustionEditData(newQuest));
      }
    }
  }, []);

  useEffect(() => {
    if (addQustionEditData) {
      setQuestionData(addQustionEditData);
    }
  }, [addQustionEditData]);

  useEffect(() => {
    // dispatch(CreateAllChecklistSuccess(""));
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
    // FormList();
    // listUser();
  }, []);
  useEffect(() => {
    const data = { url: BASE_URL + `inspectionCategory` };
    dispatch(UserInspectionApi(data));
  }, []);

  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setReportSelect(arr);
    }
  }, [subRoleList]);
  useEffect(() => {
    if (inspectionList) {
      let arr = [];
      for (let item of inspectionList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setSelectedInspecCat(arr);
    }
  }, [inspectionList]);

  // useEffect(() => {
  //   const editdata = localStorage.getItem("editdata");
  //   const listobject = JSON.parse(editdata);
  //   setListData(listobject)
  // }, [])

  // useEffect(() => {
  //   if (dataList) {
  //     const alldataStore = dataList
  //     localStorage.setItem("questiodata", JSON.stringify(aaaa))
  //     const editdata = localStorage.getItem("questiodata");
  //     const bbbb = JSON.parse(editdata)
  //     setListData(addAllformData)
  //     // dispatch(allFormData(alldataStore))

  //   }
  // }, [dataList])

  useEffect(() => {
    if (dataList) {
      const data = dataList;
      dispatch(allFormData(data));
    }
  }, [dataList, dispatch]);
  useEffect(() => {
    if (addAllformData) {
      setListData(addAllformData);
    }
  }, [addAllformData]);

  const validationSchema = Yup.object({
    title: Yup.string("Enter your title").required("Title is required"),
  });

  const handleChangeReport = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value.includes("all");
    if (isClickAll) {
      setSelectedReport(
        selectedReport.length === reportSelect?.length ? [] : reportSelect
      );
      console.log({ value, reportSelect });
      localStorage.setItem(
        "selectedReports",
        selectedReport.length === reportSelect?.length
          ? JSON.stringify([])
          : JSON.stringify(reportSelect)
      );
      return;
    }
    console.log({ value });
    setSelectedReport(value);
    localStorage.setItem("selectedReports", JSON.stringify(value));
  };

  const formik = useFormik({
    initialValues: { title: title || "", inspectionCategory: inspectionCategory || "", skuName: sku || "" },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        dispatch(allFormsUpdateData(values));
      }
      handleFinalSubmit(values);
    },
  });

  const handleFinalSubmit = (values) => {
    if (values) {

      const modifyQustionData = questionData.map(item => {
        item.skuName = formik.values.skuName

        return item
      });
      
      const formquestionValue = {
        questions: modifyQustionData,
        rules: rulesData,
        // skuName: formik.values.skuName,
        kpis: [],
        title: title,
        reports: selectedReport,
        inspectionCategory: inspectionCategory,
        isSelfieRequired: isSelfieRequired,
      };

      const allData = {
        body: formquestionValue,
      };
      const id = localStorage.getItem("companyId");
      allData.url = BASE_URL + `checklists?company_id=${id}`;
      dispatch(CreateFormApi(allData, navigatioToList));
      localStorage.setItem("rules", "[]")
      // dispatch(aEditAllFormListApi(allData))
      // navigate("/checklist/all-form")
    }
  };

  const navigatioToList = (data) => {
    if (data === 200) {
      setTimeout(() => {
        navigate({ pathname: "/checklist/all-form" });
        localStorage.removeItem("selectedSku");
        localStorage.removeItem("inspectionName");
      }, 2000);
    }
  };

  const handleClick = (item) => {
    localStorage.setItem("default", true);
    navigate("/checklist/all-form/add-form/add-question");
  };
  const handleClickEditform = (item, i) => {
    navigate("/questions/add-question-editform");
    localStorage.setItem("addQustionEditData", JSON.stringify(item));
    localStorage.setItem("qustioneditid", i);
    if (title) {
      localStorage.setItem("addQustionTitleName", title);
    }
  };
  useEffect(() => {
    if (questionData)
      localStorage.setItem("qustionDataid", questionData.length);
  }, [questionData]);
  const handleDelete = (index) => {
    const localdata = questionData.filter((item, i) => i !== index);
    setQuestionData(localdata);
    localStorage.setItem("questions", JSON.stringify(localdata));
  };

  const handleViewList = () => {
    // const id = localStorage.getItem("companyId");
    // const userId = localStorage.getItem("userId");
    // const useIdrow = JSON.parse(userId);
    // const _id = useIdrow && useIdrow._id
    // const data = {
    //   url: BASE_URL + `checklists/${_id}?company_id=${id}`,
    // };
    // if (dataList == undefined) {
    //   dispatch(ViewAllFormListApi(data));
    // }
    // dispatch(ViewAllFormListApi(data))
  };
  const handleClickEdit = (item, id) => {
    // dispatch(EditAllFormActionHandler(item))
    localStorage.setItem("editdata", JSON.stringify(item));
    localStorage.setItem("iduser", id);
    const editdata = localStorage.getItem("editdata");
    if (editdata) {
      navigate("/checklist/all-form/add-form/edit-question");
    }
  };
  useEffect(() => {
    localStorage.removeItem("addQustionEditData");
    localStorage.removeItem("qustioneditid");
  }, []);

  useEffect(() => {
    // const category = JSON.parse(localStorage.getItem("categoryData"));
    // setCategoryData(category);
    ListCatagory();
  }, []);

  useEffect(() => {
    setCategoryData(categoryListData);
    if(categoryListData.length && !selectedSku){
      formik.handleChange({
        target: {
          name: "skuName",
          value: categoryListData[0].title
        }
      })
      setSku(categoryListData[0].title);
      localStorage.setItem("selectedSku", categoryListData[0].title);
    }
  }, [categoryListData]);

  const ListCatagory = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `categories?company_id=${id}&moduleName=${"checklist"}&submoduleName=all-forms` };
    dispatch(CategoryListApi(data));
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (e) => {
    const copyListItems = [...questionData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setQuestionData(copyListItems);
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <CreateFormHead
          handleFinalSubmit={handleFinalSubmit}
          title={formik}
          //   listData={listData}
          CreateData="CreateData"
        />
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Container>
            <Grid container spacing={1} sx={{ textAlign: "left", mt: 1 }}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Title</label>
                  <TextField
                    name="title"
                    value={formik?.values?.title || title}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setTitle(e?.target?.value);
                      localStorage.setItem("titleName", e.target.value);
                    }}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    className="date-input"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select SKU</label>
                  <Select
                    size="small"
                    name="skuName"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setSku(e.target.value);
                      localStorage.setItem("selectedSku", e.target.value);
                    }}
                    value={formik.values?.skuName}
                    // defaultValue={trainingdata?.category}
                    sx={{ borderRadius: "8px" }}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "180px",
                        },
                      },
                    }}
                  >
                    {categoryData &&
                      categoryData?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option.title}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Report</label>
                  <Select
                    labelId="mutiple-select-label"
                    sx={{ borderRadius: "8px" }}
                    multiple
                    name="reportselect"
                    size="small"
                    value={selectedReport}
                    onChange={handleChangeReport}
                    renderValue={(selectedReport) =>
                      selectedReport.map((item) => item)?.join(",")
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 0,
                          },
                          height: "180px",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: isAllSelectedReport ? classes.selectedAll : "",
                      }}
                      value="all"
                      name="all"
                      data-button-all
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isAllSelectedReport}
                          indeterminate={
                            selectedReport.length > 0 &&
                            selectedReport.length < reportSelect?.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {reportSelect &&
                      reportSelect.map((option, i) => (
                        <MenuItem key={i} value={option} name={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedReport.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Inspection Category</label>
                  <Select
                    size="small"
                    name="inspectionCategory"
                    value={formik?.values?.inspectionCategory || inspectionCategory}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setInspection(e?.target?.value)
                      localStorage.setItem("inspectionName", e.target.value);
                    }
                    }
                    error={
                      formik.touched.inspectionCategory &&
                      Boolean(formik.errors.inspectionCategory)
                    }
                    helperText={
                      formik.touched.inspectionCategory &&
                      formik.errors.inspectionCategory
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      classes: { paper: classes.select },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },

                      sx: {
                        height: "200px",
                        display: "block",
                      },
                    }}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem disabled value="" className="select-item">
                      Type
                    </MenuItem>
                    {selectedInspecCat &&
                      selectedInspecCat?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      className="label-name"
                      control={
                        <Checkbox
                          type="checkbox"
                          name="repeated"
                          onChange={(e) => {
                            setIsSelfieRequired(e.target.checked);
                            e.target.checked
                              ? localStorage.setItem("isSelfieRequired", "True")
                              : localStorage.removeItem("isSelfieRequired");
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ color: "#196773" }}
                          checked={isSelfieRequired}
                        />
                      }
                      label="Selfie required"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ textAlign: "left", mt: 1 }}>
              <Grid item xs={12}>
                <Typography className="form-head">
                  <b>Form Question</b>
                </Typography>
                <Button className="add-question" onClick={handleClick}>
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Add Question
                </Button>
              </Grid>
            </Grid>
            {questionData &&
              questionData?.map((items, i) => {
                return (
                  <Card sx={{ mb: 2, borderRadius: "8px" }} key={i}>
                    <div
                      style={{ cursor: "grab" }}
                      onDragStart={(e) => dragStart(e, i)}
                      onDragEnter={(e) => dragEnter(e, i)}
                      onDragEnd={drop}
                      draggable
                    >
                      <DehazeIcon />
                    </div>
                    <Box className="align-icon">
                      <Typography
                        variant="p"
                        component="h2"
                        className="top-heading"
                        align="left"
                      >
                        Available Question
                      </Typography>
                    </Box>
                    <CardContent sx={{ color: "#27364b" }}>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Q {i + 1}.
                        </Typography>
                        <Typography
                          variant="p"
                          component="b"
                          style={{ textAlign: "left" }}
                        >
                          {items?.title}
                        </Typography>
                      </Stack>

                      <Box className="align-icon" sx={{ margin: "10px 0px" }}>
                        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                          <Typography variant="p" component="p">
                            Type :
                          </Typography>
                          <Typography variant="p" component="b">
                            {items?.type?.title}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          {/* <Chip
                            avatar={<img src={Edit} alt="Edit" />}
                            onClick={(e) => {
                              handleClickEditform(items, i);
                            }}
                            variant="outlined"
                            className="iconBox"

                            style={{ 
                              cursor: "pointer",
                              width: "33px",
                              height: "33px", 
                            }}
                          /> */}
                          <img
                            src={
                              hover.Edit && indexHover === i ? EditHover : Edit
                            }
                            alt="Edit"
                            onClick={(e) => handleClickEditform(items, i)}
                            onMouseOver={() => {
                              setHover({ Edit: true });
                              setIndexHover(i);
                            }}
                            onMouseLeave={() => {
                              setHover({ Edit: false });
                            }}
                            style={{
                              cursor: "pointer",
                              width: "33px",
                              height: "33px",
                            }}
                          />
                          <img
                            src={
                              hover.Delete && indexHover === i
                                ? DeleteHover
                                : Delete
                            }
                            alt="Delete"
                            onClick={() => handleDelete(i)}
                            onMouseOver={() => {
                              setHover({ Delete: true });
                              setIndexHover(i);
                            }}
                            onMouseLeave={() => {
                              setHover({ Delete: false });
                            }}
                            style={{
                              cursor: "pointer",
                              width: "33px",
                              height: "33px",
                            }}
                          />
                        </Stack>
                      </Box>

                      <Stack
                        direction="row"
                        spacing={1}
                        className="validation-div"
                      >
                        <Typography
                          variant="p"
                          component="b"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Validation :
                        </Typography>
                        {items?.type?.validations?.map((items, i) => {
                          return (
                            <li key={i}>
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "required"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "required" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}

                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "audio"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "audio" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}

                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "image"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "image" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}

                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "video"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "video" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b className="video-validation">{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}

                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "comment"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "comment" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}

                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "comment_required"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "comment_required" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "image_optional"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "image_optional" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "video_optional"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "video_optional" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b className="video-validation">{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                            </li>
                          );
                        })}
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
            <Divider sx={{ mb: 3 }} />
            {/* <Grid container spacing={1} sx={{ textAlign: "left" }}>
              <Grid item xs={12}>
                <Typography className="form-head">
                  <b>KPIs</b>
                </Typography>
                <Stack direction="row" spacing={1} className="Select-kpi">
                  <Button className="add-question">
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Select KPI
                  </Button>
                  <Button className="add-question">Create New KPI</Button>
                  <Button className="add-question">Manage All KPIs</Button>
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3 }} /> */}
          </Container>
          <RulesSelectionQustion
            questionData={questionData}
            setRulesData={setRulesData}
            listallData={listData}
          />
        </Box>
      </form>
    </>
  );
};

export default EditAddAllForm;
