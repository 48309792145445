/** @format */

import React, { useEffect, useState } from "react";
import {
  Stack,
  Tabs,
  Tab,
  Button,
  Card,
  Grid,
  Radio,
  FormControl,
  Modal,
  Box,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { red } from "@mui/material/colors";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { AllChecklistListArchivedApi } from "../../redux/actions/Checklist";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// const downloadAllstyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   height: 500,
//   overflow: "scroll",
//   bgcolor: "background.paper",
//   // border: "2px solid rgb(12 36 49)",
//   borderRadius: "6px",
//   boxShadow: 24,
//   p: 4,
// };

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",            
  maxWidth: 500,          
  height: "90vh",           
  maxHeight: 600,           
  overflowY: "auto",       
  overflowX: "auto",        
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
  display: "flex",        
  flexDirection: "column",  
  '@media (max-width: 600px)': {
      width: "90%",         
      height: "80vh",       
  }
};

const MenuProps = {
  disableScrollLock: true,
};

const TableHeader = (props) => {
  const allCHecklistFilter = localStorage.getItem("allCHecklistFilterData") || "{}";
  const allCHecklistFilterData = JSON.parse(allCHecklistFilter);

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterType, setFilterType] = useState(allCHecklistFilterData.checklistType || 0);
  const [frequency, setFrequency] = useState(allCHecklistFilterData.frequency || 0);

  const paginationRequest = {
    body: {
      pagination: {
        page: props.page,
        per_page: props.rowsPerPage,
      },

      sort: {
        sort_as: "updatedAt",
      },
    },
  };
  const ArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url =
      BASE_URL + `ListArchivedFormsAssignment?company_id=${id}`;
    dispatch(AllChecklistListArchivedApi(paginationRequest));
  };
  useEffect(() => {
    if (props.checkedArchive === true) {
      paginationRequest.body.pagination.page = 1;
      props?.setPage(1);
      ArchivedApi(paginationRequest);
    }
  }, [props.checkedArchive]);
  const handleChangeTabs = (newValue) => {
    setValue(newValue);
  };
  const controlProps = (item) => ({
    checked: props.selectedValue === item,
    onChange: props.handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const modalopenFilterhandleClose = () => {
    const filterObject = {
      checklistType: filterType,
      frequency: frequency,
      search: props.searchValue,
      status: props.selectedValue,
    };

    localStorage.setItem("allCHecklistFilterData", JSON.stringify(filterObject));

    props.handleFilter(filterObject);
    setOpenFilterModal(false);
  };

  const handleFreqChange = (e) => {
    setFrequency(e.target.value);
  }

  return (
    <>
      <Card className="header-card">
        <Grid container sx={{ display: "row" }}>
          <Grid item xs={12} sm={3} md={4} lg={4}>
            {/* <Grid item xs={12} sm={12} md={12} lg={4}>
              <Button variant="text" className="bg-color">
                Active{" "}
                <div
                  style={{
                    width: "25px",
                    height: "20px",
                    backgroundColor: "white",
                    borderRadius: "30%",
                  }}
                ></div>
              </Button>
            </Grid> */}
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                style={{ marginLeft: 5, height: "42px" }}
                label="Active"
                icon={
                  <p className="active-tabs-content">
                    {props?.totalpage?.total_item}
                  </p>
                }
                iconPosition="end"
                className={
                  props.checkedArchive === false
                    ? "active-bg"
                    : "active-tabs tabs-content"
                }
                onClick={() => props.setCheckedArchive(false)}
              />
              <Tab
                label="Archived"
                style={{ marginLeft: 1, height: "42px" }}
                icon={
                  <p className="active-tabs-content">
                    {props.archivedListDatasPaginate?.total_item}
                  </p>
                }
                iconPosition="end"
                className={
                  props?.checkedArchive === true ? "active-bg" : "tabs-content"
                }
                onClick={() => props.setCheckedArchive(true)}
              />
            </Tabs>
          </Grid>
          {/* <Grid item xs={12} sm={4} md={4} lg={4}>
            <Stack
              direction="row"
              className="checkbox"
              // spacing={1}
              paddingLeft={1}
              sx={{ alignItems: "center" }}
            >
              {props?.checkedArchive !== true ? (
                <>
                  <Radio
                    {...controlProps("")}
                    sx={{
                      color: "#039855",
                      "&.Mui-checked": {
                        color: "#039855",
                      },
                      justifyContent: "center",
                    }}
                    color="error"
                  />
                  <span className="radio-text negative-margin">All</span>
                  <Radio
                    className=""
                    {...controlProps("Active")}
                    sx={{
                      color: "#F39A4C",
                      "&.Mui-checked": {
                        color: "#F39A4C",
                      },
                      justifyContent: "center",
                    }}
                  />
                  <span className="negative-margin">Active</span>
                 
                  <Radio
                    {...controlProps("Expire")}
                    sx={{
                      color: "#BB251A",
                      "&.Mui-checked": {
                        color: "#BB251A",
                      },
                      justifyContent: "center",
                    }}
                    color="error"
                  />
                  <span className="radio-text negative-margin">Expired</span>
                </>
              ) : (
                ""
              )}
              
            </Stack>
          </Grid> */}
          <Grid item xs={12} sm={9} md={8} lg={8}>
            <Stack direction="row" className="Assign-Checklist">
              {/* <SearchAppBar
                searchValue={props.searchValue}
                searchOnChange={props.searchOnChange}
                handleKeyDown={props.handleKeyDown}
                sx={{ height: "42px", borderRadius: "8px" }}
              /> */}
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "42px",
                  borderRadius: "8px",
                  marginTop: "3px",
                  padding: "12px 16px",
                }}
                onClick={(e) => setOpenFilterModal(true)}
              >
                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                Filter
              </Button>
              {props?.permission?.checklist?.manageChecklist === true ? (
                <Link
                  to="/checklist/all-checklist/add"
                  className="submenu-item"
                >
                  <Button
                    className="header-add cancle-hover"
                    style={{
                      marginRight: 5,
                      marginTop: "3px",
                      height: "42px",
                      borderRadius: "8px",
                    }}
                  >
                    <AddOutlinedIcon />
                    Assign Checklist
                  </Button>
                  <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>All checklist:</strong>
                            <p>
                            On the Image gallery, we will show images that were captured by users for the checklist questions. 
                            Also, we will display the user name, form title, weightage score, start time, submitted time details, 
                            and user selfie.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            marginRight:"10px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                </Link>
                
              ) : (
                ""
              )}
            </Stack>
          </Grid>
          
        </Grid>
        <Modal
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEscapeKeyDown={false}
        >
          <Box sx={{ ...downloadAllstyle, p: 0 }}>
            <Box 
              sx={{
                position: "sticky",
                right: '15px',
                top: "15px",
                cursor: "pointer",
                textAlign: "right",
                padding: "0px 10px",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Box onClick={() => setOpenFilterModal(false)}>
                <CloseIcon />
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  pt: 2,
                  px: 4,
                }}
              >
                {/* <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Tag</label>

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    className="issueinput-fields"
                    multiline
                    name="tag"
                    // onChange={(e) => setFilterTag(e.target.value)}
                    // value={filterTag}
                  />
                </FormControl> */}

                <Stack direction="row" className="stack">
                  <FormControl
                    fullWidth
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                    }}
                  >
                    <label>Status</label>
                    {/* <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      // value={filterFlag}
                      // onChange={handleFilterFlag}
                      sx={{
                        flexDirection: "column",
                      }}
                    >
                      <FormControlLabel
                        value="like"
                        control={<Radio />}
                        label="Like"
                      />
                      <FormControlLabel
                        value="dislike"
                        control={<Radio />}
                        label="DisLike"
                      />
                      <FormControlLabel
                        value="both"
                        control={<Radio />}
                        label="Both"
                      />
                    </RadioGroup> */}
                    <Stack
                      direction="row"
                      className="checkbox"
                      // spacing={1}
                      // paddingLeft={1}
                      sx={{ alignItems: "center" }}
                    >
                      <Radio
                        {...controlProps("")}
                        sx={{
                          color: "#039855",
                          "&.Mui-checked": {
                            color: "#039855",
                          },
                          justifyContent: "center",
                        }}
                        color="error"
                      />
                      <span className="radio-text negative-margin">All</span>
                      <Radio
                        className=""
                        {...controlProps("Active")}
                        sx={{
                          color: "#F39A4C",
                          "&.Mui-checked": {
                            color: "#F39A4C",
                          },
                          justifyContent: "center",
                        }}
                      />
                      <span className="negative-margin">Active</span>
                      <Radio
                        {...controlProps("Expire")}
                        sx={{
                          color: "#BB251A",
                          "&.Mui-checked": {
                            color: "#BB251A",
                          },
                          justifyContent: "center",
                        }}
                        color="error"
                      />
                      <span className="radio-text negative-margin">
                        Expired
                      </span>
                      <Radio
                        {...controlProps("Disable")}
                        sx={{
                          color: "#505050",
                          "&.Mui-checked": {
                            color: "#505050",
                          },
                          justifyContent: "center",
                        }}
                        color="error"
                      />
                      <span className="radio-text negative-margin">
                        Disable
                      </span>
                    </Stack>
                  </FormControl>
                </Stack>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Search</label>
                  {/* <SearchAppBar
                    searchValue={props.searchValue}
                    searchOnChange={props.searchOnChange}
                    // handleKeyDown={props.handleKeyDown}
                    sx={{ height: "42px", borderRadius: "8px" }}
                  /> */}
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    value={props.searchValue}
                    placeholder="Search Category, User, Form"
                    onChange={(e) => {
                      props.searchOnChange(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Type</label>

                  <Select
                    name="type"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px", marginTop: "10px" }}
                    placeholder="Select type"
                  >
                    <MenuItem value={0}>Select type</MenuItem>
                    <MenuItem value={1}>Checklist</MenuItem>
                    <MenuItem value={2}>Audit</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Frequency</label>
                  <Select
                    name="frequency"
                    sx={{ borderRadius: "8px", textAlign: 'left', marginTop: "10px" }}
                    value={frequency || 0}
                    onChange={handleFreqChange}
                    helperText="select frequency"
                    size="small"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={0}>All Frequency</MenuItem>
                    <MenuItem value={1}>No Frequency</MenuItem>
                    <MenuItem value={2}>Hourly</MenuItem>
                    <MenuItem value={3}>Daily</MenuItem>
                    <MenuItem value={4}>Weekly</MenuItem>
                    <MenuItem value={5}>Monthly </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                position: "fixed",
                bottom: 0,
                width: "100%",
                gap: "30px",
                px: 3,
                pt: 1,
                pb: 2,
                background: "#fff",
              }}
            >
              <Button
                className="cancle-button cancle-hover"
                style={{
                  height: "46px",
                  padding: "12px 16px",
                  width: "47%",
                  background: "#fff",
                }}
                onClick={() => {
                  setFilterType(0);
                  props.handleChange({target: {value : "" }});
                  props.searchOnChange("");
                  setFrequency(0);
                  // setFilterTag("");
                  // setFilterFlag("");
                  // setFilterFormTitle("");
                  // setFilterRating("");
                }}
              >
                Clear
              </Button>
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "46px",
                  padding: "12px 16px",
                  width: "47%",
                }}
                onClick={(e) => {
                  modalopenFilterhandleClose();
                  // setOpenFilterModal(false);
                  // handleFilter();
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Modal>
      </Card>
    </>
  );
};

export default TableHeader;
