/** @format */

import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  Chip,
  TableBody,
  TableRow,
  tableCellClasses,
  TableCell,
  Container,
  Box,
  Card,
  Typography,
  CardContent,
  Grid,
  TextField,
  Button,
  Divider,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Edit from "../../images/Edit.svg";
import Editicon from "../../../assets/icons/svg-icons/Edit.svg";
import Deleteicon from "../../../assets/icons/svg-icons/Delete.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import {
  AddSubCategotyApi,
  CompanyCategoryListApi,
  CreateCompanyCatApi,
  DeleteCategoryApi,
  ModuleListListApi,
  SubModuleListsListApi,
} from "../../redux/actions/DataManager";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import Progressbar from "../../GlobalProgress/Progressbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import EditCategoryModel from "./pages/CategoryModal";
import EditSubCategoryModel from "./pages/SubCategoryModal";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CompanyCategories = () => {
  const dispatch = useDispatch();
  const [uiRender, setUiRender] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [newFieldValue, setNewFieldValue] = useState();
  const [subCategoryValue, setSubCategoryValue] = useState({});
  const [open, setOpen] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const [subCategory, setSubCategory] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [module, setModule] = useState([]);
  const [subModule, setSubModule] = useState([]);
  const [selectedmodule, setSelectedModule] = useState([]);
  const [selectedsubModule, setSelectedSubModule] = useState([]);

  const companyDataList = useSelector(
    (state) =>
      state &&
      state.apidataManager &&
      state.apidataManager.companylist &&
      state.apidataManager.companylist.data &&
      state.apidataManager.companylist.data.data
  );
  const modulesList = useSelector(
    (state) =>
      state &&
      state.apidataManager &&
      state.apidataManager.modulesList &&
      state.apidataManager.modulesList.data &&
      state.apidataManager.modulesList.data.data
  );
  const subModulesList = useSelector(
    (state) =>
      state &&
      state.apidataManager &&
      state.apidataManager.subModulesList &&
      state.apidataManager.subModulesList.data &&
      state.apidataManager.subModulesList.data.data
  );

  // console.log("modulesList", modulesList);
  // console.log("subModulesList", subModulesList);
  const Loader = useSelector(
    (state) => state && state.apidataManager && state.apidataManager.loading
  );

  const subcategoryPayload = {
    body: {
      title: categoryTitle,
      categoryId: categoryId,
    },
  };

  useEffect(() => {
    setLoader(true);
    CompanyCategoryList();
    getAllModules();
    getSubModules();
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setLoader(true);
    if (companyDataList) {
      localStorage.setItem("categoryData", JSON.stringify(companyDataList));
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [companyDataList]);

  useEffect(() => {
    setModule(modulesList);
  }, [modulesList])

  // useEffect(() => {
  //   setSubModule(subModulesList);
  // }, [subModulesList])

  useEffect(() => {
    if(selectedmodule?._id){
      const getSubModule = subModulesList.filter((item)=> item.companyModule === selectedmodule?._id)
      setSubModule(getSubModule);
      setSelectedSubModule([]);
    }
  }, [selectedmodule])

  useEffect(() => {
    setLoader(true);
    setCompanyList(companyDataList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [companyDataList]);

  const CompanyCategoryList = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `categories?company_id=${id}` };
    dispatch(CompanyCategoryListApi(data));
  };
  const handleClickEdit = (item) => {
    setNewFieldValue(item);
    setOpen(true);
  };
  const handleEditSubcategory = (item) => {
    setSubCategoryValue(item);
    setOpenSub(true);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      moduleName: "",
      submoduleName: ""
    },
    onSubmit: (values, { resetForm }) => {
      if(!values.title){
        toast.error("Title is required!")
        return
      }
      const formData = {
        title: values.title,
        subCategories: [],
        "moduleName": selectedmodule?.companyModuleSlug || "",
        "submoduleName": selectedsubModule?.companySubModuleSlug || ""
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `categories?company_id=${id}`;
        dispatch(CreateCompanyCatApi(allData));
        setTimeout(() => {
          CompanyCategoryList();
        }, 500);
        setUiRender(!uiRender);
      }
      formik.resetForm();
      setSelectedModule("");
      setSelectedSubModule("");
    },
  });

  const handleAddCategories = (id) => {
    setSubCategory(true);
    setCategoryId(id);
  };

  const cancleCategory = (e) => {
    setSubCategory(false);
    setCategoryTitle("");
  };

  const subCategoryApi = () => {
    setCategoryTitle("");
    const id = localStorage.getItem("companyId");
    subcategoryPayload.url = BASE_URL + `subCategories?company_id=${id}`;
    dispatch(AddSubCategotyApi(subcategoryPayload));
    setSubCategory(false);
    setTimeout(() => {
      CompanyCategoryList();
    }, 500);
    setUiRender(!uiRender);
  };

  const handleDelete = (categoryid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = categoryid;
            const data = {
              url: BASE_URL + `categories/${userRoleId}?company_id=${id}`,
            };
            dispatch(DeleteCategoryApi(data));
            setTimeout(() => {
              CompanyCategoryList();
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleDeleteSubcategory = (subcategoryid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = subcategoryid;
            const data = {
              url: BASE_URL + `subCategories/${userRoleId}?company_id=${id}`,
            };
            dispatch(DeleteCategoryApi(data));
            setTimeout(() => {
              CompanyCategoryList();
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSub = () => {
    setOpenSub(false);
  };

  const getAllModules = () => {
    const data = { url: BASE_URL + `companyModule/getAll` };
    dispatch(ModuleListListApi(data));
  };

  const getSubModules = () => {
    const data = { url: BASE_URL + `companySubModule/getAllSubModule` };
    dispatch(SubModuleListsListApi(data));
  };

  const handleModuleChange = (event, newValue) => {
    if (newValue) {
      setSelectedModule(newValue);
    }
  };

  const handleSubModuleChange = (event, newValue) => {
    if (newValue) {
      setSelectedSubModule(newValue);
    }
  };

  return (
    <Box>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <>
          <ToastContainer autoClose={3000} />
          <div style={{ float: "right", marginRight: "8px", marginTop: "8px" }}>
            <Tooltip
              title={
                <div style={{ maxWidth: "300px" }}>
                  <strong>Advance Checklist Wise Report:</strong>
                  <p>
                    Status: Display's selected checklist completion
                    status, completion percentage & average compliance
                    scores for the selected user and stores.
                  </p>
                  <p>
                    Percentage: Display's selected checklist
                    completion percentage & average compliance scores
                    for the selected user and stores.
                  </p>
                </div>
              }
              arrow
              interactive
            >
              <InfoIcon
                style={{
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: "#D76C00",
                }}
              />
            </Tooltip>
          </div>

          <Container
            maxWidth="xl"
            sx={{ bgcolor: "#F6F8FC", p: 1, pb: 10, borderRadius: 2 }}
            className="header-card"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card sx={{ height: "100%", p: 3, borderRadius: "8px" }}>
                  <CardContent className="zero-space">
                    <Typography
                      variant="p"
                      component="h3"
                      align="left"
                      sx={{ mb: 1 }}
                    >
                      Add Category
                    </Typography>
                    <form onSubmit={formik.handleSubmit} style={{
                      textAlign: "left"
                    }}>
                      <label style={{
                        marginTop: "10px"
                      }}>Category Title</label>
                      <TextField
                        fullWidth
                        size="small"
                        className="issueinput-fields"
                        id="fullWidth"
                        placeholder="Category Title"
                        name="title"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <div style={{
                        marginTop: "10px"
                      }}>
                        <label>Module Name</label>
                        <Autocomplete
                          options={
                            module && module.length > 0
                              ? module
                              : []
                          }
                          getOptionLabel={(option) => option?.title || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              size="small"
                              sx={{ borderRadius: "8px" }}
                              onBlur={() => {
                                if (!params.inputProps.value) {
                                  // handleClearSelection();
                                }
                              }}
                            />
                          )}
                          style={{
                            marginTop: "10px"
                          }}
                          value={selectedmodule}
                          onChange={handleModuleChange}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              {option.title}
                            </li>
                          )}
                          placeholder="Module Name"
                          clearable
                        // onClear={handleClearSelection}
                        />
                      </div>
                      <div style={{
                        marginTop: "10px"
                      }}>
                        <label>Sub-Module Name</label>
                        <Autocomplete
                          options={
                            subModule && subModule.length > 0
                              ? subModule
                              : []
                          }
                          style={{
                            marginTop: "10px"
                          }}
                          getOptionLabel={(option) => option?.title || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              size="small"
                              sx={{ borderRadius: "8px" }}
                              onBlur={() => {
                                if (!params.inputProps.value) {
                                  // handleClearSelection();
                                }
                              }}
                            />
                          )}
                          value={selectedsubModule}
                          onChange={handleSubModuleChange}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              {option.title}
                            </li>
                          )}
                          clearable
                          placeholder="Sub-Module Name"
                        // onClear={handleClearSelection}
                        />
                      </div>
                      <Box sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center"
                      }}>
                        <Button
                          variant="contained"
                          color="warning"
                          size="medium"
                          type="submit"
                          sx={{ mt: "10px", borderRadius: "8px" }}
                        >
                          Create
                        </Button>
                      </Box>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "start", my: 2, fontWeight: "bold" }}
            >
              Category List
            </Typography>
            {Loader ? (
              <Progressbar />
            ) : (
              <Grid container spacing={2}>
                {companyList &&
                  companyList.map((item, i) => {
                    return (
                      <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                        <Card
                          className="card card-list"
                          sx={{ mt: 2, height: "100%" }}
                        >
                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid item xs={9} sm={9} md={9} lg={9}>
                                <Typography
                                  variant="p"
                                  component="h3"
                                  align="left"
                                >
                                  {item?.title}
                                </Typography>
                                <Typography
                                  variant="p"
                                  component="p"
                                  align="left"
                                >
                                  {moment(item?.updatedAt).format(
                                    "MMMM D, YYYY LTS"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3}>
                                <Tooltip title="Edit">
                                  <img
                                    onClick={() => handleClickEdit(item)}
                                    src={
                                      hover.Edit && indexHover === item?.id
                                        ? EditHover
                                        : Edit
                                    }
                                    alt="Edit"
                                    style={{
                                      height: "35px",
                                      cursor: "pointer",
                                      margin: "0px 3px 0px 3px",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ Edit: true });
                                      setIndexHover(item?.id);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ Edit: false });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <img
                                    src={
                                      hover.Delete && indexHover === item?.id
                                        ? DeleteHover
                                        : Delete
                                    }
                                    alt="Delete"
                                    style={{
                                      height: "35px",
                                      cursor: "pointer",
                                      margin: "0px 3px 0px 3px",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ Delete: true });
                                      setIndexHover(item?.id);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ Delete: false });
                                    }}
                                    onClick={() => handleDelete(item.id)}
                                  />
                                </Tooltip>
                                {/* <Chip avatar={<img src={Edit} onClick={() => handleClickEdit(item)} alt="Edit" />} variant="outlined" className=' edit-hover' /> */}
                                {/* <Chip avatar={<img src={Delete} alt="Delete" onClick={() => handleDelete(item.id)} />} variant="outlined" className=' delete-hover' /> */}
                              </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            <Grid
                              container
                              spacing={0}
                              className="max-width"
                              sx={{ alignItems: "center" }}
                              mb={2}
                            >
                              <Grid item xs={8} sm={6} md={6} lg={8}>
                                <Typography
                                  variant="p"
                                  component="h4"
                                  align="left"
                                >
                                  Sub Categories
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={6}
                                md={6}
                                lg={4}
                                className="text-content"
                              >
                                {/* <Button
                              variant="contained"
                              sx={{
                                background: "#196773",
                                borderRadius: "8px",
                              }}
                              size="small"
                              className="cancle-hover"
                            >
                              <AddIcon
                                fontSize="small"
                                onClick={() => handleAddCategories(item.id)}
                              />
                              Add
                            </Button> */}
                                <Button
                                  variant="contained"
                                  sx={{
                                    background: "#196773",
                                    borderRadius: "8px",
                                  }}
                                  size="small"
                                  className="cancle-hover"
                                  onClick={() => handleAddCategories(item.id)}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <AddIcon fontSize="small" />
                                    Add
                                  </span>
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                {subCategory === true &&
                                  item.id === categoryId ? (
                                  <Box>
                                    <TextField
                                      sx={{ mb: 2 }}
                                      fullWidth
                                      size="small"
                                      id="fullWidth"
                                      className="issueinput-fields"
                                      placeholder="Add sub-category"
                                      name="categorytitle"
                                      onChange={(e) =>
                                        setCategoryTitle(e.target.value)
                                      }
                                      value={categoryTitle}
                                    />
                                    <div style={{ textAlign: "left" }}>
                                      <Button
                                        variant="contained"
                                        className="cancle-hover"
                                        sx={{
                                          background: "#196773",
                                          mr: 2,
                                          borderRadius: "8px",
                                        }}
                                        onClick={subCategoryApi}
                                        size="small"
                                      >
                                        <AddIcon fontSize="small" />
                                        Save
                                      </Button>
                                      <Button
                                        variant="contained"
                                        className="cancle-hover"
                                        sx={{
                                          background: "#196773",
                                          borderRadius: "8px",
                                        }}
                                        onClick={() => cancleCategory()}
                                        size="small"
                                      >
                                        <AddIcon fontSize="small" />
                                        Cancel
                                      </Button>
                                    </div>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                            <TableContainer>
                              <Table
                                sx={{
                                  [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                  },
                                }}
                              >
                                <TableBody>
                                  {item?.subCategories?.map((subitem, i) => {
                                    return (
                                      <TableRow
                                        key={i}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell>{subitem.title}</TableCell>
                                        <TableCell align="left">
                                          {moment(subitem?.updatedAt).format(
                                            "MMMM D, YYYY LTS"
                                          )}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          sx={{ display: "flex", gap: "5px" }}
                                        >
                                          <Tooltip title="Delete">
                                            <img
                                              src={Deleteicon}
                                              alt="Delete"
                                              className="delete-hover"
                                              onClick={() =>
                                                handleDeleteSubcategory(
                                                  subitem.id
                                                )
                                              }
                                            />
                                          </Tooltip>
                                          <Tooltip title="Edit">
                                            <img
                                              src={Editicon}
                                              alt="Edit"
                                              className="edit-hover"
                                              onClick={() =>
                                                handleEditSubcategory(subitem)
                                              }
                                            />
                                          </Tooltip>
                                          {/* <Chip avatar={<img src={Delete} alt="Delete" onClick={() => handleDeleteSubcategory(subitem.id)} />} variant="outlined" className='iconBox delete-hover' /> */}
                                          {/* <Chip avatar={<img src={Edit} alt="Edit" onClick={() => handleEditSubcategory(subitem)} />} variant="outlined" className='iconBox edit-hover' /> */}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Container>
          {/* EditCategoryModel */}
          {open && <EditCategoryModel
            open={open}
            handleClose={handleClose}
            editvalue={newFieldValue}
            listapiupdate={CompanyCategoryList}
          />}
          <EditSubCategoryModel
            open={openSub}
            handleClose={handleCloseSub}
            editvalue={subCategoryValue}
            listapiupdate={CompanyCategoryList}
          />
        </>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default CompanyCategories;
