import { Box, Button, FormControl, Grid, Modal, Stack, TextField, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment/moment";

// const filterAllstyle = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 500,
//     height: 600,
//     overflow: "scroll",
//     bgcolor: "background.paper",
//     // border: "2px solid rgb(12 36 49)",
//     borderRadius: "6px",
//     boxShadow: 24,
//     p: 4,
// };

const filterAllstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",            
    maxWidth: 500,          
    height: "90vh",           
    maxHeight: 600,           
    overflowY: "auto",       
    overflowX: "auto",        
    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    p: 4,
    display: "flex",        
    flexDirection: "column",  
    '@media (max-width: 600px)': {
        width: "90%",         
        height: "80vh",       
    }
};




const MenuProps = {
    disableScrollLock: true,
};

const LogHeader = (props) => {
    const { onFilter } = props;

    const [openFilterModal, setOpenFilterModal] = useState(false);

    const [title, setTitle] = useState("");
    // const [uniqueSlug, setUniqueSlug] = useState("");
    // const [start_date, setstart_date] = useState(
    //     moment(new Date()).format("YYYY-MM-DD")
    // );
    // const [end_date, setend_date] = useState(
    //     moment(new Date()).format("YYYY-MM-DD")
    // );

    const modalopenFilterhandleClose = () => {
        setOpenFilterModal(false);
    }

    const handleFilter = () => {
        const searchData = {
            search: title,
            // uniqueSlug,
            // start_date,
            // end_date,
        };

        onFilter(searchData); // Send the data to the parent component
    };
    const handleNewCreate = () => {
        localStorage.setItem("addLogEntry", "");
        localStorage.setItem("questions", "");
        localStorage.setItem("userId", "");
    }

    return (
        <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
            className="header-card"
        >
            <Grid container spacing={1} alignItems="center" sx={{
                padding: "0px 8px"
            }}>
                <Grid item xs={12} lg={7} md={4} sm={12} alignItems="left">
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="left"
                        justifyContent="flex-start"
                    >
                        <Stack direction="row" alignItems="left">
                            <Button
                                className="header-add cancle-hover"
                                style={{ height: "42px", borderRadius: "8px", padding: "12px 16px" }}
                                onClick={(e) => setOpenFilterModal(true)}
                            >
                                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                                Filter
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={5} md={8} sm={12}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Stack direction="row" alignItems="center">
                            <Link
                                to="/log-entry/create"
                                // state={props.submissionCancle}
                                className="submenu-item"
                            >
                                <Button
                                    className="header-add cancle-hover"
                                    sx={{ whiteSpace: "nowrap", ml: 1, height: "42px", borderRadius: "8px" }}
                                    onClick={() => handleNewCreate()}
                                >
                                    <AddOutlinedIcon sx={{ mr: 1 }} />
                                    Add Log Entries
                                </Button>
                            </Link>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Modal
                open={openFilterModal}
                onClose={modalopenFilterhandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={false}
            >
                <Box sx={{ ...filterAllstyle, p: 0 }}>
                    <Box
                        sx={{
                            position: "sticky",
                            right: "15px",
                            top: "15px",
                            cursor: "pointer",
                            textAlign: "right",
                            padding: "0px 10px",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Box onClick={() => setOpenFilterModal(false)}>
                            <CloseIcon />
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                pt: 2,
                                px: 4,
                            }}
                        >
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Search</label>
                                <TextField
                                    size="small"
                                    name="Search"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    MenuProps={MenuProps}
                                    sx={{
                                        borderRadius: "8px",
                                        textAlign: "left",
                                        marginTop: "10px",
                                    }}
                                ></TextField>
                            </FormControl>
                            {/* <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Log Entry Token</label>
                                <TextField
                                    size="small"
                                    fullWidth
                                    id="outlined-start-adornment"
                                    value={uniqueSlug}
                                    onChange={(e) => setUniqueSlug(e.target.value)}
                                    MenuProps={MenuProps}
                                    sx={{
                                        borderRadius: "8px",
                                        textAlign: "left",
                                        marginTop: "10px",
                                    }}
                                />
                            </FormControl> */}
                            {/* <label>Date</label>
                            <Box
                                sx={{ mb: 2 }}
                                display={"flex"}
                                justifyContent={"space-between"}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="DD/MM/YYYY"
                                        size="small"
                                        value={start_date}
                                        onChange={(newValue) => {
                                            let dateSort = moment(newValue?.$d)
                                                .format("YYYY-MM-DD")
                                                .split("/")
                                                .join("");
                                            setstart_date(dateSort);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        className="date-pick date-pick-submision"
                                        maxDate={new Date()}
                                    />
                                </LocalizationProvider>
                                <Typography className="date-to">to</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        // label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        size="small"
                                        value={end_date}
                                        onChange={(newValue) => {
                                            let dateSort = moment(newValue?.$d)
                                                .format("YYYY-MM-DD")
                                                .split("/")
                                                .join("");
                                            setend_date(dateSort);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        className="date-pick date-pick-submision"
                                        maxDate={new Date()}
                                        minDate={start_date}
                                    />
                                </LocalizationProvider>
                            </Box> */}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            position: "fixed",
                            bottom: 25,
                            width: "100%",
                            gap: "30px",
                            px: 3,
                            pt: 1,
                            pb: 2,
                            background: "#fff",
                        }}
                    >
                        <Button
                            className="cancle-button cancle-hover"
                            style={{
                                height: "46px",
                                padding: "12px 16px",
                                width: "47%",
                                background: "#fff",
                            }}
                            onClick={() => {

                                // const dates = {
                                //     start_date: moment(new Date()).format("YYYY-MM-DD"),
                                //     end_date: moment(new Date()).format("YYYY-MM-DD"),
                                // };
                                // setstart_date(dates.start_date);
                                // setend_date(dates.end_date);
                                setTitle("");
                                // setUniqueSlug("");
                                // const searchData = {
                                //     start_date: dates.start_date,
                                //     end_date: dates.end_date,
                                // };
                                // onFilter(searchData);
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            className="header-add cancle-hover"
                            style={{
                                height: "46px",
                                padding: "12px 16px",
                                width: "47%",
                            }}
                            onClick={() => {
                                modalopenFilterhandleClose();
                                handleFilter();
                            }}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
};

export default LogHeader;