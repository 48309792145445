/** @format */

import React, { useState, useEffect } from "react";
import Header from "./pages/Header";
import { Box, IconButton, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditSchedular from "./pages/EditSchedular";
import { styled } from "@mui/material/styles";
import View from "../images/View.svg";
import ViewHover from "../images/ViewHover.svg";
import Edit from "../images/Edit.svg";
import Delete from "../images/Delete.svg";
import DeleteHover from "../images/DeleteHover.svg";
import EditHover from "../images/EditHover.svg";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  DeleteSchedularApi,
  ListSchedularApi,
} from "../redux/actions/SchedularAction";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer } from "react-toastify";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
import SchecularToggel from "./pages/SchedularToggel";
import { useNavigate } from "react-router-dom";

const Schedular = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scheduleData, setSchedularData] = useState([]);
  const [subroleList, setSubroleList] = useState([]);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [editvalue, setEditvalue] = useState({});
  const [mapValue, setMapValue] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [loader, setLoader] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });
  const [permission, setPermission] = useState({});

  const schedularListSelector = useSelector(
    (state) =>
      state.scheduler &&
      state.scheduler.schedularList &&
      state.scheduler.schedularList.data &&
      state.scheduler.schedularList.data.data &&
      state.scheduler.schedularList.data.data?.dynamicJob
  );
  const deleteSchedularRes = useSelector(
    (state) =>
      state?.scheduler &&
      state?.scheduler?.schedularDelete &&
      state?.scheduler?.schedularDelete?.data &&
      state?.scheduler?.schedularDelete?.data?.data
  );

  const createSchedularRes = useSelector(
    (state) =>
      state?.scheduler &&
      state?.scheduler?.schedularAdd &&
      state?.scheduler?.schedularAdd?.data &&
      state?.scheduler?.schedularAdd?.data?.data
  );

  const editSchedularRes = useSelector(
    (state) =>
      state?.scheduler &&
      state?.scheduler?.schedularEdit &&
      state?.scheduler?.schedularEdit?.data &&
      state?.scheduler?.schedularEdit?.data?.data
  );

  const totalitems = useSelector(
    (state) =>
      state.scheduler &&
      state.scheduler.schedularList &&
      state.scheduler.schedularList.data &&
      state.scheduler.schedularList.data.data &&
      state.scheduler.schedularList.data.data.paginate
  );

  const subroleData = useSelector(
    (state) =>
      state.roles &&
      state.roles.subrole &&
      state.roles.subrole.data &&
      state.roles.subrole.data.data
  );

  const Loader = useSelector(
    (state) => state.schedular && state.schedular.loading
  );

  const handleViewDetails = (row) => {
    navigate("/scheduler/details", { state: { rowData: row } });
  };
  
  

  const getFrequencyText = (frequency) => {
    switch (frequency) {
      case 1:
        return "One Time";
      case 2:
        return "Daily At";
      case 3:
        return "Weekly";
      case 4:
        return "Monthly On";
      default:
        return "N/A";
    }
  };

  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);
  useEffect(() => {
    if (searchValue == "") {
      var localPageno = localStorage.getItem("pageno");
      payloadRequest.body.pagination.page = localPageno
        ? parseInt(localPageno)
        : 1;
      setLoader(true);
      listSchedular(payloadRequest);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [searchValue]);

  useEffect(() => {
    listSchedular(payloadRequest);
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    listSchedular(payloadRequest);
  }, [createSchedularRes, editSchedularRes, deleteSchedularRes]);

  useEffect(() => {
    if (schedularListSelector) {
      setSchedularData(schedularListSelector);
      setTotalCount(totalitems?.total_item);
    }
  }, [schedularListSelector]);

  useEffect(() => {
    setSubroleList(subroleData);
  }, [subroleData]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      payloadRequest.body.search = searchValue;
      payloadRequest.body.pagination.page = 1;
      setPage(1);
      listSchedular(payloadRequest);
    }
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    payloadRequest.body.search = searchValue;
    payloadRequest.body.pagination.page = newPage + 1;
    listSchedular(payloadRequest);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.search = searchValue;
    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.pagination.page = 1;
    setLoader(true);
    listSchedular(payloadRequest);
    setTimeout(() => {
      listSchedular(payloadRequest);
      setLoader(false);
    }, 500);
  };

  const listSchedular = (payloadRequest) => {
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `DynamicJobList?company_id=${id}`;
    dispatch(ListSchedularApi(payloadRequest));
  };

  const handleEdit = (row) => {
    setEditvalue(row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete it.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const _id = localStorage.getItem("companyId");
            const data = {
              url: BASE_URL + `DynamicJob/${id}`,
            };
            setLoader(true);
            dispatch(DeleteSchedularApi(data));
            setTimeout(() => {
              listSchedular(payloadRequest);
              setLoader(false);
            }, 500);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          scheduleData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }}
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Created By",
      headerName: "Created By",
      renderHeader: () => <strong>{"Created By"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.createdBy?.name) {
          result.push(params?.row?.createdBy?.name);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const user = params?.row?.createdBy?.name || "N/A";
        const truncatedUser =
          user.length > 10 ? `${user.substring(0, 10)}...` : user;
        return (
          <Tooltip title={user} arrow>
            <div style={{ maxWidth: 200, overflow: "hidden" }}>
              {truncatedUser}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Updated By",
      headerName: "Updated By",
      renderHeader: () => <strong>{"Updated By"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.updatedBy?.name) {
          result.push(params?.row?.updatedBy?.name);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const user = params?.row?.updatedBy?.name || "N/A";
        const truncatedUser =
          user.length > 10 ? `${user.substring(0, 10)}...` : user;
        return (
          <Tooltip title={user} arrow>
            <div style={{ maxWidth: 200, overflow: "hidden" }}>
              {truncatedUser}
            </div>
          </Tooltip>
        );
      },
    },

    {
      field: "Title",
      headerName: "Title",
      renderHeader: () => <strong>{"Title"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const title = params?.row?.title || "N/A";
        const truncatedTitle =
          title.length > 10 ? `${title.substring(0, 10)}...` : title;
        return (
          <Tooltip title={title} arrow>
            <div style={{ maxWidth: 200, overflow: "hidden" }}>
              {truncatedTitle}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Job Type",
      headerName: "Job Type",
      renderHeader: () => <strong>{"Job Type"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.type) {
          result.push(params?.row?.type);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const jobType = params?.row?.type || "N/A";
        const truncatedJobType =
          jobType.length > 10 ? `${jobType.substring(0, 10)}...` : jobType;
        return (
          <Tooltip title={jobType} arrow>
            <div style={{ maxWidth: 200, overflow: "hidden" }}>
              {truncatedJobType}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Task",
      headerName: "Task",
      renderHeader: () => <strong>{"Task"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.task) {
          result.push(params?.row?.task);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const taskType = params?.row?.task || "N/A";
        const truncatedJobType =
          taskType.length > 10 ? `${taskType.substring(0, 6)}...` : taskType;
        return (
          <Tooltip title={taskType} arrow>
            <div style={{ maxWidth: 200, overflow: "hidden" }}>
              {truncatedJobType}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Frequency",
      headerName: "Frequency",
      renderHeader: () => <strong>{"Frequency"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.frequency;
      },
      renderCell: (params) => {
        const frequency = params?.row?.frequency;
        const fullFrequencyText = getFrequencyText(frequency);
        const truncatedFrequencyText =
          fullFrequencyText.length > 10
            ? `${fullFrequencyText.substring(0, 10)}...`
            : fullFrequencyText;

        return (
          <Tooltip title={fullFrequencyText} arrow>
            <div style={{ maxWidth: 200, overflow: "hidden" }}>
              {truncatedFrequencyText}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      renderHeader: () => <strong>{"Created At"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 2,
      valueGetter: (params) => {
        const timeFormate = moment(params?.row?.createdAt).format("LTS");
        const dateFormate = moment(params?.row?.createdAt).format(
          "MMMM D, YYYY"
        );
        const result = [];
        if (timeFormate && dateFormate) {
          result.push(dateFormate, timeFormate);
        } else {
          result.push("N/A");
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const createdAtText = params?.row?.createdAt
          ? moment(params.row.createdAt).format("MMMM D, YYYY LTS")
          : "N/A";
        const truncatedText =
          createdAtText.length > 12
            ? `${createdAtText.substring(0, 12)}...`
            : createdAtText;

        return (
          <Tooltip title={createdAtText} arrow>
            <div style={{ maxWidth: 150, overflow: "hidden" }}>
              {truncatedText}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Updated At",
      headerName: "Updated At",
      align: "center",
      renderHeader: () => <strong>{"Updated At"}</strong>,
      headerAlign: "center",
      flex: 2,
      valueGetter: (params) => {
        const timeFormate = moment(params?.row?.updatedAt).format("LTS");
        const dateFormate = moment(params?.row?.updatedAt).format(
          "MMMM D, YYYY"
        );
        const result = [];
        if (timeFormate && dateFormate) {
          result.push(dateFormate, timeFormate);
        } else {
          result.push("N/A");
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const updatedAtText = params?.row?.updatedAt
          ? moment(params.row.updatedAt).format("MMMM D, YYYY LTS")
          : "N/A";
        const truncatedText =
          updatedAtText.length > 12
            ? `${updatedAtText.substring(0, 12)}...`
            : updatedAtText;

        return (
          <Tooltip title={updatedAtText} arrow>
            <div style={{ maxWidth: 150, overflow: "hidden" }}>
              {truncatedText}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Time",
      headerName: "Time",
      renderHeader: () => <strong>{"Time"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        const time = params?.row?.run_at;
        const dateFormate = moment(params?.row?.run_at).format("MMMM D, YYYY");
        const result = [];
        if (time && dateFormate) {
          result.push(dateFormate, time);
        } else {
          result.push("N/A");
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const scheduledTimeText = params?.row?.run_at
          ? `${params.row.run_at}`
          : "N/A";

        const truncatedText =
          scheduledTimeText.length > 12
            ? `${scheduledTimeText.substring(0, 12)}...`
            : scheduledTimeText;

        return (
          <Tooltip title={scheduledTimeText} arrow>
            <div style={{ maxWidth: 150, overflow: "hidden" }}>
              {truncatedText}
            </div>
          </Tooltip>
        );
      },
    },

    {
      field: "Status",
      headerName: "Status",
      renderHeader: () => <strong>{"Status"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <SchecularToggel row={params.row} />,
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      width: 140,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const isEditDisabled = true;
        const isViewDisabled = false;
    
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {permission?.roles?.manageRole === true ? (
              <>
                <Tooltip
                  title={
                    isViewDisabled
                      ? "View is not allowed as of now, , please contact the administrator for more information."
                      : ""
                  }
                >
                  <span>
                    <img
                      onClick={() => !isViewDisabled && handleViewDetails(params?.row)}
                      src={
                        hover.View && indexHover === params?.row?._id
                          ? ViewHover
                          : View
                      }
                      alt="VIEW"
                      style={{
                        height: "35px",
                        cursor: isViewDisabled ? "not-allowed" : "pointer",
                        opacity: isViewDisabled ? 0.5 : 1,
                        margin: "0px 3px 3px 3px",
                      }}
                      onMouseOver={() => {
                        if (!isViewDisabled) {
                          setHover({ View: true });
                          setIndexHover(params?.row?._id);
                        }
                      }}
                      onMouseLeave={() => {
                        if (!isViewDisabled) {
                          setHover({ View: false });
                        }
                      }}
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    isEditDisabled
                      ? "Edit is not allowed as of now, please contact the administrator for more information."
                      : ""
                  }
                >
                  <span>
                    <img
                      onClick={() => !isEditDisabled && handleEdit(params?.row)}
                      src={
                        hover.Edit && indexHover === params?.row?._id
                          ? EditHover
                          : Edit
                      }
                      alt="Edit"
                      style={{
                        height: "35px",
                        cursor: isEditDisabled ? "not-allowed" : "pointer",
                        opacity: isEditDisabled ? 0.5 : 1,
                        margin: "0px 3px 0px 3px",
                      }}
                      onMouseOver={() => {
                        if (!isEditDisabled) {
                          setHover({ Edit: true });
                          setIndexHover(params?.row?._id);
                        }
                      }}
                      onMouseLeave={() => {
                        if (!isEditDisabled) {
                          setHover({ Edit: false });
                        }
                      }}
                    />
                  </span>
                </Tooltip>
    
                <img
                  onClick={() => handleDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
              </>
            ) : (
              <b>Access Denied</b>
            )}
          </div>
        );
      },
    }
    
  ];

  return (
    <Box>
      <Header
        data={payloadRequest}
        searchOnChange={handleSearch}
        searchValue={searchValue}
        handleKeyDown={handleKeyDown}
        permission={permission}
      />
      <ToastContainer autoClose={3000} />
      <EditSchedular
        open={open}
        handleClose={handleClose}
        editvalue={editvalue}
      />
      <SingleGlobalTable
        data={scheduleData}
        columns={columns}
        totalCount={totalCount}
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
};

export default Schedular;
