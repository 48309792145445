/** @format */

import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Tooltip from "./ToolTip";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateFormHead from "./createFormTabs/CreateFormHead";
import RuleSection from "./createFormTabs/RuleSection";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import CustomChip from "./CustomChip";
import {
  CreateFormApi,
  ViewAllFormListApi,
  EditAllFormListApi,
  EditAllFormActionHandler,
  CategoryListApi,
} from "../../redux/actions/Checklist";
import { ToastContainer } from "react-toastify";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";

import {
  allFormData,
  allFormsUpdateData,
  allformDeletes,
  AllFormUpdateQuestionSuccess,
} from "../../redux/actions/AllformStore";
import { add } from "date-fns";
import { UserInspectionApi, UserSubRoleApi } from "../../redux/actions/User";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "16px !important",
  },
  addnewqustions: {
    background: "#ffffff !important",
    width: "160px !important",
    height: "47px !important",
    fontSize: "13px !important",
    color: "#196773 !import",
    fontWeight: "font-weight !important",
    border: "1px solid #196773 !important",
    borderRadius: "8px !imporatant",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const AddAllForm = () => {
  const selectedSku = localStorage.getItem("selectedSku");

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [questionData, setQuestionData] = useState([]);
  const [rulesData, setRulesData] = useState([]);
  const [listData, setListData] = useState([]);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const userId = location.state;
  const [titlevalue, setTitleValue] = useState();
  const [inspectionName, setInspectionName] = useState();
  const [localData, setLocalData] = useState([]);
  const [indexHover, setIndexHover] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedReport, setSelectedReport] = useState([]);
  const [selectedInspecCat, setSelectedInspecCat] = useState();
  const [reportSelect, setReportSelect] = useState([]);
  const [isSelfieRequired, setIsSelfieRequired] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [sku, setSku] = useState(selectedSku);

  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const listdata = useSelector(
    (state) => state && state.allForm && state.allForm.allForm
  );

  const createQuestion = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.allform &&
      state.checklist.allform.data &&
      state.checklist.allform.data.data &&
      state.checklist.allform.data.data.forms
  );
  const addAllformData = useSelector(
    (state) => state && state.allForm && state.allForm.allForm
  );

  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );
  const inspectionList = useSelector(
    (state) =>
      state.user &&
      state.user.inspection &&
      state.user.inspection.data &&
      state.user.inspection.data.data &&
      state.user.inspection.data.data
  );
  const isAllSelectedReport =
    reportSelect.length > 0 && selectedReport.length === reportSelect.length;

  const dataList = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.viewFormList &&
      state.checklist.viewFormList.data &&
      state.checklist.viewFormList.data.data
  );

  const categoryListData = useSelector(
    (state) => state.checklist && state.checklist.category && state.checklist.category.data && state.checklist.category.data.data || []
  );

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
  }, []);
  useEffect(() => {
    const data = { url: BASE_URL + `inspectionCategory` };
    dispatch(UserInspectionApi(data));
  }, []);
  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setReportSelect(arr);
    }
  }, [subRoleList]);
  useEffect(() => {
    if (inspectionList) {
      let arr = [];
      for (let item of inspectionList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setSelectedInspecCat(arr);
    }
  }, [inspectionList]);
  const handleChangeReport = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value.includes("all");
    if (isClickAll) {
      setSelectedReport(
        selectedReport.length === reportSelect?.length ? [] : reportSelect
      );
      return;
    }
    setSelectedReport(value);
  };

  useEffect(() => {
    handleViewList();
  }, []);
  useEffect(() => {
    if (dataList) {
      const data = dataList;
      dispatch(allFormData(data));
    }
  }, [dataList]);
  useEffect(() => {
    if (listData) localStorage.setItem("listDataid", listData.length);
    if (listdata) {
      localStorage.setItem("formRules", JSON.stringify(listdata));
    }
  }, [listData]);
  useEffect(() => {
    const validations = localStorage.getItem("validationData");
    const parseValidation = JSON.parse(validations);
    let array = localData;
    array.push(parseValidation);
    setLocalData(array);
    console.log("addAllformData",addAllformData);
    if (addAllformData) {
      setTitleValue({
        title: addAllformData.title,
        id: addAllformData && addAllformData._id,
        inspectionCategory: addAllformData && addAllformData.inspectionCategory,
        skuName: addAllformData && addAllformData?.skuName || "",
      });
      setInspectionName(addAllformData.inspectionCategory)
      setSku(addAllformData && addAllformData?.skuName || "");
      localStorage.setItem("selectedSku", addAllformData && addAllformData?.skuName || "");
      addAllformData.reports && setSelectedReport(addAllformData.reports);
      setIsSelfieRequired(addAllformData.isSelfieRequired || false);

      if (addAllformData && parseValidation == null) {
        setListData(addAllformData && addAllformData?.questions);
      } else setListData(localData);

      const questionDatas = localStorage.getItem("questions");
      if (questionDatas) {
        const qDataFromApi = addAllformData && addAllformData?.questions;
        const newQuest = JSON.parse(questionDatas);
        for (let i = 0; i < newQuest.length; i++) {
          qDataFromApi && qDataFromApi.push(newQuest[i]);
        }
        setListData(qDataFromApi);
        localStorage.removeItem("questions");
      }
    }
  }, [addAllformData]);
  useEffect(() => {
    const validations = localStorage.getItem("validationData");
  }, [localData]);

  const validationSchema = Yup.object({
    title: Yup.string("Enter your title").required("Title is required"),
  });

  const formik = useFormik({
    initialValues: titlevalue || {
      title: "",
      id: listData._id,
      inspectionCategory:  '',
      skuName: ""
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        dispatch(allFormsUpdateData(values));
      }
      handleFinalSubmit(values);
    },
  });

  const handleFinalSubmit = (values) => {
    if (values) {
      const id = localStorage.getItem("companyId");
      navigatioToList();
    }
  };

  const navigatioToList = () => {
    setTimeout(() => {
      navigate({ pathname: "/checklist/all-form" });
      localStorage.removeItem("selectedSku");
    }, 2000);
  };

  const handleClick = () => {
    navigate("/checklist/all-form/add-form/edit-addquestion");
  };

  const handleDelete = (id) => {
    const deleteId = JSON.stringify(id);
    if (id) {
      dispatch(allformDeletes(id));
    }
  };

  const handleViewList = () => {
    const id = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const useIdrow = JSON.parse(userId);
    const data = {
      url: BASE_URL + `checklists/${useIdrow._id}?company_id=${id}`,
    };
    if (dataList == undefined) {
      dispatch(ViewAllFormListApi(data));
    }
  };
  const handleClickEdit = (item, id) => {
    localStorage.setItem("usersID", addAllformData && addAllformData._id);
    localStorage.setItem("editdata", JSON.stringify(item));
    localStorage.setItem("iduser", id);
    const editdata = localStorage.getItem("editdata");
    if (editdata) {
      navigate("/checklist/all-form/add-form/edit-question");
    }
  };
  useEffect(() => {
    setListData(addAllformData && addAllformData.questions);
  }, [refreshKey]);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...listData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    dispatch(AllFormUpdateQuestionSuccess(copyListItems));
    setListData(copyListItems);
  };
console.log("formik123",formik);
console.log("inspectionName",inspectionName);
console.log("selectedInspecCat",selectedInspecCat);
  useEffect(() => {
    // const category = JSON.parse(localStorage.getItem("categoryData"));
    // setCategoryData(category);
    ListCatagory();
  }, []);

  useEffect(() => {
    setCategoryData(categoryListData);
    if(categoryListData.length && !(addAllformData && addAllformData?.skuName)){
      formik.handleChange({
        target: {
          name: "skuName",
          value: categoryListData[0].title
        }
      })
      setSku(categoryListData[0].title);
      localStorage.setItem("selectedSku", categoryListData[0].title);
    }
  }, [categoryListData]);

  const ListCatagory = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `categories?company_id=${id}&moduleName=${"checklist"}&submoduleName=all-forms` };
    dispatch(CategoryListApi(data));
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <CreateFormHead
          handleFinalSubmit={handleFinalSubmit}
          listData={listData}
          dataList={dataList}
          EditData="EditData"
          value={formik}
          reports={selectedReport}
          isSelfieRequired={isSelfieRequired}
        />
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Container>
            <Grid container spacing={1} sx={{ textAlign: "left", mt: 1 }}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Title</label>
                  <TextField
                    name="title"
                    value={formik?.values?.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    className="date-input"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select SKU</label>
                  <Select
                    size="small"
                    name="skuName"
                    onChange={formik.handleChange}
                    value={formik.values?.skuName || sku}
                    // defaultValue={trainingdata?.category}
                    sx={{ borderRadius: "8px" }}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "180px",
                        },
                      },
                    }}
                  >
                    {categoryData &&
                      categoryData?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option.title}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Report</label>
                  {console.log("selectedReport",selectedReport)}
                  <Select
                    labelId="mutiple-select-label"
                    sx={{ borderRadius: "8px" }}
                    multiple
                    name="reportselect"
                    size="small"
                    value={selectedReport}
                    onChange={handleChangeReport}
                    renderValue={(selectedReport) =>
                      selectedReport.map((item) => item)?.join(",")
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 0,
                          },
                          height: "180px",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: isAllSelectedReport ? classes.selectedAll : "",
                      }}
                      value="all"
                      name="all"
                      data-button-all
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isAllSelectedReport}
                          indeterminate={
                            selectedReport.length > 0 &&
                            selectedReport.length < reportSelect?.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {reportSelect &&
                      reportSelect.map((option, i) => (
                        <MenuItem key={i} value={option} name={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedReport.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Inspection Category</label>
                  {console.log("formik?.values.inspectionCategory",formik?.values.inspectionCategory)}
                  <Select
                      size="small"
                      name="inspectionCategory"
                      value={formik?.values?.inspectionCategory || inspectionName}
                      onChange={formik.handleChange}
                      defaultValue={addAllformData && addAllformData.inspectionCategory}
                      MenuProps={{
                        disableScrollLock: true,
                        classes: { paper: classes.select },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },

                        sx: {
                          height: "200px",
                          display: "block",
                        },
                      }}
                      sx={{ borderRadius: "8px" }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Type
                      </MenuItem>
                      {selectedInspecCat &&
                        selectedInspecCat?.map((option, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={option}
                              className="select-item"
                            >
                              {option}
                            </MenuItem>
                          );
                        })}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      className="label-name"
                      control={
                        <Checkbox
                          type="checkbox"
                          name="isSelfieRequired"
                          onChange={(e) =>
                            setIsSelfieRequired(e.target.checked)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ color: "#196773" }}
                          checked={isSelfieRequired}
                        />
                      }
                      label="Selfie required"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ textAlign: "left", mt: 1 }}>
              <Grid item xs={12}>
                <Typography className="form-head">
                  <b>Form Question</b>
                </Typography>
                <Button
                  className={"add-question " + classes.addnewqustions}
                  onClick={handleClick}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Add Question
                </Button>
              </Grid>
            </Grid>
            <div>
              {listdata &&
                listData?.map((item, i) => {
                  const optionScore =
                    item?.type?.option_score &&
                    Object?.entries(item?.type?.option_score)?.map(
                      ([key, val]) => (
                        <div>
                          {key} : {val}
                        </div>
                      )
                    );
                  return (
                    <div>
                      <Card sx={{ mb: 2, borderRadius: "8px" }} key={i}>
                        <div
                          style={{ cursor: "grab" }}
                          onDragStart={(e) => dragStart(e, i)}
                          onDragEnter={(e) => dragEnter(e, i)}
                          onDragEnd={drop}
                          draggable
                        >
                          <DehazeIcon />
                        </div>
                        <div className="liststyle-edit">
                          <ol
                            style={{ listStyle: "none", textAlign: "left" }}
                            className="list-style"
                          >
                            <Typography
                              className={
                                "list-form question-type-div " + classes.root
                              }
                            >
                              <b>
                                {i + 1}. {item?.title}
                              </b>
                            </Typography>
                            <div className="score-div">
                              <Typography
                                className={
                                  "list-form question-type-div validation-type " +
                                  classes.root
                                }
                              >
                                Type: <b>{item?.type?.title}</b>
                              </Typography>
                              <li>
                                {" "}
                                <Tooltip content={optionScore} direction="top">
                                  <CustomChip size={1.4} label="Option Score" />{" "}
                                </Tooltip>
                                <Tooltip
                                  content={item?.question_score}
                                  direction="top"
                                >
                                  {" "}
                                  <CustomChip
                                    size={1.4}
                                    label="Question Score"
                                  />
                                </Tooltip>
                              </li>
                            </div>
                            <div className="validation-maindiv">
                              <Box className="validation-div2">
                                <p
                                  className={
                                    "validations-title " + classes.root
                                  }
                                >
                                  {" "}
                                  Validations:
                                </p>
                                {item?.type?.validations?.map((items, i) => {
                                  return (
                                    <li key={i}>
                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key === "required"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key === "required" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b>{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}
                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key === "audio"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key === "audio" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b>{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}

                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key === "image"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key === "image" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b>{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}
                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key === "video"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key === "video" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b className="video-validation">{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}

                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key === "comment"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key === "comment" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b>{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}

                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key ===
                                                    "comment_required"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key ===
                                                  "comment_required" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b>{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}
                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key ===
                                                    "image_optional"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key ===
                                                  "image_optional" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b>{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}
                                      {items?.answer
                                        ?.filter((data) => data?.value === true)
                                        ?.map((datas, i) => {
                                          const answer = datas?.value === true;
                                          return (
                                            <>
                                              {answer ? (
                                                <Typography
                                                  className={
                                                    items.key ===
                                                    "video_optional"
                                                      ? "Validations-box validation-font"
                                                      : ""
                                                  }
                                                >
                                                  {items.key ===
                                                  "video_optional" ? (
                                                    <>
                                                      {items?.title} :{" "}
                                                      <b>
                                                        <b className="video-validation">{datas?.title}</b>
                                                      </b>{" "}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </>
                                          );
                                        })}
                                    </li>
                                  );
                                })}
                              </Box>
                              <Stack direction="row" spacing={2} mr={1}>
                                <img
                                  src={
                                    hover.Edit && indexHover === i
                                      ? EditHover
                                      : Edit
                                  }
                                  alt="Edit"
                                  onClick={() => handleClickEdit(item, i)}
                                  onMouseOver={() => {
                                    setHover({ Edit: true });
                                    setIndexHover(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ Edit: false });
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    width: "33px",
                                    height: "33px",
                                  }}
                                />
                                <img
                                  src={
                                    hover.Delete && indexHover === i
                                      ? DeleteHover
                                      : Delete
                                  }
                                  alt="Delete"
                                  onClick={() => {
                                    handleDelete(item.id);
                                    setRefreshKey((oldKey) => oldKey + 1);
                                  }}
                                  onMouseOver={() => {
                                    setHover({ Delete: true });
                                    setIndexHover(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ Delete: false });
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    width: "33px",
                                    height: "33px",
                                  }}
                                />
                              </Stack>
                            </div>
                          </ol>
                        </div>
                      </Card>
                    </div>
                  );
                })}
            </div>
            {questionData &&
              questionData.map((items, i) => {
                return (
                  <Card sx={{ mb: 2 }} key={i}>
                    <CardContent>
                      <Stack direction="row" spacing={2}>
                        <Typography variant="p" component="p">
                          Q 1.
                        </Typography>
                        <Typography variant="p" component="b">
                          {items?.title}
                        </Typography>
                      </Stack>
                      <Box className="align-icon">
                        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                          <Typography variant="p" component="p">
                            Type
                          </Typography>
                          <Typography variant="p" component="p">
                            {items?.type?.title}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <img
                            src={
                              hover.Edit && indexHover === i ? EditHover : Edit
                            }
                            alt="Edit"
                            onClick={handleClick}
                            onMouseOver={() => {
                              setHover({ Edit: true });
                              setIndexHover(i);
                            }}
                            onMouseLeave={() => {
                              setHover({ Edit: false });
                            }}
                          />
                          <img
                            src={
                              hover.Delete && indexHover === i
                                ? DeleteHover
                                : Delete
                            }
                            alt="Delete"
                            onClick={() => handleDelete(i)}
                            onMouseOver={() => {
                              setHover({ Delete: true });
                              setIndexHover(i);
                            }}
                            onMouseLeave={() => {
                              setHover({ Delete: false });
                            }}
                          />
                        </Stack>
                      </Box>

                      <Stack direction="row" spacing={1}>
                        <Typography variant="p" component="b">
                          Validation
                        </Typography>
                        <Box className="question-caption">
                          <Typography variant="p" component="p">
                            Is Required? :
                          </Typography>
                          <Typography variant="p" component="b">
                            {items?.type?.validations.map((aa, i) => {
                              return (
                                <div key={i}>
                                  {aa?.key === "required"
                                    ? aa?.answer?.map((bbb, i) => {
                                        return <div key={i}>{bbb?.title}</div>;
                                      })
                                    : ""}
                                </div>
                              );
                            })}
                          </Typography>
                        </Box>
                        <Box className="question-caption">
                          <Typography variant="p" component="p">
                            Record Audio? :
                          </Typography>
                          <Typography variant="p" component="b">
                            {" "}
                            {items?.type?.validations.map((aa, i) => {
                              return (
                                <div key={i}>
                                  {aa?.key === "comment"
                                    ? aa?.answer?.map((bbb, i) => {
                                        return <div key={i}>{bbb?.title}</div>;
                                      })
                                    : ""}
                                </div>
                              );
                            })}
                          </Typography>
                        </Box>
                        <Box className="question-caption">
                          <Typography variant="p" component="p">
                            Take Image? :
                          </Typography>
                          <Typography variant="p" component="b">
                            {items?.type?.validations.map((aa, i) => {
                              return (
                                <div key={i}>
                                  {aa?.key === "comment_required"
                                    ? aa?.answer?.map((bbb, i) => {
                                        return <div key={i}>{bbb?.title}</div>;
                                      })
                                    : ""}
                                </div>
                              );
                            })}
                          </Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={1} sx={{ textAlign: "left" }}></Grid>
          </Container>
          <RuleSection
            questionData={questionData}
            setRulesData={setRulesData}
            listData={listData}
          />
        </Box>
      </form>
    </>
  );
};

export default AddAllForm;
