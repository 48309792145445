import React, { useState } from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import { Button, Card, Grid } from "material-ui-core";
import SearchAppBar from "../../checklist/tableHeader/SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RoleModel from "./RoleModel";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Header = ({
  data,
  searchOnChange,
  searchValue,
  handleKeyDown,
  permission,
}) => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          {/* <Grid item xs={12} sm={12} md={12} lg={8} className="colomn-padding"> */}
          {/* <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="Active"
                icon={<p className="active-tabs-content">28</p>}
                iconPosition="end"
                className="active-tabs tabs-content"
              />
              <Tab
                label="Archived"
                icon={<p className="active-tabs-content">8</p>}
                iconPosition="end"
                className="Archived-tabs tabs-content"
              />
            </Tabs> */}
          {/* </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12} className="colomn-padding">
            <Stack
              direction="row"
              className="Assign-Checklist"
              sx={{ float: "right" }}
            >
              <SearchAppBar
                searchValue={searchValue}
                searchOnChange={searchOnChange}
                handleKeyDown={handleKeyDown}
              />
              {permission?.roles?.manageRole === true ? (
                <Button
                  className="header-add cancle-hover"
                  style={{height:"42px",marginTop:"2px"}}
                  onClick={handleClickOpen}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Add new
                </Button>
              ) : (
                ""
              )}
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Roles:</strong>
                            <p>List shows all the Subroles created in the company </p>
                            <strong>Add new:</strong>
                            <p>Admin can create new roles adding the level to be defined</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginTop:"8px",
                            marginRight:"8px",
                            marginLeft: "2px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <RoleModel open={open} handleClose={handleClose} data={data} />
    </>
  );
};

export default Header;
