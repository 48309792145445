/** @format */

import {
  ADD_ASSESSMENT_IMAGE_REQUEST,
  ADD_ASSESSMENT_IMAGE_SUCCESS,
  ADD_ASSESSMENT_IMAGE_ERROR,
  IMAGE_CERTIFICATE_ERROR,
  IMAGE_CERTIFICATE_SUCCESS,
  IMAGE_CERTIFICATE_REQUEST,
  LIST_CERTIFICATE_ERROR,
  LIST_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_ERROR,
  EDIT_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_REQUEST,
  LIST_TRAINING_ERROR,
  LIST_TRAINING_REQUEST,
  LIST_TRAINING_SUCCESS,
  TRAINING_DETAILS_ERROR,
  TRAINING_DETAILS_REQUEST,
  TRAINING_DETAILS_SUCCESS,
  ADD_CERTIFICATE_REQUEST,
  ADD_CERTIFICATE_SUCCESS,
  ADD_CERTIFICATE_ERROR,
  ADD_CERTIFICATE_IMAGE_REQUEST,
  ADD_CERTIFICATE_IMAGE_SUCCESS,
  ADD_CERTIFICATE_IMAGE_ERROR,
  GET_CERTIFICATE_DATA_REQUEST,
  GET_CERTIFICATE_DATA_SUCCESS,
  GET_CERTIFICATE_DATA_ERROR,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_ERROR,
  DELETE_CERTIFICATE_REQUEST,
  LMS_LISTING_DETAILS_REQUEST,
  LMS_LISTING_DETAILS_SUCCESS,
  LMS_LISTING_DETAILS_ERROR,
  PROGRESS_LIST_REQUEST,
  PROGRESS_LIST_SUCCESS,
  PROGRESS_LIST_ERROR,
  ASSESSMENT_LIST_REQUEST,
  ASSESSMENT_LIST_SUCCESS,
  ASSESSMENT_LIST_ERROR,
  ADD_ASSESSMENT_REQUEST,
  ADD_ASSESSMENT_SUCCESS,
  ADD_ASSESSMENT_ERROR,
  ASSESSMENT_DELETE_REQUEST,
  ASSESSMENT_DELETE_SUCCESS,
  ASSESSMENT_DELETE_ERROR,
  RESET_LIST_REQUEST,
  RESET_LIST_SUCCESS,
  RESET_LIST_ERROR,
  CREATE_NEW_TRAINING_REQUEST,
  CREATE_NEW_TRAINING_SUCCESS,
  CREATE_NEW_TRAINING_ERROR,
  ADD_TRAINING_IMAGE_REQUEST,
  ADD_TRAINING_IMAGE_SUCCESS,
  ADD_TRAINING_IMAGE_ERROR,
  USER_TRAINING_LIST_REQUEST,
  USER_TRAINING_LIST_SUCCESS,
  USER_TRAINING_LIST_ERROR,
  UPDATE_TRAINING_REQUEST,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_ALLCHECKLIS_ERROR,
  UPDATE_TRAINING_ERROR,
  STORE_TRAINING_LIST_REQUEST,
  STORE_TRAINING_LIST_SUCCESS,
  STORE_TRAINING_LIST_ERROR,
  STORE_TRAINING_VIEW_LIST_REQUEST,
  STORE_TRAINING_VIEW_LIST_SUCCESS,
  STORE_TRAINING_VIEW_LIST_ERROR,
  INDIVIDUAL_STORE_TRAINING_VIEW_LIST_REQUEST,
  INDIVIDUAL_STORE_TRAINING_VIEW_LIST_SUCCESS,
  INDIVIDUAL_STORE_TRAINING_VIEW_LIST_ERROR,
  LMS_REPORT_REQUEST,
  LMS_REPORT_SUCCESS,
  LMS_REPORT_ERROR,
  TRAINING_BY_USER_REQUEST,
  TRAINING_BY_USER_SUCCESS,
  TRAINING_BY_USER_ERROR,
  ASSESSMENTLIST_UPDATE_REQUEST,
  ASSESSMENTLIST_UPDATE_SUCCESS,
  ASSESSMENTLIST_UPDATE_ERROR,
  CERTIFICATE_ARCHIVED_REQUEST,
  CERTIFICATE_ARCHIVED_SUCCESS,
  CERTIFICATE_ARCHIVED_ERROR,
  ASSESSMENT_ARCHIVED_REQUEST,
  ASSESSMENT_ARCHIVED_SUCCESS,
  ASSESSMENT_ARCHIVED_ERROR,
  TRAINING_BY_STORE_REQUEST,
  TRAINING_BY_STORE_SUCCESS,
  TRAINING_BY_STORE_ERROR,
  LMS_TRAINING_CHART_REQUEST,
  LMS_TRAINING_CHART_SUCCESS,
  LMS_TRAINING_CHART_ERROR,
  TRAINING_ARCHIVED_REQUEST,
  TRAINING_ARCHIVED_SUCCESS,
  TRAINING_ARCHIVED_ERROR,
  LMS_RECENT_COURSE_REQUEST,
  LMS_RECENT_COURSE_SUCCESS,
  LMS_RECENT_COURSE_ERROR,
  LMS_DETAIL_VIEW_REQUEST,
  LMS_DETAIL_VIEW_SUCCESS,
  LMS_DETAIL_VIEW_ERROR,
  LMS_TRAINING_REQUEST,
  LMS_TRAINING_SUCCESS,
  LMS_TRAINING_FAILED,
  TDR_VIDEO_TYPE_REQUEST,
  TDR_VIDEO_TYPE_SUCCESS,
  TDR_VIDEO_TYPE_ERROR,
  COMPLETED_COURSE_ERROR,
  COMPLETED_COURSE_SUCCESS,
  COMPLETED_COURSE_REQUEST,
  ON_GOING_ERROR,
  ON_GOING_SUCCESS,
  ON_GOING_REQUEST,
  LMS_LISTING_DETAILS_REQUEST1,
  LMS_LISTING_DETAILS_SUCCESS1,
  LMS_LISTING_DETAILS_ERROR1,
  TDR_VIDEO_TYPE_REQUEST1,
  TDR_VIDEO_TYPE_SUCCESS1,
  TDR_VIDEO_TYPE_ERROR1,
  ADD_ASSESSMENT_IMAGE_CLEAR,
  DELETE_ASSESSMENT_IMAGE,
  ACTIVELMSREPORT_REQUEST,
  ACTIVELMSREPORT_SUCCESS,
  ACTIVELMSREPORT_ERROR,
  ADD_CERTIFICATE_IMAGE_REQUEST_RESET,
  CATEGORY_LIST_LMS_REQUEST,
  CATEGORY_LIST_LMS_SUCCESS,
  CATEGORY_LIST_LMS_ERROR,
} from "../types/Types";

const initialState = {
  loading: false,
  resetLoading: false,
  error: "",
  errorField: false,
  data: [],
  details: "",
  resetData: "",
  addImage: "",
  assesmentImages: [],
  assessmentlist: [],
  addassessment: "",
  addCertificate: "",
  getCertificate: "",
  editCertificate: "",
  detailsTrainee: [],
  detailsTrainee1: [],
  progressTraining: [],
  newtraining: [],
  trainingImage: [],
  userTraining: [],
  updateTraining: [],
  storeTraining: [],
  StoreUserTraining: [],
  IndividualStoreTraining: [],
  lmsReportData: "",
  traningByUserData: "",
  assessmentUpdateData: [],
  assessmentListArchived: "",
  traningByStoreData: "",
  lmsTrainingLineChartData: "",
  trainingListArchived: "",
  certificateListArchived: "",
  lmsRecentCourseData: "",
  lmsEditTraining: [],
  trainingData: [],
  trDetailsVideoTypeData: [],
  trDetailsVideoTypeData1: [],
  onGoingCourse: [],
  completedCourse: [],
  activeLmsReport: [],
};

const LmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_TRAINING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_TRAINING_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        loading: false,
      };
    }
    case LIST_TRAINING_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ASSESSMENTLIST_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ASSESSMENTLIST_UPDATE_SUCCESS: {
      return {
        ...state,
        assessmentUpdateData: action?.payload,
        loading: false,
      };
    }
    case ASSESSMENTLIST_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case RESET_LIST_REQUEST: {
      return {
        ...state,
        resetLoading: true,
        error: "",
      };
    }
    case RESET_LIST_SUCCESS: {
      return {
        ...state,
        resetData: action?.payload,
        resetLoading: false,
        loading: true,
      };
    }
    case RESET_LIST_ERROR: {
      return {
        ...state,
        resetLoading: false,
        error: action.error,
      };
    }
    case TRAINING_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case TRAINING_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action?.payload,
        loading: false,
      };
    }
    case TRAINING_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case LMS_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LMS_REPORT_SUCCESS: {
      return {
        ...state,
        lmsReportData: action?.payload,
        loading: false,
      };
    }
    case LMS_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case TRAINING_BY_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case TRAINING_BY_USER_SUCCESS: {
      return {
        ...state,
        traningByUserData: action?.payload,
        loading: false,
      };
    }
    case TRAINING_BY_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case TRAINING_BY_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case TRAINING_BY_STORE_SUCCESS: {
      return {
        ...state,
        traningByStoreData: action?.payload,
        loading: false,
      };
    }
    case TRAINING_BY_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case LMS_TRAINING_CHART_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LMS_TRAINING_CHART_SUCCESS: {
      return {
        ...state,
        lmsTrainingLineChartData: action?.payload,
        loading: false,
      };
    }
    case LMS_TRAINING_CHART_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    // LMS RECENT COURSE

    case LMS_RECENT_COURSE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LMS_RECENT_COURSE_SUCCESS: {
      return {
        ...state,
        lmsRecentCourseData: action?.payload,
        loading: false,
      };
    }
    case LMS_RECENT_COURSE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case LIST_TRAINING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        loading: false,
      };
    }
    case LIST_CERTIFICATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ADD_CERTIFICATE_IMAGE_REQUEST_RESET: {
      console.log("called 1");
      return {
        ...state,
        loading: false,
        addImage: [],
        error: "",
      };
    }
    case ADD_CERTIFICATE_IMAGE_REQUEST: {
      console.log("called 1");
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_CERTIFICATE_IMAGE_SUCCESS: {
      console.log(action.payload, "action.payload");
      return {
        ...state,
        addImage: [...state.addImage, action.payload],
        loading: false,
      };
    }
    case ADD_CERTIFICATE_IMAGE_ERROR: {
      console.log("called 1");
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ADD_TRAINING_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_TRAINING_IMAGE_SUCCESS: {
      return {
        ...state,
        trainingImage: action?.payload,
        loading: false,
      };
    }
    case ADD_TRAINING_IMAGE_ERROR: {
      return {
        ...state,
        trainingImage: [],
        loading: false,
        error: action.error,
      };
    }
    case ADD_CERTIFICATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        addCertificate: action?.payload,
        loading: false,
      };
    }
    case ADD_CERTIFICATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        errorField: true,
      };
    }
    case GET_CERTIFICATE_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GET_CERTIFICATE_DATA_SUCCESS: {
      return {
        ...state,
        getCertificate: action?.payload,
        loading: false,
      };
    }
    case GET_CERTIFICATE_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case EDIT_CERTIFICATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        editCertificate: action?.payload,
        loading: false,
      };
    }
    case EDIT_CERTIFICATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_CERTIFICATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        getCertificate: action?.payload,
        loading: false,
      };
    }
    case DELETE_CERTIFICATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case LMS_LISTING_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LMS_LISTING_DETAILS_SUCCESS: {
      return {
        ...state,
        detailsTrainee: action?.payload,
        loading: false,
      };
    }
    case LMS_LISTING_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        detailsTrainee: [],
        error: action.error,
      };
    }
    case LMS_LISTING_DETAILS_REQUEST1: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LMS_LISTING_DETAILS_SUCCESS1: {
      return {
        ...state,
        detailsTrainee1: action?.payload,
        loading: false,
      };
    }
    case LMS_LISTING_DETAILS_ERROR1: {
      return {
        ...state,
        loading: false,
        // detailsTrainee: [],
        error: action.error,
      };
    }
    case PROGRESS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PROGRESS_LIST_SUCCESS: {
      return {
        ...state,
        progressTraining: action?.payload,
        loading: false,
      };
    }
    case PROGRESS_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        progressTraining: [],
        error: action.error,
      };
    }
    case ADD_ASSESSMENT_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_ASSESSMENT_IMAGE_SUCCESS: {
      return {
        ...state,
        assesmentImages: { ...state.assesmentImages, ...action.payload },
        loading: false,
      };
    }
    case DELETE_ASSESSMENT_IMAGE: {
      return {
        ...state,
        assesmentImages: { ...action.payload },
        loading: false,
      };
    }
    case ADD_ASSESSMENT_IMAGE_CLEAR: {
      return {
        ...state,
        loading: false,
        assesmentImages: {},
        error: '',
      };
    }
    case ADD_ASSESSMENT_IMAGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ASSESSMENT_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        assessmentlist: action?.payload,
        loading: false,
      };
    }
    case ASSESSMENT_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        assesmentlist: [],
        error: action.error,
      };
    }

    case ADD_ASSESSMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        addassessment: action?.payload,
        loading: false,
      };
    }
    case ADD_ASSESSMENT_ERROR: {
      return {
        ...state,
        loading: false,
        addassessment: [],
        error: action.error,
      };
    }
    case ASSESSMENT_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ASSESSMENT_DELETE_SUCCESS: {
      return {
        ...state,
        delete: action?.payload,
        loading: false,
      };
    }
    case ASSESSMENT_DELETE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CREATE_NEW_TRAINING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CREATE_NEW_TRAINING_SUCCESS: {
      return {
        ...state,
        newtraining: action?.payload,
        loading: false,
      };
    }
    case CREATE_NEW_TRAINING_ERROR: {
      return {
        ...state,
        loading: false,
        newtraining: [],
        error: action.error,
      };
    }
    case USER_TRAINING_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case USER_TRAINING_LIST_SUCCESS: {
      return {
        ...state,
        userTraining: action?.payload,
        loading: false,
      };
    }
    case USER_TRAINING_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        userTraining: [],
        error: action.error,
      };
    }
    case UPDATE_TRAINING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPDATE_TRAINING_SUCCESS: {
      return {
        ...state,
        updateTraining: action?.payload,
        loading: false,
      };
    }
    case UPDATE_TRAINING_ERROR: {
      return {
        ...state,
        loading: false,
        updateTraining: [],
        error: action.error,
      };
    }
    case STORE_TRAINING_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_TRAINING_LIST_SUCCESS: {
      return {
        ...state,
        storeTraining: action?.payload,
        loading: false,
      };
    }
    case STORE_TRAINING_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        storeTraining: [],
        error: action.error,
      };
    }
    case STORE_TRAINING_VIEW_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_TRAINING_VIEW_LIST_SUCCESS: {
      return {
        ...state,
        StoreUserTraining: action?.payload,
        loading: false,
      };
    }
    case STORE_TRAINING_VIEW_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        StoreUserTraining: [],
        error: action.error,
      };
    }
    case INDIVIDUAL_STORE_TRAINING_VIEW_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case INDIVIDUAL_STORE_TRAINING_VIEW_LIST_SUCCESS: {
      return {
        ...state,
        IndividualStoreTraining: action?.payload,
        loading: false,
      };
    }
    case INDIVIDUAL_STORE_TRAINING_VIEW_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        IndividualStoreTraining: [],
        error: action.error,
      };
    }
    case CERTIFICATE_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CERTIFICATE_ARCHIVED_SUCCESS: {
      return {
        ...state,
        certificateListArchived: action?.payload,
        loading: false,
      };
    }
    case CERTIFICATE_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ASSESSMENT_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ASSESSMENT_ARCHIVED_SUCCESS: {
      return {
        ...state,
        assessmentListArchived: action?.payload,
        loading: false,
      };
    }
    case ASSESSMENT_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case TRAINING_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case TRAINING_ARCHIVED_SUCCESS: {
      return {
        ...state,
        trainingListArchived: action?.payload,
        loading: false,
      };
    }
    case TRAINING_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case LMS_DETAIL_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LMS_DETAIL_VIEW_SUCCESS: {
      return {
        ...state,
        lmsEditTraining: action?.payload,
        loading: false,
      };
    }
    case LMS_DETAIL_VIEW_ERROR: {
      return {
        ...state,
        loading: false,
        lmsEditTraining: [],
        error: action.error,
      };
    }
    case LMS_TRAINING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case LMS_TRAINING_SUCCESS: {
      return {
        ...state,
        loading: false,
        trainingData: action.payload,
      };
    }
    case LMS_TRAINING_FAILED: {
      return {
        ...state,
        loading: false,
        trainingData: [],
        error: action.error,
      };
    }

    case TDR_VIDEO_TYPE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case TDR_VIDEO_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        trDetailsVideoTypeData: action.payload,
      };
    }
    case TDR_VIDEO_TYPE_ERROR: {
      return {
        ...state,
        loading: false,
        trDetailsVideoTypeData: [],
        error: action.error,
      };
    }

    case TDR_VIDEO_TYPE_REQUEST1: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case TDR_VIDEO_TYPE_SUCCESS1: {
      return {
        ...state,
        loading: false,
        trDetailsVideoTypeData1: action.payload,
      };
    }
    case TDR_VIDEO_TYPE_ERROR1: {
      return {
        ...state,
        loading: false,
        trDetailsVideoTypeData1: [],
        error: action.error,
      };
    }

    // on going course
    case ON_GOING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case ON_GOING_SUCCESS: {
      return {
        ...state,
        loading: false,
        onGoingCourse: action.payload,
      };
    }
    case ON_GOING_ERROR: {
      return {
        ...state,
        loading: false,
        onGoingCourse: [],
        error: action.error,
      };
    }

    // completed course
    case COMPLETED_COURSE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case COMPLETED_COURSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        completedCourse: action.payload,
      };
    }
    case COMPLETED_COURSE_ERROR: {
      return {
        ...state,
        loading: false,
        completedCourse: [],
        error: action.error,
      };
    }

    case ACTIVELMSREPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case ACTIVELMSREPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeLmsReport: action.payload,
      };
    }
    case ACTIVELMSREPORT_ERROR: {
      return {
        ...state,
        loading: false,
        activeLmsReport: [],
        error: action.error,
      };
    }
    case CATEGORY_LIST_LMS_REQUEST: {
      return {
        ...state,
        loading: true,
        categoryListData: [],
      };
    }
    case CATEGORY_LIST_LMS_SUCCESS: {
      console.log("action.payload", action.payload);
      return {
        ...state,
        loading: false,
        categoryListData: action.payload,
      };
    }
    case CATEGORY_LIST_LMS_ERROR: {
      return {
        ...state,
        loading: false,
        categoryListData: [],
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default LmsReducer;
