import React from "react";
import { Stack, Button, Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import CheckIcon from "@mui/icons-material/Check";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import OfflinePinOutlinedIcon from "@mui/icons-material/OfflinePinOutlined";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  DataListApi,
  DataManagerApi,
} from "../../../redux/actions/DataManager";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Header = ({ searchOnChange, searchValue, handleKeyDown }) => {
  const dispatch = useDispatch();

  const dataListData = localStorage.getItem("dataListOfdata");
  const questionData = localStorage.getItem("questionsOfDatamanger");
  const questionGroup = localStorage.getItem("questionsGroupOfDatamanager");
  const id = localStorage.getItem("companyId");

  const handleUpdateCompany = () => {
    if (dataListData && questionData && questionGroup) {
      let datalist = JSON.parse(dataListData);
      let questionlist = JSON.parse(questionData);
      let questionGroupData = JSON.parse(questionGroup);
      const allPayload = {
        body: {
          kpis: [],
          list: datalist,
          question_groups: questionGroupData,
          questions: questionlist,
          _id: id,
        },
      };
      allPayload.url = BASE_URL + `companies/${id}`;
      dispatch(DataManagerApi(allPayload));
      DataApiList();
    }
  };

  const DataApiList = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `companies/${id}` };
    dispatch(DataListApi(data));
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Card className="header-card">
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Stack direction="row" className="datalist-header-stack">
              <Button
                variant="outlined"
                className="user-header"
                color="primary"
                style={{ml:"4px"}}
              >
                <ArchiveOutlinedIcon />
                Import Data{" "}
              </Button>
              <Button
                variant="outlined"
                className="user-header"
                color="success"
                onClick={handleUpdateCompany}
              >
                <OfflinePinOutlinedIcon />
                Update Company{" "}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack
              direction="row"
              className="Assign-Checklist"
              spacing={1}
              sx={{ float: "right" }}
            >
              <SearchAppBar
                searchOnChange={searchOnChange}
                handleKeyDown={handleKeyDown}
                searchValue={searchValue}
              />
              <Link to="/data-list/add-list" className="submenu-item">
                <Button className="header-add cancle-hover" style={{height:"42px",marginTop:"0px"}}>
                  <AddOutlinedIcon sx={{ mr:"3px",}} />
                  Add List
                </Button>
              </Link>
              <div style={{marginTop:"6px",marginRight:"8px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                  </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Header;

export const ViewHeader = () => {
  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: "#F6F8FC",
          width: "100%",
          borderRadius: "8px",
          mt: 1,
          p: 1,
        }}
        alignItems="center"
        className="top-margin"
      >
        <Grid item md={12} xs={12} sm={6} lg={12}>
          <Stack direction="row" spacing={1} sx={{ float: "right",}}>
            <Link to="/data-list" className="submenu-item">
              <Button variant="outlined" className="done-btn cancle-hover">
                <CheckIcon sx={{ mr: 1 }} /> Done
              </Button>
            </Link>
            <div style={{marginTop:"8px"}}>
                      <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
