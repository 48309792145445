/** @format */

import React from "react";
import {
  Box,
  Select,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Autocomplete,
  Tooltip,
  Chip,
  Paper,
  Divider,
  Tabs,
  Tab,
  Modal,
} from "@mui/material";
import { Button, Typography } from "material-ui-core";
import InfoIcon from "@mui/icons-material/Info";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ExcelChecklistDetailApi } from "../../redux/actions/csvSubmission";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

import * as moment from "moment";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  CsvNamSubmissionListApi,
  CsvUserSubmissionListApi,
} from "../../redux/actions/csvNamdhariSubmission";
import Progressbar from "../../GlobalProgress/Progressbar";
import ReactExport from "react-export-excel-xlsx-fix";
import { useRef } from "react";
import { IntentCsvNamSubmissionListApi } from "../../redux/actions/IntentcsvNamdhariSubmission";
import { Container } from "@mui/system";
import {
  AllActiveChecklistListApi,
  AllChecklistListApi,
} from "../../redux/actions/Checklist";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { ListStoreApi } from "../../redux/actions/Store";
import {
  ListUserChecklistApi,
  ListUsersChecklistClear,
} from "../../redux/actions/User";
import {
  CsvNamSubmissionListUserWiseApi,
  CsvNamSubmissionListUserWiseSuccess,
} from "../../redux/actions/CSVNamSubmissionWise";
import { DataGrid } from "@mui/x-data-grid";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { logDOM } from "@testing-library/react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const MenuProps = {
  disableScrollLock: true,
};

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const DownloadListHeader = () => {
  const btnRef = useRef();
  const btnUserWiseRef = useRef();
  const btnRefIndent = useRef();
  const csvUserWiseRef = useRef();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD").split("/").join("")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD").split("/").join("")
  );
  const [newDate, setNewDate] = React.useState({});
  const [valueDropdown, setValueDropdown] = React.useState("Userdata");
  const [target, setTarget] = React.useState("Basic Checklist Report");
  const [displayBy, setDisplayBy] = React.useState("ByStatus");
  const [viewBy, setViewBy] = React.useState("ByChecklist");
  const [questionWiseViewBy, setQuestionWiseViewBy] =
    React.useState("Answerwise");
  const [reportType, setTeportType] = React.useState("submission");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [cSVNamSubmissionsData, setCSVNamSubmissionsData] = React.useState([]);
  const [indentSubmissionsData, setIndentSubmissionsData] = React.useState([]);
  const [cSVNamSubmissionsUserWiseData, setCSVNamSubmissionsUserWiseData] =
    React.useState([]);
  const [csvUserWiseSubmissionReport, setCsvUserWiseSubmissionReport] =
    React.useState([]);
  const [cSVNamSubmissionFlag, setCSVNamSubmissionFlag] = useState(false);
  const [excelChecklistDetailData, setExcelChecklistDetailData] =
    useState(null);

  const [_id, setId] = useState("");
  const [touched, setTouched] = useState(false);
  const [activeChecklistData, setactiveChecklistData] = React.useState([]);
  const [filterType, setFilterType] = useState(1);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selected, setSelected] = useState([]);
  const [mapSelectData, setMapSelectData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [column, setColumn] = useState([]);
  const [indentColumn, setIndentColumn] = useState([]);
  const [indentRow, setIndentRow] = useState([]);
  const [loader, setLoader] = useState(true);
  const classes = useStyles();
  const [subRole, setSubRole] = useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const isAllSelected =
    mapSelectData.length > 0 && selected.length === mapSelectData.length;

  const handleBlur = () => {
    setTouched(true);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  let Loader = useSelector(
    (state) =>
      state.IntentCsvNamSubmissionData &&
      state.IntentCsvNamSubmissionData.loading
  );
  let checklistWiseLoader = useSelector(
    (state) =>
      state &&
      state?.CSVNamSubmissionUserWise &&
      state?.CSVNamSubmissionUserWise.loading
  );
  console.log("checklistWiseLoader", checklistWiseLoader);
  Loader = useSelector(
    (state) =>
      state.IntentCsvNamSubmissionData &&
      state.IntentCsvNamSubmissionData.loading
  );
  const CSVNamSubmissionData = useSelector(
    (state) =>
      state &&
      state?.CsvNamdhariSubmission &&
      state?.CsvNamdhariSubmission.csvNamSubmission &&
      state?.CsvNamdhariSubmission.csvNamSubmission.data &&
      state?.CsvNamdhariSubmission.csvNamSubmission.data.data
  );
  const CSVUserWiseSubmissionData = useSelector(
    (state) =>
      state &&
      state?.CsvNamdhariSubmission &&
      state?.CsvNamdhariSubmission.csvUserWiseSubmission &&
      state?.CsvNamdhariSubmission.csvUserWiseSubmission.data &&
      state?.CsvNamdhariSubmission.csvUserWiseSubmission.data.results
  );
  console.log("CSVUserWiseSubmissionData", CSVUserWiseSubmissionData);
  const CSVNamSubmissionDataUserWise = useSelector(
    (state) =>
      state &&
      state?.CSVNamSubmissionUserWise &&
      state?.CSVNamSubmissionUserWise.CSVNamSubmissionUserWise
  );

  const CSVNamSubmissionDataUserWiseLoader = useSelector(
    (state) =>
      state &&
      state?.CSVNamSubmissionUserWise &&
      state?.CSVNamSubmissionUserWise.loading
  );
  // console.log("CSVNamSubmissionDataUserWise", CSVNamSubmissionDataUserWise);
  const IntentCSVNamSubmissionData = useSelector(
    (state) =>
      state &&
      state?.IntentCsvNamSubmissionData &&
      state?.IntentCsvNamSubmissionData.intentCsvNamSubmission &&
      state?.IntentCsvNamSubmissionData.intentCsvNamSubmission.data &&
      state?.IntentCsvNamSubmissionData.intentCsvNamSubmission.data.data
  );
  const storeListMain = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data &&
      state.store.data.data.data.stores
  );
  const userList = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.userIds
  );
  const checkList = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.checklistIds
  );

  const tableData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.allactivechecklist &&
      state?.checklist?.allactivechecklist?.data &&
      state?.checklist?.allactivechecklist?.data?.data
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);
  // console.log("subRole", subRole);

  const getFreqType = (type) => {
    let fType = "N/A";
    switch (type) {
      case 1:
        fType = "No Frequency";
        break;
      case 2:
        fType = "Hourly";
        break;
      case 3:
        fType = "Daily";
        break;
      case 4:
        fType = "Weekly";
        break;
      case 5:
        fType = "Monthly";
        break;
    }
    return fType;
  };
  const getSubTime = (checklistOjb) => {
    if (checklistOjb && checklistOjb.forms && checklistOjb.forms.length) {
      let startTime = checklistOjb.forms[0].startDateTime || new Date();
      let endTime =
        checklistOjb.forms[checklistOjb.forms.length - 1].submittedTime ||
        new Date();
      const today = new Date(startTime);
      const endDate = new Date(endTime);
      const days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
      const hours = parseInt(
        (Math.abs(endDate - today) / (1000 * 60 * 60)) % 24
      );
      const minutes = parseInt(
        (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60
      );
      const seconds = parseInt(
        (Math.abs(endDate.getTime() - today.getTime()) / 1000) % 60
      );
      return (
        (hours.toString().length == 1 ? "0" + hours : hours) +
        ":" +
        (minutes.toString().length == 1 ? "0" + minutes : minutes) +
        ":" +
        (seconds.toString().length == 1 ? "0" + seconds : seconds)
      );
    }
    return "00:00";
  };

  useEffect(() => {
    const columns = [
      {
        field: "S.No",
        headerName: "S.No",
        renderHeader: () => <strong>{"S.No"}</strong>,
        flex: 1,
        minWidth: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (index) =>
          index.api.getRowIndex(index.row.UserId + index.row.StoreId) + 1,
      },
      // {
      //   field: "Date",
      //   minWidth: 150,
      //   headerName: "Date",
      //   renderHeader: () => <strong>{"Date"}</strong>,
      //   flex: 1,
      //   align: "center",
      //   headerAlign: "center",
      //   valueGetter: (params) => {
      //     let result = [];
      //     console.log("**********", params?.row);
      //     if (params?.row?.StoreName) {
      //       result.push(params?.row?.StoreName);
      //     } else {
      //       result = [""];
      //     }
      //     return result.join(", ");
      //   },
      //   renderCell: (params) => {
      //     const content = params.value || "";
      //     return (
      //       <Tooltip title={content} arrow>
      //         <span>{content}</span>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "Store Name",
        minWidth: 150,
        headerName: "Store Name",
        renderHeader: () => <strong>{"Store Name"}</strong>,
        flex: 1,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let result = [];
          if (params?.row?.StoreName) {
            result.push(params?.row?.StoreName);
          } else {
            result = [""];
          }
          return result.join(", ");
        },
        renderCell: (params) => {
          const content = params.value || "";
          return (
            <Tooltip title={content} arrow>
              <span>{content}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "User Name",
        headerName: "User Name",
        renderHeader: () => <strong>{"User Name"}</strong>,
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let result = [];
          if (params?.row?.Username) {
            result.push(params?.row?.Username);
          } else {
            result = [""];
          }
          return result.join(", ");
        },
        renderCell: (params) => {
          const content = params.value || "";
          return (
            <Tooltip title={content} arrow>
              <span>{content}</span>
            </Tooltip>
          );
        },
      },
    ];

    let checklistList = paginatedData?.[0]?.Checklists || [];
    if (displayBy === "ByStatus") {
      checklistList
        .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
        .map((cList) => {
          const columnObj = {
            field: cList.ChecklistId,
            headerName: cList.ChecklistName,
            renderHeader: () => (
              <Tooltip title={cList.ChecklistName}>
                <div
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div>
                    <strong>{cList.ChecklistName}</strong>
                  </div>
                  <div
                    style={{
                      color: "chocolate",
                    }}
                  >
                    <strong>[checklist]</strong>
                  </div>
                </div>
              </Tooltip>
            ),
            flex: 1,
            minWidth: 240,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
              let result = "";
              if (params?.row?.Checklists) {
                params?.row?.Checklists.map((item) => {
                  if (item.ChecklistId === params.field) {
                    const status = item
                      ? item.Assigned == 0
                        ? "N/A"
                        : item.CompletedPercentage == 100
                          ? "Complete"
                          : "Not Complete"
                      : "N/A";
                    result = status;
                  }
                });
              } else {
                result = "";
              }
              return result;
            },
            renderCell: (params) => {
              const content = params.value || "";
              return (
                <Tooltip title={content} arrow>
                  <span>{content}</span>
                </Tooltip>
              );
            },
          };

          columns.push(columnObj);
          columns.push({
            field: cList.ChecklistId + '--- approval-status',
            headerName: "Approval Status",
            renderHeader: () => (
              <Tooltip title={"Approval Status"}>
                <div
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div>
                    <strong>Approval Status</strong>
                  </div>
                  <div
                    style={{
                      color: "chocolate",
                    }}
                  >
                    {/* <strong>[checklist]</strong> */}
                  </div>
                </div>
              </Tooltip>
            ),
            flex: 1,
            minWidth: 240,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
              let result = "";
              if (params?.row?.Checklists) {
                params?.row?.Checklists.map((item) => {
                  if (item.ChecklistId === params.field.split('---')[0]) {
                    const status = item?.approvalStatus === "Not Applicable"
                      ? "N/A"
                      : item?.approvalStatus || "-";
                    result = status;
                  }
                });
              } else {
                result = "-";
              }
              return result;
            },
            renderCell: (params) => {
              const content = params.value || "";
              return (
                <Tooltip title={content} arrow>
                  <span>{content}</span>
                </Tooltip>
              );
            },
          });

          if (viewBy === "ByForm") {
            cList?.Forms?.sort((a, b) =>
              a.FormName < b.FormName ? -1 : 1
            ).map((form) => {
              columns.push({
                field: "Status for," + cList.ChecklistId + "," + form.FormId,
                headerName: "Status for " + form.FormName,
                renderHeader: () => (
                  <Tooltip title={form.FormName}>
                    <div
                      style={{
                        lineHeight: "20px",
                      }}
                    >
                      <div>
                        <strong>{form.FormName}</strong>
                      </div>
                      <div
                        style={{
                          color: "chocolate",
                        }}
                      >
                        <strong>[Form]</strong>
                      </div>
                    </div>
                  </Tooltip>
                ),
                minWidth: 240,
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = "";
                  if (params?.row?.Checklists) {
                    params?.row?.Checklists.map((checkObj) => {
                      if (checkObj.ChecklistId === params.field.split(",")[1]) {
                        const status = checkObj
                          ? checkObj.Assigned == 0
                            ? "N/A"
                            : ""
                          : "N/A";
                        result = status;
                        if (!result) {
                          checkObj?.Forms?.map((formObj) => {
                            if (formObj.FormId === params.field.split(",")[2]) {
                              result = formObj.Completed
                                ? "Completed"
                                : "Not Completed";
                            }
                          });
                        }
                      }
                    });
                  }
                  return result;
                },
                renderCell: (params) => {
                  const content = params.value || "";
                  return (
                    <Tooltip title={content} arrow>
                      <span>{content}</span>
                    </Tooltip>
                  );
                },
              });
            });
          }
        });
      columns.push(
        {
          field: "No of Process not completed",
          headerName: "No of Process not completed",
          renderHeader: () => <strong>{"No of Process not completed"}</strong>,
          flex: 1,
          minWidth: 240,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let comNonCount = 0;
            if (params?.row?.Checklists) {
              params?.row?.Checklists.map((cObj) => {
                const status = cObj
                  ? cObj.Assigned == 0
                    ? "N/A"
                    : cObj.CompletedPercentage == 100
                      ? "Complete"
                      : "Not Complete"
                  : "N/A";
                if (status == "Not Complete") {
                  comNonCount = comNonCount + 1;
                }
              });
            }
            return "" + comNonCount;
          },
          renderCell: (params) => {
            const content = params.value || "";
            return (
              <Tooltip title={content} arrow>
                <span>{content}</span>
              </Tooltip>
            );
          },
        },
        {
          field: "No of Process Completed",
          headerName: "No of Process Completed",
          renderHeader: () => <strong>{"No of Process Completed"}</strong>,
          flex: 1,
          minWidth: 240,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let comCount = 0;
            if (params?.row?.Checklists) {
              params?.row?.Checklists.map((cObj) => {
                const status = cObj
                  ? cObj.Assigned == 0
                    ? "N/A"
                    : cObj.CompletedPercentage == 100
                      ? "Complete"
                      : "Not Complete"
                  : "N/A";
                if (status == "Complete") {
                  comCount = comCount + 1;
                }
              });
            }
            return "" + comCount;
          },
          renderCell: (params) => {
            const content = params.value || "";
            return (
              <Tooltip title={content} arrow>
                <span>{content}</span>
              </Tooltip>
            );
          },
        }
      );
      columns.push({
        field: "Checklists Completion %",
        headerName: "Checklists Completion %",
        renderHeader: () => <strong>{"Checklists Completion %"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let comCount = 0;
          let comNonCount = 0;
          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              const status = cObj
                ? cObj.Assigned == 0
                  ? "N/A"
                  : cObj.CompletedPercentage == 100
                    ? "Complete"
                    : "Not Complete"
                : "N/A";
              if (status == "Complete") {
                comCount = comCount + 1;
              } else if (status == "Not Complete") {
                comNonCount = comNonCount + 1;
              }
            });
          }
          if (comCount > 0 || comNonCount > 0) {
            return (
              "" +
              ((comCount / (comNonCount + comCount)) * 100)?.toFixed(2) +
              "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
      columns.push({
        field: "Average Compliance Score",
        headerName: "Average Compliance Score",
        renderHeader: () => <strong>{"Average Compliance Score"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let assignedCount = 0;
          let assignedComplianceScore = 0;

          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              if (cObj.Assigned > 0) {
                assignedCount += 1;
                assignedComplianceScore += cObj.ComplianceScore;
              }
            });
          }
          if (assignedComplianceScore > 0 || assignedCount > 0) {
            return (
              "" + (assignedComplianceScore / assignedCount)?.toFixed(2) + "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
      columns.push({
        field: "Total Average Completion",
        headerName: "Total Average Completion",
        renderHeader: () => <strong>{"Total Average Completion"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let assignedComplianceScore = 0;

          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              if (cObj.Assigned > 0) {
                assignedComplianceScore += cObj.ComplianceScore;
              }
            });
          }
          if (assignedComplianceScore > 0) {
            return (
              "" +
              (
                assignedComplianceScore / params?.row?.Checklists?.length
              )?.toFixed(2) +
              "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
    }
    if (displayBy === "ByPercentage") {
      checklistList
        .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
        .map((cList) => {
          const columnObj = {
            field: cList.ChecklistId,
            headerName: cList.ChecklistName,
            renderHeader: () => (
              <Tooltip title={cList.ChecklistName}>
                <div
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div>
                    <strong>{cList.ChecklistName}</strong>
                  </div>
                  <div
                    style={{
                      color: "chocolate",
                    }}
                  >
                    <strong>[checklist]</strong>
                  </div>
                </div>
              </Tooltip>
            ),
            flex: 1,
            minWidth: 240,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
              let result = "";
              if (params?.row?.Checklists) {
                params?.row?.Checklists.map((item) => {
                  if (item.ChecklistId === params.field) {
                    const status = item
                      ? item.Assigned == 0
                        ? "N/A"
                        : ""
                      : "N/A";
                    result = status;
                    if (!status) {
                      // if (isNaN(item.Completed / item.Assigned)) {
                      //   result = (0 * 100)?.toFixed(2);
                      // } else {
                      //   result = (
                      //     (item.Completed / item.Assigned) *
                      //     100
                      //   )?.toFixed(2);
                      // }
                      result = item?.CompletedPercentage
                        ? item.CompletedPercentage.toFixed(2) + "%"
                        : "0.00%";
                    }
                  }
                });
              } else {
                result = "";
              }
              return result;
            },
            renderCell: (params) => {
              const content = params.value || "0.00%";
              return (
                <Tooltip title={content} arrow>
                  <span>{content}</span>
                </Tooltip>
              );
            },
          };

          columns.push(columnObj);
          columns.push({
            field: cList.ChecklistId + '--- approval-status',
            headerName: "Approval Status",
            renderHeader: () => (
              <Tooltip title={"Approval Status"}>
                <div
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div>
                    <strong>Approval Status</strong>
                  </div>
                  <div
                    style={{
                      color: "chocolate",
                    }}
                  >
                    {/* <strong>[checklist]</strong> */}
                  </div>
                </div>
              </Tooltip>
            ),
            flex: 1,
            minWidth: 240,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
              let result = "";
              if (params?.row?.Checklists) {
                params?.row?.Checklists.map((item) => {
                  if (item.ChecklistId === params.field.split('---')[0]) {
                    const status = item?.approvalStatus === "Not Applicable"
                      ? "N/A"
                      : item?.approvalStatus || "-";
                    result = status;
                  }
                });
              } else {
                result = "-";
              }
              return result;
            },
            renderCell: (params) => {
              const content = params.value || "";
              return (
                <Tooltip title={content} arrow>
                  <span>{content}</span>
                </Tooltip>
              );
            },
          });

          if (viewBy === "ByForm") {
            cList?.Forms?.sort((a, b) =>
              a.FormName < b.FormName ? -1 : 1
            ).map((form) => {
              columns.push({
                field:
                  "Checklists Completion," +
                  cList.ChecklistId +
                  "," +
                  form.FormId,
                headerName: "Checklists Completion " + form.FormName,
                renderHeader: () => (
                  <Tooltip title={form.FormName}>
                    <div
                      style={{
                        lineHeight: "20px",
                      }}
                    >
                      <div>
                        <strong>{form.FormName}</strong>
                      </div>
                      <div
                        style={{
                          color: "chocolate",
                        }}
                      >
                        <strong>[Form]</strong>
                      </div>
                    </div>
                  </Tooltip>
                ),
                minWidth: 240,
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = "";
                  if (params?.row?.Checklists) {
                    params?.row?.Checklists.map((checkObj) => {
                      if (checkObj.ChecklistId === params.field.split(",")[1]) {
                        const status = checkObj
                          ? checkObj.Assigned == 0
                            ? "N/A"
                            : ""
                          : "N/A";
                        result = status;
                        if (!result) {
                          checkObj?.Forms?.map((formObj) => {
                            if (formObj.FormId === params.field.split(",")[2]) {
                              result = formObj.Completed ? "100%" : "0%";
                            }
                          });
                        }
                      }
                    });
                  }
                  return result;
                },
                renderCell: (params) => {
                  const content = params.value || "";
                  return (
                    <Tooltip title={content} arrow>
                      <span>{content}</span>
                    </Tooltip>
                  );
                },
              });
            });
          }
        });
      columns.push(
        {
          field: "No of Process not completed",
          headerName: "No of Process not completed",
          renderHeader: () => <strong>{"No of Process not completed"}</strong>,
          flex: 1,
          minWidth: 240,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let comNonCount = 0;
            if (params?.row?.Checklists) {
              params?.row?.Checklists.map((cObj) => {
                const status = cObj
                  ? cObj.Assigned == 0
                    ? "N/A"
                    : cObj.CompletedPercentage == 100
                      ? "Complete"
                      : "Not Complete"
                  : "N/A";
                if (status == "Not Complete") {
                  comNonCount = comNonCount + 1;
                }
              });
            }
            return "" + comNonCount;
          },
          renderCell: (params) => {
            const content = params.value || "";
            return (
              <Tooltip title={content} arrow>
                <span>{content}</span>
              </Tooltip>
            );
          },
        },
        {
          field: "No of Process Completed",
          headerName: "No of Process Completed",
          renderHeader: () => <strong>{"No of Process Completed"}</strong>,
          flex: 1,
          minWidth: 240,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let comCount = 0;
            if (params?.row?.Checklists) {
              params?.row?.Checklists.map((cObj) => {
                const status = cObj
                  ? cObj.Assigned == 0
                    ? "N/A"
                    : cObj.CompletedPercentage == 100
                      ? "Complete"
                      : "Not Complete"
                  : "N/A";
                if (status == "Complete") {
                  comCount = comCount + 1;
                }
              });
            }
            return "" + comCount;
          },
          renderCell: (params) => {
            const content = params.value || "";
            return (
              <Tooltip title={content} arrow>
                <span>{content}</span>
              </Tooltip>
            );
          },
        }
      );
      columns.push({
        field: "Checklists Completion %",
        headerName: "Checklists Completion %",
        renderHeader: () => <strong>{"Checklists Completion %"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let comCount = 0;
          let comNonCount = 0;
          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              const status = cObj
                ? cObj.Assigned == 0
                  ? "N/A"
                  : cObj.CompletedPercentage == 100
                    ? "Complete"
                    : "Not Complete"
                : "N/A";
              if (status == "Complete") {
                comCount = comCount + 1;
              } else if (status == "Not Complete") {
                comNonCount = comNonCount + 1;
              }
            });
          }
          if (comCount > 0 || comNonCount > 0) {
            return (
              "" +
              ((comCount / (comNonCount + comCount)) * 100)?.toFixed(2) +
              "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
      columns.push({
        field: "Average Compliance Score",
        headerName: "Average Compliance Score",
        renderHeader: () => <strong>{"Average Compliance Score"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let assignedCount = 0;
          let assignedComplianceScore = 0;

          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              if (cObj.Assigned > 0) {
                assignedCount += 1;
                assignedComplianceScore += cObj.ComplianceScore;
              }
            });
          }
          if (assignedComplianceScore > 0 || assignedCount > 0) {
            return (
              "" + (assignedComplianceScore / assignedCount)?.toFixed(2) + "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
      columns.push({
        field: "Total Average Completion",
        headerName: "Total Average Completion",
        renderHeader: () => <strong>{"Total Average Completion"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let assignedComplianceScore = 0;

          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              if (cObj.Assigned > 0) {
                assignedComplianceScore += cObj.ComplianceScore;
              }
            });
          }
          if (assignedComplianceScore > 0) {
            return (
              "" +
              (
                assignedComplianceScore / params?.row?.Checklists?.length
              )?.toFixed(2) +
              "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
    }
    if (displayBy === "ByComplianceScore") {
      checklistList
        .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
        .map((cObj) => {
          columns.push({
            field: "Compliance Score for," + cObj.ChecklistId,
            headerName: "Compliance Score for " + cObj.ChecklistName,
            renderHeader: () => (
              <Tooltip title={cObj.ChecklistName}>
                <div
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div>
                    <strong>{cObj.ChecklistName}</strong>
                  </div>
                  <div
                    style={{
                      color: "chocolate",
                    }}
                  >
                    <strong>[checklist]</strong>
                  </div>
                </div>
              </Tooltip>
            ),
            flex: 1,
            align: "center",
            headerAlign: "center",
            minWidth: 240,
            valueGetter: (params) => {
              let result = "";

              if (params?.row?.Checklists) {
                params?.row?.Checklists.map((checkObj) => {
                  if (checkObj.ChecklistId === params.field.split(",")[1]) {
                    const status = checkObj
                      ? checkObj.Assigned == 0
                        ? "N/A"
                        : ""
                      : "N/A";
                    result = status;
                    if (!status) {
                      // result = checkObj.ComplianceScore?.toFixed(2)
                      //   ? checkObj.ComplianceScore?.toFixed(2) + "%"
                      //   : "0.00%";
                      result = checkObj.ComplianceScore
                        ? checkObj.ComplianceScore.toFixed(2) + "%"
                        : "0.00%";
                    }
                  }
                });
              }
              return result;
            },
            renderCell: (params) => {
              const content = params.value || "";
              return (
                <Tooltip title={content} arrow>
                  <span>{content}</span>
                </Tooltip>
              );
            },
          });
          columns.push({
            field: cList.ChecklistId + '--- approval-status',
            headerName: "Approval Status",
            renderHeader: () => (
              <Tooltip title={"Approval Status"}>
                <div
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div>
                    <strong>Approval Status</strong>
                  </div>
                  <div
                    style={{
                      color: "chocolate",
                    }}
                  >
                    {/* <strong>[checklist]</strong> */}
                  </div>
                </div>
              </Tooltip>
            ),
            flex: 1,
            minWidth: 240,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
              let result = "";
              if (params?.row?.Checklists) {
                params?.row?.Checklists.map((item) => {
                  if (item.ChecklistId === params.field.split('---')[0]) {
                    const status = item?.approvalStatus === "Not Applicable"
                      ? "N/A"
                      : item?.approvalStatus || "-";
                    result = status;
                  }
                });
              } else {
                result = "-";
              }
              return result;
            },
            renderCell: (params) => {
              const content = params.value || "";
              return (
                <Tooltip title={content} arrow>
                  <span>{content}</span>
                </Tooltip>
              );
            },
          });
          if (viewBy === "ByForm") {
            cObj?.Forms?.sort((a, b) => (a.FormName < b.FormName ? -1 : 1)).map(
              (form) => {
                columns.push({
                  field:
                    "Compliance Score for," +
                    cObj.ChecklistId +
                    "," +
                    form.FormId,
                  headerName: "Compliance Score for " + form.FormName,
                  renderHeader: () => (
                    <Tooltip title={form.FormName}>
                      <div
                        style={{
                          lineHeight: "20px",
                        }}
                      >
                        <div>
                          <strong>{form.FormName}</strong>
                        </div>
                        <div
                          style={{
                            color: "chocolate",
                          }}
                        >
                          <strong>[Form]</strong>
                        </div>
                      </div>
                    </Tooltip>
                  ),
                  minWidth: 240,
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                  valueGetter: (params) => {
                    let result = "";
                    if (params?.row?.Checklists) {
                      params?.row?.Checklists.map((checkObj) => {
                        if (
                          checkObj.ChecklistId === params.field.split(",")[1]
                        ) {
                          const status = checkObj
                            ? checkObj.Assigned == 0
                              ? "N/A"
                              : ""
                            : "N/A";
                          result = status;
                          if (!result) {
                            checkObj?.Forms?.map((formObj) => {
                              if (
                                formObj.FormId === params.field.split(",")[2]
                              ) {
                                result =
                                  formObj.ComplianceScore?.toFixed(2) + "%";
                              }
                            });
                          }
                        }
                      });
                    }
                    return result;
                  },
                  renderCell: (params) => {
                    const content = params.value || "";
                    return (
                      <Tooltip title={content} arrow>
                        <span>{content}</span>
                      </Tooltip>
                    );
                  },
                });
              }
            );
          }
        });
      columns.push(
        {
          field: "No of Process not completed",
          headerName: "No of Process not completed",
          renderHeader: () => <strong>{"No of Process not completed"}</strong>,
          flex: 1,
          minWidth: 240,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let comNonCount = 0;
            if (params?.row?.Checklists) {
              params?.row?.Checklists.map((cObj) => {
                const status = cObj
                  ? cObj.Assigned == 0
                    ? "N/A"
                    : cObj.CompletedPercentage == 100
                      ? "Complete"
                      : "Not Complete"
                  : "N/A";
                if (status == "Not Complete") {
                  comNonCount = comNonCount + 1;
                }
              });
            }
            return "" + comNonCount;
          },
          renderCell: (params) => {
            const content = params.value || "";
            return (
              <Tooltip title={content} arrow>
                <span>{content}</span>
              </Tooltip>
            );
          },
        },
        {
          field: "No of Process Completed",
          headerName: "No of Process Completed",
          renderHeader: () => <strong>{"No of Process Completed"}</strong>,
          flex: 1,
          minWidth: 240,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let comCount = 0;
            if (params?.row?.Checklists) {
              params?.row?.Checklists.map((cObj) => {
                const status = cObj
                  ? cObj.Assigned == 0
                    ? "N/A"
                    : cObj.CompletedPercentage == 100
                      ? "Complete"
                      : "Not Complete"
                  : "N/A";
                if (status == "Complete") {
                  comCount = comCount + 1;
                }
              });
            }
            return "" + comCount;
          },
          renderCell: (params) => {
            const content = params.value || "";
            return (
              <Tooltip title={content} arrow>
                <span>{content}</span>
              </Tooltip>
            );
          },
        }
      );
      columns.push({
        field: "Checklists Completion %",
        headerName: "Checklists Completion %",
        renderHeader: () => <strong>{"Checklists Completion %"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let comCount = 0;
          let comNonCount = 0;
          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              const status = cObj
                ? cObj.Assigned == 0
                  ? "N/A"
                  : cObj.CompletedPercentage == 100
                    ? "Complete"
                    : "Not Complete"
                : "N/A";
              if (status == "Complete") {
                comCount = comCount + 1;
              } else if (status == "Not Complete") {
                comNonCount = comNonCount + 1;
              }
            });
          }
          if (comCount > 0 || comNonCount > 0) {
            return (
              "" +
              ((comCount / (comNonCount + comCount)) * 100)?.toFixed(2) +
              "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
      columns.push({
        field: "Average Compliance Score",
        headerName: "Average Compliance Score",
        renderHeader: () => <strong>{"Average Compliance Score"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let assignedCount = 0;
          let assignedComplianceScore = 0;

          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              if (cObj.Assigned > 0) {
                assignedCount += 1;
                assignedComplianceScore += cObj.ComplianceScore;
              }
            });
          }
          if (assignedComplianceScore > 0 || assignedCount > 0) {
            return (
              "" + (assignedComplianceScore / assignedCount)?.toFixed(2) + "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
      columns.push({
        field: "Total Average Completion",
        headerName: "Total Average Completion",
        renderHeader: () => <strong>{"Total Average Completion"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let assignedComplianceScore = 0;

          if (params?.row?.Checklists) {
            params?.row?.Checklists.map((cObj) => {
              if (cObj.Assigned > 0) {
                assignedComplianceScore += cObj.ComplianceScore;
              }
            });
          }
          if (assignedComplianceScore > 0) {
            return (
              "" +
              (
                assignedComplianceScore / params?.row?.Checklists?.length
              )?.toFixed(2) +
              "%"
            );
          } else {
            return "0.00%";
          }
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      });
    }
    setColumn(columns);
  }, [selectedDate]);

  useEffect(() => {
    const columns = [];
    const rowD = [];

    columns.push(
      {
        field: "S.No",
        headerName: "S.No",
        renderHeader: () => <strong>{"S.No"}</strong>,
        flex: 1,
        minWidth: 120,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return params.row.index;
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      },
      {
        field: "Date",
        headerName: "Date",
        renderHeader: () => <strong>{"Date"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          const dateValue = params.row.date.date;
          return moment(dateValue).format("MMM DD, YYYY") || "N/A";
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      },
      {
        field: "Store",
        headerName: "Store",
        renderHeader: () => <strong>{"Store"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          let result = params.row.StoreName;
          return result;
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      }
    );

    if (
      target === "Advance Question/Indent Wise Report" &&
      IntentCSVNamSubmissionData &&
      IntentCSVNamSubmissionData?.length > 0
    ) {
      let IndentchecklistList = IntentCSVNamSubmissionData.sort((a, b) => {
        return b.StoreName - a.StoreName;
      });
      if (
        IntentCSVNamSubmissionData &&
        IntentCSVNamSubmissionData?.length > 0
      ) {

        IndentchecklistList?.sort((a, b) =>
          a.StoreName < b.StoreName ? -1 : 1
        ).map((csvData, index) => {
          if (index == 0) {
            let formList =
              csvData.dates &&
                csvData.dates.length &&
                csvData.dates[0].checklist.forms &&
                csvData.dates[0].checklist.forms.length
                ? csvData.dates[0].checklist.forms
                : [];

            formList
              .sort((a, b) => (a.title > b.title ? -1 : 1))
              .map((fList) => {
                let quesList = fList.questions;

                quesList
                  .sort((a, b) => (a.title < b.title ? -1 : 1))
                  .map((qList, key) => {
                    columns.push({
                      field: qList.id + "---" + qList.title + "---" + fList.title,
                      headerName: qList.title,
                      renderHeader: () => <strong>{qList.title}</strong>,
                      flex: 1,
                      minWidth: 240,
                      align: "center",
                      headerAlign: "center",
                      // valueGetter: (params) => {
                      //   let result = "0";
                      //   params.row.date.checklist.forms
                      //     .sort((a, b) => (a.title > b.title ? -1 : 1))
                      //     .map((fList) => {
                      //       fList.questions
                      //         .sort((a, b) => (a.title < b.title ? -1 : 1))
                      //         .map((qList, key) => {
                      //           if (params.field.split("---")[0] === qList.id) {
                      //             if (
                      //               target ===
                      //                 "Advance Question/Indent Wise Report" &&
                      //               questionWiseViewBy === "Scorewise"
                      //             ) {
                      //               result =
                      //                 qList &&
                      //                 qList.answer &&
                      //                 qList.answer.answer &&
                      //                 qList.answer.answer.value
                      //                   ? qList.answer.answer.option_score
                      //                   : "0";
                      //             } else {
                      //               result =
                      //                 qList &&
                      //                 qList.answer &&
                      //                 qList.answer.answer &&
                      //                 qList.answer.answer.value
                      //                   ? qList.answer.answer.value === true
                      //                     ? "Yes"
                      //                     : qList.answer.answer.value === false
                      //                     ? "No"
                      //                     : qList.answer.answer.value
                      //                   : qList?.answer?.answer?.value === false
                      //                   ? "No"
                      //                   : "0";
                      //             }
                      //           }
                      //         });
                      //     });
                      //   return result;
                      // },
                      valueGetter: (params) => {
                        let result = "0";

                        // Loop through forms and questions
                        params.row.date.checklist.forms
                          .sort((a, b) => (a.title > b.title ? -1 : 1))
                          .map((fList) => {
                            fList.questions
                              .sort((a, b) => (a.title < b.title ? -1 : 1))
                              .map((qList, key) => {
                                if (params.field.split("---")[0] === `${qList.id}`) {
                                  // Handle file type
                                  if (qList?.answer?.type === "file") {
                                    if (
                                      qList?.answer?.answer &&
                                      Array.isArray(
                                        qList?.answer?.answer?.answer
                                      ) &&
                                      qList?.answer?.answer?.answer?.length > 0
                                    ) {
                                      result = "Yes";
                                    } else {
                                      result = "No";
                                    }
                                  }
                                  // Handle datetime type
                                  else if (qList?.answer?.type === "datetime") {
                                    if (
                                      qList?.answer?.answer &&
                                      qList?.answer?.answer?.date
                                    ) {
                                      const date = qList?.answer?.answer?.date;
                                      const time =
                                        qList?.answer?.answer?.time || "";
                                      result = `${date} ${time}`.trim();
                                    }
                                  }
                                  // Default behavior for other answer types
                                  else {
                                    if (
                                      target ===
                                      "Advance Question/Indent Wise Report" &&
                                      questionWiseViewBy === "Scorewise"
                                    ) {
                                      result =
                                        qList &&
                                          qList?.answer &&
                                          qList?.answer?.answer &&
                                          qList?.answer?.answer?.value
                                          ? `${qList?.answer?.answer?.option_score}`
                                          : "0";
                                    } else {
                                      result =
                                        qList &&
                                          qList?.answer &&
                                          qList?.answer?.answer &&
                                          qList?.answer?.answer?.value
                                          ? qList?.answer?.answer?.value !== undefined
                                            ? qList?.answer?.answer?.value === true
                                              ? "Yes"
                                              : qList.answer?.answer?.value ===
                                                false
                                                ? "No"
                                                : qList?.answer?.answer.value
                                            : "0"
                                          : qList?.answer?.answer?.title ? qList?.answer?.answer?.title : "0";
                                    }
                                  }
                                }
                              });
                          });

                        return result;
                      },

                      renderCell: (params) => {
                        const content = params.value || "";
                        return <span>{content}</span>;
                      },
                    });
                  });
              });


          }

          let datesList =
            csvData.dates && csvData.dates.length && csvData.dates;
          datesList.map((date) => {
            rowD.push({
              index: rowD.length + 1,
              StoreName: csvData.StoreName,
              date,
            });
          });
        });
      }
    }

    columns.push(
      {
        field: "Submitted By",
        headerName: "Submitted By",
        renderHeader: () => <strong>{"Submitted By"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return params.row.date.checklist.userName || "Not Submitted";
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      },
      {
        field: "Submission Date UTC +05:30 (dd/mm/yyyy)",
        headerName: "Submission Date UTC +05:30 (dd/mm/yyyy)",
        renderHeader: () => (
          <strong>{"Submission Date UTC +05:30 (dd/mm/yyyy)"}</strong>
        ),
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          const updatedAt = params.row.date.checklist.updatedAt;
          return params.row.date.checklist.userName
            ? moment(updatedAt).format("lll") || "N/A"
            : "N/A";
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      },
      {
        field: "Time taken for submission",
        headerName: "Time taken for submission",
        renderHeader: () => (
          <strong>{"Time taken for submission"}</strong>
        ),
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return getSubTime(params.row.date.checklist);
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      },
      {
        field: "Status",
        headerName: "Status",
        renderHeader: () => <strong>{"Status"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return params.row.date.checklist.userName
            ? "Submitted"
            : "Not Submitted";
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      },
      {
        field: "PDF Report",
        headerName: "PDF Report",
        renderHeader: () => <strong>{"PDF Report"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return params.row.date?.checklist?.pdfReport || "N/A";
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      },
      {
        field: "Complete Percentage",
        headerName: "Complete Percentage",
        renderHeader: () => <strong>{"Complete Percentage"}</strong>,
        flex: 1,
        minWidth: 240,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return (
            (params.row.date.checklist.completePercentage || 0) + "%"
          );
        },
        renderCell: (params) => {
          const content = params.value || "";
          return <span>{content}</span>;
        },
      }
    );

    // console.log("columns", columns);
    // console.log("rowD", rowD);

    setIndentColumn(columns);
    setIndentRow(rowD);
    setOpenFilterModal(false);
  }, [IntentCSVNamSubmissionData]);

  console.log(
    "indentRow", indentRow
  );
  console.log(
    "indentColumn", indentColumn
  );
  useEffect(() => {
    getApi();
  }, []);

  useEffect(() => {
    if (tableData) {
      setactiveChecklistData(tableData);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [tableData, setactiveChecklistData]);

  useEffect(() => {
    if (tableData) {
      let arr = [];
      for (let item of tableData) {
        let obj = { _id: item?._id, title: item?.title };
        arr.push(obj);
      }
      setMapSelectData(arr);
    }
  }, [tableData]);

  useEffect(() => {
    if (
      CSVNamSubmissionDataUserWise &&
      CSVNamSubmissionDataUserWise?.length > 0
    ) {
      openFilterModal && setOpenFilterModal(false)
      setSelectedDate(CSVNamSubmissionDataUserWise[0]);
      setPaginatedData(CSVNamSubmissionDataUserWise[0].Users);
    } else {
      setSelectedDate("");
      setPaginatedData([]);
    }

    let subArr = [];
    let all = "All Dates";
    let allColumnsList = [];
    let allCsvSubRows = [];
    let allCsvColumns = [];

    console.log("CSVNamSubmissionDataUserWise", CSVNamSubmissionDataUserWise);

    CSVNamSubmissionDataUserWise &&
      CSVNamSubmissionDataUserWise?.length > 0 &&
      CSVNamSubmissionDataUserWise?.map((csvData, index) => {
        const usersList = csvData.Users;
        let checklistList = usersList[0]?.Checklists;

        // if(checklistList.length > allCsvColumns.length){
        checklistList && allCsvColumns.push(...checklistList);
        // }
      });
    const uniqueAllChecklist = [
      ...new Map(
        allCsvColumns.map((item) => [item["ChecklistId"], item])
      ).values(),
    ];

    CSVNamSubmissionDataUserWise &&
      CSVNamSubmissionDataUserWise?.length > 0 &&
      CSVNamSubmissionDataUserWise?.sort((a, b) =>
        a.Date < b.Date ? -1 : 1
      ).map((csvData, index) => {
        const newStartDate = new Date(csvData.Date);
        const yyyySD = newStartDate.getFullYear();
        let mmSD = newStartDate.getMonth() + 1;
        let ddSD = newStartDate.getDate();
        if (ddSD < 10) ddSD = "0" + ddSD;
        if (mmSD < 10) mmSD = "0" + mmSD;
        const formattedSD = yyyySD + "-" + mmSD + "-" + ddSD;
        let columsList = [];
        let csvSubRows = [];
        let inerAllcsvSubRows = [];
        let colRowOne = [];
        let allColRowOne = [];
        const usersList = csvData.Users;
        const usersListDate = moment(csvData.Date).format("YYYY-MM-DD");

        columsList.push(
          {
            value: "",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          }
        );
        colRowOne.push(
          {
            value: "S.No",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Date",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Store Name",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "User Name",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          }
        );

        allColumnsList.push(...columsList);
        allColRowOne.push(...colRowOne);

        if (usersList && usersList.length) {
          let getIndex = 0;
          usersList.map((user, userIndex) =>
            user.Checklists.map((checklist) =>
              checklist.SubmissionDate === null || checklist.SubmissionDate
                ? (getIndex = userIndex)
                : ""
            )
          );
          let checklistList = usersList[getIndex].Checklists;

          if (displayBy === "ByComplianceScore") {
            checklistList
              .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
              .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
              .map((cList) => {
                const columnObj = {
                  value: getFreqType(cList.Frequency),
                  widthPx: 90,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                };
                columsList.push(
                  columnObj,
                  {
                    value: "",
                    widthPx: 90,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }
                );

                colRowOne.push({
                  value: cList.ChecklistName,
                  widthPx: 130,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }, {
                  value: "Approval Status",
                  widthPx: 130,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                });

                if (cList.SubmissionDate === null || cList.SubmissionDate) {
                  columsList.push({
                    value: "",
                    widthPx: 90,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                  colRowOne.push({
                    value: "Submission Date",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
                // if(cList.SubmissionDate){
                //   columsList.push(
                //     {
                //       value: "",
                //       widthPx: 90,
                //       style: {
                //         font: { sz: "24", bold: true },
                //         alignment: { wrapText: true },
                //       },
                //     }
                //   );
                //   colRowOne.push({
                //     value: "Submission Date",
                //     widthPx: 130,
                //     style: {
                //       font: { sz: "24", bold: true },
                //       alignment: { wrapText: true },
                //     },
                //   });
                // }
                if (viewBy === "ByForm") {
                  cList?.Forms?.sort((a, b) =>
                    a.FormName < b.FormName ? -1 : 1
                  ).map((form) => {
                    columsList.push({
                      value: "",
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });

                    colRowOne.push({
                      value: form.FormName,
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  });
                }
              });

            columsList.push(
              {
                value: "No of Process not complete",
                widthPx: 120,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "No of Process Complete",
                widthPx: 120,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Checklists Completion %",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Average compliance Score",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Total Average Completion",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              }
            );
          }
          if (displayBy === "ByStatus") {
            checklistList
              .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
              .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
              .map((cList) => {
                const columnObj = {
                  value: getFreqType(cList.Frequency),
                  widthPx: 90,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                };
                columsList.push(
                  columnObj,
                  {
                    value: "",
                    widthPx: 90,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }
                );
                const rowObj = {
                  value: cList.ChecklistName,
                  widthPx: 90,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                };

                colRowOne.push(
                  rowObj,
                  {
                    value: "Approval Status",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }
                );

                if (cList.SubmissionDate === null || cList.SubmissionDate) {
                  colRowOne.push({
                    value: "Submission Date",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                  columsList.push({
                    value: "",
                    widthPx: 90,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
                // if(cList.AuditSubmission === true){
                //   colRowOne.push({
                //     value: "Submission Date",
                //     widthPx: 130,
                //     style: {
                //       font: { sz: "24", bold: true },
                //       alignment: { wrapText: true },
                //     },
                //   });
                //   columsList.push(
                //     {
                //       value: "",
                //       widthPx: 90,
                //       style: {
                //         font: { sz: "24", bold: true },
                //         alignment: { wrapText: true },
                //       },
                //     }
                //   );
                // }

                if (viewBy === "ByForm") {
                  cList?.Forms?.sort((a, b) =>
                    a.FormName < b.FormName ? -1 : 1
                  ).map((form) => {
                    columsList.push({
                      value: "",
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                    colRowOne.push({
                      value: form.FormName,
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  });
                }
              });

            columsList.push(
              {
                value: "No of Process not complete",
                widthPx: 120,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "No of Process Complete",
                widthPx: 120,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Checklists Completion %",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Average compliance Score",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Total Average Completion",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              }
            );
          }
          if (displayBy === "ByPercentage") {
            checklistList
              .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
              .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
              .map((cList) => {
                const columnObj = {
                  value: getFreqType(cList.Frequency),
                  widthPx: 90,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                };
                columsList.push(
                  columnObj,
                  {
                    value: "",
                    widthPx: 90,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }
                );
                const rowObj = {
                  value: cList.ChecklistName,
                  widthPx: 90,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                };
                colRowOne.push(rowObj,
                  {
                    value: "Approval Status",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }
                );

                if (cList.SubmissionDate === null || cList.SubmissionDate) {
                  colRowOne.push({
                    value: "Submission Date",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                  columsList.push({
                    value: "",
                    widthPx: 90,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
                // if(cList.AuditSubmission === true){
                //   colRowOne.push({
                //     value: "Submission Date",
                //     widthPx: 130,
                //     style: {
                //       font: { sz: "24", bold: true },
                //       alignment: { wrapText: true },
                //     },
                //   });
                //   columsList.push(
                //     {
                //       value: "",
                //       widthPx: 90,
                //       style: {
                //         font: { sz: "24", bold: true },
                //         alignment: { wrapText: true },
                //       },
                //     }
                //   );
                // }

                if (viewBy === "ByForm") {
                  cList?.Forms?.sort((a, b) =>
                    a.FormName < b.FormName ? -1 : 1
                  ).map((form) => {
                    columsList.push({
                      value: "",
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                    colRowOne.push({
                      value: form.FormName,
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  });
                }
              });

            columsList.push(
              {
                value: "No of Process not complete",
                widthPx: 120,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "No of Process Complete",
                widthPx: 120,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Checklists Completion %",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Average compliance Score",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Total Average Completion",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              }
            );
          }

          uniqueAllChecklist
            .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
            .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
            .map((cList) => {
              const columnObj = {
                value: getFreqType(cList.Frequency),
                widthPx: 90,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              };
              allColumnsList.push(
                columnObj,
                {
                  value: "",
                  widthPx: 90,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );

              const rowObj = {
                value: cList.ChecklistName,
                widthPx: 90,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              };
              allColRowOne.push(rowObj,
                {
                  value: "Approval Status",
                  widthPx: 130,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );

              if (viewBy === "ByForm") {
                cList?.Forms?.sort((a, b) =>
                  a.FormName < b.FormName ? -1 : 1
                ).map((form) => {
                  allColumnsList.push({
                    value: "",
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                  allColRowOne.push({
                    value: form.FormName,
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                });
              }
            });

          allColumnsList.push(
            {
              value: "No of Process not complete",
              widthPx: 120,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "No of Process Complete",
              widthPx: 120,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "Checklists Completion %",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "Average compliance Score",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "Total Average Completion",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            }
          );

          csvSubRows.push(colRowOne);
          inerAllcsvSubRows.push(allColRowOne);
          if (index === 0) {
            allCsvSubRows.push(...inerAllcsvSubRows);
          }
          usersList.map((sList, i) => {
            const dName = usersListDate;
            const sName = sList.StoreName;
            const uName = sList.Username;
            const cList = sList.Checklists;
            let colRow = [];
            let allColRow = [];
            colRow.push(
              {
                value: "" + (i + 1),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFF0000" },
                  },
                },
              },
              {
                value: dName,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFF0000" },
                  },
                },
              },
              {
                value: sName,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFF0000" },
                  },
                },
              },
              {
                value: uName,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFF0000" },
                  },
                },
              }
            );
            allColRow.push(...colRow);

            if (displayBy === "ByComplianceScore") {
              let comCount = 0;
              let comNonCount = 0;

              let assignedCount = 0;
              let assignedComplianceScore = 0;

              uniqueAllChecklist
                .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
                .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
                .map((uObj) => {
                  const getCObj = cList.filter(
                    (gObj) => gObj.ChecklistId === uObj.ChecklistId
                  );
                  const cObj = getCObj && getCObj.length && getCObj[0];

                  if (cObj) {
                    const columnObj = {
                      value: cObj.ComplianceScore?.toFixed(2)
                        ? cObj.ComplianceScore?.toFixed(2) + "%"
                        : "0.00%",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    };

                    if (cObj.Assigned > 0) {
                      if (
                        cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                        cObj.ChecklistName === "C16 - PRN"
                      ) {
                        columnObj.value = "100%";
                      }
                    }

                    allColRow.push(columnObj,
                      {
                        value: cObj?.approvalStatus === "Not Applicable"
                          ? "N/A"
                          : cObj?.approvalStatus || "-",
                        style: {
                          fill: {
                            patternType: "solid",
                          },
                        },
                      }
                    );

                    if (viewBy === "ByForm") {
                      cObj?.Forms?.sort((a, b) =>
                        a.FormName < b.FormName ? -1 : 1
                      ).map((form) => {
                        allColRow.push({
                          value: form.ComplianceScore?.toFixed(2)
                            ? form.ComplianceScore?.toFixed(2) + "%"
                            : "0.00%",
                          widthPx: 120,
                          style: {
                            font: { sz: "24", bold: true },
                            alignment: { wrapText: true },
                            fgColor: {
                              rgb: form.Completed ? "c8ee75" : "d87a7a",
                            },
                          },
                        });
                      });
                    }
                  } else {
                    const columnObj = {
                      value: "N/A",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    };

                    allColRow.push(columnObj);

                    if (viewBy === "ByForm") {
                      uObj?.Forms?.sort((a, b) =>
                        a.FormName < b.FormName ? -1 : 1
                      ).map((form) => {
                        allColRow.push({
                          value: "N/A",
                          widthPx: 120,
                          style: {
                            font: { sz: "24", bold: true },
                            alignment: { wrapText: true },
                            fgColor: {
                              rgb: form.Completed ? "c8ee75" : "d87a7a",
                            },
                          },
                        });
                      });
                    }
                  }
                });

              cList
                .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
                .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
                .map((cObj, cIndex) => {
                  const status = cObj
                    ? cObj.Assigned == 0
                      ? "N/A"
                      : cObj.CompletedPercentage == 100 ||
                        cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                        cObj.ChecklistName === "C16 - PRN"
                        ? "Complete"
                        : "Not Complete"
                    : "N/A";
                  if (status == "Complete") {
                    comCount = comCount + 1;
                  } else if (status == "Not Complete") {
                    comNonCount = comNonCount + 1;
                  }

                  if (cObj.Assigned > 0) {
                    assignedCount += 1;
                    if (
                      cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                      cObj.ChecklistName === "C16 - PRN"
                    ) {
                      assignedComplianceScore += 100;
                    } else {
                      assignedComplianceScore += cObj.ComplianceScore
                        ? cObj.ComplianceScore
                        : 0;
                    }
                  }

                  const columnObj = {
                    value: cObj.ComplianceScore?.toFixed(2)
                      ? cObj.ComplianceScore?.toFixed(2) + "%"
                      : "0.00%",
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  };

                  if (cObj.Assigned > 0) {
                    if (
                      cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                      cObj.ChecklistName === "C16 - PRN"
                    ) {
                      columnObj.value = "100%";
                    }
                  }
                  // colRow.push(columnObj);
                  colRow.push(columnObj,
                    {
                      value: cObj?.approvalStatus === "Not Applicable"
                        ? "N/A"
                        : cObj?.approvalStatus || "-",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    }
                  );

                  if (cObj.SubmissionDate) {
                    colRow.push({
                      value: moment(cObj.SubmissionDate).format("YYYY-MM-DD"),
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  } else if (cObj.SubmissionDate === null) {
                    colRow.push({
                      value: "N/A",
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  } else {
                    if (
                      !cObj.SubmissionDate &&
                      colRowOne?.[colRow.length]?.value === "Submission Date"
                    ) {
                      colRow.push({
                        value: "N/A",
                        widthPx: 120,
                        style: {
                          font: { sz: "24", bold: true },
                          alignment: { wrapText: true },
                        },
                      });
                    }
                  }

                  if (viewBy === "ByForm") {
                    cObj?.Forms?.sort((a, b) =>
                      a.FormName < b.FormName ? -1 : 1
                    ).map((form) => {
                      colRow.push({
                        value: form.ComplianceScore?.toFixed(2)
                          ? form.ComplianceScore?.toFixed(2) + "%"
                          : "0.00%",
                        widthPx: 120,
                        style: {
                          font: { sz: "24", bold: true },
                          alignment: { wrapText: true },
                        },
                      });
                    });
                  }
                });

              colRow.push(
                {
                  value: "" + comNonCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: "" + comCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    comCount && assignedCount
                      ? ((comCount / assignedCount) * 100)?.toFixed(2) + "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    assignedComplianceScore && assignedCount
                      ? (assignedComplianceScore / assignedCount)?.toFixed(2) +
                      "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: assignedComplianceScore
                    ? (assignedComplianceScore / cList.length)?.toFixed(2) + "%"
                    : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );

              allColRow.push(
                {
                  value: "" + comNonCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: "" + comCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    comCount && assignedCount
                      ? ((comCount / assignedCount) * 100)?.toFixed(2) + "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    assignedComplianceScore && assignedCount
                      ? (assignedComplianceScore / assignedCount)?.toFixed(2) +
                      "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: assignedComplianceScore
                    ? (assignedComplianceScore / cList.length)?.toFixed(2) + "%"
                    : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );
            }
            if (displayBy === "ByStatus") {
              let comCount = 0;
              let comNonCount = 0;

              let assignedCount = 0;
              let assignedComplianceScore = 0;

              uniqueAllChecklist
                .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
                .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
                .map((uObj) => {
                  const getCObj = cList.filter(
                    (gObj) => gObj.ChecklistId === uObj.ChecklistId
                  );
                  const cObj = getCObj && getCObj.length && getCObj[0];

                  if (cObj) {
                    const status = cObj
                      ? cObj.Assigned == 0
                        ? "N/A"
                        : cObj.CompletedPercentage == 100 ||
                          cObj.ChecklistName ===
                          "C15 GRN (Goods Receipt Note)" ||
                          cObj.ChecklistName === "C16 - PRN"
                          ? "Complete"
                          : "Not Complete"
                      : "N/A";

                    const columnObj = {
                      value: status,
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    };

                    allColRow.push(columnObj,
                      {
                        value: cObj?.approvalStatus === "Not Applicable"
                          ? "N/A"
                          : cObj?.approvalStatus || "-",
                        style: {
                          fill: {
                            patternType: "solid",
                          },
                        },
                      }
                    );

                    if (viewBy === "ByForm") {
                      cObj?.Forms?.sort((a, b) =>
                        a.FormName < b.FormName ? -1 : 1
                      ).map((form) => {
                        allColRow.push({
                          value:
                            status === "N/A"
                              ? status
                              : form.Completed
                                ? "Completed"
                                : "Not Completed",
                          widthPx: 120,
                          style: {
                            font: { sz: "24", bold: true },
                            alignment: { wrapText: true },
                            fgColor: {
                              rgb: form.Completed ? "c8ee75" : "d87a7a",
                            },
                          },
                        });
                      });
                    }
                  } else {
                    const columnObj = {
                      value: "N/A",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    };

                    allColRow.push(columnObj);

                    if (viewBy === "ByForm") {
                      uObj?.Forms?.sort((a, b) =>
                        a.FormName < b.FormName ? -1 : 1
                      ).map((form) => {
                        allColRow.push({
                          value: "N/A",
                          widthPx: 120,
                          style: {
                            font: { sz: "24", bold: true },
                            alignment: { wrapText: true },
                            fgColor: {
                              rgb: form.Completed ? "c8ee75" : "d87a7a",
                            },
                          },
                        });
                      });
                    }
                  }
                });

              cList
                .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
                .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
                .map((cObj, cIndex) => {
                  const status = cObj
                    ? cObj.Assigned == 0
                      ? "N/A"
                      : cObj.CompletedPercentage == 100 ||
                        cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                        cObj.ChecklistName === "C16 - PRN"
                        ? "Complete"
                        : "Not Complete"
                    : "N/A";
                  if (status == "Complete") {
                    comCount = comCount + 1;
                  } else if (status == "Not Complete") {
                    comNonCount = comNonCount + 1;
                  }

                  if (cObj.Assigned > 0) {
                    assignedCount += 1;
                    assignedComplianceScore += cObj.ComplianceScore
                      ? cObj.ComplianceScore
                      : 0;
                  }
                  const columnObj = {
                    value: status,
                    style: {
                      fill: {
                        patternType: "solid",
                        fgColor: {
                          rgb: status == "Complete" ? "c8ee75" : "d87a7a",
                        },
                      },
                    },
                  };

                  colRow.push(columnObj,
                    {
                      value: cObj?.approvalStatus === "Not Applicable"
                        ? "N/A"
                        : cObj?.approvalStatus || "-",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    }
                  );

                  if (cObj.SubmissionDate) {
                    colRow.push({
                      value: moment(cObj.SubmissionDate).format("YYYY-MM-DD"),
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  } else if (cObj.SubmissionDate === null) {
                    colRow.push({
                      value: "N/A",
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  } else {
                    if (
                      !cObj.SubmissionDate &&
                      colRowOne?.[colRow.length]?.value === "Submission Date"
                    ) {
                      colRow.push({
                        value: "N/A",
                        widthPx: 120,
                        style: {
                          font: { sz: "24", bold: true },
                          alignment: { wrapText: true },
                        },
                      });
                    }
                  }

                  if (viewBy === "ByForm") {
                    cObj?.Forms?.sort((a, b) =>
                      a.FormName < b.FormName ? -1 : 1
                    ).map((form) => {
                      colRow.push({
                        value:
                          status === "N/A"
                            ? status
                            : form.Completed
                              ? "Completed"
                              : "Not Completed",
                        widthPx: 120,
                        style: {
                          font: { sz: "24", bold: true },
                          alignment: { wrapText: true },
                        },
                      });
                    });
                  }
                });

              colRow.push(
                {
                  value: "" + comNonCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: "" + comCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    comCount && assignedCount
                      ? ((comCount / assignedCount) * 100)?.toFixed(2) + "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    assignedComplianceScore && assignedCount
                      ? (assignedComplianceScore / assignedCount)?.toFixed(2) +
                      "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: assignedComplianceScore
                    ? (assignedComplianceScore / cList.length)?.toFixed(2) + "%"
                    : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );

              allColRow.push(
                {
                  value: "" + comNonCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: "" + comCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    comCount && assignedCount
                      ? ((comCount / assignedCount) * 100)?.toFixed(2) + "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    assignedComplianceScore && assignedCount
                      ? (assignedComplianceScore / assignedCount)?.toFixed(2) +
                      "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: assignedComplianceScore
                    ? (assignedComplianceScore / cList.length)?.toFixed(2) + "%"
                    : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );
            }
            if (displayBy === "ByPercentage") {
              let comCount = 0;
              let comNonCount = 0;

              let assignedCount = 0;
              let assignedComplianceScore = 0;

              cList
                .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
                .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
                .map((cObj, cIndex) => {
                  const status = cObj
                    ? cObj.Assigned == 0
                      ? "N/A"
                      : cObj.CompletedPercentage == 100 ||
                        cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                        cObj.ChecklistName === "C16 - PRN"
                        ? "Complete"
                        : "Not Complete"
                    : "N/A";
                  if (status == "Complete") {
                    comCount = comCount + 1;
                  } else if (status == "Not Complete") {
                    comNonCount = comNonCount + 1;
                  }

                  if (cObj.Assigned > 0) {
                    assignedCount += 1;
                    if (
                      cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                      cObj.ChecklistName === "C16 - PRN"
                    ) {
                      assignedComplianceScore += 100;
                    } else {
                      assignedComplianceScore += cObj.ComplianceScore
                        ? cObj.ComplianceScore
                        : 0;
                    }
                  }

                  let result = "";
                  if (isNaN(cObj.Completed / cObj.Assigned)) {
                    result = (0 * 100).toFixed(2) + "%";
                  } else {
                    result =
                      ((cObj.Completed / cObj.Assigned) * 100)?.toFixed(2) +
                      "%";
                  }

                  const columnObj = {
                    value: cObj?.CompletedPercentage
                      ? cObj.CompletedPercentage.toFixed(2) + "%"
                      : "0.00%",
                    style: {
                      fill: {
                        patternType: "solid",
                      },
                    },
                  };

                  if (cObj.Assigned > 0) {
                    if (
                      cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                      cObj.ChecklistName === "C16 - PRN"
                    ) {
                      columnObj.value = "100%";
                    }
                  }

                  colRow.push(columnObj,
                    {
                      value: cObj?.approvalStatus === "Not Applicable"
                        ? "N/A"
                        : cObj?.approvalStatus || "-",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    }
                  );

                  if (cObj.SubmissionDate) {
                    colRow.push({
                      value: moment(cObj.SubmissionDate).format("YYYY-MM-DD"),
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  } else if (cObj.SubmissionDate === null) {
                    colRow.push({
                      value: "N/A",
                      widthPx: 120,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  } else {
                    if (
                      !cObj.SubmissionDate &&
                      colRowOne?.[colRow.length]?.value === "Submission Date"
                    ) {
                      colRow.push({
                        value: "N/A",
                        widthPx: 120,
                        style: {
                          font: { sz: "24", bold: true },
                          alignment: { wrapText: true },
                        },
                      });
                    }
                  }

                  if (viewBy === "ByForm") {
                    cObj?.Forms?.sort((a, b) =>
                      a.FormName < b.FormName ? -1 : 1
                    ).map((form) => {
                      colRow.push({
                        value: form.Completed ? "100%" : "0%",
                        widthPx: 120,
                        style: {
                          font: { sz: "24", bold: true },
                          alignment: { wrapText: true },
                          fgColor: {
                            rgb: form.Completed ? "c8ee75" : "d87a7a",
                          },
                        },
                      });
                    });
                  }
                });

              uniqueAllChecklist
                .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
                .sort((a, b) => (a.Frequency < b.Frequency ? -1 : 1))
                .map((uObj) => {
                  const getCObj = cList.filter(
                    (gObj) => gObj.ChecklistId === uObj.ChecklistId
                  );
                  const cObj = getCObj && getCObj.length && getCObj[0];

                  if (cObj) {
                    const columnObj = {
                      value:
                        cObj.Assigned == 0
                          ? "N/A"
                          : cObj?.CompletedPercentage + ".00%" ?? "0.00%",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    };

                    if (cObj.Assigned > 0) {
                      if (
                        cObj.ChecklistName === "C15 GRN (Goods Receipt Note)" ||
                        cObj.ChecklistName === "C16 - PRN"
                      ) {
                        columnObj.value = "100%";
                      }
                    }

                    allColRow.push(columnObj,
                      {
                        value: cObj?.approvalStatus === "Not Applicable"
                          ? "N/A"
                          : cObj?.approvalStatus || "-",
                        style: {
                          fill: {
                            patternType: "solid",
                          },
                        },
                      }
                    );

                    if (viewBy === "ByForm") {
                      cObj?.Forms?.sort((a, b) =>
                        a.FormName < b.FormName ? -1 : 1
                      ).map((form) => {
                        allColRow.push({
                          value: form.Completed ? "100%" : "0%",
                          widthPx: 120,
                          style: {
                            font: { sz: "24", bold: true },
                            alignment: { wrapText: true },
                            fgColor: {
                              rgb: form.Completed ? "c8ee75" : "d87a7a",
                            },
                          },
                        });
                      });
                    }
                  } else {
                    const columnObj = {
                      value: "N/A",
                      style: {
                        fill: {
                          patternType: "solid",
                        },
                      },
                    };

                    allColRow.push(columnObj);

                    if (viewBy === "ByForm") {
                      uObj?.Forms?.sort((a, b) =>
                        a.FormName < b.FormName ? -1 : 1
                      ).map((form) => {
                        allColRow.push({
                          value: "N/A",
                          widthPx: 120,
                          style: {
                            font: { sz: "24", bold: true },
                            alignment: { wrapText: true },
                            fgColor: {
                              rgb: form.Completed ? "c8ee75" : "d87a7a",
                            },
                          },
                        });
                      });
                    }
                  }
                });

              colRow.push(
                {
                  value: "" + comNonCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: "" + comCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    comCount && assignedCount
                      ? ((comCount / assignedCount) * 100)?.toFixed(2) + "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    assignedComplianceScore && assignedCount
                      ? (assignedComplianceScore / assignedCount)?.toFixed(2) +
                      "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: assignedComplianceScore
                    ? (assignedComplianceScore / cList.length)?.toFixed(2) + "%"
                    : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );
              allColRow.push(
                {
                  value: "" + comNonCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: "" + comCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    comCount && assignedCount
                      ? ((comCount / assignedCount) * 100)?.toFixed(2) + "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    assignedComplianceScore && assignedCount
                      ? (assignedComplianceScore / assignedCount)?.toFixed(2) +
                      "%"
                      : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: assignedComplianceScore
                    ? (assignedComplianceScore / cList.length)?.toFixed(2) + "%"
                    : "0.00%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );
            }

            csvSubRows.push(colRow);

            const modifiedRow = [];

            modifiedRow.push(...allColRow);
            modifiedRow.shift();
            modifiedRow.unshift({
              value: `${allCsvSubRows.length}`,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "FFFF0000" },
                },
              },
            });
            allCsvSubRows.push(modifiedRow);
          });
        }
        // if (allColumnsList.length < columsList.length) {
        //   allColumnsList = columsList;
        // }

        const dataSet = [
          {
            columns: columsList,
            data: csvSubRows,
          },
        ];
        console.log("dataSet", dataSet);
        subArr.push({ formattedSD, dataSet });
      });

    if (subArr.length && cSVNamSubmissionFlag) {
      const dataSet = [
        {
          columns: allColumnsList,
          data: allCsvSubRows,
        },
      ];
      subArr.push({ formattedSD: all, dataSet });
      setCSVNamSubmissionFlag(false);
      // Sort subArr by date in ascending order
      subArr.sort((a, b) => new Date(b.formattedSD) - new Date(a.formattedSD));
      setCSVNamSubmissionsUserWiseData(subArr);
    }
  }, [CSVNamSubmissionDataUserWise]);

  useEffect(() => {
    let subArr = [];
    CSVNamSubmissionData &&
      CSVNamSubmissionData?.length > 0 &&
      CSVNamSubmissionData?.map((csvData, index) => {
        const newStartDate = new Date(csvData.Date);
        const yyyySD = newStartDate.getFullYear();
        let mmSD = newStartDate.getMonth() + 1;
        let ddSD = newStartDate.getDate();
        if (ddSD < 10) ddSD = "0" + ddSD;
        if (mmSD < 10) mmSD = "0" + mmSD;
        const formattedSD = yyyySD + "-" + mmSD + "-" + ddSD;
        let columsList = [];
        let csvSubRows = [];
        let colRowOne = [];
        const storeList = csvData.Stores;
        columsList.push(
          {
            value: "",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          }
        );
        colRowOne.push(
          {
            value: "S.No",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Store Name",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          }
        );
        if (storeList && storeList.length) {
          let checklistList = storeList[0].Checklists;

          // checklistList
          //   .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
          //   .map((cList) => {
          //     const columnObj = {
          //       value: getFreqType(cList.Frequency),
          //       widthPx: 90,
          //       style: {
          //         font: { sz: "24", bold: true },
          //         alignment: { wrapText: true },
          //       },
          //     };
          //     columsList.push(columnObj);
          //     const rowObj = {
          //       value: cList.ChecklistName,
          //       widthPx: 90,
          //       style: {
          //         font: { sz: "24", bold: true },
          //         alignment: { wrapText: true },
          //       },
          //     };
          //     colRowOne.push(rowObj);
          //   });
          // columsList.push(
          //   {
          //     value: "No of Process not complete",
          //     widthPx: 120,
          //     style: {
          //       font: { sz: "24", bold: true },
          //       alignment: { wrapText: true },
          //     },
          //   },
          //   {
          //     value: "No of Process Complete",
          //     widthPx: 120,
          //     style: {
          //       font: { sz: "24", bold: true },
          //       alignment: { wrapText: true },
          //     },
          //   },
          //   {
          //     value: "Checklists Completion %",
          //     widthPx: 130,
          //     style: {
          //       font: { sz: "24", bold: true },
          //       alignment: { wrapText: true },
          //     },
          //   }
          // );
          // colRowOne.push(
          //   {
          //     value: "",
          //     widthPx: 120,
          //     style: {
          //       font: { sz: "24", bold: true },
          //       alignment: { wrapText: true },
          //     },
          //   },
          //   {
          //     value: "",
          //     widthPx: 120,
          //     style: {
          //       font: { sz: "24", bold: true },
          //       alignment: { wrapText: true },
          //     },
          //   },
          //   {
          //     value: "",
          //     widthPx: 120,
          //     style: {
          //       font: { sz: "24", bold: true },
          //       alignment: { wrapText: true },
          //     },
          //   }
          // );
          // csvSubRows.push(colRowOne);
          storeList
            .sort((a, b) => (a.StoreName < b.StoreName ? -1 : 1))
            .map((sList, index) => {
              const sName = sList.StoreName;
              const cList = sList.Checklists;
              let colRow = [];
              colRow.push(
                {
                  value: "" + (index + 1),
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: sName,
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                }
              );
              let comCount = 0;
              let comNonCount = 0;
              cList
                .sort((a, b) => (a.ChecklistName < b.ChecklistName ? -1 : 1))
                .map((cObj, cIndex) => {
                  if (index === 0) {
                    const columnObj = {
                      value: getFreqType(cObj.Frequency),
                      widthPx: 90,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    };
                    columsList.push(columnObj);
                    const rowObj = {
                      value: cObj.ChecklistName,
                      widthPx: 90,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    };
                    colRowOne.push(rowObj);
                  }
                  const status = cObj
                    ? cObj.Assigned == 0
                      ? "N/A"
                      : cObj.Missed == 0
                        ? "Complete"
                        : cObj.Missed < 0
                          ? "Complete"
                          : "Not Complete"
                    : "N/A";
                  if (status == "Complete") {
                    comCount = comCount + 1;
                  } else if (status == "Not Complete") {
                    comNonCount = comNonCount + 1;
                  }
                  const columnObj = {
                    value: status,
                    style: {
                      fill: {
                        patternType: "solid",
                        fgColor: {
                          rgb: status == "Complete" ? "c8ee75" : "d87a7a",
                        },
                      },
                    },
                  };
                  colRow.push(columnObj);
                });

              if (index === 0) {
                columsList.push(
                  {
                    value: "No of Process not complete",
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: "No of Process Complete",
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: "Checklists Completion %",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }
                );
                colRowOne.push(
                  {
                    value: "",
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: "",
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: "",
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }
                );
                csvSubRows.push(colRowOne);
              }
              colRow.push(
                {
                  value: "" + comNonCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: "" + comCount,
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value:
                    ((comCount / (comNonCount + comCount)) * 100)?.toFixed(2) +
                    "%",
                  widthPx: 120,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );
              csvSubRows.push(colRow);
            });
        }
        const dataSet = [
          {
            columns: columsList,
            data: csvSubRows,
          },
        ];
        subArr.push({ formattedSD, dataSet });
      });
    if (subArr && cSVNamSubmissionFlag) {
      setCSVNamSubmissionFlag(false);
      setCSVNamSubmissionsData(subArr);
      setTimeout(() => {
        btnRef.current.click();
      }, 400);
    }
  }, [CSVNamSubmissionData]);
  // useEffect(() => {
  //   let subArr = [];
  //   if (IntentCSVNamSubmissionData && IntentCSVNamSubmissionData?.length > 0) {
  //     let IndentchecklistList = IntentCSVNamSubmissionData.sort((a, b) => {
  //       return b.StoreName - a.StoreName;
  //     });
  //     let columsList = [];
  //     let csvSubRows = [];
  //     try {
  //       IndentchecklistList?.sort((a, b) =>
  //         a.StoreName < b.StoreName ? -1 : 1
  //       ).map((csvData, index) => {
  //         if (index == 0) {
  //           columsList.push(
  //             {
  //               value: "S.No",
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             },
  //             {
  //               value: "Date",
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             },
  //             {
  //               value: "Store",
  //               widthPx: 130,
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             }
  //           );
  //           let formList =
  //             csvData.dates &&
  //             csvData.dates.length &&
  //             csvData.dates[0].checklist.forms &&
  //             csvData.dates[0].checklist.forms.length
  //               ? csvData.dates[0].checklist.forms
  //               : [];
  //           formList
  //             .sort((a, b) => (a.title > b.title ? -1 : 1))
  //             .map((fList) => {
  //               let quesList = fList.questions;
  //               quesList
  //                 .sort((a, b) => (a.title < b.title ? -1 : 1))
  //                 .map((qList, key) => {
  //                   columsList.push({
  //                     value: qList.title,
  //                     widthPx: 130,
  //                     style: {
  //                       font: { sz: "24", bold: true },
  //                       alignment: { wrapText: true },
  //                     },
  //                   });
  //                 });
  //             });
  //           columsList.push(
  //             {
  //               value: "Submitted By",
  //               widthPx: 90,
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             },
  //             {
  //               value: "Submission Date UTC +05:30 (dd/mm/yyyy)",
  //               widthPx: 150,
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             },
  //             {
  //               value: "Time taken for submission",
  //               widthPx: 80,
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             },
  //             {
  //               value: "Status",
  //               widthPx: 90,
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             },
  //             {
  //               value: "PDF Report",
  //               widthPx: 200,
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             },
  //             {
  //               value: "Complete Percentage",
  //               widthPx: 100,
  //               style: {
  //                 font: { sz: "24", bold: true },
  //                 alignment: { wrapText: true },
  //               },
  //             }
  //           );
  //         }
  //         let StoreName = csvData.StoreName;
  //         let datesList = csvData.dates;

  //         // datesList
  //         //   .sort((a, b) => (a.ChecklistName > b.ChecklistName ? -1 : 1))
  //         //   .map((dList) => {
  //         //     let csvSubRowList = [];
  //         //     const newStartDate = new Date(dList.date);
  //         //     const yyyySD = newStartDate.getFullYear();
  //         //     let mmSD = newStartDate.getMonth() + 1;
  //         //     let ddSD = newStartDate.getDate();
  //         //     if (ddSD < 10) ddSD = "0" + ddSD;
  //         //     if (mmSD < 10) mmSD = "0" + mmSD;
  //         //     const formattedSD = yyyySD + "-" + mmSD + "-" + ddSD;
  //         //     csvSubRowList.push(
  //         //       {
  //         //         value: formattedSD,
  //         //         style: {
  //         //           fill: {
  //         //             patternType: "solid",
  //         //             fgColor: {
  //         //               rgb: status == "Complete" ? "c8ee75" : "d87a7a",
  //         //             },
  //         //           },
  //         //         },
  //         //       },
  //         datesList
  //           .sort((a, b) => (a.ChecklistName > b.ChecklistName ? -1 : 1))
  //           .map((dList) => {
  //             let csvSubRowList = [];
  //             const newStartDate = new Date(dList.date);
  //             const formattedSD = moment(newStartDate).format("lll") || "N/A";

  //             csvSubRowList.push(
  //               {
  //                 value: "" + (index + 1),
  //                 style: {
  //                   font: { sz: "24", bold: true },
  //                   alignment: { wrapText: true },
  //                 },
  //               },
  //               {
  //                 value: formattedSD,
  //                 style: {
  //                   fill: {
  //                     patternType: "solid",
  //                     fgColor: {
  //                       rgb: status == "Complete" ? "c8ee75" : "d87a7a",
  //                     },
  //                   },
  //                 },
  //               },
  //               {
  //                 value: StoreName,
  //                 style: {
  //                   fill: {
  //                     patternType: "solid",
  //                     fgColor: {
  //                       rgb: status == "Complete" ? "c8ee75" : "d87a7a",
  //                     },
  //                   },
  //                 },
  //               }
  //             );
  //             let formList =
  //               dList.checklist.forms && dList.checklist.forms.length
  //                 ? dList.checklist.forms
  //                 : [];
  //             formList
  //               .sort((a, b) => (a.title > b.title ? -1 : 1))
  //               .map((fList) => {
  //                 let quesList = fList.questions;
  //                 quesList
  //                   .sort((a, b) => (a.title < b.title ? -1 : 1))
  //                   .map((qList) => {
  //                     let answerVal = "";
  //                     if (
  //                       target === "Advance Question/Indent Wise Report" &&
  //                       questionWiseViewBy === "Scorewise"
  //                     ) {
  //                       console.log("if cond", answerVal);
  //                       answerVal =
  //                         qList &&
  //                         qList.answer &&
  //                         qList.answer.answer &&
  //                         qList.answer.answer.value
  //                           ? qList.answer.answer.option_score
  //                           : "0";
  //                     } else {
  //                       console.log("else cond", qList);
  //                       answerVal =
  //                         qList &&
  //                         qList.answer &&
  //                         qList.answer.answer &&
  //                         qList.answer.answer.value
  //                           ? qList.answer.answer.value === true
  //                             ? "Yes"
  //                             : qList.answer.answer.value === false
  //                             ? "No"
  //                             : qList.answer.answer.value
  //                           : qList?.answer?.answer?.value === false
  //                           ? "No"
  //                           : 0;
  //                     }
  //                     csvSubRowList.push({
  //                       value: "" + answerVal,
  //                       widthPx: 130,
  //                       style: {
  //                         font: { sz: "24", bold: true },
  //                         alignment: { wrapText: true },
  //                       },
  //                     });
  //                   });
  //               });
  //             csvSubRowList.push(
  //               {
  //                 value: dList.checklist.userName || "Not Submitted",
  //                 widthPx: 130,
  //                 style: {
  //                   font: { sz: "24", bold: true },
  //                   alignment: { wrapText: true },
  //                 },
  //               },
  //               // {
  //               //   value: dList.checklist.userName
  //               //     ? dList.checklist.updatedAt
  //               //     : dList.date,
  //               //   widthPx: 130,
  //               //   style: {
  //               //     font: { sz: "24", bold: true },
  //               //     alignment: { wrapText: true },
  //               //   },
  //               // },
  //               {
  //                 value: dList.checklist.userName
  //                   ? moment(dList.checklist.updatedAt).format("lll") || "N/A"
  //                   : "N/A",
  //                 widthPx: 150,
  //                 style: {
  //                   font: { sz: "24", bold: true },
  //                   alignment: { wrapText: true },
  //                 },
  //               },
  //               {
  //                 value: getSubTime(dList.checklist),
  //                 widthPx: 80,
  //                 style: {
  //                   font: { sz: "24", bold: true },
  //                   alignment: { wrapText: true },
  //                 },
  //               },
  //               {
  //                 value: dList.checklist.userName
  //                   ? "Submitted"
  //                   : "Not Submitted",
  //                 widthPx: 130,
  //                 style: {
  //                   font: { sz: "24", bold: true },
  //                   alignment: { wrapText: true },
  //                 },
  //               },
  //               {
  //                 value: dList?.checklist?.pdfReport || "N/A",
  //                 widthPx: 130,
  //                 style: {
  //                   font: { sz: "24", bold: true },
  //                   alignment: { wrapText: true },
  //                 },
  //               },
  //               {
  //                 value: (dList?.checklist?.completePercentage || 0) + "%",
  //                 widthPx: 130,
  //                 style: {
  //                   font: { sz: "24", bold: true },
  //                   alignment: { wrapText: true },
  //                 },
  //               }
  //             );
  //             csvSubRows.push(csvSubRowList);
  //           });
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     const dataSet = [
  //       {
  //         columns: columsList,
  //         data: csvSubRows,
  //       },
  //     ];
  //     if (subArr && cSVNamSubmissionFlag) {
  //       setCSVNamSubmissionFlag(false);
  //       setIndentSubmissionsData(dataSet);
  //       if (target === "Basic Checklist Report" && reportType === "intent") {
  //         setTimeout(() => {
  //           btnRefIndent.current.click();
  //         }, 400);
  //       }
  //     }
  //   }
  // }, [IntentCSVNamSubmissionData]);

  useEffect(() => {
    let subArr = [];
    if (IntentCSVNamSubmissionData && IntentCSVNamSubmissionData.length > 0) {
      let IndentchecklistList = IntentCSVNamSubmissionData.sort((a, b) => {
        return b.StoreName - a.StoreName;
      });
      let columsList = [];
      let csvSubRows = [];
      try {
        IndentchecklistList.sort((a, b) =>
          a.StoreName < b.StoreName ? -1 : 1
        ).forEach((csvData, index) => {
          if (index === 0) {
            columsList.push(
              {
                value: "S.No",
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Date",
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Store",
                widthPx: 130,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              }
            );
            let formList = csvData.dates?.[0]?.checklist?.forms || [];
            formList
              .sort((a, b) => (a.title > b.title ? -1 : 1))
              .forEach((fList) => {
                fList.questions
                  .sort((a, b) => (a.title < b.title ? -1 : 1))
                  .forEach((qList) => {
                    columsList.push({
                      value: qList.title,
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    });
                  });
              });
            columsList.push(
              {
                value: "Submitted By",
                widthPx: 90,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Submission Date UTC +05:30 (dd/mm/yyyy)",
                widthPx: 150,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Time taken for submission",
                widthPx: 80,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Status",
                widthPx: 90,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "PDF Report",
                widthPx: 200,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              },
              {
                value: "Complete Percentage",
                widthPx: 100,
                style: {
                  font: { sz: "24", bold: true },
                  alignment: { wrapText: true },
                },
              }
            );
          }
          let StoreName = csvData.StoreName;
          let datesList = csvData.dates || [];

          datesList
            .sort((a, b) => (a.ChecklistName > b.ChecklistName ? -1 : 1))
            .forEach((dList) => {
              let csvSubRowList = [];
              const newStartDate = new Date(dList.date);
              const formattedSD = moment(newStartDate).format("lll") || "N/A";

              csvSubRowList.push(
                {
                  value: "" + (index + 1),
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: formattedSD,
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: {
                        rgb:
                          dList.checklist.status === "Complete"
                            ? "c8ee75"
                            : "d87a7a",
                      },
                    },
                  },
                },
                {
                  value: StoreName,
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: {
                        rgb:
                          dList.checklist.status === "Complete"
                            ? "c8ee75"
                            : "d87a7a",
                      },
                    },
                  },
                }
              );

              let formList = dList.checklist.forms || [];
              formList
                .sort((a, b) => (a.title > b.title ? -1 : 1))
                .forEach((fList) => {
                  fList.questions
                    .sort((a, b) => (a.title < b.title ? -1 : 1))
                    .forEach((qList) => {
                      let answerVal = "";

                      if (qList?.answer && qList?.answer?.type === "file") {
                        answerVal =
                          qList?.answer?.answer &&
                            Array.isArray(qList?.answer?.answer?.answer) &&
                            qList?.answer?.answer?.answer?.length > 0
                            ? "Yes"
                            : "No";
                      } else if (
                        qList?.answer &&
                        qList?.answer?.type === "datetime"
                      ) {
                        if (qList?.answer?.answer && qList?.answer?.answer.date) {
                          const date = qList?.answer?.answer?.date;
                          const time = qList?.answer?.answer?.time || "";
                          answerVal = `${date} ${time}`.trim();
                        } else {
                          answerVal = "N/A";
                        }
                      } else if (
                        target === "Advance Question/Indent Wise Report" &&
                        questionWiseViewBy === "Scorewise"
                      ) {
                        answerVal = qList?.answer?.answer?.option_score || "0";
                      } else {
                        answerVal =
                          qList.answer?.answer?.value === true
                            ? "Yes"
                            : qList.answer?.answer?.value === false
                              ? "No"
                              : qList.answer?.answer?.value
                                ? qList?.answer?.answer?.value
                                : qList?.answer?.answer?.title || "0";
                      }

                      csvSubRowList.push({
                        value: "" + answerVal,
                        widthPx: 130,
                        style: {
                          font: { sz: "24", bold: true },
                          alignment: { wrapText: true },
                        },
                      });
                    });
                });

              csvSubRowList.push(
                {
                  value: dList.checklist.userName || "Not Submitted",
                  widthPx: 130,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: dList.checklist.userName
                    ? moment(dList.checklist.updatedAt).format("lll") || "N/A"
                    : "N/A",
                  widthPx: 150,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: getSubTime(dList.checklist),
                  widthPx: 80,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: dList.checklist.userName
                    ? "Submitted"
                    : "Not Submitted",
                  widthPx: 130,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: dList.checklist.pdfReport || "N/A",
                  widthPx: 130,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                },
                {
                  value: (dList.checklist.completePercentage || 0) + "%",
                  widthPx: 130,
                  style: {
                    font: { sz: "24", bold: true },
                    alignment: { wrapText: true },
                  },
                }
              );

              csvSubRows.push(csvSubRowList);
            });
        });
      } catch (e) {
        console.log(e);
      }
      const dataSet = [
        {
          columns: columsList,
          data: csvSubRows,
        },
      ];
      if (subArr && cSVNamSubmissionFlag) {
        setCSVNamSubmissionFlag(false);
        setIndentSubmissionsData(dataSet);
        if (target === "Basic Checklist Report" && reportType === "intent") {
          setTimeout(() => {
            btnRefIndent.current.click();
          }, 400);
        }
      }
    }
  }, [IntentCSVNamSubmissionData]);

  useEffect(() => {
    const columsList = [];
    let csvSubRows = [];

    let subArr = [];
    var formattedSD = "";

    columsList.push(
      {
        value: "S.No",
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "User Name",
        widthPx: 200,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Status",
        widthPx: 150,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Compliance score",
        widthPx: 130,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      }
    );

    if (CSVUserWiseSubmissionData) {
      CSVUserWiseSubmissionData.map((item) => {
        // formattedSD = item.date;
        const newStartDate = new Date(item.date);
        const yyyySD = newStartDate.getFullYear();
        let mmSD = newStartDate.getMonth() + 1;
        let ddSD = newStartDate.getDate();
        if (ddSD < 10) ddSD = "0" + ddSD;
        if (mmSD < 10) mmSD = "0" + mmSD;
        formattedSD = yyyySD + "-" + mmSD + "-" + ddSD;

        item.users.map((user, i) => {
          const rowD = [];

          rowD.push(
            {
              value: `${i + 1}`,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: user.Username,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: user.Submissionstatus,
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: `${user.ComplianceScore ? user.ComplianceScore?.toFixed(2) : "0.00"
                }%`,
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            }
          );
          csvSubRows.push(rowD);
        });
        const dataSet = [
          {
            columns: columsList,
            data: csvSubRows,
          },
        ];
        subArr.push({ formattedSD, dataSet });
      });
    }

    console.log("subArr", subArr);
    setCsvUserWiseSubmissionReport(subArr);
    setCSVNamSubmissionFlag(false);
    if (
      target === "Basic Checklist Report" &&
      reportType === "UserwiseSubmission"
    ) {
      setTimeout(() => {
        csvUserWiseRef.current.click();
      }, 400);
    }
  }, [CSVUserWiseSubmissionData]);

  const handleType = (e) => {
    setFilterType(e.target.value);
    setSelectedStore([]);
    setSelectedUsers([]);
    setSelectedChecklist([]);

    setSelectedDate([]);
    setPaginatedData([]);
    dispatch(CsvNamSubmissionListUserWiseSuccess([]));

    listUser(
      {
        body: {
          storeIds: [],
          userIds: [],
          subRole: subRole,
          startDate: startDate,
          endDate: endDate,
        },
      },
      "user",
      e.target.value
    );
  };

  const handleDateClick = (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      setErrorMessage("Start Date and End Date required");
      return;
    } else {
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (date1.getTime() > date2.getTime()) {
        setErrorMessage("End Date should be greater Start Date");
        return;
      } else if (
        target === "Advance Question/Indent Wise Report" &&
        Difference_In_Days > 29
      ) {
        setErrorMessage("Dates should be within 30 days");
        return;
      } else if (
        target === "Basic Checklist Report" &&
        reportType !== "UserwiseSubmission" &&
        Difference_In_Days > 29
      ) {
        setErrorMessage("Dates should be within 30 days");
        return;
      } else if (
        target === "Basic Checklist Report" &&
        reportType === "UserwiseSubmission" &&
        Difference_In_Days > 29
      ) {
        setErrorMessage("Dates should be within 30 days");
        return;
      }
    }
    const newStartDate = new Date(startDate);
    const yyyySD = newStartDate.getFullYear();
    let mmSD = newStartDate.getMonth() + 1;
    let ddSD = newStartDate.getDate();
    if (ddSD < 10) ddSD = "0" + ddSD;
    if (mmSD < 10) mmSD = "0" + mmSD;
    const formattedSD = yyyySD + "-" + mmSD + "-" + ddSD;

    const newEndDate = new Date(endDate);
    const yyyyED = newEndDate.getFullYear();
    let mmED = newEndDate.getMonth() + 1;
    let ddED = newEndDate.getDate();
    if (ddED < 10) ddED = "0" + ddED;
    if (mmED < 10) mmED = "0" + mmED;
    const formattedED = yyyyED + "-" + mmED + "-" + ddED;

    const id = localStorage.getItem("companyId");
    if (
      target === "Basic Checklist Report" &&
      reportType == "UserwiseSubmission"
    ) {
      const payloadRequest = {
        url:
          BASE_URL +
          `UserWiseSubmissionReport?company_id=${id}&startDate=${formattedSD}&endDate=${formattedED}`,
      };
      dispatch(CsvUserSubmissionListApi(payloadRequest));
    } else if (
      target === "Basic Checklist Report" &&
      reportType == "submission"
    ) {
      const payloadRequest = {
        url:
          BASE_URL +
          `StorewiseChecklistReportOld?company_id=${id}&startDate=${formattedSD}&endDate=${formattedED}`,
      };
      dispatch(CsvNamSubmissionListApi(payloadRequest));
    } else if (target === "Basic Checklist Report" && reportType == "intent") {
      if (!selected || !selected._id) {
        setErrorMessage("Select checklist to fetch reports");
        return;
      }
      const payloadRequest = {
        url:
          BASE_URL +
          //   `IndentReport?company_id=${id}&checklistId=64479726f82847403d1aec33&startDate=${formattedSD}&endDate=${formattedED}`,
          `IndentReportOld?company_id=${id}&checklistId=${selected._id}&startDate=${formattedSD}&endDate=${formattedED}`,
      };
      dispatch(IntentCsvNamSubmissionListApi(payloadRequest));
    } else {
      if (!selectedChecklist || !selectedChecklist._id) {
        setErrorMessage("Select checklist to fetch reports");
        return;
      }
      const payloadRequest = {
        url:
          BASE_URL +
          //   `IndentReport?company_id=${id}&checklistId=64479726f82847403d1aec33&startDate=${formattedSD}&endDate=${formattedED}`,
          `IndentReport?company_id=${id}&checklistId=${selectedChecklist._id}&startDate=${formattedSD}&endDate=${formattedED}`,
        body: {
          userIds: selectedUsers.map((item) => item._id),
          storeIds: selectedStore.map((item) => item._id),
        },
      };
      dispatch(IntentCsvNamSubmissionListApi(payloadRequest));
    }
    setCSVNamSubmissionFlag(true);
  };

  const handleDownloadReport = (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      setErrorMessage("Start Date and End Date required");
      return;
    } else {
      if (endDate < startDate) {
        setErrorMessage("End Date should be greater Start Date");
        return;
      } else if (
        new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 29)) >
        new Date(startDate)
      ) {
        setErrorMessage("Dates should be within 30 days");
        return;
      }
    }
    const newStartDate = new Date(startDate);
    const yyyySD = newStartDate.getFullYear();
    let mmSD = newStartDate.getMonth() + 1;
    let ddSD = newStartDate.getDate();
    if (ddSD < 10) ddSD = "0" + ddSD;
    if (mmSD < 10) mmSD = "0" + mmSD;
    const formattedSD = yyyySD + "-" + mmSD + "-" + ddSD;

    const newEndDate = new Date(endDate);
    const yyyyED = newEndDate.getFullYear();
    let mmED = newEndDate.getMonth() + 1;
    let ddED = newEndDate.getDate();
    if (ddED < 10) ddED = "0" + ddED;
    if (mmED < 10) mmED = "0" + mmED;
    const formattedED = yyyyED + "-" + mmED + "-" + ddED;

    const id = localStorage.getItem("companyId");
    const payloadRequest = {
      url:
        BASE_URL +
        `UserwiseChecklistReport?company_id=${id}&startDate=${formattedSD}&endDate=${formattedED}&checklistType=${filterType}&role=["${subRole}"]`,
      body: {
        userIds: selectedUsers.map((item) => item._id),
        storeIds: selectedStore.map((item) => item._id),
        checklistIds: selectedChecklist.map((item) => item._id),
      },
    };

    dispatch(CsvNamSubmissionListUserWiseApi(payloadRequest));

    setCSVNamSubmissionFlag(true);
  };

  const handleChange = (event) => {
    setTeportType(event.target.value);
    setErrorMessage("");
    setSelectedStore(null);
    setSelectedUsers([]);
    setSelectedChecklist([]);
    setSelectAll(false);
    setSelectActive(false);
  };

  const handleChangeTarget = (event) => {
    setTarget(event.target.value);
    setErrorMessage("");
    setSelectedChecklist([]);
    setSelectedStore([]);
    setSelectedUsers([]);
    setSelectActive(false);
    dispatch(ListUsersChecklistClear());
  };

  const handleDisplayBy = (event) => {
    setDisplayBy(event.target.value);
  };

  const handleViewBy = (event) => {
    setViewBy(event.target.value);
  };
  const handleQuestionWiseViewBy = (event) => {
    setQuestionWiseViewBy(event.target.value);
  };

  const payloadRequest = {
    body: {
      page: 1,
      per_page: 200000,
      sort_order: -1,
      sort_by: "createdAt",
      type: "report",
    },
  };

  useEffect(() => {
    var localPageno = localStorage.getItem("pageno");
    payloadRequest.body.page = localPageno ? parseInt(localPageno) : 1;
    payloadRequest.body.subRole = subRole;
    (payloadRequest.body.startDate = startDate),
      (payloadRequest.body.endDate = endDate),
      // Clearing store select, user and checklist during sub role select
      setSelectedStore([]);
    setSelectedUsers([]);
    setSelectedChecklist([]);
    setSelectedDate(null);
    listStore(payloadRequest);
  }, [subRole]);

  const listStore = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `stores/index?company_id=${id}`;
    setLoader(false);
    dispatch(ListStoreApi(data));
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const listUser = (data, key, checklistType) => {
    const id = localStorage.getItem("companyId");
    if (target === "Advance Checklist Wise Report") {
      data.url =
        BASE_URL +
        `report/dropdown?company_id=${id}&checklistType=${checklistType}`;
    } else {
      data.url = BASE_URL + `report/dropdown?company_id=${id}`;
    }
    setLoader(true);
    dispatch(ListUserChecklistApi(data, key));
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const getApi = () => {
    const id = localStorage.getItem("companyId");
    const paginationRequest = {
      url: BASE_URL + `activechecklistdropdown?company_id=${id}`,
    };
    dispatch(AllActiveChecklistListApi(paginationRequest));
  };

  const handleChecklistChange = (event, newValue) => {
    if (newValue) {
      setSelected(newValue);
    }
  };

  const handleStoreClose = () => {
    if (selectedStore.length) {
      listUser(
        {
          body: {
            storeIds: selectedStore.map((item) => item._id),
            userIds: [],
            subRole: subRole,
            startDate: startDate,
            endDate: endDate,
          },
        },
        "user",
        filterType
      );
    }
  };

  const handleStoreChange = (event, newValue) => {
    if (newValue) {
      setSelectedStore(newValue);
      setSelectedUsers([]);
      setSelectedChecklist([]);
    }
    if (!newValue.length) {
      listUser(
        {
          body: {
            storeIds: newValue.map((item) => item._id),
            userIds: [],
            subRole: subRole,
            startDate: startDate,
            endDate: endDate,
          },
        },
        "user",
        filterType
      );
    }
  };

  const handleUserClose = () => {
    if (selectedUsers.length) {
      listUser(
        {
          body: {
            storeIds: selectedStore.map((item) => item._id),
            userIds: selectedUsers.map((item) => item._id),
            startDate: startDate,
            endDate: endDate,
          },
        },
        "checklist",
        filterType
      );
    }
  };

  const handleUserChange = (event, newValue) => {
    if (newValue) {
      setSelectedUsers(newValue);
      setSelectedChecklist([]);
    }
    if (!newValue.length) {
      listUser(
        {
          body: {
            storeIds: selectedStore.map((item) => item._id),
            userIds: newValue.map((item) => item._id),
            startDate: startDate,
            endDate: endDate,
          },
        },
        "checklist",
        filterType
      );
    }
  };

  const handleCheckChange = (event, newValue) => {
    if (newValue) {
      setSelectedChecklist(newValue);
    }
  };

  const handleDateChange = (event, newValue) => {
    if (newValue) {
      setSelectedDate(newValue);
      setPaginatedData(newValue.Users);
    }
  };

  const handleClearSelection = () => {
    setSelected([]);
  };

  const handleClearStore = () => {
    setSelectedStore([]);
  };
  const handleClearUsers = () => {
    setSelectedUsers([]);
  };

  // Sort the checklist items with active items first
  const sortedCheckList =
    checkList && checkList.length > 0
      ? [...checkList].sort((a, b) => (a.expired ? 1 : -1))
      : [];

  const [selectAll, setSelectAll] = useState(false);
  const [selectInactive, setSelectInactive] = useState(false);
  const [selectActive, setSelectActive] = useState(false);

  const activeChecklists = checkList
    ? checkList.filter((item) => !item.expired)
    : [];
  const inactiveChecklists = checkList
    ? checkList.filter((item) => item.expired)
    : [];

  const handleSelectAll = (e) => {
    const value = e.target.checked;
    setSelectAll(value);
    setSelectInactive(value);
    setSelectActive(value);
    handleCheckChange("", value ? sortedCheckList : []);
  };

  const handleSelectInactive = (e) => {
    const value = e.target.checked;
    setSelectInactive(value);
    const selected = value
      ? [...inactiveChecklists]
      : selectedChecklist.filter((item) => !item.expired);
    handleCheckChange("", selected);
  };

  const handleSelectActive = (e) => {
    const value = e.target.checked;
    setSelectActive(value);
    const selected = value
      ? [...activeChecklists]
      : selectedChecklist.filter((item) => item.expired);
    handleCheckChange("", selected);
  };

  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    let tab = "";
    if (newValue === 0) {
      tab = "Basic Checklist Report"
    } else if (newValue === 1) {
      tab = "Advance Question/Indent Wise Report"
    } else if (newValue === 2) {
      tab = "Advance Checklist Wise Report"
    }
    setTarget(tab);
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      {loader && <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ProgressbarTransparent play />
      </div>}
      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        <Box>
          <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
            <Tab
              sx={{
                fontWeight: "600",
                fotnSize: "16px"
              }}
              label={
                <div>
                  Basic Checklist Report{" "}
                  <Tooltip
                    className="custom-tooltip"
                    title={
                      <div style={{ maxWidth: "300px" }}>
                        <strong>Checklist and Indent Report:</strong>
                        <ul>
                          <li>
                            This report helps you download store-wise
                            checklists for selected days, displaying
                            responses of the selected checklist.
                          </li>
                          <li>
                            (Note: This report works only for users mapped
                            to a single store)
                          </li>
                        </ul>
                        <strong>Checklist Submission Report:</strong>
                        <ul>
                          <li>
                            This report helps you download the active
                            store-wise checklist statuses for selected days.
                          </li>
                        </ul>
                      </div>
                    }
                    arrow
                    interactive
                  >
                    <InfoIcon
                      style={{
                        marginLeft: "8px",
                        verticalAlign: "middle",
                        cursor: "pointer",
                        color: "#D76C00",
                      }}
                    />
                  </Tooltip>
                </div>
              }
              {...a11yProps(0)} />
            <Tab sx={{
              fontWeight: "600",
              fotnSize: "16px",
              maxWidth: "max-content"
            }}
              label={
                <div>
                  Advance Question/Indent Wise Report{" "}
                  <Tooltip
                    title={
                      <div style={{ maxWidth: "300px" }}>
                        <strong>
                          Advance Question/Indent Wise Report:
                        </strong>
                        <p>Info currently not available..</p>
                      </div>
                    }
                    arrow
                    interactive
                  >
                    <InfoIcon
                      style={{
                        marginLeft: "8px",
                        verticalAlign: "middle",
                        cursor: "pointer",
                        color: "#D76C00",
                      }}
                    />
                  </Tooltip>
                </div>
              } {...a11yProps(1)} />
            <Tab
              sx={{
                fontWeight: "600",
                fotnSize: "16px"
              }}
              label={
                <div>
                  Advance Checklist Wise Report{" "}
                  <Tooltip
                    title={
                      <div style={{ maxWidth: "300px" }}>
                        <strong>Advance Checklist Wise Report:</strong>
                        <p>
                          Status: Display's selected checklist completion
                          status, completion percentage & average compliance
                          scores for the selected user and stores.
                        </p>
                        <p>
                          Percentage: Display's selected checklist
                          completion percentage & average compliance scores
                          for the selected user and stores.
                        </p>
                      </div>
                    }
                    arrow
                    interactive
                  >
                    <InfoIcon
                      style={{
                        marginLeft: "8px",
                        verticalAlign: "middle",
                        cursor: "pointer",
                        color: "#D76C00",
                      }}
                    />
                  </Tooltip>
                </div>
              } {...a11yProps(2)} />
          </Tabs>
          <div style={{ position: "absolute", right: "20px", top: "54px" }}>
            <Tooltip
              title={
                <div style={{ maxWidth: "300px" }}>
                  <strong>Advance Checklist Wise Report:</strong>
                  <p>
                    Percentage: Display's selected checklist completion percentage &
                    average compliance scores for the selected user and stores.
                  </p>
                </div>
              }
              arrow
              interactive
            >
              <InfoIcon
                style={{
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: "#D76C00",
                }}
              />
            </Tooltip>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <CustomTabPanel value={value} index={0}>
          <Box sx={{
            display: "flex",
            alignItems: "start",
            columnGap: "100px",
            padding: 0
          }}>
            <Stack direction="row" className="stack">
              <FormControl fullWidth sx={{ mb: 2, padding: "10px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={reportType}
                  onChange={handleChange}
                  sx={{
                    flexDirection: "column"
                  }}
                >
                  <FormControlLabel
                    value="intent"
                    control={<Radio />}
                    label="Checklist and Indent Report"
                  />
                  <FormControlLabel
                    value="submission"
                    control={<Radio />}
                    label="Checklist Submission Report"
                  />

                  <FormControlLabel
                    value="UserwiseSubmission"
                    control={<Radio />}
                    label={
                      <div>
                        Userwise Submission Report{" "}
                        <Tooltip
                          title={
                            <div style={{ maxWidth: "300px" }}>
                              <strong>
                                Get users with submission status. :
                              </strong>
                              <p>
                                If user submit any checklists for selected
                                date then status will be submitted else
                                status will be not submitted.
                              </p>
                              {/* <p>
                                Percentage: Display's selected checklist
                                completion percentage & average compliance scores
                                for the selected user and stores.
                              </p> */}
                            </div>
                          }
                          arrow
                          interactive
                        >
                          <InfoIcon
                            style={{
                              marginLeft: "8px",
                              verticalAlign: "middle",
                              cursor: "pointer",
                              color: "#D76C00",
                            }}
                          />
                        </Tooltip>
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Box sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: "10px"
            }}>
              <label style={{ textAlign: "left", marginBottom: "10px" }}>
                <strong>
                  Select dates within 3 days only
                </strong>
              </label>
              <Stack direction="row" spacing={1} alignItems="center" width={"100%"} justifyContent={"space-between"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={startDate}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setStartDate(dateSort);
                      setErrorMessage("");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "max-content" }} />
                    )}
                    className="date-pick"
                    sx={{ width: "40%" }}
                  />
                </LocalizationProvider>
                <Typography className="date-to">To</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={endDate}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setEndDate(dateSort);
                      setErrorMessage("");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "max-content" }} />
                    )}
                    className="date-pick"
                    sx={{ width: "40%" }}
                  />
                </LocalizationProvider>
              </Stack>
              {reportType === "intent" ? (
                <Stack direction="row" className="stack">
                  <FormControl fullWidth sx={{ mt: 2, width: "400px" }}>
                    <label style={{ textAlign: "left", marginBottom: "10px" }}>
                      <strong>Active Checklist</strong>
                    </label>
                    <Autocomplete
                      options={
                        mapSelectData && mapSelectData.length > 0
                          ? mapSelectData
                          : []
                      }
                      getOptionLabel={(option) => option?.title || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          sx={{ borderRadius: "8px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selected}
                      onChange={handleChecklistChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.title}
                        </li>
                      )}
                      disabled={reportType === "submission"}
                      clearable
                      onClear={handleClearSelection}
                    />
                  </FormControl>
                </Stack>
              ) : (
                ""
              )}
              <FormControl fullWidth sx={{ mb: 2, width: "100%" }}>
                <Button
                  className="header-add cancle-hover"
                  variant="outlined"
                  style={{
                    height: "47px", // make the button smaller
                    padding: "6px 10px",
                    marginTop: "2rem", // add some spacing above the button
                  }}
                  onClick={(e) => handleDateClick(e)}
                  disabled={cSVNamSubmissionFlag}
                >
                  <CheckOutlinedIcon sx={{ padding: { lg: 0 } }} />
                  Download Report
                </Button>
              </FormControl>
            </Box>
            {cSVNamSubmissionsData && cSVNamSubmissionsData.length ? (
              <Box style={{ display: "none" }}>
                <ExcelFile
                  element={
                    <Button
                      className="header-add cancle-hover"
                      sx={{
                        whiteSpace: "nowrap",
                        display: "none !important",
                      }}
                      ref={btnRef}
                    >
                      Export Excel
                    </Button>
                  }
                  filename={"PopprobeChecklistRecords_" + Date.now()}
                >
                  {cSVNamSubmissionsData.map((csvData, key) => (
                    <ExcelSheet
                      key={key}
                      dataSet={csvData.dataSet}
                      name={csvData.formattedSD}
                    />
                  ))}
                </ExcelFile>
              </Box>
            ) : null}
            {indentSubmissionsData && indentSubmissionsData.length ? (
              <Box style={{ display: "none" }}>
                <ExcelFile
                  element={
                    <Button
                      className="header-add cancle-hover"
                      sx={{
                        whiteSpace: "nowrap",
                        display: "none !important",
                      }}
                      ref={btnRefIndent}
                    >
                      Export Excel
                    </Button>
                  }
                  filename={"PopprobeIndentChecklistReport_" + Date.now()}
                >
                  <ExcelSheet
                    dataSet={indentSubmissionsData}
                    name={
                      selected && selected.title
                        ? selected.title.substring(0, 25) + "..."
                        : "Indent Report"
                    }
                  />
                </ExcelFile>
              </Box>
            ) : null}

            {csvUserWiseSubmissionReport?.length > 0 &&
              csvUserWiseSubmissionReport &&
              csvUserWiseSubmissionReport.length ? (
              <Box
                sx={{
                  display: "none",
                }}
              >
                <ExcelFile
                  element={
                    <Button
                      className="header-add cancle-hover"
                      style={{
                        whiteSpace: "nowrap",
                        width: "80%",
                      }}
                      ref={csvUserWiseRef}
                    >
                      Export Excel
                      <FileDownloadIcon />
                    </Button>
                  }
                  filename={"PopprobeUserWiseChecklistRecords_" + Date.now()}
                >
                  {csvUserWiseSubmissionReport.map((csvData, key) => (
                    <ExcelSheet
                      key={key}
                      dataSet={csvData.dataSet}
                      name={csvData.formattedSD}
                    />
                  ))}
                </ExcelFile>
              </Box>
            ) : null}
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack direction="row" justifyContent="end">
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "42px",
                  borderRadius: "8px",
                  padding: "12px 16px",
                }}
                onClick={(e) => setOpenFilterModal(true)}
              >
                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                Filter
              </Button>
            </Stack>
            {indentSubmissionsData && indentSubmissionsData.length ? (
              <Box
                sx={{
                  marginLeft: "auto",
                  marginBottom: "10px",
                  width: "200px",
                }}
              >
                <ExcelFile
                  element={
                    <Button
                      className="header-add cancle-hover"
                      style={{
                        whiteSpace: "nowrap",
                        width: "80%",
                      }}
                      ref={btnUserWiseRef}
                    >
                      Export Excel
                      <FileDownloadIcon />
                    </Button>
                  }
                  filename={"PopprobeChecklistRecords_" + Date.now()}
                >
                  <ExcelSheet
                    dataSet={indentSubmissionsData}
                    name={
                      selected && selected.title
                        ? selected.title.substring(0, 25) + "..."
                        : "Indent Report"
                    }
                  />
                </ExcelFile>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                bgcolor: "#FFFFFF",
                height: "65vh",
                mt: 1,
                overflowX: "auto",
                overflowY: "scroll",
                borderRadius: "8px",
                "&::-webkit-scrollbar": {
                  width: 0,
                },
                "&::-webkit-scrollbar-track": {
                  padding: "12px 5px",
                  margin: "5px",
                  backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#64748B",
                  borderRadius: "8px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                rows={indentRow || []}
                columns={indentColumn}
                getRowId={() => Math.random()}
                // hideFooter={true}
                // hideFooterPagination={true}
                pagination
                localeText={{ noRowsLabel: indentColumn.length > 9 ? "No data found!" : "Please select filter for report" }}
              />
            </Box>
          </Box>
          <Modal
            open={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown={false}
          >
            <Box sx={{ ...downloadAllstyle, p: 4 }}>
              <Box width={"100%"}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <label style={{ textAlign: "left", marginBottom: "10px" }}>
                    <strong>Select dates within 30 days</strong>
                  </label>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      // padding: "10px",
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      marginTop: "10px"
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={startDate}
                        onChange={(newValue) => {
                          let dateSort = moment(newValue?.$d)
                            .format("YYYY-MM-DD")
                            .split("/")
                            .join("");
                          setStartDate(dateSort);
                          setErrorMessage("");
                          setSelectedStore([]);
                          setSelectedUsers([]);
                          setSelectedChecklist([]);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        className="date-pick date-pick-submision"
                        style={{ width: "200px" }}
                      />
                    </LocalizationProvider>
                    <Typography className="date-to">To</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={endDate}
                        onChange={(newValue) => {
                          let dateSort = moment(newValue?.$d)
                            .format("YYYY-MM-DD")
                            .split("/")
                            .join("");
                          setEndDate(dateSort);
                          setErrorMessage("");
                          setSelectedStore([]);
                          setSelectedUsers([]);
                          setSelectedChecklist([]);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        className="date-pick date-pick-submision"
                        style={{ width: "200px" }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </div>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                }}
              >
                <Stack
                  direction="row"
                  className="stack"
                // sx={{ width: "30%" }}
                >
                  <FormControl fullWidth>
                    <label style={{ textAlign: "left", marginBottom: "10px" }}>
                      <strong>Store</strong>
                    </label>
                    <Autocomplete
                      options={
                        storeListMain && storeListMain.length > 0
                          ? storeListMain
                          : []
                      }
                      getOptionLabel={(option) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          placeholder="Select Store"
                          sx={{ borderRadius: "8px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedStore}
                      onClose={handleStoreClose}
                      onChange={handleStoreChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          <Checkbox
                            checked={
                              selectedStore
                                ? selectedStore.includes(option)
                                : false
                            }
                            sx={{ mr: 1 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const newSelected = selectedStore
                                ? [...selectedStore]
                                : [];
                              const selectedIndex =
                                newSelected.indexOf(option);

                              if (selectedIndex === -1) {
                                newSelected.push(option);
                              } else {
                                newSelected.splice(selectedIndex, 1);
                              }

                              setSelectedUsers([]);
                              setSelectedChecklist([]);

                              // listUser(
                              //   {
                              //     body: {
                              //       storeIds: newSelected.map(
                              //         (item) => item._id
                              //       ),
                              //       userIds: [],
                              //       subRole: subRole,
                              //       startDate: startDate,
                              //       endDate: endDate,
                              //     },
                              //   },
                              //   "user"
                              // );
                              if (!newSelected.length) {
                                listUser(
                                  {
                                    body: {
                                      storeIds: newSelected.map(
                                        (item) => item._id
                                      ),
                                      userIds: [],
                                      subRole: subRole,
                                      startDate: startDate,
                                      endDate: endDate,
                                    },
                                  },
                                  "user",
                                  filterType
                                );
                              }

                              setSelectedStore(newSelected);
                            }}
                          />
                          {option.name}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (index === 0) {
                            return (
                              <>
                                <Chip
                                  key={option.name}
                                  label={option.name}
                                  {...getTagProps({ index })}
                                  color="primary"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                                {value.length > 1 ? (
                                  <Chip
                                    key={`${value.length - 1
                                      } more selected`}
                                    label={`${value.length - 1
                                      } more selected`}
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                        })
                      }
                      clearable
                      onClear={handleClearStore}
                      multiple
                      disableCloseOnSelect
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()}
                              pl={3}
                              py={0.5}
                            >
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    selectedStore.length > 0 &&
                                    selectedStore.length ===
                                    storeListMain.length
                                  ) {
                                    handleStoreChange("", []);
                                  } else {
                                    handleStoreChange("", storeListMain);
                                  }
                                }}
                                label="Select all"
                                control={
                                  <Checkbox
                                    sx={{ mr: 1 }}
                                    id="select-all-checkbox"
                                    checked={
                                      selectedStore.length > 0 &&
                                      selectedStore.length ===
                                      storeListMain.length
                                    }
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  className="stack"
                // sx={{ width: "30%" }}
                >
                  <FormControl fullWidth>
                    <label style={{ textAlign: "left", marginBottom: "10px", marginTop: "10px" }}>
                      <strong>User</strong>
                    </label>
                    <Autocomplete
                      options={
                        userList && userList.length > 0 ? userList : []
                      }
                      getOptionLabel={(option) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          placeholder="Select User"
                          sx={{ borderRadius: "8px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedUsers}
                      onClose={handleUserClose}
                      onChange={handleUserChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          <Checkbox
                            checked={
                              selectedUsers
                                ? selectedUsers.filter(
                                  (seUser) => option._id === seUser._id
                                ).length
                                : false
                            }
                            sx={{ mr: 1 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const newSelected = selectedUsers
                                ? [...selectedUsers]
                                : [];
                              const selectedIndex =
                                newSelected.indexOf(option);

                              if (selectedIndex === -1) {
                                newSelected.push(option);
                              } else {
                                newSelected.splice(selectedIndex, 1);
                              }

                              setSelectedChecklist([]);
                              // listUser(
                              //   {
                              //     body: {
                              //       storeIds: selectedStore.map(
                              //         (item) => item._id
                              //       ),
                              //       userIds: newSelected.map(
                              //         (item) => item._id
                              //       ),
                              //     },
                              //   },
                              //   "checklist"
                              // );
                              if (!newSelected.length) {
                                listUser(
                                  {
                                    body: {
                                      storeIds: selectedStore.map(
                                        (item) => item._id
                                      ),
                                      userIds: newSelected.map(
                                        (item) => item._id
                                      ),
                                      startDate: startDate,
                                      endDate: endDate,
                                    },
                                  },
                                  "checklist",
                                  filterType
                                );
                              }

                              setSelectedUsers(newSelected);
                            }}
                          />
                          {option.name}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (index === 0) {
                            return (
                              <>
                                <Chip
                                  key={option.name}
                                  label={option.name}
                                  {...getTagProps({ index })}
                                  color="primary"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                                {value.length > 1 ? (
                                  <Chip
                                    key={`${value.length - 1
                                      } more selected`}
                                    label={`${value.length - 1
                                      } more selected`}
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                        })
                      }
                      clearable
                      onClear={handleClearUsers}
                      multiple
                      disableCloseOnSelect
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()}
                              pl={3}
                              py={0.5}
                            >
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    userList &&
                                    selectedUsers.length > 0 &&
                                    selectedUsers.length === userList.length
                                  ) {
                                    handleUserChange("", []);
                                  } else {
                                    handleUserChange("", userList);
                                  }
                                }}
                                label="Select all"
                                control={
                                  <Checkbox
                                    sx={{ mr: 1 }}
                                    id="select-all-checkbox"
                                    checked={
                                      userList &&
                                      selectedUsers.length > 0 &&
                                      selectedUsers.length ===
                                      userList.length
                                    }
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  className="stack"
                // sx={{ width: "35%" }}
                >
                  <FormControl fullWidth>
                    <label style={{ textAlign: "left", marginBottom: "10px", marginTop: "10px" }}>
                      <strong>Checklist</strong>
                    </label>
                    <Autocomplete
                      // options={
                      //   checkList && checkList.length > 0 ? checkList : []
                      // }
                      options={sortedCheckList}
                      getOptionLabel={(option) => option?.title || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          placeholder="Select Checklist"
                          sx={{ borderRadius: "8px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedChecklist}
                      onChange={handleCheckChange}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            color: option.expired ? "#eb4f4f" : "",
                          }}
                          key={option._id}
                        >
                          {option.title}
                        </li>
                      )}
                      clearable
                      onClear={handleClearSelection}
                    />
                  </FormControl>
                </Stack>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "50px",
                  marginTop: "10px",
                }}
              >
                <Stack direction="column" className="stack" width={"100%"}>
                  <FormControl
                    fullWidth
                    sx={{
                      // padding: "10px",
                      background: "#FFFFFF",
                      borderRadius: "5px",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      <strong>View/Download</strong>
                    </label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={questionWiseViewBy}
                      onChange={handleQuestionWiseViewBy}
                    >
                      <FormControlLabel
                        value="Answerwise"
                        control={<Radio />}
                        label="Answer wise"
                      />
                      <FormControlLabel
                        value="Scorewise"
                        control={<Radio />}
                        label="Score wise"
                      />
                    </RadioGroup>
                  </FormControl>
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <FormControl
                    fullWidth
                    sx={{
                      width: "100%",
                      // padding: "10px",
                      background: "#FFFFFF",
                      borderRadius: "5px",
                    }}
                  >
                    <Button
                      className="header-add cancle-hover"
                      variant="outlined"
                      style={{
                        height: "47px",
                        padding: "6px 10px",
                      }}
                      onClick={(e) => handleDateClick(e)}
                      disabled={cSVNamSubmissionFlag}
                    >
                      <CheckOutlinedIcon sx={{ padding: { lg: 0 } }} />
                      Get Report
                    </Button>
                  </FormControl>
                </Stack>
              </Box>
            </Box>
          </Modal>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack direction="row" justifyContent="end">
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "42px",
                  borderRadius: "8px",
                  padding: "12px 16px",
                }}
                onClick={(e) => setOpenFilterModal(true)}
              >
                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                Filter
              </Button>
            </Stack>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                p: "10px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {CSVNamSubmissionDataUserWise?.length > 0 && (
                <Stack
                  direction="row"
                  className="stack"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <label style={{ textAlign: "left" }}>
                    <strong>Select Date</strong>
                  </label>
                  <FormControl
                    fullWidth
                    sx={{ width: "400px", display: "flex" }}
                  >
                    <Autocomplete
                      options={
                        CSVNamSubmissionDataUserWise &&
                          CSVNamSubmissionDataUserWise.length > 0
                          ? CSVNamSubmissionDataUserWise
                          : []
                      }
                      getOptionLabel={(option) =>
                        (option?.Date &&
                          moment(option?.Date).format(
                            "MMMM D, YYYY hh:mm"
                          )) ||
                        ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          placeholder="Select Date"
                          sx={{ borderRadius: "8px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedDate}
                      onChange={handleDateChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id} value={option?.Date}>
                          {moment(option?.Date).format("MMMM D, YYYY hh:mm")}
                        </li>
                      )}
                      clearable
                      onClear={handleClearSelection}
                    />
                  </FormControl>

                  {/* Export Excel  */}
                  {CSVNamSubmissionDataUserWise?.length > 0 &&
                    cSVNamSubmissionsUserWiseData &&
                    cSVNamSubmissionsUserWiseData.length ? (
                    <Box
                      sx={{
                        width: "200px",
                      }}
                    >
                      <ExcelFile
                        element={
                          <Button
                            className="header-add cancle-hover"
                            style={{
                              whiteSpace: "nowrap",
                              width: "80%",
                            }}
                            ref={btnUserWiseRef}
                          >
                            Export Excel
                            <FileDownloadIcon />
                          </Button>
                        }
                        filename={"PopprobeChecklistRecords_" + Date.now()}
                      >
                        {cSVNamSubmissionsUserWiseData.map((csvData, key) => (
                          <ExcelSheet
                            key={key}
                            dataSet={csvData.dataSet}
                            name={csvData.formattedSD}
                          />
                        ))}
                      </ExcelFile>
                    </Box>
                  ) : null}
                </Stack>
              )}
            </Box>
          </Box>
          {/* {CSVNamSubmissionDataUserWise?.length > 0 &&
            paginatedData && ( */}
          <Box
            sx={{
              width: "100%",
              bgcolor: "#FFFFFF",
              height: "65vh",
              mt: 1,
              p: 1,
              overflowX: "auto",
              overflowY: "scroll",
              borderRadius: "8px",
              px: 1,
              "&::-webkit-scrollbar": {
                width: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                margin: "5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            <>
              <DataGrid
                disableColumnMenu
                rows={paginatedData || []}
                columns={column}
                getRowId={(paginatedData) =>
                  paginatedData.UserId + paginatedData.StoreId
                }
                // hideFooter={true}
                // hideFooterPagination={true}
                pagination
                localeText={{ noRowsLabel: column.length > 8 ? "No data found!" : "Please select filter for report" }}
              />
            </>
          </Box>

          <Modal
            open={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown={false}
          >
            <Box sx={{ ...downloadAllstyle, p: 4 }}>
              <Box width={"100%"}>
                <FormControl
                  fullWidth
                  sx={{
                    background: "#FFFFFF",
                  }}
                >
                  <label style={{ textAlign: "left" }}>
                    <strong>Checklist type</strong>
                  </label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={filterType}
                    onChange={handleType}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Checklist"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Audit"
                    />
                  </RadioGroup>
                </FormControl>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <label style={{ textAlign: "left", marginBottom: "10px" }}>
                    <strong>Select dates within 30 days</strong>
                  </label>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      width: "100%"
                    }}
                    justifyContent="space-between"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={startDate}
                        onChange={(newValue) => {
                          let dateSort = moment(newValue?.$d)
                            .format("YYYY-MM-DD")
                            .split("/")
                            .join("");
                          setStartDate(dateSort);
                          setErrorMessage("");
                          // Clear the selected Checklist items when the start date is changed
                          setSelectedChecklist([]);
                          setSelectedUsers([]);
                          setSelectedStore([]);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        className="date-pick date-pick-submision"
                      // style={{ width: "200px" }}
                      />
                    </LocalizationProvider>
                    <Typography className="date-to">To</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={endDate}
                        onChange={(newValue) => {
                          let dateSort = moment(newValue?.$d)
                            .format("YYYY-MM-DD")
                            .split("/")
                            .join("");
                          setEndDate(dateSort);
                          setErrorMessage("");
                          // Clear the selected Checklist items when the end date is changed
                          setSelectedChecklist([]);
                          setSelectedUsers([]);
                          setSelectedStore([]);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        className="date-pick date-pick-submision"
                      // style={{ width: "200px" }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </div>
                <Stack
                  direction="row"
                  className="stack"
                  sx={{ width: "100%" }}
                >
                  <FormControl fullWidth>
                    <label style={{ textAlign: "left", marginBottom: "10px", marginTop: "10px" }}>
                      <strong>Store</strong>
                    </label>
                    <Autocomplete
                      options={
                        storeListMain && storeListMain.length > 0
                          ? storeListMain
                          : []
                      }
                      getOptionLabel={(option) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          placeholder="Select Store"
                          sx={{ borderRadius: "8px", marginBottom: "10px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedStore}
                      onClose={handleStoreClose}
                      onChange={handleStoreChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          <Checkbox
                            checked={
                              selectedStore
                                ? selectedStore.includes(option)
                                : false
                            }
                            sx={{ mr: 1 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const newSelected = selectedStore
                                ? [...selectedStore]
                                : [];
                              const selectedIndex =
                                newSelected.indexOf(option);

                              if (selectedIndex === -1) {
                                newSelected.push(option);
                              } else {
                                newSelected.splice(selectedIndex, 1);
                              }

                              setSelectedUsers([]);
                              setSelectedChecklist([]);

                              if (!newSelected.length) {
                                listUser(
                                  {
                                    body: {
                                      storeIds: newSelected.map(
                                        (item) => item._id
                                      ),
                                      userIds: [],
                                      subRole: subRole,
                                      startDate: startDate,
                                      endDate: endDate,
                                    },
                                  },
                                  "user",
                                  filterType
                                );
                              }

                              setSelectedStore(newSelected);
                            }}
                          />
                          {option.name}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (index === 0) {
                            return (
                              <>
                                <Chip
                                  key={option.name}
                                  label={option.name}
                                  {...getTagProps({ index })}
                                  color="primary"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                                {value.length > 1 ? (
                                  <Chip
                                    key={`${value.length - 1
                                      } more selected`}
                                    label={`${value.length - 1
                                      } more selected`}
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                        })
                      }
                      clearable
                      onClear={handleClearStore}
                      multiple
                      disableCloseOnSelect
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()}
                              pl={3}
                              py={0.5}
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  selectedStore.length > 0 &&
                                  selectedStore.length ===
                                  storeListMain.length
                                ) {
                                  handleStoreChange("", []);
                                } else {
                                  handleStoreChange("", storeListMain);
                                }
                              }}
                            >
                              <FormControlLabel
                                label="Select all"
                                control={
                                  <Checkbox
                                    sx={{ mr: 1 }}
                                    id="select-all-checkbox"
                                    checked={
                                      selectedStore.length > 0 &&
                                      selectedStore.length ===
                                      storeListMain.length
                                    }
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  className="stack"
                  sx={{ width: "100%" }}
                >
                  <FormControl fullWidth>
                    <label style={{ textAlign: "left", marginBottom: "10px" }}>
                      <strong>User</strong>
                    </label>
                    <Autocomplete
                      options={
                        userList && userList.length > 0 ? userList : []
                      }
                      getOptionLabel={(option) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          placeholder="Select User"
                          sx={{ borderRadius: "8px", marginBottom: "10px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedUsers}
                      onClose={handleUserClose}
                      onChange={handleUserChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          <Checkbox
                            checked={
                              selectedUsers
                                ? selectedUsers.filter(
                                  (seUser) => option._id === seUser._id
                                ).length
                                : false
                            }
                            sx={{ mr: 1 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const newSelected = selectedUsers
                                ? [...selectedUsers]
                                : [];
                              const selectedIndex =
                                newSelected.indexOf(option);

                              if (selectedIndex === -1) {
                                newSelected.push(option);
                              } else {
                                newSelected.splice(selectedIndex, 1);
                              }
                              setSelectedChecklist([]);

                              if (!newSelected.length) {
                                listUser(
                                  {
                                    body: {
                                      storeIds: selectedStore.map(
                                        (item) => item._id
                                      ),
                                      userIds: newSelected.map(
                                        (item) => item._id
                                      ),
                                      startDate: startDate,
                                      endDate: endDate,
                                    },
                                  },
                                  "checklist",
                                  filterType
                                );
                              }

                              setSelectedUsers(newSelected);
                            }}
                          />
                          {option.name}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (index === 0) {
                            return (
                              <div key={option.name}>
                                <Chip
                                  label={option.name}
                                  {...getTagProps({ index })}
                                  color="primary"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                                {value.length > 1 ? (
                                  <Chip
                                    key={`${value.length - 1
                                      } more selected`}
                                    label={`${value.length - 1
                                      } more selected`}
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          }
                        })
                      }
                      clearable
                      onClear={handleClearUsers}
                      disableCloseOnSelect
                      multiple
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()}
                              pl={3}
                              py={0.5}
                            >
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    userList &&
                                    selectedUsers.length > 0 &&
                                    selectedUsers.length === userList.length
                                  ) {
                                    handleUserChange("", []);
                                  } else {
                                    handleUserChange("", userList);
                                  }
                                }}
                                label="Select all"
                                control={
                                  <Checkbox
                                    sx={{ mr: 1 }}
                                    id="select-all-checkbox"
                                    checked={
                                      userList &&
                                      selectedUsers.length > 0 &&
                                      selectedUsers.length ===
                                      userList.length
                                    }
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  className="stack"
                  sx={{ width: "100%" }}
                >
                  <FormControl fullWidth>
                    <label style={{ textAlign: "left", marginBottom: "10px" }}>
                      <strong>Checklist</strong>
                    </label>
                    <Autocomplete
                      options={
                        checkList && checkList.length > 0 ? checkList : []
                      }
                      getOptionLabel={(option) => option?.title || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          placeholder="Select Checklist"
                          sx={{ borderRadius: "8px", marginBottom: "10px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedChecklist}
                      onChange={handleCheckChange}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            color: option.expired ? "#eb4f4f" : "",
                          }}
                          key={option._id}
                        >
                          <Checkbox
                            checked={
                              selectedChecklist
                                ? selectedChecklist.filter(
                                  (seUser) => option._id === seUser._id
                                ).length
                                : false
                            }
                            sx={{ mr: 1 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const newSelected = selectedChecklist
                                ? [...selectedChecklist]
                                : [];
                              const selectedIndex =
                                newSelected.indexOf(option);

                              if (selectedIndex === -1) {
                                newSelected.push(option);
                              } else {
                                newSelected.splice(selectedIndex, 1);
                              }

                              setSelectedChecklist(newSelected);
                            }}
                          />
                          {option.title}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (index === 0) {
                            return (
                              <>
                                <Chip
                                  key={option.title}
                                  label={option.title}
                                  {...getTagProps({ index })}
                                  color="primary"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                                {value.length > 1 ? (
                                  <Chip
                                    key={`${value.length - 1
                                      } more selected`}
                                    label={`${value.length - 1
                                      } more selected`}
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                        })
                      }
                      clearable
                      onClear={handleClearSelection}
                      disableCloseOnSelect
                      multiple
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()}
                              pl={3}
                              py={0.5}
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  checkList &&
                                  selectedChecklist.length > 0 &&
                                  selectedChecklist.length ===
                                  checkList.length
                                ) {
                                  handleCheckChange("", []);
                                } else {
                                  handleCheckChange("", checkList);
                                }
                              }}
                            >
                              <FormControlLabel
                                label="Select all"
                                control={
                                  <Checkbox
                                    sx={{ mr: 1 }}
                                    id="select-all-checkbox"
                                    checked={
                                      checkList &&
                                      selectedChecklist.length > 0 &&
                                      selectedChecklist.length ===
                                      checkList.length
                                    }
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" className="stack">
                  <FormControl
                    fullWidth
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      <strong>Display By</strong>
                    </label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={displayBy}
                      onChange={handleDisplayBy}
                    >
                      <FormControlLabel
                        value="ByStatus"
                        control={<Radio />}
                        label="Status"
                      />
                      <FormControlLabel
                        value="ByPercentage"
                        control={<Radio />}
                        label="Percentage"
                      />
                      <FormControlLabel
                        value="ByComplianceScore"
                        control={<Radio />}
                        label="Compliance Score"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Stack direction="row" className="stack">
                  <FormControl
                    fullWidth
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      <strong>View/Download</strong>
                    </label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={viewBy}
                      onChange={handleViewBy}
                    >
                      <FormControlLabel
                        value="ByChecklist"
                        control={<Radio />}
                        label="Checklist wise"
                      />
                      <FormControlLabel
                        value="ByForm"
                        control={<Radio />}
                        label="Form wise"
                      />
                    </RadioGroup>
                  </FormControl>
                  {/* Second Get Report  */}
                </Stack>
                {errorMessage && (
                  <p style={{ color: "red" }}>{errorMessage}</p>
                )}
                <FormControl
                  fullWidth
                  sx={{
                    width: "100%",
                    // padding: "10px",
                    background: "#FFFFFF",
                    borderRadius: "5px",
                  }}
                  className="stack"
                >
                  <Button
                    className="header-add cancle-hover"
                    variant="outlined"
                    style={{
                      height: "47px",
                      padding: "6px 10px",
                      width: "100%",
                    }}
                    onClick={(e) => handleDownloadReport(e)}
                    disabled={CSVNamSubmissionDataUserWiseLoader}
                  >
                    <CheckOutlinedIcon sx={{ padding: { lg: 0 } }} />
                    Get Report
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Modal>
          {/* )} */}
        </CustomTabPanel>
      </Box>
    </>
  )
};

export default DownloadListHeader;
