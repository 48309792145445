/** @format */

import { Box, Button, Card, Grid, Typography, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState, useEffect } from "react";
import View from "../../images/View.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import ViewHover from "../../images/ViewHover.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  DeleteConfigApi,
  DeleteConfigSuccess,
  EditConfigSuccess,
  ListAttendanceApi,
} from "../../redux/actions/AttendanceAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import * as moment from "moment";
import NoPaginationGlobalTable from "../../GlobalComponent/ProgressbarTransparent";

const Config = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [permission, setPermission] = useState({});
  const [configData, setConfigData] = useState([]);
  const [indexHover, setIndexHover] = useState(0);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [isNewLogButtonDisabled, setIsNewLogButtonDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });
  const attendanceLogList = useSelector(
    (state) =>
      state.attendanceLog &&
      state.attendanceLog.configList &&
      state.attendanceLog.configList.data &&
      state.attendanceLog.configList.data.data
  );

  const configListDelete = useSelector(
    (state) =>
      state &&
      state?.attendanceLog &&
      state?.attendanceLog?.deleteAttendance &&
      state?.attendanceLog?.deleteAttendance.data &&
      state?.attendanceLog?.deleteAttendance.data.data
  );

  const EditConfig = useSelector(
    (state) =>
      state.attendanceLog &&
      state.attendanceLog.editConfig &&
      state.attendanceLog.editConfig.data
  );

  useEffect(() => {
    if (Array.isArray(configData) && configData.length > 0) {
      setIsNewLogButtonDisabled(true);
    } else {
      setIsNewLogButtonDisabled(false);
    }
  }, [configData]);

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    dispatch(EditConfigSuccess(""));
  }, [EditConfig]);

  useEffect(() => {
    if (attendanceLogList) {
      localStorage.setItem("configData", JSON.stringify(attendanceLogList));
    }
  }, [attendanceLogList]);

  useEffect(() => {
    if (attendanceLogList) {
      setConfigData(attendanceLogList);
    } else {
      setConfigData([]);
    }
  }, [attendanceLogList]);

  useEffect(() => {
    if (configListDelete) {
      setLoader(true);
      listConfig();
      dispatch(DeleteConfigSuccess(""));
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [configListDelete]);

  useEffect(() => {
    setLoader(true);
    listConfig();
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [dispatch]);

  const listConfig = () => {
    const id = localStorage.getItem("companyId");
    const data = {
      url: BASE_URL + `getAllAttendanceConfig?company_id=${id}`,
    };
    dispatch(ListAttendanceApi(data));
  };

  const handleClickDelete = (userid) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete it?.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const _id = localStorage.getItem("companyId");
            const userRoleId = userid;
            const data = {
              url:
                BASE_URL +
                `deleteAttendanceConfig/${userRoleId}?company_id=${_id}`,
            };
            setLoader(true);
            dispatch(DeleteConfigApi(data));
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleClickEdit = (row) => {
    navigate(`/attendance-log-config/edit-config`, { state: row });
  };

  const columns = [
    {
      field: "S.No",
      headerName: "S.No",
      renderHeader: () => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const index =
          configData.indexOf(params.row) + 1;
        return index;
      },
    },
    {
      field: "CheckIn Time",
      headerName: "CheckIn Time",
      renderHeader: () => <strong>{"CheckIn Time"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const checkInTime = params?.row?.checkInTime;
        if (checkInTime) {
          if (/^\d{2}:\d{2}$/.test(checkInTime)) {
            return checkInTime;
          } 
          return moment(checkInTime).format("h:mm A");
        }
        return "N/A";
      },
    },
    {
      field: "Checkout Time",
      headerName: "Checkout Time",
      renderHeader: () => <strong>{"Checkout Time"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const checkOutTime = params?.row?.checkOutTime;
        if (checkOutTime) {
          if (/^\d{2}:\d{2}$/.test(checkOutTime)) {
            return checkOutTime;
          }
          return moment(checkOutTime).format("h:mm A");
        }
        return "N/A";
      },
    },
    
    {
      field: "Working Hours	",
      headerName: "Working Hours	",
      renderHeader: () => <strong>{"Working Hours	"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.workingHours) {
          result.push(params?.row?.workingHours);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Selfie Required",
      headerName: "Selfie Required",
      renderHeader: () => <strong>{"Selfie Required"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.selfieRequire ? (
          <CheckCircleIcon style={{ color: "green", fontSize: "24px" }} />
        ) : (
          <CancelIcon style={{ color: "red", fontSize: "24px" }} />
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      renderHeader: () => <strong>{"Status"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.status ? (
          <CheckCircleIcon style={{ color: "green", fontSize: "24px" }} />
        ) : (
          <CancelIcon style={{ color: "red", fontSize: "24px" }} />
        );
      },
    },

    {
      field: "Created At",
      headerName: "Created At",
      renderHeader: () => <strong>{"Created At"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.createdAt) {
          // Format date using moment.js
          result.push(moment(params.row.createdAt).format("h:mm A MMM D, YY"));
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Updated At",
      headerName: "Updated At",
      renderHeader: () => <strong>{"Updated At"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.updatedAt) {
          // Format date using moment.js
          result.push(moment(params.row.updatedAt).format("h:mm A MMM D, YY"));
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Action",
      headerName: "Action",
      hide: checkedArchive === true ? true : false,
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "5px",
            }}
          >
            <Tooltip title="Edit">
              <img
                onClick={() => handleClickEdit(params?.row?._id)}
                src={
                  hover.Edit && indexHover === params?.row?._id
                    ? EditHover
                    : Edit
                }
                alt="Edit"
                style={{
                  height: "35px",
                  cursor: "pointer",
                }}
                onMouseOver={() => {
                  setHover({ Edit: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Edit: false });
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <img
                onClick={() => handleClickDelete(params?.row?._id)}
                src={
                  hover.Delete && indexHover === params?.row?._id
                    ? DeleteHover
                    : Delete
                }
                alt="Delete"
                style={{ height: "35px", cursor: "pointer" }}
                onMouseOver={() => {
                  setHover({ Delete: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Delete: false });
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textAlign: "left", marginLeft: "10px" }}
            >
              Attendance Log Config
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="colomn-padding"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {isNewLogButtonDisabled ? (
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "40px",
                  marginTop: "2px",
                  marginLeft: "auto",
                  marginRight: "4px",
                }}
                variant="contained"
                disabled
              >
                <AddOutlinedIcon sx={{ mr: 1 }} />
                New Log
              </Button>
            ) : (
              <Link
                to="/attendance-log-config/new-log"
                className="submenu-item"
              >
                <Button
                  className="header-add cancle-hover"
                  style={{
                    height: "40px",
                    marginTop: "2px",
                    marginLeft: "auto",
                    marginRight: "4px",
                  }}
                  variant="contained"
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  New Log
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Card>

      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <NoPaginationGlobalTable
          data={configData}
          columns={columns}
          totalCount={totalCount}
          loading={loader}
          disablePagination={true}
          pagination={false}
        />
      </Box>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default Config;
