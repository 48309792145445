/** @format */

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import navitems, { navItemsIfisAuditor } from "./sidebarData";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import problogo from "../images/POPProbe-Logo 1.svg";
import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs } from "@material-ui/core";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import MenuIcon from "@mui/icons-material/Menu";
import companies from "./CompanyMenu";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { CompanyListApi } from "../redux/actions/CreateCompany";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRouteApi } from "../redux/actions/LogInAction";
import Lottie from "react-lottie-player";
import lottieJson from "./../GlobalProgress/LottieLogo.json";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 2px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...(open && {}),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [opens, setOpens] = useState(null);
  const [active, setActive] = useState(null);
  const [companyIdget, setCompanyIdget] = useState("");
  const [title, setTitle] = useState("");
  const [companyListData, setCompanyListData] = useState([]);
  const [PermissionData, setPermissionData] = useState({});
  const [companyName, setCompanyName] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  let isAuditor = false;
  const [compId, setCompId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (isAuditor !== undefined) {
      console.log(isAuditor, "isAuditor");
    }
  }, [isAuditor]);

  const companyId = localStorage.getItem("companyId");
  const userRole = localStorage.getItem("role");
  if (userRole == "auditor") {
    isAuditor = true;
    const companyIdselect = location && location.state;
    if (companyIdselect && companyIdselect.companyId) {
      localStorage.setItem("companyIdselect", companyIdselect.companyId);
    } else {
      localStorage.setItem("companyIdselect", companyIdselect);
    }
  } else {
    const companyIdselect = location && location.state;
    localStorage.setItem("companyIdselect", companyIdselect);
  }
  const pathname = window?.location?.pathname;
  const pathnames = pathname.split("/").filter(Boolean);
  const [companyTitle, setCompanyTitle] = useState("");
  const companyList = useSelector(
    (state) =>
      state.company &&
      state.company.companylist &&
      state.company.companylist.data &&
      state.company.companylist.data.data
  );

  const companyListStatus = useSelector(
    (state) =>
      state.company &&
      state?.company?.companylist &&
      state?.company?.companylist?.status
  );
  const permissionDataApi = useSelector(
    (state) =>
      state?.logIn &&
      state?.logIn?.routingData &&
      state?.logIn?.routingData?.data &&
      state?.logIn?.routingData?.data?.data
  );
  const profileMeGetRecords = useSelector(
    (state) =>
      state?.MyProfileDetails &&
      state?.MyProfileDetails?.progileMeGetApiRes &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data?.data
  );

  useEffect(() => {
    setPermissionData(permissionDataApi);
  }, [permissionDataApi]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    setCompId(companyId);
    ListCompany();
  }, [localStorage.getItem("companyId")]);

  useEffect(() => {
    if (permissionDataApi) {
      localStorage.setItem("permissionData", JSON.stringify(permissionDataApi));
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let getRoute = location.pathname.includes("make-payment");
    if (token === "" && getRoute) {
      navigate(`/?nextURL=${location.pathname}`);
      // return;
    } else if (token === "") {
      navigate("/");
      // return;
    } else if (token !== "") {
      const id = localStorage.getItem("companyId");
      const data = {
        url: BASE_URL + `useradminpermission/permissions?company_id=${id}`,
      };
      dispatch(PrivateRouteApi(data));
    }
  }, []);

  useEffect(() => {
    if (companyList) {
      setCompanyListData(companyList);
    }
  }, [companyList]);

  useEffect(() => {
    localStorage.setItem("companyName", companyName);
    const companyTitle1 = localStorage.getItem("companyName");
    if (companyName) {
      setCompanyTitle(companyTitle1);
    }
  }, [companyName, companyId]);

  useEffect(() => {
    setCompanyIdget(companyId);
  }, [companyId]);

  useEffect(() => {
    setTitle(location && location.pathname);
  }, [location, companyId]);

  const handleDrawerOpen = (event) => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = (event, text) => {
    if (opens === event) {
      setOpens(null);
    } else {
      setOpens(event);
    }
    setActive(text);
  };

  const ListCompany = () => {
    const data = { url: BASE_URL + `profile/me` };
  };
  const removeLocalStoragePageNo = () => {
    localStorage.removeItem("pageno");
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (companyList && companyList.length > 0 && compId) {
      const matchedCompany = companyList.find(
        (company) => company._id === compId
      );
      // console.log("matchedCompany", matchedCompany);
      if (matchedCompany) {
        setSelectedCompany({
          company: matchedCompany,
          companyName: matchedCompany.name,
        });
      } else {
        setSelectedCompany(null);
      }
    }
  }, [companyList, compId]);

  useEffect(() => {
    if (selectedCompany) {
      setCompanyName(selectedCompany?.company?.name);
    }
  }, [selectedCompany]);

  return (
    <>
      <Box sx={{ display: "flex" }} className="hover-saidenav main-menu">
        <CssBaseline />
        <AppBar position="fixed" open={open} className="main-header">
          <Toolbar>
            <div className="dasboad-main" style={{
              width: "100% !important"
            }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{}}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h4"
                noWrap
                component="div"
                className="pathname"
                style={{
                  width: "100% !important"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "30px"
                  }}
                >
                  {companyListStatus === 200 ? (
                    <h6
                      className="title-head"
                      style={{ margin: 0, marginRight: "10px", width: "auto !important" }}
                    >
                      {title !== "/company-list"
                        ? companyTitle
                        : "Company List"}
                    </h6>
                  ) : (
                    ""
                  )}

                  {title !== "/company-list" ? (
                    <MUIBreadcrumbs
                      aria-label="breadcrumb"
                      className="navigation border-box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100% !important",
                      }}
                    >
                      {pathnames.length ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Link
                            to={isAuditor ? `/auditor-home` : `/dashboard`}
                            className="submenu-item navigation-link fontsize-link"
                          >
                            <OtherHousesIcon
                              sx={{ color: "black", marginTop: "3px" }}
                            />
                          </Link>
                        </div>
                      ) : (
                        <Typography
                          aria-label="breadcrumb"
                          className="navigation border-box"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // width: "300px",
                          }}
                        >
                          {" "}
                          Dashboard{" "}
                        </Typography>
                      )}

                      {pathnames.map((name, index) => {
                        const routeTo = `/${pathnames
                          .slice(0, index + 1)
                          .join("/")}`;
                        const isLast = index === pathnames.length - 1;
                        return isLast ? (
                          <Typography
                            key={name}
                            className="navigation fontsize-link"
                          >
                            {name.split("-").join(" ")}
                          </Typography>
                        ) : (
                          <Link
                            key={name}
                            to={routeTo}
                            className="navigation navigation-link fontsize-link"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#4e73df",
                            }}
                          >
                            {name.split("-").join(" ")}
                          </Link>
                        );
                      })}
                    </MUIBreadcrumbs>
                  ) : (
                    ""
                  )}
                </div>
              </Typography>
            </div>
            <Header />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          className={
            open
              ? "sidedrawer scrollhost bg-sidebar-main"
              : "mobile-sidedrawer scrollhost bg-sidebar-main"
          }
        >
          <DrawerHeader>
            <div className="logo-image">
              <div className="image-logo-top">
                <Lottie
                  animationData={lottieJson}
                  style={{ width: 62, height: 42 }}
                  loop
                  speed={2}
                  play
                ></Lottie>
              </div>
              <Box
                sx={{ textAlign: "left", color: "#27364b", margin: "15px 0px" }}
              >
                <div style={{ fontWeight: "600" }}>Copyright</div>
                <div style={{ fontWeight: "600" }}>@ HOPE RESEARCH GROUP</div>
              </Box>
            </div>
          </DrawerHeader>
          <Divider />

          <List
            sx={{ padding: open ? 1 : 1 }}
            className="hover-saidenav"
            color="inherit"
            aria-label="open drawer"
            onMouseOver={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
            edge="start"
          >
            {companies.map((text, index) => (
              <>
                <Link
                  to={text?.path}
                  key={index}
                  state={companyId}
                  className={`submenu-item ${active === text && "active"}`}
                >
                  <ListItem
                    key={text?.id}
                    disablePadding
                    sx={{ display: "block" }}
                    className="nav-icon menu-link"
                  >
                    <ListItemButton
                      key={text?.id}
                      className={
                        text?.items && opens === index
                          ? "nav-button"
                          : text?.items && opens !== index
                          ? "nav-button"
                          : "nav-button"
                      }
                      open={opens}
                      onClick={() => handleClick(index, text)}
                    >
                      <div
                        className={`icon-image ${active === text && "active"}`}
                      >
                        <ListItemIcon
                          className={`image-icon ${
                            active === text && "active"
                          }`}
                        >
                          {text?.icon}
                        </ListItemIcon>
                      </div>
                      <ListItemText
                        sx={{ opacity: open ? 1 : 0 }}
                        primary={text?.title}
                        className="title-text"
                      />
                      {text?.items && opens === index ? (
                        <ExpandLess
                          sx={{ opacity: open ? 1 : 0 }}
                          className="arrow-icon"
                        />
                      ) : text?.items && opens !== index ? (
                        <ExpandMore
                          sx={{ opacity: open ? 1 : 0 }}
                          className="arrow-icon"
                        />
                      ) : (
                        ""
                      )}
                    </ListItemButton>
                    <Collapse
                      in={opens === index}
                      timeout="auto"
                      key={text?.items?.id}
                      unmountOnExit
                      className="collapse-menu"
                    >
                      <List
                        component="li"
                        disablePadding
                        key={text?.id}
                        className="submenu-item side-submenu"
                      >
                        {text?.items?.map((item, i) => (
                          <Link
                            to={item?.path}
                            key={i}
                            onClick={() => setOpens(!opens)}
                            className="submenu-item"
                            state={item.title}
                          >
                            <List className="list-item" key={item?.id}>
                              <ListItemText
                                sx={{ pl: 4 }}
                                key={item?.id}
                                primary={item?.title}
                              />
                            </List>
                          </Link>
                        ))}
                      </List>
                    </Collapse>
                  </ListItem>
                </Link>
              </>
            ))}
          </List>
          <Divider />
          {companyIdget ? (
            <List
              sx={{ padding: open ? 1 : 1 }}
              className="hover-saidenav"
              color="inherit"
              aria-label="open drawer"
              onMouseOver={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
              edge="start"
            >
              {isAuditor
                ? navitems.map((text, index) => (
                    <>
                      {text?.dashboard === false ||
                      text?.auditorDashboard === true ||
                      text?.checklist === false ||
                      text?.auditorLms === false ||
                      text?.lms === false ||
                      text?.audit === false ||
                      text?.boadcastMessage === false ||
                      text?.customerLog === false ||
                      text?.department === false ||
                      text?.issueLog === false ||
                      text?.mobileAccess === false ||
                      text?.roles === false ||
                      text?.store === false ||
                      text?.user === false ||
                      text?.wastageLog === false ||
                      text?.datamanager === false ||
                      text?.issueLog === false ? (
                        <>
                          <Link
                            to={text?.path}
                            key={index}
                            state={companyId}
                            className={`submenu-item ${
                              active === text && "active"
                            }`}
                          >
                            <ListItem
                              key={text?.id}
                              disablePadding
                              sx={{ display: "block" }}
                              className="nav-icon menu-link"
                            >
                              <ListItemButton
                                className={
                                  text?.items && opens === index
                                    ? "nav-button"
                                    : text?.items && opens !== index
                                    ? "nav-button"
                                    : "nav-button"
                                }
                                open={opens}
                                onClick={() => {
                                  handleClick(index, text);
                                  setSidebar(true);
                                  removeLocalStoragePageNo();
                                }}
                              >
                                <div
                                  className={`icon-image ${
                                    active === text && "active"
                                  }`}
                                >
                                  <ListItemIcon
                                    className={`image-icon ${
                                      active === text && "active"
                                    }`}
                                  >
                                    {text?.icon}
                                  </ListItemIcon>
                                </div>
                                <ListItemText
                                  sx={{ opacity: open ? 1 : 0 }}
                                  primary={text?.title}
                                  className="title-text"
                                />
                                {text?.items && opens === index ? (
                                  <ExpandLess
                                    sx={{ opacity: open ? 1 : 0 }}
                                    className="arrow-icon"
                                  />
                                ) : text?.items && opens !== index ? (
                                  <ExpandMore
                                    sx={{ opacity: open ? 1 : 0 }}
                                    className="arrow-icon"
                                  />
                                ) : (
                                  ""
                                )}
                              </ListItemButton>
                            </ListItem>
                          </Link>
                          <Collapse
                            sx={{ display: open ? "" : "none" }}
                            in={opens === index}
                            timeout="auto"
                            key={text?.items?.id}
                            unmountOnExit
                            className="collapse-menu"
                          >
                            {sidebar && (
                              <span className="singleOrder">
                                {text?.items?.map((item, i) => {
                                  return (
                                    <Link
                                      to={item?.path}
                                      key={i}
                                      onClick={() => {
                                        if (width < 993) {
                                          handleDrawerClose();
                                          setOpens(!opens);
                                        } else {
                                          setOpens(!opens);
                                        }
                                      }}
                                      className="submenu-item"
                                      state={item.title}
                                    >
                                      <div className="list-item" key={item?.id}>
                                        <ListItemText
                                          sx={{ pl: 4 }}
                                          key={item?.id}
                                          primary={item?.title}
                                          className="ListItemText-subitem"
                                        />
                                      </div>
                                    </Link>
                                  );
                                })}
                              </span>
                            )}
                          </Collapse>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))
                : navitems.map((text, index) => (
                    <>
                      {text?.dashboard === true ||
                      text?.checklist === PermissionData?.checklist?.enable ||
                      text?.lms === PermissionData?.lms?.enable ||
                      text?.audit === PermissionData?.audit?.enable ||
                      text?.chat === true ||
                      text?.attendanceLog === true ||
                      text?.logEntries === true ||
                      text?.boadcastMessage ===
                        PermissionData?.boadcastMessage?.enable ||
                      text?.customerLog ===
                        PermissionData?.customerLog?.enable ||
                      text?.department === PermissionData?.department?.enable ||
                      text?.issueLog === PermissionData?.issueLog?.enable ||
                      text?.mobileAccess ===
                        PermissionData?.mobileAccess?.enable ||
                      text?.roles === PermissionData?.roles?.enable ||
                      text?.store === PermissionData?.store?.enable ||
                      text?.user === PermissionData?.user?.enable ||
                      text?.wastageLog === PermissionData?.wastageLog?.enable ||
                      text?.datamanager ===
                        PermissionData?.addCompany?.dataManager ||
                      text?.issueLog === PermissionData?.issueLog?.enable ||
                      (text?.director === true &&
                        profileMeGetRecords?.role?.key === "directors") ? (
                        <>
                          <Link
                            to={text?.path}
                            key={index}
                            state={companyId}
                            className={`submenu-item ${
                              active === text && "active"
                            }`}
                          >
                            <ListItem
                              key={text?.id}
                              disablePadding
                              sx={{ display: "block" }}
                              className="nav-icon menu-link"
                            >
                              <ListItemButton
                                className={
                                  text?.items && opens === index
                                    ? "nav-button"
                                    : text?.items && opens !== index
                                    ? "nav-button"
                                    : "nav-button"
                                }
                                open={opens}
                                onClick={() => {
                                  handleClick(index, text);
                                  setSidebar(true);
                                  removeLocalStoragePageNo();
                                }}
                              >
                                <div
                                  className={`icon-image ${
                                    active === text && "active"
                                  }`}
                                >
                                  <ListItemIcon
                                    className={`image-icon ${
                                      active === text && "active"
                                    }`}
                                  >
                                    {text?.icon}
                                  </ListItemIcon>
                                </div>
                                <ListItemText
                                  sx={{ opacity: open ? 1 : 0 }}
                                  primary={text?.title}
                                  className="title-text"
                                />
                                {text?.items && opens === index ? (
                                  <ExpandLess
                                    sx={{ opacity: open ? 1 : 0 }}
                                    className="arrow-icon"
                                  />
                                ) : text?.items && opens !== index ? (
                                  <ExpandMore
                                    sx={{ opacity: open ? 1 : 0 }}
                                    className="arrow-icon"
                                  />
                                ) : (
                                  ""
                                )}
                              </ListItemButton>
                            </ListItem>
                          </Link>
                          <Collapse
                            sx={{ display: open ? "" : "none" }}
                            in={opens === index}
                            timeout="auto"
                            key={text?.items?.id}
                            unmountOnExit
                            className="collapse-menu"
                          >
                            {sidebar && (
                              <span className="singleOrder">
                                {text?.items?.map((item, i) => {
                                  return (
                                    <Link
                                      to={item?.path}
                                      key={i}
                                      onClick={() => {
                                        if (width < 993) {
                                          handleDrawerClose();
                                          setOpens(!opens);
                                        } else {
                                          setOpens(!opens);
                                        }
                                      }}
                                      className="submenu-item"
                                      state={item.title}
                                    >
                                      <div className="list-item" key={item?.id}>
                                        <ListItemText
                                          sx={{ pl: 4 }}
                                          key={item?.id}
                                          primary={item?.title}
                                          className="ListItemText-subitem"
                                        />
                                      </div>
                                    </Link>
                                  );
                                })}
                              </span>
                            )}
                          </Collapse>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
            </List>
          ) : (
            ""
          )}
          <Divider />
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;
