/** @format */

import React, { useState } from "react";
import { Stack, Tabs, Tab, Typography, Tooltip } from "@mui/material";
import { Button, Card, Grid } from "material-ui-core";
import SearchAppBar from "../../checklist/tableHeader/SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddSchedular from "./AddSchedular";

const Header = ({
  data,
  searchOnChange,
  searchValue,
  handleKeyDown,
  permission,
}) => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  // Add new button disabled from here
  const isAddDisabled = true; 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12} className="colomn-padding">
            <Stack
              direction="row"
              className="Assign-Checklist"
              sx={{ float: "right" }}
            >
              <SearchAppBar
                searchValue={searchValue}
                searchOnChange={searchOnChange}
                handleKeyDown={handleKeyDown}
              />
              {permission?.roles?.manageRole === true ? (
                <Tooltip
                  title={
                    isAddDisabled
                      ? "Add new is not allowed as of now, please connect with the administrator for more information."
                      : ""
                  }
                >
                  <span>
                    <Button
                      className="header-add cancle-hover"
                      onClick={handleClickOpen}
                      disabled={isAddDisabled} 
                      style={{
                        opacity: isAddDisabled ? 0.5 : 1, 
                        cursor: isAddDisabled ? "not-allowed" : "pointer", 
                      }}
                    >
                      <AddOutlinedIcon sx={{ mr: 1 }} />
                      Add new
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <AddSchedular open={open} handleClose={handleClose} data={data} />
    </>
  );
};

export default Header;
