import {
  ISSUE_LIST_REQUEST,
  ISSUE_LIST_SUCCESS,
  ISSUE_LIST_ERROR,
  ADD_ISSUE_REQUEST,
  ADD_ISSUE_SUCCESS,
  ADD_ISSUE_ERROR,
  ISSUE_DETAILS_REQUEST,
  ISSUE_DETAILS_SUCCESS,
  ISSUE_DETAILS_ERROR,
  GET_ISSUE_FORMDATA_REQUEST,
  GET_ISSUE_FORMDATA_SUCCESS,
  GET_ISSUE_FORMDATA_ERROR,
  EDIT_ISSUE_REQUEST,
  EDIT_ISSUE_SUCCESS,
  EDIT_ISSUE_ERROR,
  ISSUE_DELETE_REQUEST,
  ISSUE_DELETE_SUCCESS,
  ISSUE_DELETE_ERROR,
  ADD_ISSUE_IMAGE_REQUEST,
  ADD_ISSUE_IMAGE_SUCCESS,
  ADD_ISSUE_IMAGE_ERROR,
  //Media code here
  ADD_ISSUE_MEDIA_REQUEST,
  ADD_ISSUE_MEDIA_SUCCESS,
  ADD_ISSUE_MEDIA_ERROR,
  //Media code end
  ISSUE_ARCHIVED_REQUEST,
  ISSUE_ARCHIVED_SUCCESS,
  ISSUE_ARCHIVED_ERROR,
  ISSUE_COUNT_REQUEST,
  ISSUE_COUNT_SUCCESS,
  ISSUE_COUNT_ERROR,
  ISSUE_CATEGORY_REQUEST,
  ISSUE_CATEGORY_SUCCESS,
  ISSUE_CATEGORY_ERROR,
  LINECHART_ISSUE_REQUEST,
  LINECHART_ISSUE_SUCCESS,
  LINECHART_ISSUE_ERROR,
  ISSUE_CREATED_REQUEST,
  ISSUE_CREATED_SUCCESS,
  ISSUE_CREATED_ERROR,
  ISSUE_AVERAGE_REQUEST,
  ISSUE_AVERAGE_SUCCESS,
  ISSUE_AVERAGE_ERROR,
  ALL_ISSUE_LIST_REQUEST,
  ALL_ISSUE_LIST_SUCCESS,
  ALL_ISSUE_LIST_ERROR,
  CATEGORY_LIST_ISSUE_REQUEST,
  CATEGORY_LIST_ISSUE_SUCCESS,
  CATEGORY_LIST_ISSUE_ERROR,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  data: [],
  allIssuedata: [],
  issueArchivedDataList: [],
  addIssue: "",
  addImage: "",
  addMedia: "",
  details: "",
  categoryList: "",
  departmentList: "",
  storeList: "",
  getIssueData: "",
  editIssue: "",
  deleteIssue: "",
  issueCount: [],
  mostIssueCategory: [],
  createdMostIssue: [],
  lineChartData: [],
  averageData: [],
  categoryData: [],
};

const IssueReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LIST_ISSUE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CATEGORY_LIST_ISSUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        categoryData: action?.payload,
      };
    }
    case CATEGORY_LIST_ISSUE_ERROR: {
      return {
        ...state,
        loading: false,
        categoryData: [],
        error: action.error,
      };
    }
    case ALL_ISSUE_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ALL_ISSUE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        allIssuedata: action?.payload,
      };
    }
    case ALL_ISSUE_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        allIssuedata: [],
        error: action.error,
      };
    }
    case ISSUE_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.payload,
      };
    }
    case ISSUE_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ISSUE_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_ARCHIVED_SUCCESS: {
      return {
        ...state,
        loading: false,
        issueArchivedDataList: action?.payload,
      };
    }
    case ISSUE_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ADD_ISSUE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_ISSUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        addIssue: action?.payload,
      };
    }
    case ADD_ISSUE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case ADD_ISSUE_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_ISSUE_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        addImage: action?.payload,
      };
    }
    case ADD_ISSUE_IMAGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    //Media Code Here
    case ADD_ISSUE_MEDIA_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_ISSUE_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        addMedia: action?.payload,
      };
    }
    case ADD_ISSUE_MEDIA_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    //Media Code End
    case GET_ISSUE_FORMDATA_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GET_ISSUE_FORMDATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        getIssueData: action?.payload,
      };
    }
    case GET_ISSUE_FORMDATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case EDIT_ISSUE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_ISSUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        editIssue: action?.payload,
      };
    }
    case EDIT_ISSUE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case ISSUE_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: action?.payload,
      };
    }
    case ISSUE_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ISSUE_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        deleteIssue: action?.payload,
      };
    }
    case ISSUE_DELETE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ISSUE_COUNT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        issueCount: action?.payload,
      };
    }
    case ISSUE_COUNT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ISSUE_CATEGORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        mostIssueCategory: action?.payload,
      };
    }
    case ISSUE_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ISSUE_CREATED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_CREATED_SUCCESS: {
      return {
        ...state,
        loading: false,
        createdMostIssue: action?.payload,
      };
    }
    case ISSUE_CREATED_ERROR: {
      return {
        ...state,
        loading: false,
        createdMostIssue: [],
        error: action.error,
      };
    }
    case LINECHART_ISSUE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LINECHART_ISSUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        lineChartData: action?.payload,
      };
    }
    case LINECHART_ISSUE_ERROR: {
      return {
        ...state,
        loading: false,
        lineChartData: [],
        error: action.error,
      };
    }
    case ISSUE_AVERAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ISSUE_AVERAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        averageData: action?.payload,
      };
    }
    case ISSUE_AVERAGE_ERROR: {
      return {
        ...state,
        loading: false,
        averageData: [],
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default IssueReducer;
