/** @format */

import React, { useEffect, useState, useMemo } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
  Typography,
  Button,
  Divider,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Card,
  // Table,
  // TableHead,
  // TableRow,
  // TableCell,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useFormik } from "formik";
import {
  // ImageAddApi,
  ListUserApi,
  UserSubRoleApi,
} from "../../../redux/actions/User";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AddCertificateImageReset,
  AddCertificateImageApi,
  // CertificateListApi,
  CreateNewTrainingApi,
  CreateNewTrainingSuccess,
  CategoryListApi,
} from "../../../redux/actions/LmsAction";
import AssesmentModal from "./AssesmentModal";
import CertificateModal from "./CertificateModal";
// import DeleteIcon from "@mui/icons-material/Delete";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
import { toast, ToastContainer } from "react-toastify";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import EditHover from "../../../images/EditHover.svg";
import Edit from "../../../images/Edit.svg";
import DeleteHover from "../../../images/DeleteHover.svg";
import Delete from "../../../images/Delete.svg";
// import Progressbar from "../../../GlobalProgress/Progressbar";
import parse from "html-react-parser";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const AddTraining = () => {
  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data
  );

  const trainingSuccessRes = useSelector(
    (state) => state.lms && state.lms.newtraining && state.lms.newtraining.data
  );
  
  const categoryListData = useSelector(
    (state) => state.lms && state.lms.categoryListData && state.lms.categoryListData.data && state.lms.categoryListData.data.data || []
  );

  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );
  const getImageKey = useSelector((state) => state.lms && state.lms.addImage);

  // const datapayload = {
  //   body: {
  //     page: 1,
  //     per_page: 2000000000000,
  //   },
  // };

  const bodyObj = {};

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const chapterData = location?.state?.steps?.steps
    ? location?.state?.steps?.steps
    : location?.state?.steps;
  const cancleTrainingPath = location?.state?.trainingPath;
  // let LocalData = localStorage.getItem("trainingChapters");
  let addedAssesment = localStorage.getItem("assesmentData");
  let addedCertificate = localStorage.getItem("certificateData");
  let trainingdata = JSON.parse(localStorage.getItem("addTrainingData"));
  const classes = useStyles();
  const [categoryData, setCategoryData] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [userSelect, setUserSelect] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selected, setSelected] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [certificateIdList, setCertificateIdList] = useState([]);
  const [deletedCertificate, setDeletedCertificate] = useState({});
  const [certificatePayloadId, setCertificatePayloadId] = useState([]);
  const [assesmentIdList, setAssesmentIdList] = useState([]);
  const [deletedAssesment, setDeletedAssesment] = useState({});
  const [assesmentPayloadId, setAssesmentPayloadId] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCerti, setOpenCerti] = useState(false);
  const [authorId, setAuthorId] = useState([]);
  const [bodyImage, setBodyImage] = useState([]);
  const [newvalues, setNewvalues] = useState([]);
  const [image, setImage] = useState([]);
  const [arraylength, setArraylength] = useState([]);
  const [indexHover, setIndexHover] = useState(0);
  const [repeated, setRepeated] = useState(
    trainingdata?.repeated ? trainingdata?.repeated : false
  );
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  useEffect(() => {
    for (let item of chapters) {
      if (item?.type !== 1) {
        if (item?.content?.size) {
          setImage((image) => [...image, { content: item?.content, item }]);
          // setImage(item.content)
        }
      }
    }
    setBodyImage(chapters);
  }, [chapters]);

  useEffect(() => {
    let newValues =
      bodyImage &&
      bodyImage?.map((item, i) => {
        if (item?.type !== 1) {
          let file = getImageKey.filter(
            (item1) => item?.name === item1?.chapterData?.name
          );
          let fileName = file[0]?.imageData[0]?.filename;
          let itemName = item?.content?.name;
          let itemKey = file[0]?.imageData[0].key;
          if (fileName === itemName) {
            item.content = itemKey;
            setArraylength((arraylength) => [...arraylength, itemKey]);
          }
          return (bodyImage[i] = item);
        }
        return item;
      });
    setNewvalues(newValues);
  }, [getImageKey]);

  useEffect(() => {
    if (getImageKey?.length === image?.length) {
      formSubmit(formik.values, newvalues);
    }
  }, [getImageKey]);

  useEffect(() => {
    if (trainingSuccessRes) {
      // console.log("called");
      dispatch(CreateNewTrainingSuccess(""));
      setTimeout(() => {
        navigate("/training");
      }, 500);
    }
  }, [trainingSuccessRes]);

  const isAllSelected =
    userSelect?.length > 0 && selected?.length === userSelect?.length;

  useEffect(() => {
    if (assesmentIdList) {
      let idList = assesmentIdList.map((ids, i) => {
        return ids._id;
      });
      setAssesmentPayloadId(idList);
    }
    if (certificateIdList) {
      let idList = certificateIdList.map((ids, i) => {
        return ids._id;
      });
      setCertificatePayloadId(idList);
    }
  }, [assesmentIdList, certificateIdList]);

  // useEffect(() => {
  //   if (LocalData) {
  //     let chapterList = JSON.parse(LocalData);
  //     setChapters(chapterList);
  //   }
  // }, [LocalData])
  useEffect(() => {
    let day = trainingdata?.starts_at?.toString().slice(6, 8);
    let month = trainingdata?.starts_at?.toString().slice(4, 6);
    let year = trainingdata?.starts_at?.toString().slice(0, 4);
    let sortedDate = new Date(`${month}-${day}-${year}`);

    setStartDate(sortedDate);
    let daysecond = trainingdata?.expires_at?.toString().slice(6, 8);
    let monthsecond = trainingdata?.expires_at?.toString().slice(4, 6);
    let yearsecond = trainingdata?.expires_at?.toString().slice(0, 4);
    let sortedDatesecond = new Date(
      `${monthsecond}-${daysecond}-${yearsecond}`
    );
    setEndDate(sortedDatesecond);
  }, []);
  useEffect(() => {
    if (chapterData) {
      // let chapterList = JSON.parse(chapterData);
      setChapters(chapterData);
    }
  }, [chapterData]);

  useEffect(() => {
    if (addedAssesment) {
      let chapterList = JSON.parse(addedAssesment);
      setAssesmentIdList(chapterList);
    }
  }, [addedAssesment]);

  useEffect(() => {
    if (addedCertificate) {
      let chapterList = JSON.parse(addedCertificate);
      setCertificateIdList(chapterList);
    }
  }, [addedCertificate]);

  useEffect(() => {
    if (userList?.length > 0) {
      let arr = [];
      for (let item of userList) {
        let obj = item?.name;
        arr.push(obj);
      }
      // setUserSelect(arr);
      setUserSelect(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (subRoleList) {
      setRoleSelect(subRoleList);
    }
  }, [subRoleList]);

  useEffect(() => {
    dispatch(CreateNewTrainingSuccess(""));
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
    setUserSelect([]);
  }, []);

  useEffect(() => {
    // const category = JSON.parse(localStorage.getItem("categoryData"));
    // setCategoryData(category);
    ListCatagory();
  }, []);

  useEffect(() => {
    setCategoryData(categoryListData);
  }, [categoryListData]);

  const ListCatagory = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `categories?company_id=${id}&moduleName=${"lms"}&submoduleName=training` };
    dispatch(CategoryListApi(data));
  };

  // const listUser = (data) => {
  //   const id = localStorage.getItem("companyId");
  //   datapayload.url = BASE_URL + `users/index?company_id=${id}`;
  //   dispatch(ListUserApi(datapayload));
  // };

  const handleChapter = (data, selected) => {
    let new_startDate = moment(startDate)
      .format("YYYY-MM-DD")
      .split("-")
      .join("");
    let new_endDate = moment(endDate).format("YYYY-MM-DD").split("-").join("");
    const trainingFields = {
      authors: selected,
      category: data?.category,
      name: data?.name,
      role: data?.role,
      subCategory: data?.subCategory,
      summary: data?.summary,
      starts_at: new_startDate,
      expires_at: new_endDate,
      assessment: assesmentIdList,
      certificate: certificateIdList,
      repeated: repeated,
    };
    localStorage.setItem("addTrainingData", JSON.stringify(trainingFields));
    let trainingdata = JSON.parse(localStorage.getItem("addTrainingData"));
    if (trainingdata) {
      navigate("/training/add-training/add-chapter", {
        state: { path: "newaddchapter", row: chapters },
      });
    }
  };
  const handleAuther = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelected(selected.length === userSelect?.length ? [] : userSelect);
      return;
    }
    setSelected(value);
  };

  useEffect(() => {
    if (trainingdata !== null) {
      // let authorid = [];
      // for (let item of trainingdata?.authors) {
      //   authorid.push(item?._id);
      // }
      // setAuthorId(authorid);
      setSelected(trainingdata?.authors);
    }
  }, []);

  useEffect(() => {
    if (selected?.length) {
      let authorid = [];
      for (let item of selected) {
        authorid.push(item?._id);
      }
      setAuthorId(authorid);
    }
  }, [selected]);

  useEffect(() => {
    // console.log("getImageKey", getImageKey);
  }, [getImageKey]);

  const formik = useFormik({
    initialValues: {
      name: trainingdata?.name ? trainingdata?.name : "",
      summary: trainingdata?.summary ? trainingdata?.summary : "",
      role: trainingdata !== null ? trainingdata?.role : "",
      category: trainingdata !== null ? trainingdata?.category : "",
      subCategory: trainingdata !== null ? trainingdata?.subCategory : "",
      authors: trainingdata !== null ? trainingdata?.authors : [],
      repeated: trainingdata && trainingdata?.repeated ? trainingdata?.repeated : false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let startStr = JSON.stringify(startDate);
      let endStr = JSON.stringify(endDate);
      if (startStr !== "null") {
        let new_startDate = moment(startDate)
          .format("YYYY-MM-DD")
          .split("-")
          .join("");
        values.starts_at = parseInt(new_startDate);
      }
      if (endStr !== "null") {
        let new_endDate = moment(endDate)
          .format("YYYY-MM-DD")
          .split("-")
          .join("");
        values.expires_at = parseInt(new_endDate);
      }

      if (!values.name) {
        toast.error("Training Name is required");
        return;
      }
      if (!values.role) {
        toast.error("Authors are mandatory");
        return;
      }
      if (!authorId.length) {
        toast.error("Authors are mandatory");
        return;
      }
      if (!values.category) {
        toast.error("Category is required");
        return;
      }
      if (!values.subCategory) {
        toast.error("Sub-Category is required");
        return;
      }
      if (chapters.length <= 0) {
        toast.error("At least One Chapter is required");
        return;
      }

      if (
        values.repeated &&
        (!assesmentIdList || assesmentIdList.length == 0)
      ) {
        toast.error("One assessment is required for dynamic submission.");
        return;
      }
      // if (
      //   assesmentIdList &&
      //   assesmentIdList.length > 1
      // ) {
      //   toast.error("Only one assessment is allowed for dynamic submission.");
      //   return;
      // }

      if (startStr !== "null" && endStr !== "null") {
        dispatch(AddCertificateImageReset());
        if (image?.length > 0) {
          for (let item of image) {
            const imagefile = {
              files: item.content,
            };

            const imageData = {
              body: imagefile,
            };

            if (
              imageData.body.files != "" &&
              imageData.body.files != undefined
            ) {
              imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
              dispatch(AddCertificateImageApi(imageData, item.item));
            }
          }
        } else {
          let imgKey = chapters;
          formSubmit(values, imgKey);
        }
      } else {
        toast.error("Select Date");
      }
      // formSubmit(values, imgKey)
    },
  });

  const formSubmit = (values, newchapters) => {
    if (newchapters?.length > 0) {
      values.steps = newchapters;

      let startStr = JSON.stringify(startDate);
      let endStr = JSON.stringify(endDate);
      if (startStr !== "null") {
        let new_startDate = moment(startDate)
          .format("YYYY-MM-DD")
          .split("-")
          .join("");
        values.starts_at = parseInt(new_startDate);
      }
      if (endStr !== "null") {
        let new_endDate = moment(endDate)
          .format("YYYY-MM-DD")
          .split("-")
          .join("");
        values.expires_at = parseInt(new_endDate);
      }
      values.repeated = repeated;
      values.certificate = certificatePayloadId;
      values.assessment = assesmentPayloadId;
      values.duration = null;
      values.authors = authorId;

      const payloadData = {
        body: values,
      };
      if (payloadData && startStr !== "null" && endStr !== "null") {
        const id = localStorage.getItem("companyId");
        payloadData.url = BASE_URL + `training?company_id=${id}`;
        dispatch(CreateNewTrainingApi(payloadData));
      } else {
        toast.error("Select Date");
      }
    }
  };

  useEffect(() => {
    const subRoleUser = formik.values.role;
    if (subRoleUser !== "") {
      listUser(subRoleUser);
    }
  }, [formik.values.role]);

  function getFilteredList() {
    if (!formik.values.category) {
      return [];
    }
    return categoryData.filter((item) => item.title === formik.values.category);
  }

  const filteredList = useMemo(getFilteredList, [
    formik.values.category,
    categoryData,
  ]);

  useEffect(() => {
    let subcategoryList =
      filteredList &&
      filteredList?.map((list, i) => {
        setSubCategory(list?.subCategories);
      });
    // return subcategoryList
  }, [filteredList]);

  // const handleAssesment = (item) =>{
  //   setAssesmentIdList((assesmentIdList)=> [...assesmentIdList, item])
  // }
  // const handleCertificate = (item) =>{
  //   setCertificateIdList((certificateIdList)=> [...certificateIdList, item])
  // }

  const handleDelete = (list) => {
    setDeletedAssesment(list);
    const target = assesmentIdList.filter((item, i) => item._id !== list._id);
    setAssesmentIdList(target);
    localStorage.setItem('assesmentData', JSON.stringify(target));
    localStorage.setItem('editAssessment', JSON.stringify(target.map(item => item._id)));
  };
  const handleCertificateDelete = (list) => {
    setDeletedCertificate(list);
    const target = certificateIdList.filter((item, i) => item._id !== list._id);
    setCertificateIdList(target);
    localStorage.setItem('certificateData', JSON.stringify(target));
    localStorage.setItem('editCertificate', JSON.stringify(target.map(item => item._id)));

  };

  const handleChapterDelete = (index) => {
    // setChapters(chapters.filter((item, i) => i !== index))
    let newcustom = chapters.filter((item, i) => i !== index);
    let newArray = [...newcustom];
    setChapters(newArray);
    // let local = localStorage.setItem(
    //   "trainingChapters",
    //   JSON.stringify(newArray)
    // );
  };
  const handleChapterEdit = (items, index) => {
    navigate("/training/add-training/edit-chapter", {
      state: { items, path: "newEditchapter", row: chapters },
    });
  };

  const listUser = (authers) => {
    const id = localStorage.getItem("companyId");
    const datapayload = {
      url: BASE_URL + `MapUsers?company_id=${id}&subRole=${authers}`,
    };
    dispatch(ListUserApi(datapayload));
  };

  const trainingLocalClear = () => {
    localStorage.removeItem("addTrainingData");
    if (cancleTrainingPath === "alltrainingreport") {
      navigate("/training");
    } else if (cancleTrainingPath === "storetraining") {
      navigate("/store-training");
    } else if (cancleTrainingPath === "usertraining") {
      navigate("/user-training");
    } else {
      navigate("/alltraining-report");
    }
  };

  const handleAssesment = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAssesmentIdList([]);
    setOpen(false);
  };

  const handleCertificate = () => {
    setOpenCerti(true);
  };
  const handleCloseCerti = () => {
    setCertificateIdList([]);
    setOpenCerti(false);
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      {Loader && <ProgressbarTransparent play />}
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                {/* <Link to="/alltrainingreport" className="submenu-item"> */}
                <Button
                  className="cancle-button cancle-hover"
                  onClick={trainingLocalClear}
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
                {/* </Link> */}
                {/* <Link to="/alltrainingreport" className="submenu-item"> */}
                <Button
                  className="header-add cancle-hover"
                  type="submit"
                  // onClick={trainingLocalClear}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Create
                </Button>
                {/* </Link> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              // height: "80vh",
              height: "calc(100vh - 220px)",
              mt: 1,
              p: 3,
              overflowX: "hidden",
              overflowY: "scroll",
              borderRadius: "8px",
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                md={6}
                xs={12}
                sm={12}
                lg={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  maxWidth="sm"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    p: 3,
                    textAlign: "left",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Title</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="name"
                          onChange={formik.handleChange}
                          // defaultValue={trainingdata?.name}
                          value={formik.values.name}
                          className="date-input"
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Role</label>
                        <Select
                          size="small"
                          name="role"
                          onChange={(e) => {
                            formik.handleChange(e);
                            setSelected([]);
                          }}
                          value={formik.values.role}
                          // defaultValue={trainingdata?.role}
                          sx={{ borderRadius: "8px" }}
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            PaperProps: {
                              sx: {
                                bgcolor: "#FFFFFF",
                                "& .MuiMenuItem-root": {
                                  padding: 1,
                                },
                                height: "180px",
                              },
                            },
                          }}
                        >
                          {roleSelect &&
                            roleSelect?.map((option, i) => {
                              return (
                                <MenuItem key={i} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Category</label>
                        <Select
                          size="small"
                          name="category"
                          onChange={formik.handleChange}
                          value={formik.values.category}
                          // defaultValue={trainingdata?.category}
                          sx={{ borderRadius: "8px" }}
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            PaperProps: {
                              sx: {
                                bgcolor: "#FFFFFF",
                                "& .MuiMenuItem-root": {
                                  padding: 1,
                                },
                                height: "180px",
                              },
                            },
                          }}
                        >
                          {categoryData &&
                            categoryData?.map((option, i) => {
                              return (
                                <MenuItem key={i} value={option.title}>
                                  {option.title}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {/* <Select
                    size="small"
                    name="category"
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    sx={{ borderRadius: "8px" }}
                  >
                    {categoryData &&
                  categoryData?.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option}>
                        {option.title}
                      </MenuItem>
                    );
                  })}
                  </Select> */}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Summary</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="summary"
                          onChange={formik.handleChange}
                          value={formik.values.summary}
                          // defaultValue={trainingdata?.summary}
                          className="date-input"
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Select Author</label>
                        <Select
                          labelId="mutiple-select-label"
                          multiple
                          name="authors"
                          size="small"
                          value={selected}
                          onChange={handleAuther}
                          sx={{ borderRadius: "8px" }}
                          MenuProps={{
                            disableScrollLock: true,
                            PaperProps: {
                              sx: {
                                bgcolor: "#FFFFFF",
                                "& .MuiMenuItem-root": {
                                  padding: 0,
                                },
                                height: "180px",
                              },
                            },
                          }}
                          renderValue={(selected) =>
                            selected.map((item) => item.name)?.join(",")
                          }
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root: isAllSelected ? classes.selectedAll : "",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                classes={{
                                  indeterminate: classes.indeterminateColor,
                                }}
                                checked={isAllSelected}
                                indeterminate={
                                  selected?.length > 0 &&
                                  selected?.length < userSelect?.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: classes.selectAllText }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {userSelect &&
                            userSelect.map((option, i) => {
                              return (
                                <MenuItem key={i} value={option}>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={
                                        selected.findIndex(
                                          (item) => item?._id === option?._id
                                        ) > -1
                                      }
                                      // checked={selected.indexOf(option) > -1}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={option?.name} />
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Sub-Category</label>
                        <Select
                          size="small"
                          name="subCategory"
                          onChange={formik.handleChange}
                          value={formik.values.subCategory}
                          sx={{ borderRadius: "8px" }}
                        >
                          {subCategory &&
                            subCategory?.map((option, i) => {
                              return (
                                <MenuItem key={i} value={option.title}>
                                  {option.title}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Training Period</label>
                    <Stack direction="row" className="stack">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          inputFormat="DD/MM/YYYY"
                          size="small"
                          value={startDate}
                          onChange={(newValue) => {
                            //   let dateSort = newValue.map((val) =>
                            //   moment(val?.$d).format("YYYY/MM/DD").split("/").join("")
                            // );
                            let dateSort = moment(newValue?.$d)
                              .format("YYYY-MM-DD")
                              .split("-")
                              .join("");
                            setStartDate(newValue?.$d);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          className="training-date"
                          minDate={new Date()}
                        />
                      </LocalizationProvider>
                      <Typography className="date-to">to</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          inputFormat="DD/MM/YYYY"
                          size="small"
                          value={endDate}
                          onChange={(newValue) => {
                            let dateSort = moment(newValue?.$d)
                              .format("YYYY-MM-DD")
                              .split("-")
                              .join("");
                            setEndDate(newValue?.$d);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          className="training-date"
                          // maxDate={new Date()}
                          minDate={new Date()}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormGroup>
                      <FormControlLabel
                        className="label-name"
                        control={
                          <Checkbox
                            type="checkbox"
                            name="repeated"
                            onChange={(e) => {
                              formik.handleChange(e)
                              setRepeated(e.target.checked)
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ color: "#196773" }}
                            checked={formik.values.repeated}
                          />
                        }
                        label="Dynamic Assessment Submission"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={6} xs={12} sm={12} lg={6}>
                <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                  <Grid item md={6}>
                    <Typography variant="p" component="b">
                      Assessment
                    </Typography>
                    <Button
                      variant="outlined"
                      className="add-button cancle-hover"
                      sx={{ width: "90%", mb: 1, mt: 1, whiteSpace: "nowrap" }}
                      onClick={handleAssesment}
                    >
                      <AddIcon sx={{ mx: 1 }} />
                      Add Assessment
                    </Button>
                    <Box sx={{ mb: 1 }}>
                      {assesmentIdList &&
                        assesmentIdList.length > 0 &&
                        assesmentIdList?.map((list, i) => {
                          return (
                            <Box
                              key={i}
                              sx={{ width: "70%", display: "inline-block" }}
                            >
                              <Typography className="assesment-list">
                                <LightTooltip title={list.title}>
                                  <Typography className="text-short-assesment">
                                    {list.title}
                                  </Typography>
                                </LightTooltip>
                                <Typography>
                                  <ClearIcon
                                    onClick={() => handleDelete(list)}
                                  />
                                </Typography>
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="p" component="b">
                      Certificate
                    </Typography>
                    <Button
                      variant="outlined"
                      className="add-button cancle-hover"
                      sx={{ width: "90%", mb: 1, mt: 1, whiteSpace: "nowrap" }}
                      onClick={handleCertificate}
                    >
                      <AddIcon sx={{ mx: 1 }} />
                      Add Certificate
                    </Button>
                    <Box>
                      {certificateIdList &&
                        certificateIdList.length > 0 &&
                        certificateIdList?.map((list, i) => {
                          return (
                            <Box
                              key={i}
                              sx={{ width: "70%", display: "inline-block" }}
                            >
                              <Typography className="assesment-list">
                                <LightTooltip title={list.title}>
                                  <Typography className="text-short-assesment">
                                    {list.title}
                                  </Typography>
                                </LightTooltip>
                                <Typography>
                                  <ClearIcon
                                    onClick={() =>
                                      handleCertificateDelete(list)
                                    }
                                  />
                                </Typography>
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12} sm={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{ justifyContent: "center", margin: "0 auto" }}
                >
                  <Grid item md={6} sx={12} sm={12}>
                    <Typography variant="p" component="b" sx={{ mt: 2 }}>
                      Chapter
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleChapter(formik.values, selected);
                      }}
                      className="add-button cancle-hover"
                      sx={{ width: "95%", mt: 1 }}
                    >
                      <AddIcon sx={{ mx: 1 }} />
                      Add New Chapter
                    </Button>
                  </Grid>
                </Grid>
                {chapters && chapters?.length > 0
                  ? chapters &&
                    chapters?.length > 0 &&
                    chapters?.map((items, i) => {
                      return (
                        <Card key={i} sx={{ my: 2 }}>
                          <Grid container spacing={1}>
                            <Grid item md={10}>
                              <Box sx={{ px: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h3>
                                    {i + 1}. &nbsp;&nbsp;{items.name}
                                  </h3>
                                  <p>
                                    type:{" "}
                                    <b>
                                      {items.type === 1
                                        ? "Text"
                                        : items.type === 2
                                        ? "Video"
                                        : items.type === 3
                                        ? "Audio"
                                        : items.type === 4
                                        ? "Image"
                                        : items.type === 5
                                        ? "Doc"
                                        : ""}
                                    </b>
                                  </p>
                                </Box>
                                <h5 style={{ textAlign: "left", margin: "0" }}>
                                  Summary of Chapter
                                </h5>
                                <Card
                                  sx={{
                                    p: 2,
                                    my: 1,
                                    border: "1px solid #CBD4E1",
                                    borderRadius: "8px",
                                    background: "#F6F8FC",
                                    textAlign: "left",
                                  }}
                                >
                                  <p>{items.summary}</p>
                                </Card>
                                <h5
                                  style={{
                                    textAlign: "left",
                                    margin: "0",
                                    paddingTop: "20px",
                                  }}
                                >
                                  Content
                                </h5>
                                <Card
                                  sx={{
                                    p: 2,
                                    my: 1,
                                    mb: 1,
                                    border: "1px solid #CBD4E1",
                                    borderRadius: "8px",
                                    background: "#F6F8FC",
                                    textAlign: "left",
                                  }}
                                >
                                  {items?.type === 2 ||
                                  items?.type === 3 ||
                                  items?.type === 4 ||
                                  items?.type === 5 ? (
                                    <>
                                      <p>
                                        {items?.content?.name
                                          ? items?.content?.name
                                          : items?.content}
                                      </p>
                                      <p>{items?.content?.size}</p>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {items?.type === 1 ? (
                                    <>
                                      {items && items?.content
                                        ? (() => {
                                            if (items && items?.content) {
                                              let newContent = JSON.parse(
                                                items?.content
                                              );
                                              return (
                                                <>
                                                  <p className="content-list">
                                                    {" "}
                                                    {parse(newContent)}
                                                  </p>
                                                  <p> {newContent.name}</p>
                                                  {newContent?.blocks &&
                                                  newContent?.blocks?.length > 0
                                                    ? newContent?.blocks &&
                                                      newContent?.blocks
                                                        ?.length > 0 &&
                                                      newContent?.blocks?.map(
                                                        (conten, i) => {
                                                          return (
                                                            <p>{conten.text}</p>
                                                          );
                                                        }
                                                      )
                                                    : ""}
                                                </>
                                              );
                                            }
                                          })()
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Card>
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box className="box-icons">
                                <Tooltip title="Delete">
                                <h3>
                                  <img
                                    onClick={() => handleChapterDelete(i)}
                                    src={
                                      hover.Delete && indexHover === i
                                        ? DeleteHover
                                        : Delete
                                    }
                                    alt="Delete"
                                    style={{
                                      height: "35px",
                                      cursor: "pointer",
                                      margin: "0px 3px 0px 3px",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ Delete: true });
                                      setIndexHover(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ Delete: false });
                                    }}
                                  />
                                  {/* <DeleteIcon className="delete-image" onClick={() => handleChapterDelete(i)} /> */}
                                </h3>
                                </Tooltip>
                                <Tooltip title="Edit">
                                <h3>
                                  <img
                                    onClick={() => handleChapterEdit(items, i)}
                                    src={
                                      hover.Edit && indexHover === i
                                        ? EditHover
                                        : Edit
                                    }
                                    alt="Edit"
                                    style={{
                                      height: "35px",
                                      cursor: "pointer",
                                      margin: "0px 3px 0px 3px",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ Edit: true });
                                      setIndexHover(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ Edit: false });
                                    }}
                                  />
                                  {/* <BorderColorIcon className="edit-image" onClick={() => handleChapterEdit(items, i)} /> */}
                                </h3>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      );
                    })
                  : ""}
              </Grid>
            </Grid>
          </Box>
        }
      </form>
      <AssesmentModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        assesmentIdList={assesmentIdList}
        deletedAssesment={deletedAssesment}
      />
      <CertificateModal
        open={openCerti}
        setOpen={setOpenCerti}
        handleClose={handleCloseCerti}
        certificateIdList={certificateIdList}
        deletedCertificate={deletedCertificate}
      />
    </Box>
  );
};

export default AddTraining;
