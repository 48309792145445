import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from "react-router-dom";

const SchedularViewHeader = (props) => {
    const navigate = useNavigate();

    return (
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={8} sm={12} lg={8}>
              <Box className="allform-head-time">
              {/* <Typography sx={{ ml: 2, textAlign: "left" }} className="form-head">
                <b>Schedular Details</b>
              </Typography> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sm={12} lg={4}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ float: "right", paddingRight:{lg:1} }}
                className="plus-icon"
              >
                <Button
                  
                  className="header-add cancle-hover"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon sx={{ mr: 1 }} />
                  Back
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      );
}
export default SchedularViewHeader;

