import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EditDepartmentApi,
  GetDepartmentApi,
} from "../../redux/actions/Department";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ListUserApi } from "../../redux/actions/User";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const EditDepartment = () => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState([]);
  const [newFieldValue, setNewFieldValue] = useState({});
  const [userSelect, setUserSelect] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserForCcEmails, setSelectedUserForCcEmails] = useState([]);
  const [selectedUserForEscalationOne, setSelectedUserForccEscalationOne] =
    useState([]);
  const [selectedUserForEscalationTwo, setSelectedUserForccEscalationTwo] =
    useState([]);
  const [selectedUserForEscalationThree, setSelectedUserForccEscalationThree] =
    useState([]);
  const [
    selectedUserForEscalationWeeklyReminder,
    setSelectedUserEscalationWeeklyReminder,
  ] = useState([]);

  const [simplifiedCcEmailsUsers, setSimplifiedCcEmailsUsers] = useState([]);
  const [simplifiedEcalationOneUsers, setSimplifiedEcalationOneUsers] =
    useState([]);
  const [simplifiedeEscalationTwoUsers, setSimplifiedEscalationTwoUsers] =
    useState([]);
  const [simplifiedeEscalationThreeUsers, setSimplifiedEscalationThreeUsers] =
    useState([]);
  const [
    simplifiedeEscalationWeeklyReminderUsers,
    setSimplifiedEscalationWeeklyReminderUsers,
  ] = useState([]);

  const isAllSelectedccEmailsUser =
    userSelect.length > 0 &&
    selectedUserForCcEmails.length === userSelect.length;

  const isAllSelectedescalationOneUser =
    userSelect.length > 0 &&
    selectedUserForEscalationOne.length === userSelect.length;

  const isAllSelectedescalationTwoUser =
    userSelect.length > 0 &&
    selectedUserForEscalationTwo.length === userSelect.length;

  const isAllSelectedescalationThreeUser =
    userSelect.length > 0 &&
    selectedUserForEscalationThree.length === userSelect.length;

  const isAllSelectedescalationWeeklyUser =
    userSelect.length > 0 &&
    selectedUserForEscalationWeeklyReminder.length === userSelect.length;

  const [simplifiedUsers, setSimplifiedUsers] = useState([]);
  const isAllSelectedUser =
    userSelect.length > 0 && selectedUser.length === userSelect.length;

  // console.log("simplifiedUsers", simplifiedUsers);
  // console.log("newFieldValue", newFieldValue);
  // console.log("inputValue", inputValue);

  const FormData = useSelector(
    (state) =>
      state.department &&
      state.department.res &&
      state.department.res.data &&
      state.department.res.data.data
  );
  // console.log("FormData", FormData);

  const EditDepartment = useSelector(
    (state) =>
      state.department &&
      state.department.update &&
      state.department.update.data
  );

  const datapayload = {
    body: {
      page: 1,
      per_page: 20000,
    },
  };

  const listUser = (data) => {
    const id = localStorage.getItem("companyId");
    datapayload.url = BASE_URL + `users/index?company_id=${id}`;
    dispatch(ListUserApi(datapayload));
  };

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );

  useEffect(() => {
    if (userList) {
      let arr = [];
      for (let item of userList) {
        let obj = item;
        arr.push(obj);
      }
      setUserSelect(arr);
    }
  }, [userList]);

  const handleChangeccEmailsUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";

    if (isClickAll) {
      if (selectedUserForCcEmails.length === userSelect?.length) {
        setSelectedUserForCcEmails([]);
        setSimplifiedCcEmailsUsers([]);
      } else {
        setSelectedUserForCcEmails(userSelect);
        const simplified = userSelect.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));
        setSimplifiedCcEmailsUsers(simplified);
      }
      return;
    }
    setSelectedUserForCcEmails(value);
    const simplified = value.map((user) => ({
      name: user.name,
      email: user.email,
      _id: user._id,
    }));
    setSimplifiedCcEmailsUsers(simplified);
  };

  const handlEescalationOneUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";

    if (isClickAll) {
      if (selectedUserForEscalationOne.length === userSelect?.length) {
        setSelectedUserForccEscalationOne([]);
        setSimplifiedEcalationOneUsers([]);
      } else {
        setSelectedUserForccEscalationOne(userSelect);
        const simplified = userSelect.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));
        setSimplifiedEcalationOneUsers(simplified);
      }
      return;
    }
    setSelectedUserForccEscalationOne(value);
    const simplified = value.map((user) => ({
      name: user.name,
      email: user.email,
      _id: user._id,
    }));
    setSimplifiedEcalationOneUsers(simplified);
  };

  const handlEescalationTwoUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";

    if (isClickAll) {
      if (selectedUserForEscalationTwo.length === userSelect?.length) {
        setSelectedUserForccEscalationTwo([]);
        setSimplifiedEscalationTwoUsers([]);
      } else {
        setSelectedUserForccEscalationTwo(userSelect);
        const simplified = userSelect.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));
        setSimplifiedEscalationTwoUsers(simplified);
      }
      return;
    }
    setSelectedUserForccEscalationTwo(value);
    const simplified = value.map((user) => ({
      name: user.name,
      email: user.email,
      _id: user._id,
    }));
    setSimplifiedEscalationTwoUsers(simplified);
  };

  const handlEescalationThreeUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";

    if (isClickAll) {
      if (selectedUserForEscalationThree.length === userSelect?.length) {
        setSelectedUserForccEscalationThree([]);
        setSimplifiedEscalationThreeUsers([]);
      } else {
        setSelectedUserForccEscalationThree(userSelect);
        const simplified = userSelect.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));
        setSimplifiedEscalationThreeUsers(simplified);
      }
      return;
    }
    setSelectedUserForccEscalationThree(value);
    const simplified = value.map((user) => ({
      name: user.name,
      email: user.email,
      _id: user._id,
    }));
    setSimplifiedEscalationThreeUsers(simplified);
  };

  const handlEescalationWeeklyReminderUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";

    if (isClickAll) {
      if (
        selectedUserForEscalationWeeklyReminder.length === userSelect?.length
      ) {
        setSelectedUserEscalationWeeklyReminder([]);
        setSimplifiedEscalationWeeklyReminderUsers([]);
      } else {
        setSelectedUserEscalationWeeklyReminder(userSelect);
        const simplified = userSelect.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));
        setSimplifiedEscalationWeeklyReminderUsers(simplified);
      }
      return;
    }
    setSelectedUserEscalationWeeklyReminder(value);
    const simplified = value.map((user) => ({
      name: user.name,
      email: user.email,
      _id: user._id,
    }));
    setSimplifiedEscalationWeeklyReminderUsers(simplified);
  };

  useEffect(() => {
    listUser();
  }, []);

  const handleChangeUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";

    if (isClickAll) {
      if (selectedUser.length === userSelect?.length) {
        setSelectedUser([]);
        setSimplifiedUsers([]);
      } else {
        setSelectedUser(userSelect);
        const simplified = userSelect.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));
        setSimplifiedUsers(simplified);
      }
      return;
    }
    setSelectedUser(value);
    const simplified = value.map((user) => ({
      name: user.name,
      email: user.email,
      _id: user._id,
    }));
    setSimplifiedUsers(simplified);
  };

  useEffect(() => {
    if (EditDepartment && EditDepartment.status === 200) {
      // toast.success(EditDepartment.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/department");
      }, 2000);
    }
  }, [EditDepartment]);

  useEffect(() => {
    setInputValue(FormData);
  }, [FormData]);

  useEffect(() => {
    getDataDetails();
  }, [dispatch]);

  useEffect(() => {
    setInputValue(inputValue);
    setNewFieldValue({
      deptName: inputValue?.deptName,
      deptManager: inputValue?.deptManager,
      managerEmail: inputValue?.managerEmail,
      managerMobileNumber: inputValue?.managerMobileNumber,
      deptAddr: inputValue?.deptAddr,
      deptState: inputValue?.deptState,
      deptCity: inputValue?.deptCity,
      deptCountry: inputValue?.deptCountry,
      deptPinCode: inputValue?.deptPinCode,
      // ccEmails: inputValue?.ccEmails?.map(email => email.name) || [],
      // escalationOne: inputValue?.escalationOne?.map(item => item.name) || [],
      // escalationTwo: inputValue?.escalationTwo?.map(item => item.name) || [],
      // escalationThree: inputValue?.escalationThree?.map(item => item.name) || [],
      // escalationWeeklyReminder: inputValue?.escalationWeeklyReminder?.map(item => item.name) || []
    });
    setSelectedUserForCcEmails(inputValue?.ccEmails || []);
    setSelectedUserForccEscalationOne(inputValue?.escalationOne || []);
    setSelectedUserForccEscalationTwo(inputValue?.escalationThree || []);
    setSelectedUserForccEscalationThree(inputValue?.escalationTwo || []);
    setSelectedUserEscalationWeeklyReminder(
      inputValue?.escalationWeeklyReminder || []
    );
  }, [inputValue]);
  console.log("FormData", FormData);

  const getDataDetails = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state;
    const data = { url: BASE_URL + `department/${userId}?company_id=${_id}` };
    dispatch(GetDepartmentApi(data));
  };

  const validationSchema = Yup.object({
    deptName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    managerName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    managerEmail: Yup.string().email("Please enter valid email"),
    managerMobile: Yup.string().matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Please enter valid number"
    ),
    stateName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    cityName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    countryName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    pinCode: Yup.string().max(6, "Enter maximum length of 6 characters"),
    // simplifiedCcEmailsUsers: Yup.array()
    // .min(1, "At least one user must be selected")
    // .of(
    //   Yup.object().shape({
    //     id: Yup.number().required(),
    //     name: Yup.string().required(),
    //   })
    // ),
    // simplifiedEcalationOneUsers
    // simplifiedeEscalationTwoUsers
    // simplifiedeEscalationThreeUsers
    // simplifiedeEscalationWeeklyReminderUsers
  });

  const formik = useFormik({
    initialValues: newFieldValue || {
      deptName: "",
      deptManager: "",
      managerEmail: "",
      managerMobileNumber: "",
      deptAddr: "",
      deptState: "",
      deptCity: "",
      deptCountry: "",
      deptPinCode: "",
      simplifiedCcEmailsUsers: [],
      simplifiedEcalationOneUsers: [],
      simplifiedeEscalationTwoUsers: [],
      simplifiedeEscalationThreeUsers: [],
      simplifiedeEscalationWeeklyReminderUsers: [],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        deptName: values.deptName,
        deptManager: values.managerName
          ? values.managerName
          : values.deptManager,
        managerEmail: values.managerEmail,
        managerMobileNumber: values.managerMobile
          ? values.managerMobile
          : values.managerMobileNumber,
        deptAddr: values.deptAddress ? values.deptAddress : values.deptAddr,
        deptState: values.stateName ? values.stateName : values.deptState,
        deptCity: values.cityName ? values.cityName : values.deptCity,
        deptCountry: values.countryName
          ? values.countryName
          : values.deptCountry,
        deptPinCode: values.pinCode ? values.pinCode : values.deptPinCode,
        _id: inputValue._id,

        ccEmails: simplifiedCcEmailsUsers,
        escalationOne: simplifiedEcalationOneUsers,
        escalationTwo: simplifiedeEscalationTwoUsers,
        escalationThree: simplifiedeEscalationThreeUsers,
        escalationWeeklyReminder: simplifiedeEscalationWeeklyReminderUsers,
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `department?company_id=${id}`;
        dispatch(EditDepartmentApi(allData));
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box className="header-card">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                  <Link to="/department" className="submenu-item">
                    <Button className="cancle-button cancle-hover">
                      <CloseOutlinedIcon sx={{ mr: 1 }} />
                      Cancel
                    </Button>
                  </Link>
                  <Button className="header-add cancle-hover" type="submit">
                    <TaskAltIcon sx={{ mr: 1 }} />
                    Update
                  </Button>
                  <div style={{ marginRight: "8px", marginTop: "6px" }}>
                    <Tooltip
                      title={
                        <div style={{ maxWidth: "300px" }}>
                          <strong>Advance Checklist Wise Report:</strong>
                          <p>
                            Status: Display's selected checklist completion
                            status, completion percentage & average compliance
                            scores for the selected user and stores.
                          </p>
                          <p>
                            Percentage: Display's selected checklist completion
                            percentage & average compliance scores for the
                            selected user and stores.
                          </p>
                        </div>
                      }
                      arrow
                      interactive
                    >
                      <InfoIcon
                        style={{
                          marginLeft: "2px",
                          verticalAlign: "middle",
                          cursor: "pointer",
                          color: "#D76C00",
                        }}
                      />
                    </Tooltip>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", mt: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                p: 1,
                textAlign: "left",
              }}
            >
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Department Name</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="deptName"
                    onChange={formik.handleChange}
                    className="issueinput-fields"
                    onBlur={formik.handleBlur}
                    value={formik?.values?.deptName}
                    error={
                      formik.touched.deptName && Boolean(formik.errors.deptName)
                    }
                  />
                  <small className="error">
                    {formik.errors.deptName && formik.touched.deptName
                      ? formik.errors.deptName
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Manager Name</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="deptManager"
                    onChange={formik.handleChange}
                    className="issueinput-fields"
                    onBlur={formik.handleBlur}
                    value={formik?.values?.deptManager}
                    error={
                      formik.touched.deptManager &&
                      Boolean(formik.errors.deptManager)
                    }
                  />
                  <small className="error">
                    {formik.errors.deptManager && formik.touched.deptManager
                      ? formik.errors.deptManager
                      : null}
                  </small>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Manager Email</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="managerEmail"
                    className="issueinput-fields"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.managerEmail}
                    error={
                      formik.touched.managerEmail &&
                      Boolean(formik.errors.managerEmail)
                    }
                  />
                  <small className="error">
                    {formik.errors.managerEmail && formik.touched.managerEmail
                      ? formik.errors.managerEmail
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select CC-Emails User</label>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    name="ccEmails"
                    size="small"
                    className="issueinput-fields"
                    value={selectedUserForCcEmails}
                    onChange={handleChangeccEmailsUser}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 0,
                          },
                          height: "180px",
                        },
                      },
                    }}
                    renderValue={(selectedUserForCcEmails) =>
                      selectedUserForCcEmails
                        .map((item) => item.name)
                        ?.join(", ")
                    }
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedccEmailsUser
                          ? classes.selectedAll
                          : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={
                            selectedUserForCcEmails.length === userSelect.length
                          }
                          indeterminate={
                            selectedUserForCcEmails.length > 0 &&
                            selectedUserForCcEmails.length < userSelect.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {userSelect &&
                      userSelect.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                selectedUserForCcEmails.indexOf(option) > -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.name}
                            secondary={option.email}
                            className="user-list-all"
                          />
                        </MenuItem>
                      ))}
                  </Select>
                  <small className="error">
                    {formik.errors.ccEmails && formik.touched.ccEmails
                      ? formik.errors.ccEmails
                      : null}
                  </small>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Escalation One User</label>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    name="escalationOne"
                    size="small"
                    className="issueinput-fields"
                    value={selectedUserForEscalationOne}
                    onChange={handlEescalationOneUser}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 0,
                          },
                          height: "180px",
                        },
                      },
                    }}
                    renderValue={(selectedUserForEscalationOne) =>
                      selectedUserForEscalationOne
                        .map((item) => item.name)
                        ?.join(", ")
                    }
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedescalationOneUser
                          ? classes.selectedAll
                          : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={
                            selectedUserForEscalationOne.length ===
                            userSelect.length
                          }
                          indeterminate={
                            selectedUserForEscalationOne.length > 0 &&
                            selectedUserForEscalationOne.length <
                              userSelect.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {userSelect &&
                      userSelect.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                selectedUserForEscalationOne.indexOf(option) >
                                -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.name}
                            secondary={option.email}
                            className="user-list-all"
                          />
                        </MenuItem>
                      ))}
                  </Select>
                  <small className="error">
                    {formik.errors.escalationOne && formik.touched.escalationOne
                      ? formik.errors.escalationOne
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Escalation Two User</label>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    name="escalationTwo"
                    size="small"
                    className="issueinput-fields"
                    value={selectedUserForEscalationTwo}
                    onChange={handlEescalationTwoUser}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 0,
                          },
                          height: "180px",
                        },
                      },
                    }}
                    renderValue={(selectedUserForEscalationTwo) =>
                      selectedUserForEscalationTwo
                        .map((item) => item.name)
                        ?.join(", ")
                    }
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedescalationTwoUser
                          ? classes.selectedAll
                          : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={
                            selectedUserForEscalationTwo.length ===
                            userSelect.length
                          }
                          indeterminate={
                            selectedUserForEscalationTwo.length > 0 &&
                            selectedUserForEscalationTwo.length <
                              userSelect.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {userSelect &&
                      userSelect.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                selectedUserForEscalationTwo.indexOf(option) >
                                -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.name}
                            secondary={option.email}
                            className="user-list-all"
                          />
                        </MenuItem>
                      ))}
                  </Select>
                  <small className="error">
                    {formik.errors.escalationTwo && formik.touched.escalationTwo
                      ? formik.errors.escalationTwo
                      : null}
                  </small>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Escalation Three User</label>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    name="escalationThree"
                    size="small"
                    className="issueinput-fields"
                    value={selectedUserForEscalationThree}
                    onChange={handlEescalationThreeUser}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 0,
                          },
                          height: "180px",
                        },
                      },
                    }}
                    renderValue={(selectedUserForEscalationThree) =>
                      selectedUserForEscalationThree
                        .map((item) => item.name)
                        ?.join(", ")
                    }
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedescalationThreeUser
                          ? classes.selectedAll
                          : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={
                            selectedUserForEscalationThree.length ===
                            userSelect.length
                          }
                          indeterminate={
                            selectedUserForEscalationThree.length > 0 &&
                            selectedUserForEscalationThree.length <
                              userSelect.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {userSelect &&
                      userSelect.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                selectedUserForEscalationThree.indexOf(option) >
                                -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.name}
                            secondary={option.email}
                            className="user-list-all"
                          />
                        </MenuItem>
                      ))}
                  </Select>
                  <small className="error">
                    {formik.errors.escalationThree &&
                    formik.touched.escalationThree
                      ? formik.errors.escalationThree
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label
                    style={{
                      whiteSpace: "nowrap",
                      display: "inline-block",

                      marginRight: "10px",
                    }}
                  >
                    Select Escalation WeeklyReminder User
                  </label>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    name="escalationWeeklyReminder"
                    size="small"
                    className="issueinput-fields"
                    value={selectedUserForEscalationWeeklyReminder}
                    onChange={handlEescalationWeeklyReminderUser}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 0,
                          },
                          height: "180px",
                        },
                      },
                    }}
                    renderValue={(selectedUserForEscalationWeeklyReminder) =>
                      selectedUserForEscalationWeeklyReminder
                        .map((item) => item.name)
                        ?.join(", ")
                    }
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedescalationWeeklyUser
                          ? classes.selectedAll
                          : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={
                            selectedUserForEscalationWeeklyReminder.length ===
                            userSelect.length
                          }
                          indeterminate={
                            selectedUserForEscalationWeeklyReminder.length >
                              0 &&
                            selectedUserForEscalationWeeklyReminder.length <
                              userSelect.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {userSelect &&
                      userSelect.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                selectedUserForEscalationWeeklyReminder.indexOf(
                                  option
                                ) > -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.name}
                            secondary={option.email}
                            className="user-list-all"
                          />
                        </MenuItem>
                      ))}
                  </Select>
                  <small className="error">
                    {formik.errors.escalationWeeklyReminder &&
                    formik.touched.escalationWeeklyReminder
                      ? formik.errors.escalationWeeklyReminder
                      : null}
                  </small>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Manager Mobile</label>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    size="small"
                    name="managerMobileNumber"
                    className="issueinput-fields"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.managerMobileNumber}
                    error={
                      formik.touched.managerMobileNumber &&
                      Boolean(formik.errors.managerMobileNumber)
                    }
                  />
                  <small className="error">
                    {formik.errors.managerMobileNumber &&
                    formik.touched.managerMobileNumber
                      ? formik.errors.managerMobileNumber
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Department Address</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="deptAddr"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="issueinput-fields"
                    value={formik?.values?.deptAddr}
                    error={
                      formik.touched.deptAddr && Boolean(formik.errors.deptAddr)
                    }
                  />
                  <small className="error">
                    {formik.errors.deptAddr && formik.touched.deptAddr
                      ? formik.errors.deptAddr
                      : null}
                  </small>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>State Name</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="deptState"
                    onChange={formik.handleChange}
                    className="issueinput-fields"
                    onBlur={formik.handleBlur}
                    value={formik?.values?.deptState}
                    error={
                      formik.touched.deptState &&
                      Boolean(formik.errors.deptState)
                    }
                  />
                  <small className="error">
                    {formik.errors.deptState && formik.touched.deptState
                      ? formik.errors.deptState
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>City Name</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="deptCity"
                    onChange={formik.handleChange}
                    className="issueinput-fields"
                    onBlur={formik.handleBlur}
                    value={formik?.values?.deptCity}
                    error={
                      formik.touched.deptCity && Boolean(formik.errors.deptCity)
                    }
                  />
                  <small className="error">
                    {formik.errors.deptCity && formik.touched.deptCity
                      ? formik.errors.deptCity
                      : null}
                  </small>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Country Name</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="deptCountry"
                    onChange={formik.handleChange}
                    className="issueinput-fields"
                    onBlur={formik.handleBlur}
                    value={formik?.values?.deptCountry}
                    error={
                      formik.touched.deptCountry &&
                      Boolean(formik.errors.deptCountry)
                    }
                  />
                  <small className="error">
                    {formik.errors.deptCountry && formik.touched.deptCountry
                      ? formik.errors.deptCountry
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Pin Code</label>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    size="small"
                    name="deptPinCode"
                    onChange={formik.handleChange}
                    className="issueinput-fields"
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.deptPinCode
                        ? formik.values.deptPinCode
                        : inputValue?.deptdeptPinCode
                    }
                    error={
                      formik.touched.deptPinCode &&
                      Boolean(formik.errors.deptPinCode)
                    }
                  />
                  <small className="error">
                    {formik.errors.deptPinCode && formik.touched.deptPinCode
                      ? formik.errors.deptPinCode
                      : null}
                  </small>
                </FormControl>
              </Box>
            </Container>
          </Box>
        </form>
      </Box>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default EditDepartment;
