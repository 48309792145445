import { CREATE_LOG_ENTRY_ERROR, CREATE_LOG_ENTRY_REQUEST, CREATE_LOG_ENTRY_SUCCESS, DELETE_LOG_ENTRY_ERROR, DELETE_LOG_ENTRY_REQUEST, DELETE_LOG_ENTRY_SUCCESS, GET_LOG_ENTRY_SUBMISSION_ERROR, GET_LOG_ENTRY_SUBMISSION_REQUEST, GET_LOG_ENTRY_SUBMISSION_SUCCESS, UPLOAD_IMG_EDIT_LOG_QUES_ERROR, UPLOAD_IMG_EDIT_LOG_QUES_REQUEST, UPLOAD_IMG_EDIT_LOG_QUES_SUCCESS, VIEW_ALLLOG_ENTRY_ERROR, VIEW_ALLLOG_ENTRY_REQUEST, VIEW_ALLLOG_ENTRY_SUCCESS } from "../types/Types";

const initialState = {
    loading: false,
    error: "",
    logEntryListData: [],
    logEntrySubmissionData: [],
    uploadedMedia: {},
};

const logEntryReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_ALLLOG_ENTRY_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case VIEW_ALLLOG_ENTRY_SUCCESS: {
            return {
                ...state,
                logEntryListData: action?.payload,
                loading: false,
            };
        }
        case VIEW_ALLLOG_ENTRY_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
                logEntryListData: [],
            };
        }
        case CREATE_LOG_ENTRY_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case CREATE_LOG_ENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case CREATE_LOG_ENTRY_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case DELETE_LOG_ENTRY_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case DELETE_LOG_ENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case DELETE_LOG_ENTRY_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case GET_LOG_ENTRY_SUBMISSION_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
                logEntrySubmissionData: [],
            };
        }
        case GET_LOG_ENTRY_SUBMISSION_SUCCESS: {
            return {
                ...state,
                loading: false,
                logEntrySubmissionData: action.payload,
            };
        }
        case GET_LOG_ENTRY_SUBMISSION_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
                logEntrySubmissionData: [],
            };
        }
        case UPLOAD_IMG_EDIT_LOG_QUES_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
                uploadedMedia: [],
            };
        }
        case UPLOAD_IMG_EDIT_LOG_QUES_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: "",
                uploadedMedia: action.payload,
            };
        };
        case UPLOAD_IMG_EDIT_LOG_QUES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
                uploadedMedia: {},
            };
        };
        default: {
            return state;
        }
    }
};

export default logEntryReducer;