/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, FormControlLabel, TextField } from "@mui/material";
import { Box, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { AddSchedularApi } from "../../redux/actions/SchedularAction";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const frequencyOptions = [
  { value: 1, label: "One Time" },
  { value: 2, label: "Daily At" },
  { value: 3, label: "Weekly" },
  { value: 4, label: "Monthly On" },
];

const taskOptions = [
  { value: "checklists_reminder", label: "checklists_reminder" },
  { value: "issuelog_escalation", label: "issuelog_escalation" },
  { value: "customerlog_reminder", label: "customerlog_reminder" },
];

const typeOptions = [
  { value: "checklist", label: "checklist" },
  { value: "lms", label: "lms" },
  { value: "issueLog", label: "issueLog" },
  { value: "customerLog", label: "customerLog" },
  { value: "logEntry", label: "logEntry" },
];

const AddSchedular = (props) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(null);
  const companyId = localStorage.getItem("companyId");

  const createSchedularRes = useSelector(
    (state) =>
      state?.scheduler &&
      state?.scheduler?.schedularAdd &&
      state?.scheduler?.schedularAdd?.data &&
      state?.scheduler?.schedularAdd?.data?.data
  );

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    type: "",
    task: "",
    otherData: {
      checklist_id: "",
      issuelog_id: "",
      customerlog_id: "",
    },
    run_at: null,
    frequency: "",
    company_id: "",
    status: "",
    createdBy: {
      userId: "",
      userName: "",
    },
    updatedBy: {
      userId: "",
      userName: "",
    },
  });

  const handleDayLimit = (value) => {
    setTime(value);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationSchema1 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      type: Yup.string().required("Job Type is required"),
      task: Yup.string().required("Task is required"),
      description: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      run_at: Yup.string().required("Time is required"),
    });

    const validationSchema2 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      type: Yup.string().required("Job Type is required"),
      task: Yup.string().required("Task is required"),
      description: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      run_at: Yup.string().required("Time is required"),
    });

    try {
      if (formValues.frequency === 2) {
        await validationSchema2.validate(formValues, { abortEarly: false });
      } else {
        await validationSchema1.validate(formValues, { abortEarly: false });
      }

      const commonData = {
        title: formValues.title,
        type:
          typeOptions.find((option) => option.value === formValues.type)
            ?.label || "",
        task:
          taskOptions.find((option) => option.value === formValues.task)
            ?.label || "",
        description: formValues.description,
        company_id: companyId,
      };

      let payload;
      let selectedTime = formValues.run_at
        ? moment(formValues.run_at.$d).format("HH:mm")
        : null;

      if (formValues.frequency === 2) {
        payload = {
          frequency: 2,
          run_at: selectedTime,
          ...commonData,
        };
      } else if (formValues.frequency === 1) {
        payload = {
          frequency: 1,
          run_at: moment(formValues.time.$d).format("HH:mm"),
          ...commonData,
        };
        if (formValues.expires_at) {
          payload.date = moment(formValues.expires_at).format("YYYY-MM-DD");
        }
      }

      const allData = {
        body: payload,
      };

      if (allData) {
        allData.url = BASE_URL + `DynamicJob`;
        dispatch(AddSchedularApi(allData));
      }

      // Clear form values
      setFormValues({
        title: "",
        frequency: "",
        type: "",
        task: "",
        description: "",
        date: "",
        run_at: null,
      });

      props.handleClose();
    } catch (error) {
      error.inner
        ? error.inner?.forEach((err) => {
            toast.error(err.message);
          })
        : toast.error(error.message);
    }
  };

  useEffect(() => {
    if (createSchedularRes) {
    }
  }, [createSchedularRes]);

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="sm"
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Add Schedular
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit}>
          <ToastContainer autoClose={3000} />
          <DialogContent dividers>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Title</b>
                    </label>
                    <TextField
                      className="input-fields"
                      id="title"
                      variant="outlined"
                      name="title"
                      placeholder="Enter Title"
                      value={formValues.title}
                      onChange={handleFormChange}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Frequency</b>
                    </label>
                    <Select
                      id="frequency"
                      name="frequency"
                      variant="outlined"
                      value={formValues.frequency}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="" disabled>
                        Select Frequency
                      </MenuItem>
                      {frequencyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Job Type</b>
                    </label>
                    <Select
                      id="type"
                      name="type"
                      variant="outlined"
                      Value={formValues.type}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="" disabled>
                        Select Job Type
                      </MenuItem>
                      {typeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Task</b>
                    </label>
                    <Select
                      id="tesk"
                      name="task"
                      variant="outlined"
                      value={formValues.task}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="" disabled>
                        Select Task
                      </MenuItem>
                      {taskOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Job Description</b>
                    </label>
                    <TextField
                      className="input-fields"
                      id="description"
                      variant="outlined"
                      name="description"
                      placeholder="Enter Job Description"
                      value={formValues.description}
                      onChange={handleFormChange}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Time</b>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        clearable
                        clearText="Clear"
                        value={formValues.run_at}
                        onChange={(value) => {
                          handleDayLimit(value);
                          setFormValues({
                            ...formValues,
                            run_at: value,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...params}
                            placeholder={
                              formValues.run_at ? undefined : "Select Time"
                            }
                          />
                        )}
                        className="date-input"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              color="success"
              className="issue-modal-savebtn"
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="issue-modal-cancelbtn"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default AddSchedular;
