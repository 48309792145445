/** @format */

import React from "react";
import {
  SCHEDULAR_ADD_ERROR,
  SCHEDULAR_ADD_REQUEST,
  SCHEDULAR_ADD_SUCCESS,
  SCHEDULAR_DELETE_ERROR,
  SCHEDULAR_DELETE_REQUEST,
  SCHEDULAR_DELETE_SUCCESS,
  SCHEDULAR_EDIT_ERROR,
  SCHEDULAR_EDIT_REQUEST,
  SCHEDULAR_EDIT_SUCCESS,
  SCHEDULAR_LIST_ERROR,
  SCHEDULAR_LIST_REQUEST,
  SCHEDULAR_LIST_SUCCESS,
  SCHEDULAR_VIEW_ERROR,
  SCHEDULAR_VIEW_REQUEST,
  SCHEDULAR_VIEW_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  schedularList: [],
  schedularAdd: [],
  schedularEdit: [],
  schedularView: [],
};

const SchedularReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULAR_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SCHEDULAR_LIST_SUCCESS: {
      return {
        ...state,
        schedularList: action?.payload,
        loading: false,
      };
    }
    case SCHEDULAR_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SCHEDULAR_ADD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SCHEDULAR_ADD_SUCCESS: {
      return {
        ...state,
        schedularAdd: action?.payload,
        loading: false,
      };
    }
    case SCHEDULAR_ADD_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SCHEDULAR_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SCHEDULAR_EDIT_SUCCESS: {
      return {
        ...state,
        schedularEdit: action?.payload,
        loading: false,
      };
    }
    case SCHEDULAR_EDIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SCHEDULAR_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SCHEDULAR_DELETE_SUCCESS: {
      return {
        ...state,
        schedularDelete: action?.payload,
        loading: false,
      };
    }
    case SCHEDULAR_DELETE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SCHEDULAR_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SCHEDULAR_VIEW_SUCCESS: {
      return {
        ...state,
        schedularView: action?.payload,
        loading: false,
      };
    }
    case SCHEDULAR_VIEW_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default SchedularReducer;
