/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, TextField, Grid, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { EditSchedularApi } from "../../redux/actions/SchedularAction";
import * as moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

// Styled BootstrapDialog component
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// Custom BootstrapDialogTitle component
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

const frequencyOptions = [
  { value: 1, label: "One Time" },
  { value: 2, label: "Daily At" },
  { value: 3, label: "Weekly" },
  { value: 4, label: "Monthly On" },
];

const taskOptions = [
  { value: "checklists_reminder", label: "checklists_reminder" },
  { value: "issuelog_escalation", label: "issuelog_escalation" },
  { value: "customerlog_reminder", label: "customerlog_reminder" },
];

const typeOptions = [
  { value: "checklist", label: "checklist" },
  { value: "lms", label: "lms" },
  { value: "issueLog", label: "issueLog" },
  { value: "customerLog", label: "customerLog" },
  { value: "logEntry", label: "logEntry" },
];

const EditSchedular = (props) => {
  const dispatch = useDispatch();
  const companyId = localStorage.getItem("companyId");

  // State variables
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type: "",
    task: "",
    otherData: {
      checklist_id: "",
      issuelog_id: "",
      customerlog_id: "",
    },
    run_at: null,
    frequency: 1,
    company_id: "",
    status: "",
    createdBy: {
      userId: "",
      userName: "",
    },
    updatedBy: {
      userId: "",
      userName: "",
    },
  });

  const [fieldValue, setFieldValue] = useState({});
  const [newFieldValue, setNewFieldValue] = useState({});
  const [run_at, setRun_at] = useState(null);

  const valuesEdit = props?.editvalue;

  useEffect(() => {
    if (valuesEdit) {
      setFormData({
        title: valuesEdit?.title || "",
        type: valuesEdit?.type || "",
        task: valuesEdit?.task || "",
        description: valuesEdit?.description || "",
        frequency: valuesEdit?.frequency || 1,
        run_at: valuesEdit?.run_at
          ? moment(valuesEdit.run_at, "hh:mm").toDate()
          : null,
      });

      setFieldValue(valuesEdit);
      setNewFieldValue({
        type: valuesEdit?.type,
        task: valuesEdit?.task,
        frequency: valuesEdit?.frequency,
      });
    }
  }, [valuesEdit]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDayLimit = (value) => {
    setRun_at(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationSchema1 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      type: Yup.string().required("Job Type is required"),
      task: Yup.string().required("Task is required"),
      description: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      run_at: Yup.string().required("Time is required"),
    });

    const validationSchema2 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      type: Yup.string().required("Job Type is required"),
      task: Yup.string().required("Task is required"),
      description: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      run_at: Yup.string().required("Time is required"),
    });

    try {
      if (formData.frequency === 2) {
        await validationSchema2.validate(formData, { abortEarly: false });
      } else {
        await validationSchema1.validate(formData, { abortEarly: false });
      }

      let payload = {
        title: formData.title,
        type: formData.type,
        task: formData.task,
        description: formData.description,
        run_at: convertTime12to24(moment(formData.run_at.$d).format("hh:mm A")),
        company_id: companyId,
      };

      if (formData.frequency === 2) {
        payload = {
          ...payload,
          frequency: 2,
        };
      } else if (formData.frequency === 1) {
        payload = {
          ...payload,
          frequency: 1,
        };
      }

      const allData = {
        body: payload,
      };

      if (allData) {
        const id = localStorage.getItem("companyId");
        const schedularId = valuesEdit?._id;
        allData.url = BASE_URL + `DynamicJob/${schedularId}`;
        dispatch(EditSchedularApi(allData));
        props.handleClose();
      }
    } catch (error) {
      error.inner
        ? error.inner?.forEach((err) => {
            toast.error(err.message);
          })
        : toast.error(error.message);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="sm"
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Edit Scheduler
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit}>
          <ToastContainer autoClose={3000} />
          <DialogContent dividers>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Title</b>
                    </label>
                    <TextField
                      className="input-fields"
                      id="title"
                      variant="outlined"
                      name="title"
                      placeholder="Enter Title"
                      value={formData.title}
                      onChange={handleFormChange}
                      error={false}
                      helperText={""}
                      fullWidth
                    />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Frequency</b>
                    </label>
                    <Select
                      id="frequency"
                      name="frequency"
                      variant="outlined"
                      value={formData.frequency}
                      onChange={handleFormChange}
                      sx={{ width: "100%" }}
                      disabled
                    >
                      {frequencyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label className="rolemodel-lebel">
                    <b>Job Type</b>
                  </label>
                  <Select
                    id="type"
                    name="type"
                    variant="outlined"
                    value={formData.type}
                    onChange={handleFormChange}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="" disabled>
                      Select Job Type
                    </MenuItem>
                    {typeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label className="rolemodel-lebel">
                    <b>Task</b>
                  </label>
                  <Select
                    id="task"
                    name="task"
                    variant="outlined"
                    value={formData.task}
                    onChange={handleFormChange}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="" disabled>
                      Select Task
                    </MenuItem>
                    {taskOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label className="rolemodel-lebel">
                      <b>Job Description</b>
                    </label>
                    <TextField
                      className="input-fields"
                      id="description"
                      variant="outlined"
                      name="description"
                      placeholder="Enter Job Type"
                      value={formData.description}
                      onChange={handleFormChange}
                      error={false}
                      helperText={""}
                      fullWidth
                    />
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Grid item xs={12}>
                    <label className="rolemodel-lebel">
                      <b>Time</b>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        clearable
                        clearText="Clear"
                        value={formData.run_at}
                        onChange={(value) => {
                          handleDayLimit(value);
                          handleFormChange({
                            target: {
                              name: "run_at",
                              value,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...params}
                            placeholder={
                              formData.run_at ? undefined : "Select Time"
                            }
                          />
                        )}
                        className="date-input"
                      />
                    </LocalizationProvider>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              color="success"
              className="issue-modal-savebtn"
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="issue-modal-cancelbtn"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default EditSchedular;
