import JwtAuthService from "../apiConfig/ApiConfig";
import {
    COMPANY_CATEGORY_LIST_REQUEST,
    COMPANY_CATEGORY_LIST_SUCCESS,
    COMPANY_CATEGORY_LIST_ERROR,
    CREATE_COMPANY_CATEGORY_REQUEST,
    CREATE_COMPANY_CATEGORY_SUCCESS,
    CREATE_COMPANY_CATEGORY_ERROR,
    ADD_DATA_LIST_REQUEST,
    ADD_DATA_LIST_SUCCESS,
    ADD_DATA_LIST_ERROR,
    DATA_LIST_REQUEST,
    DATA_LIST_SUCCESS,
    DATA_LIST_ERROR,
    ADD_COMPANY_SUBCATEGORY_REQUEST,
    ADD_COMPANY_SUBCATEGORY_SUCCESS,
    ADD_COMPANY_SUBCATEGORY_ERROR,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_ERROR,
    EDIT_CATEGORY_REQUEST,
    EDIT_CATEGORY_SUCCESS,
    EDIT_CATEGORY_ERROR,
    DATA_MANAGER_UPDATE_REQUEST,
    DATA_MANAGER_UPDATE_SUCCESS,
    DATA_MANAGER_UPDATE_ERROR,
    MODULE_REQUEST,
    MODULE_SUCCESS,
    MODULE_ERROR,
    SUB_MODULE_REQUEST,
    SUB_MODULE_SUCCESS,
    SUB_MODULE_ERROR
} from "../types/Types"
import { toast } from "react-toastify";

export const AddDataList = () => ({
    type: ADD_DATA_LIST_REQUEST,
});

export const AddDataListSuccess = (addDataList) => ({
    type: ADD_DATA_LIST_SUCCESS,
    payload: addDataList
})

export const AddDataListFailed = (error) => ({
    type: ADD_DATA_LIST_ERROR,
    payload: error
})

export const AddDataListData = (data) => {
    return (dispatch) => {
        dispatch(AddDataList(data));
        if (data) {
            const addDataList = data;
            dispatch(AddDataListSuccess(addDataList));
        }
    }
}

export const DataListRequest = () => ({
    type: DATA_LIST_REQUEST,
});

export const DataListSuccess = (dataList) => ({
    type: DATA_LIST_SUCCESS,
    payload: dataList
})

export const DataListFailed = (error) => ({
    type: DATA_LIST_ERROR,
    payload: error
})

export const DataListApi = (data) => dispatch => {
    try {
        dispatch(DataListRequest());
        JwtAuthService.GetApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(DataListSuccess(data));
            }
            else {
                dispatch(DataListFailed(response?.statusText));
            }
        })
    } catch (error) {
        dispatch(DataListFailed(error))
        console.error('Something went Wrong', error);
    }
};

// export const ComapanyTypeRquest = () => ({
//     type: COMPANY_TYPE_REQUEST
// })
// export const ComapanyTypeSuccess = (type) => ({
//     type: COMPANY_TYPE_SUCCESS,
//     payload: type
// })
// export const ComapanyTypeFailed = (error) => ({
//     type: COMPANY_TYPE_SUCCESS,
//     payload: error
// })


// export const CompanyTypeApi = (data) => dispatch => {
//     try {
//         dispatch(ComapanyTypeRquest(data));
//         JwtAuthService.GetApiService(data).then(response => {
//             if (response?.status === 200) {
//                 const data = response && response.data && response.data.data;
//                 dispatch(ComapanyTypeSuccess(data));

//             }
//             else {
//                 dispatch(ComapanyTypeFailed(response.statusText));
//             }
//         })
//     } catch (error) {
//         dispatch(ComapanyTypeFailed(error))
//     }

// };

export const CreateCompanyCatRquest = (company) => ({
    type: CREATE_COMPANY_CATEGORY_REQUEST,
    payload: company
})
export const CreateCompanyCatSuccess = (company) => ({
    type: CREATE_COMPANY_CATEGORY_SUCCESS,
    payload: company
})
export const CreateCompanyCatFailed = (error) => ({
    type: CREATE_COMPANY_CATEGORY_ERROR,
    payload: error
})


export const CreateCompanyCatApi = (data) => dispatch => {
    try {
        dispatch(CreateCompanyCatRquest());
        JwtAuthService.PostApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(CreateCompanyCatSuccess(data));
                toast.success(`${response?.data?.message}`);
            }
            else {
                dispatch(CreateCompanyCatFailed(response?.statusText));
                toast.error(`${response?.data?.message}`);
            }
        })
    } catch (error) {
        dispatch(CreateCompanyCatFailed(error))
        console.error('Something went Wrong', error);
    }
};

export const DataManagerRquest = (company) => ({
    type: DATA_MANAGER_UPDATE_REQUEST,
    payload: company
})
export const DataManagerSuccess = (company) => ({
    type: DATA_MANAGER_UPDATE_SUCCESS,
    payload: company
})
export const DataManagerFailed = (error) => ({
    type: DATA_MANAGER_UPDATE_ERROR,
    payload: error
})


export const DataManagerApi = (data) => dispatch => {
    try {
        dispatch(DataManagerRquest());
        JwtAuthService.PutApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(DataManagerSuccess(data));
                toast.success(`${response?.data?.message}`);
            }
            else {
                dispatch(DataManagerFailed(response?.statusText));
                toast.error(`${response?.data?.message}`);
            }
        })
    } catch (error) {
        dispatch(DataManagerFailed(error))
        console.error('Something went Wrong', error);
    }
};

export const CompanyCategoryListRquest = () => ({
    type: COMPANY_CATEGORY_LIST_REQUEST
})
export const CompanyCategoryListSuccess = (list) => ({
    type: COMPANY_CATEGORY_LIST_SUCCESS,
    payload: list
})
export const CompanyCategoryListFailed = (error) => ({
    type: COMPANY_CATEGORY_LIST_ERROR,
    payload: error
})


export const CompanyCategoryListApi = (data) => dispatch => {
    try {
        dispatch(CompanyCategoryListRquest(data));
        JwtAuthService.GetApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(CompanyCategoryListSuccess(data));
            }
            else {
                dispatch(CompanyCategoryListFailed(response?.statusText));
            }
        })
    } catch (error) {
        dispatch(CompanyCategoryListFailed(error))
        console.error('Something went Wrong', error);
    }

};

export const AddSubCategotyRquest = () => ({
    type: ADD_COMPANY_SUBCATEGORY_REQUEST
})
export const AddSubCategotySuccess = (list) => ({
    type: ADD_COMPANY_SUBCATEGORY_SUCCESS,
    payload: list
})
export const AddSubCategotyFailed = (error) => ({
    type: ADD_COMPANY_SUBCATEGORY_ERROR,
    payload: error
})


export const AddSubCategotyApi = (data) => dispatch => {
    try {
        dispatch(AddSubCategotyRquest(data));
        JwtAuthService.PostApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(AddSubCategotySuccess(data));
                toast.success(`${response?.data?.message}`);
            }
            else {
                dispatch(AddSubCategotyFailed(response?.statusText));
                toast.error(`${response?.data?.message}`);
            }
        })
    } catch (error) {
        dispatch(AddSubCategotyFailed(error))
        toast.error(`${response?.data?.message}`);
        console.error('Something went Wrong', error);
    }

};
export const DeleteCategoryRquest = () => ({
    type: DELETE_CATEGORY_REQUEST
})
export const DeleteCategorySuccess = (list) => ({
    type: DELETE_CATEGORY_SUCCESS,
    payload: list
})
export const DeleteCategoryFailed = (error) => ({
    type: DELETE_CATEGORY_ERROR,
    payload: error
})


export const DeleteCategoryApi = (data) => dispatch => {
    try {
        dispatch(DeleteCategoryRquest(data));
        JwtAuthService.DeleteApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(DeleteCategorySuccess(data));
                toast.success(`${response?.data?.message}`);
            }
            else {
                dispatch(DeleteCategoryFailed(response?.statusText));
                toast.error(`${response?.data?.message}`);
            }
        })
    } catch (error) {
        dispatch(DeleteCategoryFailed(error))
        toast.error(`${response?.data?.message}`);
        console.error('Something went Wrong', error);
    }

};
export const EditCategotyRquest = () => ({
    type: EDIT_CATEGORY_REQUEST
})
export const EditCategotySuccess = (list) => ({
    type: EDIT_CATEGORY_SUCCESS,
    payload: list
})
export const EditCategotyFailed = (error) => ({
    type: EDIT_CATEGORY_ERROR,
    payload: error
})


export const EditCategotyApi = (data) => dispatch => {
    try {
        dispatch(EditCategotyRquest(data));
        JwtAuthService.PutApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(EditCategotySuccess(data));
                toast.success(`${response?.data?.message}`);
            }
            else {
                dispatch(EditCategotyFailed(response?.statusText));
                toast.error(`${response?.data?.message}`);
            }
        })
    } catch (error) {
        dispatch(EditCategotyFailed(error))
        toast.error(`${response?.data?.message}`);
        console.error('Something went Wrong', error);
    }

};


export const ModuleListRequest = () => ({
    type: MODULE_REQUEST,
});
export const ModuleListSuccess = (type) => ({
    type: MODULE_SUCCESS,
    payload: type,
});
export const ModuleListFailed = (error) => ({
    type: MODULE_ERROR,
    payload: error,
});

export const ModuleListListApi = (data) => (dispatch) => {
    try {
        dispatch(ModuleListRequest(data));
        JwtAuthService.GetApiService(data).then((response) => {
            if (response?.status === 200) {
                const data = response && response.data && response.data.data;
                dispatch(ModuleListSuccess(response));
            } else {
                dispatch(ModuleListFailed(response.statusText));
            }
        });
    } catch (error) {
        dispatch(ModuleListFailed(error));
    }
};


export const SubModuleListsRequest = () => ({
    type: SUB_MODULE_REQUEST,
});
export const SubModuleListsSuccess = (type) => ({
    type: SUB_MODULE_SUCCESS,
    payload: type,
});
export const SubModuleListsFailed = (error) => ({
    type: SUB_MODULE_ERROR,
    payload: error,
});

export const SubModuleListsListApi = (data) => (dispatch) => {
    try {
        dispatch(SubModuleListsRequest(data));
        JwtAuthService.GetApiService(data).then((response) => {
            if (response?.status === 200) {
                const data = response && response.data && response.data.data;
                dispatch(SubModuleListsSuccess(response));
            } else {
                dispatch(SubModuleListsFailed(response.statusText));
            }
        });
    } catch (error) {
        dispatch(SubModuleListsFailed(error));
    }
};
// export const CompanyGetRequest = (data) => ({
//     type: GET_COMPANY_REQUEST,
//     payload: data
// })
// export const CompanyGetSuccess = (data) => ({
//     type: GET_COMPANY_SUCCESS,
//     payload: data
// })
// export const CompanyGetFailed = (error) => ({
//     type: GET_COMPANY_ERROR,
//     payload: error
// })


// export const CompanyGetApi = (data) => dispatch => {
//     try {
//         dispatch(CompanyGetRequest(data));
//         JwtAuthService.GetApiService(data).then(response => {
//             if (response?.status === 200) {
//                 const data = response;
//                 dispatch(CompanyGetSuccess(data));
//             }
//             else {
//                 dispatch(CompanyGetFailed(response.statusText));
//             }
//         })
//     } catch (error) {
//         dispatch(CompanyGetFailed(error))
//         console.error('Something went Wrong', error);
//     }

// };

// export const EditCompanyRquest = (company) => ({
//     type: EDIT_COMPANY_REQUEST,
//     payload: company
// })
// export const EditCompanySuccess = (company) => ({
//     type: EDIT_COMPANY_SUCCESS,
//     payload: company
// })
// export const EditCompanyFailed = (error) => ({
//     type: EDIT_COMPANY_ERROR,
//     payload: error
// })


// export const EditCompanyApi = (data) => dispatch => {
//     try {
//         dispatch(EditCompanyRquest());
//         JwtAuthService.PutApiService(data).then(response => {
//             if (response?.status === 200) {
//                 const data = response;
//                 dispatch(EditCompanySuccess(data));
//             }
//             else {
//                 dispatch(EditCompanyFailed(response.statusText));
//             }
//         })
//     } catch (error) {
//         dispatch(EditCompanyFailed(error))
//         console.error('Something went Wrong', error);
//     }

// };