import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { EditallFormUpdateData } from "../../../redux/actions/EditAllForms";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { ToastContainer } from "react-toastify";
import * as moment from "moment";
import { useSelector } from "react-redux";
import { allFormEditQustionResetData } from "../../../redux/actions/AllformStore";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CreateFormHead = (props) => {
  const navigate = useNavigate();
  // listData
  const [calculatedoptionscore, setCalulatedOption] = useState();
  const [calculatedqustionscore, setCalulatedQustion] = useState();
  const [formRulesUpdate, setFormRulesUpdate] = useState([]);
  const [createdAt, setCreatedAt] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [deleted, setDeleted] = useState();
  const [isActive, setIsActive] = useState();
  const addAllformData = useSelector(
    (state) => state && state.allForm && state.allForm.allForm
  );

  const formRules = useSelector(
    (state) => state && state.checklist && state.checklist.FormRulesUpdate
  );
  useEffect(() => {
    if (
      addAllformData &&
      addAllformData.calculated_option_score &&
      addAllformData.calculated_question_score &&
      addAllformData.createdAt &&
      addAllformData.updatedAt &&
      addAllformData.deleted &&
      addAllformData.isActive
    ) {
      setCalulatedOption(addAllformData.calculated_option_score);
      setCalulatedQustion(addAllformData.calculated_question_score);
      setCreatedAt(addAllformData.createdAt);
      setUpdatedAt(addAllformData.updatedAt);
      setDeleted(addAllformData.deleted);
      setIsActive(addAllformData.isActive);
    }
  }, [addAllformData]);
  useEffect(() => {
    if (formRules) {
      setFormRulesUpdate(formRules);
    }
  }, [formRules]);
  const dispatch = useDispatch();
  const datareq = (res) => {
    if (res && res.data && res.data.status == 200) {
      localStorage.removeItem("formRules");
      setTimeout(() => {
        navigate("/checklist/all-form");
      }, 2000);

    } else {
      toast.error(res && res.data && res.data.message);
    }
  };
  const handleCreate = (values) => {
    // props.handleFinalSubmit(values);

    if (
      props &&
      props.listData &&
      props.value &&
      props.value.values &&
      props.value.values.title &&
      props.value.values.id
    ) {
      const data = {};
      const modifyQustionData = props.listData.map(item => {
        item.skuName = props.value.values.skuName

        return item
      });

      const editdataall = {
        _id: props.value.values.id,
        kpis: [],
        questions: modifyQustionData,
        rules: formRules,
        isActive: addAllformData.isActive,
        deleted: addAllformData.deleted,
        title: props.value.values.title,
        calculated_option_score:
          addAllformData && addAllformData.calculated_option_score,
        calculated_question_score: addAllformData.calculated_question_score,
        createdAt: addAllformData.createdAt,
        updatedAt: addAllformData.updatedAt,
        isSelfieRequired: props.isSelfieRequired,
        reports: props.reports,
        skuName: props.value.values.skuName,
      };
      data.body = editdataall;
      const id = localStorage.getItem("companyId");
      data.url = BASE_URL + `checklists?company_id=${id}`;
      dispatch(EditallFormUpdateData(data, datareq));
    } else {
    }
  };
  const handleCreates = (values) => {
    dispatch(allFormEditQustionResetData(null, null));
    if (values) {
      props.handleFinalSubmit(values);
    }
  };
  return (
    <Box
      sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
      className="header-card"
    >
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={8} sm={12} lg={8} >
          <Box className="allform-head-time">
            <Typography sx={{ display: "flex", alignItems: "center", marginLeft: { lg: 1 } }}>
              <small
                style={{
                  marginLeft: "6px",
                  marginRight: "5px",
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                }}
              >
                Created :{" "}
              </small>
              <b>{moment(props?.dataList?.createdAt).format("lll")} &nbsp; </b>
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <small
                style={{
                  marginRight: "5px",
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                }}
              >
                Modified :{" "}
              </small>
              <b>{moment(props?.listData?.updatedAt).format("lll")}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ float: "right", marginRight: { lg: 1 } }}
            className="create-new-form"
          >
            <Link to="/checklist/all-form" className="submenu-item">
              <Button
                className="cancle-button cancle-hover"
                sx={{ backgroundColor: "#ffffff" }}
              >
                <CloseOutlinedIcon sx={{ mr: 1 }} />
                Cancel
              </Button>
            </Link>
            {/* <Link to="/checklist/all-form" className="submenu-item" /> */}
            {props.CreateData ? (
              <Button
                sx={{ paddingRight: { lg: 1 } }}
                className="header-add cancle-hover"
                type="submit"
                onClick={handleCreates}
              //sx={{ marginRight:{lg:1} }}
              >
                <TaskAltOutlinedIcon sx={{ mr: 1 }} />
                Create
              </Button>
            ) : props.EditData ? (
              <Button
                className="header-add cancle-hover"
                type="submit"
                onClick={handleCreate}
              >
                <TaskAltOutlinedIcon sx={{ mr: 1 }} />
                Update
              </Button>

            ) : (
              ""
            )}
            <div style={{ marginRight: "8px", marginTop: "8px" }}>
              <Tooltip
                title={
                  <div style={{ maxWidth: "300px" }}>
                    <strong>Select Report: </strong>
                    <p> This section is used to select to whom the report will be sent via email  when the form is submitted.</p>
                    <strong>Selfie Required:</strong>
                    <p>Check the Selfie required check box if you want the one submitting the checklist to take a selfie before he submits it. It acts as a proof of who has submitted the checklist.</p>
                    <strong>Form Question:</strong>
                    <p> The user can add a question by clicking on the “Add Question” button under this section.</p>
                  </div>
                }
                arrow
                interactive
              >
                <InfoIcon
                  style={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: "#D76C00",
                  }}
                />
              </Tooltip>
            </div>
            {/* </Link> */}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateFormHead;
