import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { editLogEntrySubmission, UploadImgEditQSuccess, UploadImgEditQuesApi } from "../redux/actions/LogEntry";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useNavigate } from "react-router-dom";

const booleanOptions = [
    "Yes",
    "No",
];

const EditLogEntrySubmission = () => {
    const logEntrySubmissionData = localStorage.getItem("logEntrySubmissionData")
    const parsedLogEntrySubmissionData = JSON.parse(logEntrySubmissionData)
    const companyId = localStorage.getItem("companyId");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSubmissionInitiated, setIsSubmissionInitiated] = useState(false);
    const [submissionData, setSubmissionData] = useState({});
    const [formData, setFormData] = useState({});
    const [questionData, setQuestionData] = useState([]);
    const [timerValue, setTimerValue] = useState(0);
    const [timerRunning, setTimerRunning] = useState(false);
    const [timerInterval, setTimerInterval] = useState(null);
    const [uploadedMedia, setUploadedMedia] = useState({});

    const ProfileDetailData = useSelector(
        (state) =>
            state.ProfileDetailData &&
            state.ProfileDetailData.ProfileData &&
            state.ProfileDetailData.ProfileData.data &&
            state.ProfileDetailData.ProfileData.data
    );

    const uploadedMediaData = useSelector(
        (state) =>
            state.logEntry &&
            state.logEntry.uploadedMedia || {}
    );

    useEffect(() => {
        setSubmissionData(parsedLogEntrySubmissionData);
        setQuestionData(parsedLogEntrySubmissionData.questions)
    }, [])

    useEffect(() => {
        if(uploadedMediaData.imgKey){
            const modifyQustionData = questionData;
            modifyQustionData[uploadedMediaData.questionIndex] = {
                ...modifyQustionData[uploadedMediaData.questionIndex],
                answer: {
                    type: modifyQustionData[uploadedMediaData.questionIndex]?.type?.key,
                    answer: {
                        answer: [uploadedMediaData?.imgKey],
                    },
                    startTime: modifyQustionData[uploadedMediaData.questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
            setQuestionData(modifyQustionData);
        }
    }, [uploadedMediaData])

    const handleFormChange = (e, questionIndex) => {
        const fieldName = e.target.name;
        const updatedFormData = {
            ...formData,
            [fieldName]: e.target.value,
        };
        const modifiedQuestionData = [...questionData];
        if (modifiedQuestionData[questionIndex]?.type?.key === "single_select") {
            const answer = modifiedQuestionData[
                questionIndex
            ]?.attached_list?.items.filter((ans) => ans.title === e.target.value);

            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                currentAnswer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        title: e.target.value,
                        id: answer[0] && answer[0].id,
                        option_score: modifiedQuestionData[questionIndex]?.question_score,
                    },
                    startTime: startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "date") {
            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        value: e.target.value,
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "datetime") {
            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        date: moment(e.target.value?.$d).format("YYYY-MM-DD"),
                        time: moment(e.target.value?.$d).format("HH:mm:ss"),
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "time") {
            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        value: e.target.value,
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "file") {
            const mediatype = [];

            const fetchMediaFileType = questionData[questionIndex]?.type?.validations?.filter(item => item.key === "mediaFileType")
            if (fetchMediaFileType.length) {
                fetchMediaFileType[0].answer.map(item => {
                    if (item.value) {
                        mediatype.push(item.typeString);
                    }
                })
            }
            
            if (!mediatype.join(',').includes(e.target.value.type.split('/')[0])) {
                toast.error(`Question ${questionIndex + 1} only supports ${mediatype.join(',')} format`);
                return;
            }

            var filename = e.target.value.name.substring(
                0,
                e.target.value.name.lastIndexOf(".")
            );

            const pos = e.target.value.name.lastIndexOf(".");

            var modifiedFilename = `PP-${e.target.value.type.split('/')[0].toUpperCase()}.${filename.split(" ").join("_")}-${Date.now()}.${e.target.value.name.slice(
                pos + 1
            )}`;

            const file = e.target.value;
            const medias =  { ...uploadedMedia } ;

            medias[questionIndex] = {
                path: e.target.value,
                fileName: modifiedFilename,
                originalname: modifiedFilename,
                key: modifiedFilename,
                name: modifiedFilename,
                fileType: e.target.value.type.split('/')[0].toUpperCase(),
                contentType: file.type,
                addedBy: ProfileDetailData._id,
                company: companyId,
                size: file.size,
            };

            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        answer: [],
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
            setUploadedMedia(medias);
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "boolean") {
            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        title: e.target.value,
                        value: true,
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else {
            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        value: e.target.value,
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        }
    
        setFormData(updatedFormData);

        setQuestionData(modifiedQuestionData);
    };

    console.log("questionData", questionData);

    const renderBooleanField = (question, questionIndex) => {
        const fieldName = `question_${questionIndex + 1}_answer`;
        const selectedAnswer = question?.answer?.answer?.title || "";
        const isAnswerGivenBoolean =
            typeof selectedAnswer === "string" && selectedAnswer !== "";

        return (
            <FormControl component="fieldset">
                <RadioGroup
                    style={{ textAlign: "left", marginLeft: 0 }}
                    row
                    aria-label={`Answer for Question ${questionIndex + 1}`}
                    name={fieldName}
                    value={selectedAnswer}
                    onChange={(e) => handleFormChange(e, questionIndex)}
                >
                    {booleanOptions?.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            value={option}
                            control={<Radio />}
                            label={option}
                        />
                    ))}
                </RadioGroup>
                {isSubmissionInitiated && !isAnswerGivenBoolean && (
                    <FormHelperText error>
                        This field is required, Please select a answer first.
                    </FormHelperText>
                )}
  
            </FormControl>
        );
    };

    const renderAdditionalFields = (
        question,
        questionIndex,
        handleFormChange
    ) => {

        const typeKey = question.type?.key;


        const qAnswer =
            question?.answer?.answer?.value ||
            question?.answer?.answer?.title ||
            question?.answer?.answer?.answer ||
            question?.answer?.answer ||
            "";

        switch (typeKey) {
            case "number":
                const numericQAnswer = parseFloat(qAnswer);
                const isAnswerGiven = !isNaN(numericQAnswer) && numericQAnswer >= 0;
                return (
                    <div style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label={`Numeric Answer for Question ${questionIndex + 1}`}
                            name={`question_${questionIndex + 1}_numeric_answer`}
                            variant="outlined"
                            fullWidth
                            value={question?.answer?.answer?.value}
                            onChange={(e) => handleFormChange(e, questionIndex)}
                            margin="normal"
                            type="number"
                            required
                            error={isSubmissionInitiated && !isAnswerGiven}
                            helperText={
                                isSubmissionInitiated && !isAnswerGiven
                                    ? "This field is required, Please provide number as an answer first."
                                    : ""
                            }
                        />
                    </div>
                );

            case "text":
                const isAnswerGivenText =
                    typeof qAnswer === "string" && qAnswer.trim() !== "";
                return (
                    <div style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label={`Text Answer for Question ${questionIndex + 1}`}
                            name={`question_${questionIndex + 1}_text_answer`}
                            variant="outlined"
                            fullWidth
                            value={question?.answer?.answer?.value}
                            onChange={(e) => handleFormChange(e, questionIndex)}
                            margin="normal"
                            required
                            error={isSubmissionInitiated && !isAnswerGivenText}
                            helperText={
                                isSubmissionInitiated && !isAnswerGivenText
                                    ? "This field is required, Please provide text as an answer first."
                                    : ""
                            }
                        />
                    </div>
                );

            case "single choice":
                const singleSelectOptions = question?.attachedlist?.items;
                const isAnswerGivenSingleSelect =
                    typeof qAnswer === "string" && qAnswer.trim() !== "";
                const isInvalidSingleSelect =
                    isSubmissionInitiated && !isAnswerGivenSingleSelect;

                return (
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label={`Answer for Question ${questionIndex + 1}`}
                            name={`question_${questionIndex + 1}_answer`}
                            value={question?.answer?.answer?.title}
                            onChange={(e) => handleFormChange(e, questionIndex)}
                        >
                            {singleSelectOptions?.map((option) => {
                                return (
                                    <FormControlLabel
                                        key={option.title}
                                        value={option.title}
                                        control={<Radio />}
                                        label={`${option.title}`}
                                    />
                                );
                            })}
                        </RadioGroup>
                        {isInvalidSingleSelect && (
                            <FormHelperText error>
                                This field is required, Please select a answer first.
                            </FormHelperText>
                        )}
                    </FormControl>
                );

            case "multiple choice":
                const isAnswerGivenMultiSelect =
                    Array.isArray(qAnswer) && qAnswer.length > 0;

                const multiSelectOptions = question?.attachedlist?.items;
                const selectedOptions = question?.answer?.answer || [];
                const handleMultiSelectChange = (e) => {
                    const selectedOption = e.target.value;
                    const modifiedQuestionData = [...questionData];

                    const answer = modifiedQuestionData[
                        questionIndex
                    ]?.attachedlist?.items.filter((ans) => ans.title === e.target.value);

                    let updatedOptions = [...selectedOptions];

                    if (e.target.checked) {
                        // updatedOptions.push({
                        //     id: answer[0] && answer[0].id,
                        //     title: selectedOption,
                        // });
                        updatedOptions.push(answer[0]);
                    } else {
                        updatedOptions = updatedOptions.filter(
                            (upOp) => upOp.title !== selectedOption
                        );
                    }

                    const fieldName = `question_${questionIndex + 1}_answer`;
                    const updatedFormData = {
                        ...formData,
                        [fieldName]: updatedOptions,
                    };
                    modifiedQuestionData[questionIndex] = {
                        ...modifiedQuestionData[questionIndex],
                        answer: {
                            type: modifiedQuestionData[questionIndex]?.type?.key,
                            answer: updatedOptions,
                            startTime: modifiedQuestionData[questionIndex]?.startTime,
                            endTime: moment().format(),
                        },
                    };

                    // if (selectedRemainingData && selectedRemainingData?._id) {
                    //     localStorage.setItem(
                    //         "answeredQuestions_" + selectedRemainingData?._id,
                    //         JSON.stringify(modifiedQuestionData)
                    //     );
                    //     dispatch(
                    //         AuditorChecklistSubmissionDataSuccess(modifiedQuestionData)
                    //     );
                    // }
                    setQuestionData(modifiedQuestionData);
                    setFormData(updatedFormData);
                    // updateAnsweredQuestions(fieldName, updatedOptions, questionIndex);
                };

                const optionsAnswer = selectedOptions.map((option) => option.title);
                const isInvalidMultiSelect =
                    isSubmissionInitiated && !isAnswerGivenMultiSelect;

                return (
                    <div>
                        {multiSelectOptions?.map((option) => (
                            <FormControlLabel
                                key={option.title}
                                control={<Checkbox />}
                                label={`${option.title}`}
                                checked={optionsAnswer.includes(option.title)}
                                onChange={handleMultiSelectChange}
                                value={option.title}
                            />
                        ))}
                        {isInvalidMultiSelect && (
                            <FormHelperText error>
                                This field is required, Please select one or more answer first.
                            </FormHelperText>
                        )}
                    </div>
                );

            case "boolean":
                return renderBooleanField(question, questionIndex, handleFormChange);

            case "file":
                const handleRemoveFile = (file, qIndex) => {
                    const modifiedQuestionData = [...questionData];
                    
                    const mediaAnswer = modifiedQuestionData[
                        questionIndex
                    ]?.answer?.answer?.answer.filter(
                        (uploadedFile) => uploadedFile !== file
                    );

                    modifiedQuestionData[questionIndex] = {
                        ...modifiedQuestionData[questionIndex],
                        answer: {
                            answer: {
                                answer: mediaAnswer,
                            },
                        },
                    };

                    const modifiedUploadedMedia = { ...uploadedMedia };

                    delete modifiedUploadedMedia[qIndex];

                    setUploadedMedia(modifiedUploadedMedia);
                    setQuestionData(modifiedQuestionData);
                };

                const storedFile = qAnswer.length ? qAnswer : uploadedMedia?.[questionIndex]?.fileName ? [uploadedMedia?.[questionIndex]?.fileName] : [];
                // (auditorChecklistSubmissionData?.[questionIndex]?.media?.[0]?.path
                //     ?.name &&
                //     auditorChecklistSubmissionData?.[questionIndex]?.answer?.answer) ||
                // [];

                const isAnswerGivenFile = qAnswer;
                const mediatype = [];

                const fetchMediaFileType = question?.type?.validations?.filter(item => item.key === "mediaFileType")
                if (fetchMediaFileType.length) {
                    fetchMediaFileType[0].answer.map(item => {
                        if (item.value) {
                            mediatype.push(item.typeString);
                        }
                    })
                }

                return (
                    <Card>
                        <CardContent>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            e.target.value = null;
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                    // const imageSrc = e.target.result;
                                                    // setCapturedImage(imageSrc);
                                                };
                                                reader.readAsDataURL(file);
                                                const fieldName = `question_${questionIndex + 1
                                                    }_file_answer`;
                                                handleFormChange(
                                                    {
                                                        target: {
                                                            name: fieldName,
                                                            value: file,
                                                        },
                                                    },
                                                    questionIndex
                                                );
                                            }
                                        }}
                                        style={{ display: "none" }}
                                        id={`fileInput-${questionIndex}`}
                                        multiple
                                        accept={mediatype.join(",")}
                                    />
                                    <label htmlFor={`fileInput-${questionIndex}`}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            component="span"
                                            startIcon={<AttachFileIcon />}
                                        >
                                            Select File
                                        </Button>
                                    </label>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                                {storedFile?.length
                                    ? storedFile.map((file) => (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <InsertDriveFileIcon
                                                color="primary"
                                                style={{ marginRight: "8px" }}
                                            />
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                style={{ marginRight: "8px" }}
                                            >
                                                {/* <a href={`https://popprobe-saas.s3.us-west-2.amazonaws.com/${file}`} target="_blank"> */}
                                                {`${file}`}
                                                {/* </a> */}
                                            </Typography>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleRemoveFile(file, questionIndex)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    ))
                                    : ""}
                                <Button variant="contained" color="warning" onClick={() => handleMediaUpload(questionIndex)}>Upload</Button>
                            </div>
                            {isSubmissionInitiated && !isAnswerGivenFile && (
                                <FormHelperText error>
                                    This field is required, Please attach a file as an answer
                                    first.
                                </FormHelperText>
                            )}
                        </CardContent>
                    </Card>
                );

            case "date":
                const isAnswerGivenDate =
                    question?.answer?.answer?.value !== null &&
                    question?.answer?.answer?.value !== undefined &&
                    question?.answer?.answer?.value !== "";

                const fetchFutureDatesValidation = question?.type?.validations?.filter(item => item.key === "allowFutureDates")
                const fetchPastDatesValidation = question?.type?.validations?.filter(item => item.key === "allowPastDates")

                return (
                    <div style={{ marginBottom: "1rem" }}>
                        <FormControl component="fieldset">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Date"
                                    size="small"
                                    value={question?.answer?.answer?.value}
                                    maxDate={fetchFutureDatesValidation?.[0]?.answer?.[1]?.value ? new Date() : ""}
                                    minDate={fetchPastDatesValidation?.[0]?.answer?.[1]?.value ? new Date() : ""}
                                    onChange={(newValue) => {
                                        if (newValue && newValue.$d) {
                                            let dateSort = moment(newValue.$d).format("YYYY-MM-DD");
                                            handleFormChange(
                                                {
                                                    target: {
                                                        name: `question_${questionIndex + 1}_date_answer`,
                                                        value: dateSort,
                                                    },
                                                },
                                                questionIndex
                                            );
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    PopperProps={{
                                        popperOptions: {
                                            modifiers: [
                                                {
                                                    name: "preventOverflow",
                                                    options: {
                                                        padding: 8,
                                                    },
                                                },
                                                {
                                                    name: "flip",
                                                    options: {
                                                        padding: 8,
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        {isSubmissionInitiated && !isAnswerGivenDate && (
                            <FormHelperText error>
                                This field is required, Please select a date as an answer.
                            </FormHelperText>
                        )}
                    </div>
                );

            case "datetime":
                const isAnswerGivenDatetime =
                    question?.value !== null && question?.value !== undefined;

                const handleDateTimeChange = (newDateTime) => {
                    if (newDateTime) {
                        const formattedDateTime = moment(newDateTime).format(
                            "YYYY-MM-DDThh:mm:ss"
                        );
                        handleFormChange(
                            {
                                target: {
                                    name: `question_${questionIndex + 1}_datetime_answer`,
                                    value: newDateTime,
                                },
                            },
                            questionIndex
                        );
                    }
                };

                return (
                    <div>
                        <div style={{ marginBottom: "1rem" }}>
                            <FormControl component="fieldset">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date"
                                        value={
                                            (qAnswer?.date ? qAnswer?.date : null)
                                        }
                                        onChange={(newValue) => {
                                            if (newValue && newValue.$d) {
                                                let dateSort = moment(newValue.$d).format("YYYY-MM-DD");
                                                // setStartDateAsDateTime(dateSort);
                                                // setEndDateAsDateTime(dateSort);
                                                handleDateTimeChange(newValue);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <FormControl component="fieldset">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Time"
                                        value={qAnswer?.time ? dayjs(`${qAnswer?.date}T${qAnswer?.time}`) : ""}
                                        onChange={(newValue) => {
                                            // setSelectedTimeForTime(newValue);
                                            handleDateTimeChange(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        views={["hours", "minutes", "seconds"]}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        {isSubmissionInitiated && !isAnswerGivenDatetime && (
                            <FormHelperText error>This field is required</FormHelperText>
                        )}
                    </div>
                );

            case "time":
                const isAnswerGivenTime =
                    question?.value !== null && question?.value !== undefined;

                const handleTimeChange = (newTime) => {
                    if (newTime) {
                        // setSelectedTime(newTime);
                        const formattedTime = newTime
                            ? moment(newTime?.$d).format("HH:mm:ss")
                            : "";
                        handleFormChange(
                            {
                                target: {
                                    name: `question_${questionIndex + 1}_time_answer`,
                                    value: formattedTime,
                                },
                            },
                            questionIndex
                        );
                    }
                };

                return (
                    <div style={{ marginBottom: "1rem" }}>
                        <FormControl component="fieldset">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label="Time"
                                    value={qAnswer ? dayjs(`2024-09-06T${qAnswer}`) : ""}
                                    onChange={(newValue) => {
                                        // setSelectedTime(newValue);
                                        handleTimeChange(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    views={["hours", "minutes", "seconds"]}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        {isSubmissionInitiated && !isAnswerGivenTime && (
                            <FormHelperText error>Time answer is required</FormHelperText>
                        )}
                    </div>
                );

            case "timer":
                const isTimerStarted = timerValue !== null && timerValue !== undefined;

                return (
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton color="primary" onClick={() => startTimer(question?.answer?.answer?.value)}>
                                <PlayArrowIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => pauseTimer(questionIndex)}
                            >
                                <PauseIcon fontSize="small" />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => resetTimer(questionIndex)}>
                                <ReplayIcon fontSize="small" />
                            </IconButton>
                        </div>
                        {isSubmissionInitiated && !isTimerStarted && (
                            <FormHelperText error>
                                This field is required, Please start timer and stop it.
                            </FormHelperText>
                        )}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "8px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "8px",
                                    }}
                                >
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Days:{" "}
                                        {Math.floor(
                                            (timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) /
                                            (24 * 60 * 60)
                                        )}
                                    </Typography>
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Hrs:{" "}
                                        {Math.floor(
                                            ((timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) %
                                                (24 * 60 * 60)) /
                                            (60 * 60)
                                        )}
                                    </Typography>
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Mins:{" "}
                                        {Math.floor(
                                            (timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) / 60
                                        )}
                                    </Typography>
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Sec:{" "}
                                        {(timerValue ||
                                            question?.answer?.answer?.value ||
                                            0) %
                                            60 ===
                                            0
                                            ? 0
                                            : (timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) % 60}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const startTimer = (val) => {
        setTimerValue(val);

        if (!timerRunning) {
            setTimerRunning(true);
            setTimerInterval(
                setInterval(() => {
                    setTimerValue((prevValue) => prevValue + 1);
                }, 1000)
            );
        }
    };

    const pauseTimer = (questionIndex) => {
        setTimerRunning(false);
        clearInterval(timerInterval);
        handleFormChange(
            {
                target: {
                    name: `question_${questionIndex + 1}_timer_answer`,
                    value: timerValue,
                },
            },
            questionIndex
        );
    };

    const resetTimer = (questionIndex) => {
        setTimerValue(0);
        setTimerRunning(false);
        clearInterval(timerInterval);
        setTimerInterval(null);
        handleFormChange(
            {
                target: {
                    value: 0,
                },
            },
            questionIndex
        );
    };

    const validateQuestionsAnswered = () => {
        for (let i = 0; i < questionData.length; i++) {
            const question = questionData[i];

            switch (question.type.key) {
                case "boolean":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question.answer.answer === undefined || question.answer.answer === null)
                    ) {
                        toast.error(`Select an answer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "text":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            typeof question?.answer?.answer?.value === "undefined" ||
                            question?.answer?.answer?.value === null ||
                            (typeof question?.answer?.answer?.value === "string" &&
                                question?.answer?.answer?.value.trim() === "")
                        )
                    ) {
                        toast.error(`Answer question ${i + 1} first!`);
                        return false;
                    }
                    if ((question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                        question?.answer?.answer?.value === "")) {
                        toast.error(`Answer question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "number":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            (typeof question?.answer?.answer?.value === "string" &&
                                question?.answer?.answer?.value.trim() === "")
                        )
                    ) {
                        toast.error(`Answer question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "single choice":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.title === undefined ||
                            question?.answer?.answer?.title === null)
                    ) {
                        toast.error(`Select an answer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "multiple choice":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer === undefined ||
                            question?.answer?.answer === null ||
                            (Array.isArray(question?.answer?.answer) && question?.answer?.answer.length === 0))
                    ) {
                        toast.error(`Select an answer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "file":
                    if((question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                        uploadedMedia[i].fileName && !question?.answer?.answer?.answer.length
                    )){
                        toast.error(`Upload a attached file for question ${i + 1} first!`);
                        return false;
                    } else if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) &&(
                            question?.answer?.answer?.answer === undefined ||
                            question?.answer?.answer?.answer === null ||
                            !question?.answer?.answer?.answer.length)
                    ) {
                        toast.error(`Attach a file for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "date":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            question?.answer?.answer?.value === null)
                    ) {
                        toast.error(`Provide a valid date for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "datetime":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.date === undefined ||
                            question?.answer?.answer?.time === undefined ||
                            question?.answer?.answer?.date === null ||
                            question?.answer?.answer?.time === null)
                    ) {
                        toast.error(
                            `Provide a valid date and time for question ${i + 1} first!`
                        );
                        return false;
                    }
                    break;

                case "time":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            question?.answer?.answer?.value === null)
                    ) {
                        toast.error(`Provide a valid time for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "timer":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            question?.answer?.answer?.value === 0 ||
                            question?.answer?.answer?.value === null)
                    ) {
                        toast.error(`Start the timer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                default:
                    break;
            }
        }
        return true;
    };
    console.log("uploadedMedia", uploadedMedia);

    const handleMediaUpload = (questionIndex) => {
        if (uploadedMedia[questionIndex]) {
            const formData = new FormData();
            formData.append("files", uploadedMedia[questionIndex].path);
            const imageData = {
                body: formData,
            };

            imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
            dispatch(UploadImgEditQuesApi(imageData, questionIndex));
        }
    }

    const handleSubmit = () => {
        const body = {
            ...parsedLogEntrySubmissionData,
            questions: questionData,
        };
        
        const callback = () => {
            setTimeout(()=>{
                dispatch(UploadImgEditQSuccess({}));
                navigate("/log-entry/view-log-entries-submissions")
            }, 3000)
        }

        const submissionData = {
            body: body,
        };

        submissionData.url =
            BASE_URL + `logEntry/Submission/${parsedLogEntrySubmissionData._id}/?company_id=${companyId}`;

        dispatch(editLogEntrySubmission(submissionData, callback));
    }

    return (
        <Box>
            <Card className="header-card">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                            variant="h6"
                            component="h6"
                            align="left"
                            style={{
                                padding: "4px",
                                marginLeft: "10px",
                                marginBottom: "0",
                                fontSize: "1.2rem",
                            }}
                        >
                            {submissionData?.logEntryObject?.logEntryTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                            variant="h6"
                            component="h6"
                            align="right"
                            style={{
                                textTransform: "uppercase",
                                padding: "4px",
                                marginRight: "10px",
                                marginBottom: "0",
                                fontSize: "1.2rem",
                            }}
                        >
                            {submissionData?.store?.title}
                        </Typography>
                    </Grid>
                </Grid>
                <ToastContainer />
                {false ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <ProgressbarTransparent play />
                    </div>
                ) : (
                    ""
                )}
                <Box
                    sx={{
                        bgcolor: "#F6F8FC",
                        height: "80vh",
                        mt: 1,
                        p: 1,
                        borderRadius: 1,
                        overflowX: "auto",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                        "&::-webkit-scrollbar-track": {
                            padding: "12px 5px",
                            backgroundColor: "#CBD4E1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#64748B",
                            borderRadius: 8,
                        },
                    }}
                >
                    <form>
                        {questionData?.map((question, questionIndex) => (
                            <Card
                                key={questionIndex}
                                sx={{
                                    p: 2,
                                    mt: 2,
                                    textAlign: "left",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    style={{ whiteSpace: "pre-line" }}
                                >
                                    <Box
                                        style={{
                                            background: "#f26539",
                                            padding: "10px",
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box style={{ color: "white", fontWeight: "bold" }}>
                                                Question. {questionIndex + 1}
                                            </Box>
                                        </Typography>
                                    </Box>
                                    <span>
                                        {(question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && <span style={{ color: "red" }}>*</span>}
                                        <strong>{question.title}</strong>{" "}
                                    </span>
                                    <Tooltip
                                        title={question.desc}
                                        arrow
                                        placement="right"
                                        style={{
                                            backgroundColor: "#f26539",
                                            marginLeft: "0.5em",
                                        }}
                                    >
                                        <span
                                            style={{
                                                cursor: "help",
                                                color: "#f26539",
                                            }}
                                        >
                                            <InfoIcon fontSize="small" />
                                        </span>
                                    </Tooltip>
                                </Typography>
                                {question.type?.key === "boolean"
                                    ? renderBooleanField(question, questionIndex)
                                    : renderAdditionalFields(
                                        question,
                                        questionIndex,
                                        handleFormChange
                                    )}
                            </Card>
                        ))}
                    </form>
                    <Button
                        className="cancle-hover"
                        variant="contained"
                        sx={{ mt: 2, backgroundColor: "#196773" }}
                        onClick={() => {
                            // setIsSubmissionInitiated(true);
                            if (validateQuestionsAnswered()) {
                                handleSubmit();
                                //     setReviewModalOpen(true);
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </Card>
        </Box>
    )
};

export default EditLogEntrySubmission;