import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  Grid,
  Typography,
  Box,
  Modal,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Autocomplete,
  Checkbox,
  Chip,
  Paper,
  FormControlLabel,
  Divider,
  Tabs,
  Tab,
  Card,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SearchAppBar from "../../../checklist/tableHeader/SearchButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  AllIssueListSuccess,
  ArchivedIssueListApi,
} from "../../../redux/actions/Issue";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import * as moment from "moment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ReactExport from "react-export-excel-xlsx-fix";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// const downloadAllstyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   height: 600,
//   overflow: "scroll",
//   bgcolor: "background.paper",
//   // border: "2px solid rgb(12 36 49)",
//   borderRadius: "6px",
//   boxShadow: 24,
//   p: 4,
// };

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto", 
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const MenuProps = {
  disableScrollLock: true,
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Header = ({
  searchOnChange,
  searchValue,
  handleKeyDown,
  totalitems,
  checkedArchive,
  setCheckedArchive,
  per_page,
  page,
  archievdIssueListTotalItems,
  permission,
  onFilter,
  getIssueForExport,
}) => {
  const location = useLocation();
  const issuesScreenFilter = location.state;
  const btnExportRef = useRef();

  const dispatch = useDispatch();

  const allIssueList = useSelector(
    (state) =>
      state.issue &&
      state.issue.allIssuedata &&
      state.issue.allIssuedata.data &&
      state.issue.allIssuedata.data.data &&
      state.issue.allIssuedata.data.data.issues
  );

  const categoryData = useSelector(
    (state) =>
      state.issue &&
      state.issue.categoryData &&
      state.issue.categoryData.data &&
      state.issue.categoryData.data.data || []
  );

  const getIssues = () => {
    setAllIssueExportData([]);
    getIssueForExport();
  };

  const [value, setValue] = useState(0);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterStore, setFilterStore] = React.useState([]);
  const [issueStatus, setStatus] = React.useState(
    issuesScreenFilter?.status || ""
  );
  const [severity, setseverity] = React.useState("");
  const [tokenNumber, settokenNumber] = useState("");
  const [category, setcategory] = React.useState("");
  const [subCategory, setsubCategory] = React.useState("");
  const [departmentName, setdepartmentName] = React.useState("");
  const [storeId, setStore] = React.useState("");
  const [start_date, setstart_date] = useState(
    moment(
      issuesScreenFilter?.startDate || moment().subtract(1, "months")
    ).format("YYYY-MM-DD")
  );
  const [end_date, setend_date] = useState(
    moment(issuesScreenFilter?.endDate || new Date()).format("YYYY-MM-DD")
  );
  const [newDate, setNewDate] = useState({ start_date, end_date });
  const [categoryList, setCategoryList] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [storeList, setStoreList] = useState();
  const [allIssueExportData, setAllIssueExportData] = useState([]);

  const [searchQuery, setSearchQuery] = React.useState("");
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const issueDepartmentList = JSON.parse(
      localStorage.getItem("departmentData")
    );
    // const issueCategoryList = JSON.parse(localStorage.getItem("categoryData"));
    const issueStoreList = JSON.parse(localStorage.getItem("storeData"));
    setDepartmentList(issueDepartmentList);
    setStoreList(issueStoreList);
  }, []);

  useEffect(() => {
    setCategoryList(categoryData);
  }, [categoryData])

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: per_page,
      },
      archived: false,
      search: "",
    },
  };

  const IssueListArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `ListArchivedIssue?company_id=${id}`;
    dispatch(ArchivedIssueListApi(paginationRequest));
  };

  useEffect(() => {
    if (checkedArchive === true) {
      IssueListArchivedApi(paginationRequest);
    }
  }, [checkedArchive]);
  const modalopenFilterhandleClose = () => {
    setOpenFilterModal(false);
  };
  const handleStoreChange = (event, newValue) => {
    if (newValue) {
      setFilterStore(newValue);
    }
  };

  const storeListBasedOnRole = useSelector((state) => state.issue);

  const handleFilter = () => {
    const searchData = {
      severity,
      issueStatus,
      // priority,
      start_date,
      end_date,
      searchQuery,
      category,
      subCategory,
      tokenNumber,
      departmentName,
      storeId,
    };

    console.log("Check form data and call APIs", searchData);
    onFilter(searchData); // Send the data to the parent component
  };

  useEffect(() => {
    let subArr = [];

    let allColumnsList = [];
    let allCsvSubRows = [];

    const formattedSD = "Issue list";

    if (allIssueList && allIssueList.length) {
      allColumnsList.push(
        {
          value: "Token",
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
            fill: {
              patternType: "solid",
              bgColor: { rgb: "FFFFAA00" },
              fgColor: { rgb: "FFFFAA00" },
            },
          },
        },
        {
          value: "Category",
          widthPx: 200,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Sub-Category",
          widthPx: 200,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Priority",
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Status",
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Store",
          widthPx: 120,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Department",
          widthPx: 120,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Manager",
          widthPx: 80,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Mobile",
          widthPx: 80,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Email",
          widthPx: 120,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Observation",
          widthPx: 200,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Action",
          widthPx: 200,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },

        {
          value: "Created by",
          widthPx: 120,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Created at",
          widthPx: 100,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Attachment",
          widthPx: 1000,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: "Media Files",
          widthPx: 1000,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        }
      );

      allIssueList.map((item) => {
        allCsvSubRows.push([
          {
            value: `${item?.tokenNumber || "N/A"}`,
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.category || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.subCategory || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.severity || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.issueStatus || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.store?.title || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value:
              item?.departments?.map((item) => item.deptName).join(", ") ||
              "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value:
              item?.departments?.map((item) => item.deptManager).join(", ") ||
              "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value:
              item?.departments
                ?.map((item) => item.managerMobileNumber)
                .join(", ") || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value:
              item?.departments?.map((item) => item.managerEmail).join(", ") ||
              "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.observation || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.action || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.loggedBy?.name || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value:
              (item?.createdAt &&
                moment(item?.createdAt).format("YYYY-MM-DD")) ||
              "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.attachment?.url || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: item?.mediaFiles?.map((item) => item.name).join(", "),
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
        ]);
      });

      const dataSet = [
        {
          columns: allColumnsList,
          data: allCsvSubRows,
        },
      ];
      subArr.push({ formattedSD, dataSet });
    }

    if (subArr.length) {
      setAllIssueExportData(subArr);
      setTimeout(() => {
        btnExportRef.current.click();
        dispatch(AllIssueListSuccess([]));
      }, 1000);
    }
  }, [allIssueList]);

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={6} lg={6} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                style={{ marginLeft: "8px" }}
                label="Active"
                icon={<p className="active-tabs-content">{totalitems}</p>}
                iconPosition="end"
                className={
                  checkedArchive === false
                    ? "active-bg"
                    : "active-tabs tabs-content"
                }
                onClick={() => setCheckedArchive(false)}
              />
              <Tab
                label="Archived"
                icon={
                  <p className="active-tabs-content">
                    {archievdIssueListTotalItems?.total_item}
                  </p>
                }
                iconPosition="end"
                className={
                  checkedArchive === true
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                onClick={() => setCheckedArchive(true)}
              />
            </Tabs>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={0}
            lg={0}
            style={{ padding: "0px" }}
          ></Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} className="colomn-padding">
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="left"
                justifyContent="flex-start"
                sx={{
                  marginRight: "1px",
                  marginLeft: "10px",
                }}
              >
                <Stack direction="row" alignItems="left">
                  <Box
                  >
                    <ExcelFile
                      element={
                        <Button
                          className="header-add cancle-hover"
                          style={{
                            whiteSpace: "nowrap",
                            display: "none",
                          }}
                          ref={btnExportRef}
                          // onClick={() => getIssues()}
                        >
                          Export Excel
                          <FileDownloadIcon
                            style={{
                              marginLeft: "5px",
                            }}
                          />
                        </Button>
                      }
                      filename={"PopprobeIssueList_" + Date.now()}
                    >
                      {allIssueExportData.map((csvData, key) => (
                        <ExcelSheet
                          key={key}
                          dataSet={csvData.dataSet}
                          name={csvData.formattedSD}
                        />
                      ))}
                    </ExcelFile>
                    <Button
                      className="header-add cancle-hover"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => getIssues()}
                    >
                      Export Excel
                      <FileDownloadIcon
                        style={{
                          marginLeft: "5px",
                        }}
                      />
                    </Button>
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="left"
                justifyContent="flex-start"
                sx={{
                  marginRight: "1px",
                  marginLeft: "10px",
                }}
              >
                <Stack direction="row" alignItems="left">
                  <Button
                    className="header-add cancle-hover"
                    onClick={(e) => setOpenFilterModal(true)}
                  >
                    <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                    Filter
                  </Button>
                </Stack>
              </Stack>
              {permission?.issueLog?.manageIssues === true ? (
                <Link to="/issues/list/create" className="submenu-item">
                  <Button
                    className="header-add cancle-hover"
                  >
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    New Issue
                  </Button>
                </Link>
              ) : (
                ""
              )}
              <div style={{ marginTop: "8px", marginRight: "10px" }}>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>IssueLog :</strong>
                      <p>
                        Shows the list of issues raised by both web and app
                        having details of the issues like Observation . Action
                        need for the issue raised and image attached
                      </p>
                      <strong>New issue: </strong>
                      <p>You can create New issues from the admin logged in.</p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={openFilterModal}
        onClose={modalopenFilterhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={{ ...downloadAllstyle, p: 0 }}>
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setOpenFilterModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                pt: 2,
                px: 4,
              }}
            >
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Status</label>
                  <Select
                    size="small"
                    name="issueStatus"
                    onChange={(e) => setStatus(e.target.value)}
                    sx={{ borderRadius: "8px" }}
                    value={issueStatus}
                    MenuProps={MenuProps}
                    placeholder="Select type"
                  >
                    <MenuItem selected value="">
                      Select type
                    </MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                    <MenuItem value="In-progress">In-progress</MenuItem>
                    <MenuItem value="Resolved">Resolved</MenuItem>
                    <MenuItem value="Open">Open</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>severity</label>
                  <Select
                    size="small"
                    name="severity"
                    onChange={(e) => setseverity(e.target.value)}
                    value={severity}
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Select type"
                  >
                    <MenuItem value={""}>Select type</MenuItem>
                    <MenuItem value="Critical">Critical</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Non-Critical">Non-Critical</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Category</label>
                  <Select
                    size="small"
                    name="category"
                    onChange={(e) => setcategory(e.target.value)}
                    placeholder="Select type"
                    value={category}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.category}
                    sx={{ borderRadius: "8px" }}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "200px",
                        },
                      },
                    }}
                  >
                    <MenuItem value={""}>Select type</MenuItem>
                    {categoryList &&
                      categoryList?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option.title}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Department</label>
                  <Select
                    size="small"
                    name="departmentName"
                    onChange={(e) => setdepartmentName(e.target.value)}
                    value={departmentName}
                    placeholder="Select type"
                    sx={{ borderRadius: "8px" }}
                    // value={formik.values.department}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={""}>Select type</MenuItem>
                    {departmentList &&
                      departmentList?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option.deptName}>
                            {option.deptName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <label>Store</label>
                  <Select
                    size="small"
                    name="storeId"
                    onChange={(e) => setStore(e.target.value)}
                    placeholder="Select type"
                    helperText="Select type"
                    value={storeId}
                    sx={{
                      borderRadius: "8px",
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                    // value={formik.values.department}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={""}>Select type</MenuItem>
                    {storeList &&
                      storeList?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option._id}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>TokenNumber</label>
                  <TextField
                    size="small"
                    name="TockenNumber"
                    onChange={(e) => settokenNumber(e.target.value)}
                    value={tokenNumber}
                    MenuProps={MenuProps}
                    placeholder="Seach by token number"
                    sx={{
                      borderRadius: "8px",
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                  ></TextField>
                </FormControl>
              </Box>

              <label>Date</label>
              <Box
                sx={{ mb: 2 }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={start_date}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setstart_date(dateSort);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
                <Typography className="date-to">to</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="End Date"
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={end_date}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setend_date(dateSort);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={new Date()}
                    minDate={start_date}
                  />
                </LocalizationProvider>
              </Box>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Sub-Category</label>
                <Select
                  size="small"
                  name="subCategory"
                  onChange={(e) => setsubCategory(e.target.value)}
                  placeholder="Select type"
                  value={subCategory}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.category}
                  sx={{ borderRadius: "8px" }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "200px",
                      },
                    },
                  }}
                >
                  <MenuItem value={""}>Select type</MenuItem>
                  {categoryList &&
                    categoryList?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.title}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <Stack alignItems="left" sx={{ mb: 2 }}>
                <label>Search</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  value={searchQuery}
                  placeholder="Search User, Store, Category"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "sticky",
              bottom: 0,
              width: "100%",
              gap: "30px",
              px: 3,
              pt: 1,
              pb: 2,
              background: "#fff",
            }}
          >
            <Button
              className="cancle-button cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
                background: "#fff",
              }}
              onClick={() => {
                setStatus("");
                setseverity("");
                setdepartmentName("");
                settokenNumber("");
                setcategory("");
                setStore("");
                //setPriority("");
                setNewDate("");
                setSearchQuery("");
                const dates = {
                  start_date: moment(new Date()).format("YYYY-MM-DD"),
                  end_date: moment(new Date()).format("YYYY-MM-DD"),
                };
                setstart_date(dates.start_date);
                setend_date(dates.end_date);
                setNewDate(dates);
                const searchData = {
                  severity: "",
                  issueStatus: "",
                  // priority: "",
                  start_date: dates.start_date,
                  end_date: dates.end_date,
                  searchQuery: "",
                  category: "",
                  subCategory: "",
                  tokenNumber: "",
                  departmentName: "",
                  storeId: "",
                };
                // onFilter(searchData);
              }}
            >
              Clear
            </Button>
            <Button
              className="header-add cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
              }}
              onClick={() => {
                modalopenFilterhandleClose();
                handleFilter();
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
