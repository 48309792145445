import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EditSchedularApi } from "../../redux/actions/SchedularAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

const SchecularToggel = ({ row }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(row.isactive); 

  useEffect(() => {
    setIsChecked(row.isactive); 
  }, [row]);

  const handleToggleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    handleToggleFunction(row._id, newValue);
  };

  const handleToggleFunction = (id, isChecked) => {
    let payload = {
      isactive: isChecked,
    };

    const allData = {
      body: payload,
      url: BASE_URL + `DynamicJob/${id}`,
    };

    dispatch(EditSchedularApi(allData));
  };

  return (
    <div className={`toggle-switch ${isChecked ? "on" : "off"}`} onClick={handleToggleChange}>
      <div className="toggle-thumb" />
    </div>
  );
};

export default SchecularToggel;
