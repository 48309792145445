/** @format */

import React, { useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Card,
  Stack,
  FormControlLabel,
  Typography,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  Divider,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Fab from "@material-ui/core/Fab";
import Blank from "../../../images/Blank.png";
import { useFormik } from "formik";
import { useEffect } from "react";
import questiontype from "./QuestionType";
import SelectRuleIssueModal from "./SelectRuleIssueModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  RulesAddedApi,
  UploadImageEditQuesApi,
  UploadImgEditQSuccess,
} from "../../../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { allFormUpdateData } from "../../../redux/actions/AllformStore";
import { id } from "date-fns/locale";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditQuestionAllform = () => {
  const location = useLocation();
  const pathName = location?.pathname;
  const editdata = JSON.parse(localStorage.getItem("editdata"));
  const optionsAnswer = [
    { title: "Yes", value: "yes" },
    { title: "No", value: "no" },
    { title: "Acceptable", value: "accetable" },
    { title: "Not Acceptable", value: "notAcceptable" },
    { title: "Discarded", value: "discarded" },
    { title: "Returned", value: "returned" },
    { title: "Not Applicable", value: "notApplicable" },
  ];
  const numericCondition = [
    {
      id: 1,
      title: "=",
      key: "=",
    },
    {
      id: 2,
      title: ">",
      key: ">",
    },
    {
      id: 3,
      title: ">=",
      key: ">=",
    },
    {
      id: 4,
      title: "<",
      key: "<",
    },
    {
      id: 5,
      title: "<=",
      key: "<=",
    },
  ];
  const optionsAns = [
    { title: "Yes", value: editdata?.type?.validations[0]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[0]?.answer[1]?.value },
    {
      title: "Acceptable",
      value: editdata?.type?.validations[0]?.answer[2]?.value,
    },
    {
      title: "Not Acceptable",
      value: editdata?.type?.validations[0]?.answer[3]?.value,
    },
    {
      title: "Discarded",
      value: editdata?.type?.validations[0]?.answer[4]?.value,
    },
    {
      title: "Returned",
      value: editdata?.type?.validations[0]?.answer[5]?.value,
    },
    {
      title: "Not Applicable",
      value: editdata?.type?.validations[0]?.answer[6]?.value,
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [value, setValue] = useState(0);
  const [val, setVal] = useState("");
  const [firstData, setFirstData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [require, setRequire] = useState(optionsAns);
  const [RecordAudio, setRecordAudio] = useState("");
  const maxImagesAllowed = 2;
  const maxImageSize = 5 * 1024 * 1024;
  const [RecordAudioField, setRecordAudioField] = useState([
    { title: "Yes", value: editdata?.type?.validations[1]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[1]?.answer[1]?.value },
  ]);
  const [takeImage, setTakeImage] = useState("");
  const [takeImageData, setTakeImageData] = useState([
    { title: "Yes", value: editdata?.type?.validations[2]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[2]?.answer[1]?.value },
  ]);

  const [takeVideo, setTakeVideo] = useState("");
  const [takeVideoData, setTakeVideoData] = useState([
    { title: "Yes", value: editdata?.type?.validations[3]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[3]?.answer[1]?.value },
  ]);

  const [allowComment, setAllowComment] = useState("");
  const [allowCommentData, setAllowCommentData] = useState([
    { title: "Yes", value: editdata?.type?.validations[4]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[4]?.answer[1]?.value },
  ]);
  const [imageOptional, setImageOptional] = useState("");
  const [imageOptionalData, setImageOptionalData] = useState([
    { title: "Yes", value: editdata?.type?.validations[5]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[5]?.answer[1]?.value },
  ]);

  const [videoOptional, setVideoOptional] = useState("");
  const [videoOptionalData, setVideoOptionalData] = useState([
    { title: "Yes", value: editdata?.type?.validations[6]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[6]?.answer[1]?.value },
  ]);

  const [requiredComment, setRequiredComment] = useState("");
  const [validatarr, setValidatarr] = useState([]);
  const [ruleList, setRuleList] = useState([]);
  const [uiRender, setUiRender] = useState(false);
  const [weightScore, setWeightScore] = useState({});
  const [editData, setEditData] = useState();
  const [newFieldValue, setNewFieldValue] = useState({});
  const [singlemultichoice, setMultiSingleChoice] = useState();
  const [singleandmultichoiceedit, setSingleAndMultiChoiceEdit] = useState({});
  const [multiimageUrl, setMultiimageUrl] = useState([]);
  const [allKey, setAllKey] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isQuestionToInspections, setIsQuestionToInspections] = useState(false);

  // const [requiredCommentData, setRequiredCommentData] = useState([
  //   { title: "Yes", value: editdata?.type?.validations[5]?.answer[0]?.value },
  //   { title: "No", value: editdata?.type?.validations[5]?.answer[1]?.value },
  // ]);
  const [requiredCommentData, setRequiredCommentData] = useState([
    { title: "Yes", value: editdata?.type?.validations[7]?.answer[0]?.value },
    { title: "No", value: editdata?.type?.validations[7]?.answer[1]?.value },
  ]);

  const rulesdata = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.ruleslist &&
      state.checklist.ruleslist.data &&
      state.checklist.ruleslist.data.data
  );

  useEffect(() => {
    setRuleList(rulesdata);
  }, [rulesdata]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `rule/template?type=2&company_id=${id}` };
    dispatch(RulesAddedApi(data));
  }, []);

  const handleDelete = (index) => {
    setFirstData(firstData.filter((item, i) => i !== index));
  };

  const listData = (values) => {
    setFormValues(values);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const navigatioToQuestion = () => {
  //   setLoader(true);
  //   setTimeout(() => {
  //     navigate("/checklist/all-form/edit-forms");
  //   }, 1000);
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 2000);
  // };

  const getImageKey = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.uploadImgEditQ &&
      state.checklist.uploadImgEditQ.data &&
      state.checklist.uploadImgEditQ.data.data
  );

  // const handleImageUpload = async (e) => {
  //   const files = e.target.files;
  //   const newImages = [...formik.values.titleImages];
  //   const formData = new FormData();

  //   for (const file of files) {
  //     newImages.push(URL.createObjectURL(file));
  //     formData.append("files", file);
  //   }

  //   formik.setFieldValue("titleImages", newImages);

  //   try {
  //     const imageData = {
  //       body: formData,
  //     };
  //     imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
  //     await dispatch(UploadImageEditQuesApi(imageData));
  //     setMultiimageUrl(newImages);
  //     e.target.value = [];
  //   } catch (error) {
  //     e.target.value = [];
  //     console.error("Error uploading images:", error);
  //   }
  // };

  const handleImageUpload = async (e) => {
    const files = e.target.files;

    for (const file of files) {
      var idxDot = file.name.lastIndexOf(".") + 1;
      var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();

      if (extFile != "jpg" && extFile != "jpeg" && extFile != "png") {
        alert("Only jpg/jpeg and png files are allowed!");
        e.target.value = [];
        return;
      }

      if (file.size > maxImageSize) {
        toast.error("Only up to 5 MB images are allowed.");
        e.target.value = [];
        return;
      }
    }
    if (files.length + allKey.length > maxImagesAllowed) {
      toast.error(`Only ${maxImagesAllowed} images are allowed.`);
      e.target.value = [];
      return;
    }
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }

    try {
      const imageData = {
        body: formData,
      };
      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      await dispatch(UploadImageEditQuesApi(imageData));

      const newAllKey = [...allKey];
      for (let i = 0; i < files.length; i++) {
        if (newAllKey.length === maxImagesAllowed) {
          newAllKey.pop();
        }
        // newAllKey.unshift({ key: generateKeyForNewImage() });
      }
      setAllKey(newAllKey);
      e.target.value = [];
    } catch (error) {
      e.target.value = [];
      console.error("Error uploading images:", error);
    }
  };

  const handleDeleteImage = (item) => {
    // const newImages = formik.values.titleImages.filter((_, i) => i !== index);
    // formik.setFieldValue("titleImages", newImages);

    const newAllKey = [...allKey].filter((img) => img.key !== item);
    setAllKey(newAllKey);
  };

  useEffect(() => {
    if (getImageKey && getImageKey.length > 0) {
      setAllKey([...allKey, ...getImageKey]);
    }
  }, [getImageKey]);

  const handleChangeRules = (event, items, index) => {
    let allRules = ruleList;
    if (event.target.name === "condition") {
      allRules[index].condition = event.target.value;
    } else {
      allRules[index].answer = event.target.value;
    }
    setRuleList(allRules);
    setUiRender(!uiRender);
  };

  const handleWeightScore = (e) => {
    const value = e.target.value;
    setWeightScore({
      ...weightScore,
      [e.target.name]: value,
    });
  };

  const idusers = localStorage.getItem("iduser");

  const handleDone = (data) => {
    if (data) {
      setLoader(true);
      setTimeout(() => {
        navigate("/checklist/all-form/edit-forms");
      }, 1000);

      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (editdata) {
      setEditData(editdata);
    }
  }, []);
  const handleTakeImage = (event) => {
    setTakeImage(event.target.value);
    let events = event.target.value;
    for (let item of takeImageData) {
      if (events == "yes") {
        takeImageData[0].value = true;
        takeImageData[1].value = false;
      } else if (events == "no") {
        takeImageData[0].value = false;
        takeImageData[1].value = true;
      }
    }
    let options = takeImageData;
    setTakeImageData(options);
  };

  const handleTakeVideo = (event) => {
    setTakeVideo(event.target.value);
    let events = event.target.value;
    for (let item of takeVideoData) {
      if (events == "yes") {
        takeVideoData[0].value = true;
        takeVideoData[1].value = false;
      } else if (events == "no") {
        takeVideoData[0].value = false;
        takeVideoData[1].value = true;
      }
    }
    let options = takeVideoData;
    setTakeVideoData(options);
  };

  const handleRequire = (event) => {
    let events = event.target.value;
    setVal(events);
    for (let item of require) {
      if (events == "yes") {
        require[0].value = true;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "no") {
        require[0].value = false;
        require[1].value = true;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = true;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "not acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = true;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "discarded") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = true;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "returned") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = true;
        require[6].value = false;
      } else if (events == "not applicable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = true;
      }
    }
    let options = require;
    setRequire(options);
    console.log("require>>>", options);
    // setRequire(event.target.value);
  };
  console.log("require>>>");
  const handleRecordaudio = (event) => {
    setRecordAudio(event.target.value);
    let events = event.target.value;
    for (let item of RecordAudioField) {
      if (events == "yes") {
        RecordAudioField[0].value = true;
        RecordAudioField[1].value = false;
      } else if (events == "no") {
        RecordAudioField[0].value = false;
        RecordAudioField[1].value = true;
      }
    }
    let options = RecordAudioField;
    setRecordAudioField(options);
  };

  const handleAllowComment = (event) => {
    setAllowComment(event.target.value);
    let events = event.target.value;
    for (let item of allowCommentData) {
      if (events == "yes") {
        allowCommentData[0].value = true;
        allowCommentData[1].value = false;
      } else if (events == "no") {
        allowCommentData[0].value = false;
        allowCommentData[1].value = true;
      }
    }
    let options = allowCommentData;
    setAllowCommentData(options);
  };
  const handleImageOptional = (event) => {
    setImageOptional(event.target.value);
    let events = event.target.value;
    for (let item of imageOptionalData) {
      if (events == "yes") {
        imageOptionalData[0].value = true;
        imageOptionalData[1].value = false;
      } else if (events == "no") {
        imageOptionalData[0].value = false;
        imageOptionalData[1].value = true;
      }
    }
    let options = imageOptionalData;
    setImageOptionalData(options);
  };

  const handleVideoOptional = (event) => {
    setVideoOptional(event.target.value);
    let events = event.target.value;
    for (let item of videoOptionalData) {
      if (events == "yes") {
        videoOptionalData[0].value = true;
        videoOptionalData[1].value = false;
      } else if (events == "no") {
        videoOptionalData[0].value = false;
        videoOptionalData[1].value = true;
      }
    }
    let options = videoOptionalData;
    setVideoOptionalData(options);
  };

  const handleRequiredComment = (event) => {
    setRequiredComment(event.target.value);
    let events = event.target.value;
    for (let item of requiredCommentData) {
      if (events == "yes") {
        requiredCommentData[0].value = true;
        requiredCommentData[1].value = false;
      } else if (events == "no") {
        requiredCommentData[0].value = false;
        requiredCommentData[1].value = true;
      }
    }
    let options = requiredCommentData;
    setRequiredCommentData(options);
  };
  useEffect(() => {
    if (editData) {
      setAllKey((editData && editData.titleImages) || []);
      setIsQuestionToInspections(editData && editData.isQuestionToInspection);
      setNewFieldValue({
        titleImages:
          (editData &&
            editData.titleImages &&
            editData.titleImages.map((image) => image.url)) ||
          [],
        title: editData && editData.title,
        isQuestionToInspection: editData && editData.isQuestionToInspection,
        desc: editData && editData.desc,
        question_score: editData && editData.question_score,
        questiontype: editData && editData.type && editData.type.title,
        rules: editData && editData.rules && editData.rules,
        id: editData && editData.id,
        validations: editData && editData.type.validations,
        option_score: {
          acceptable:
            editdata &&
            editdata.type &&
            editdata.type.option_score &&
            editdata.type.option_score.acceptable &&
            editdata.type.option_score.acceptable,
          discarded:
            editdata &&
            editdata.type &&
            editdata.type.option_score &&
            editdata.type.option_score.discarded &&
            editdata.type.option_score.discarded,
          no:
            editdata &&
            editdata.type &&
            editdata.type.option_score &&
            editdata.type.option_score.no &&
            editdata.type.option_score.no,
          notAcceptable:
            editdata &&
            editdata.type &&
            editdata.type.option_score &&
            editdata.type.option_score.notAcceptable &&
            editdata.type.option_score.notAcceptable,
          notApplicable:
            editdata &&
            editdata.type &&
            editdata.type.option_score &&
            editdata.type.option_score.notApplicable &&
            editdata.type.option_score.notApplicable,
          returned:
            editdata &&
            editdata.type &&
            editdata.type.option_score &&
            editdata.type.option_score.returned &&
            editdata.type.option_score.returned,
          yes:
            editdata &&
            editdata.type &&
            editdata.type.option_score &&
            editdata.type.option_score.yes &&
            editdata.type.option_score.yes,
        },
      });
      if (
        (editdata && editData.type && editData.type.key === "single_select") ||
        "multi_select"
      ) {
        // const singlechoicearr = [];
        // singlechoicearr.push(editData.type.option_score);
        setMultiSingleChoice(editData?.type?.option_score);
        setSingleAndMultiChoiceEdit(editData?.type?.option_score);
      }
      let tmpRequired = editData.type.validations.filter(
        (val) => val.key === "required"
      );
      let required =
        tmpRequired && tmpRequired.length > 0
          ? tmpRequired[0]?.answer?.filter((ans) => ans.value === true)
          : false;
      setVal(
        required && required.length > 0 ? required[0].title.toLowerCase() : ""
      );
      let tmpRecordAudio = editData.type.validations.filter(
        (val) => val.key === "audio"
      );
      let recordAudio =
        tmpRecordAudio && tmpRecordAudio.length > 0
          ? tmpRecordAudio[0]?.answer?.filter((ans) => ans.value === true)
          : false;
      setRecordAudio(
        recordAudio && recordAudio.length > 0
          ? recordAudio[0].title.toLowerCase()
          : ""
      );

      let tmpRecordImage = editData.type.validations.filter(
        (val) => val.key === "image"
      );
      let recordImage =
        tmpRecordImage && tmpRecordImage.length > 0
          ? tmpRecordImage[0]?.answer?.filter((ans) => ans.value === true)
          : false;
      setTakeImage(
        recordImage && recordImage.length > 0
          ? recordImage[0].title.toLowerCase()
          : ""
      );

      let tmpRecordVideo = editData.type.validations.filter(
        (val) => val.key === "video"
      );
      let recordVideo =
        tmpRecordVideo && tmpRecordVideo.length > 0
          ? tmpRecordVideo[0]?.answer?.filter((ans) => ans.value === true)
          : false;
      setTakeVideo(
        recordVideo && recordVideo.length > 0
          ? recordVideo[0].title.toLowerCase()
          : ""
      );

      let tmpRecordComment = editData.type.validations.filter(
        (val) => val.key === "comment"
      );
      let recordComment =
        tmpRecordComment && tmpRecordComment.length > 0
          ? tmpRecordComment[0]?.answer?.filter((ans) => ans.value === true)
          : false;
      setAllowComment(
        recordComment && recordComment.length > 0
          ? recordComment[0].title.toLowerCase()
          : ""
      );

      let tmpRecordOptional = editData.type.validations.filter(
        (val) => val.key === "image_optional"
      );
      let recordOptional =
        tmpRecordOptional && tmpRecordOptional.length > 0
          ? tmpRecordOptional[0]?.answer?.filter((ans) => ans.value === true)
          : false;
      setImageOptional(
        recordOptional && recordOptional.length > 0
          ? recordOptional[0].title.toLowerCase()
          : ""
      );

      let tmpVideoOptional = editData.type.validations.filter(
        (val) => val.key === "video_optional"
      );
      let videoOptional =
        tmpVideoOptional && tmpVideoOptional.length > 0
          ? tmpVideoOptional[0]?.answer?.filter((ans) => ans.value === true)
          : false;
      setVideoOptional(
        videoOptional && videoOptional.length > 0
          ? videoOptional[0].title.toLowerCase()
          : ""
      );

      let tmpRecordRequiredComment = editData.type.validations.filter(
        (val) => val.key === "comment_required"
      );
      let recordRequiredComment =
        tmpRecordRequiredComment && tmpRecordRequiredComment.length > 0
          ? tmpRecordRequiredComment[0]?.answer?.filter(
              (ans) => ans.value === true
            )
          : false;
      setRequiredComment(
        recordRequiredComment && recordRequiredComment.length > 0
          ? recordRequiredComment[0].title.toLowerCase()
          : ""
      );
    }
    setFirstData(editdata && editdata.rules);
  }, [editData]);
  // const done = (data) => {
  //   if (data) {
  //     setLoader(true);
  //     navigate("/checklist/all-form/edit-forms");

  //     setTimeout(() => {
  //       setLoader(false);
  //     }, 2000);
  //   }
  // };
  let noFieldsSelected = true;

  validatarr.forEach((obj) => {
    obj.answer.forEach((option) => {
      if (option.value) {
        noFieldsSelected = false;
      }
    });
  });
  const formik = useFormik({
    initialValues: newFieldValue || {
      title: "",
      desc: "",
      question_score: "",
      isQuestionToInspection: false,
      questiontype: "",
      titleImages: [],
      option_score: {
        acceptable: "",
        discarded: "",
        no: "",
        notAcceptable: "",
        notApplicable: "",
        returned: "",
        yes: "",
      },
      rules: [],

      validations: [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const qustionDataId = localStorage.getItem("listDataid");
      if (!noFieldsSelected) {
        const alldata = {
          title: values.title,
          desc: values.desc,
          question_score: values.question_score,
          titleImages: allKey?.map((index) => ({
            key: index?.key,
            name: index?.name,
            url: index?.location || index?.url,
          })),
          type: {
            validations: validatarr,
            option_score:
              singleandmultichoiceedit &&
              editdata &&
              editData.type &&
              editData.type.key === "single_select"
                ? singleandmultichoiceedit
                : singleandmultichoiceedit &&
                  editdata &&
                  editData.type &&
                  editData.type.key === "multi_select"
                ? singleandmultichoiceedit
                : formik.values.option_score,
            key:
              formik.values.questiontype === "Single Choice"
                ? "single_select"
                : formik.values.questiontype === "Multiple Choice"
                ? "multi_select"
                : values?.questiontype?.toLowerCase() === "numeric"
                ? "number"
                : values?.questiontype?.toLowerCase() === "media"
                ? "file"
                : formik.values.questiontype.toLowerCase(),
            title:
              formik?.values?.questiontype === "Single Choice"
                ? formik?.values?.questiontype
                : formik.values.questiontype === "Multiple Choice"
                ? formik?.values?.questiontype
                : formik?.values?.questiontype?.charAt(0).toUpperCase() +
                  values?.questiontype?.slice(1),
            title_to_show:
              formik?.values?.questiontype === "Single Choice"
                ? formik?.values?.questiontype
                : formik.values.questiontype === "Multiple Choice"
                ? formik?.values?.questiontype
                : formik?.values?.questiontype?.charAt(0).toUpperCase() +
                  values?.questiontype?.slice(1),
            id: editdata && editdata.type && editdata.type.id,
          },
          rules: firstData,
          attached_list:
            editdata && editdata?.attached_list
              ? editdata?.attached_list
              : null,
          order: editdata && editdata.order,
          id: editdata && editdata.id,
        };
        if (pathName == "/checklist/all-form/add-form/edit-question") {
          alldata.isQuestionToInspection = values.isQuestionToInspection;
        }
        if (alldata) {
          // dispatch(allFormUpdateData(alldata, Number(idusers), done));
          dispatch(allFormUpdateData(alldata, Number(idusers), handleDone));
          dispatch(UploadImgEditQSuccess({ data: { data: [] } }));
        }
      } else {
        toast.error("Validation fields are required");
      }
    },
  });
  const handleFirst = (event, index, formValues) => {
    let item = event;
    if (event.condition && event.answer && formValues.observation) {
      if (
        formik &&
        formik?.values &&
        (formik?.values?.questiontype === "multiplechoice" ||
          formik?.values?.questiontype === "Multiple Choice")
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: event.answer,
          issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        formik &&
        formik?.values &&
        (formik?.values?.questiontype === "singlechoice" ||
          formik?.values?.questiontype === "Single Choice")
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setFormValues("");
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        formik &&
        formik?.values &&
        (formik?.values?.questiontype === "numeric" ||
          formik?.values?.questiontype === "Numeric")
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },

          answer: Number(event?.answer),
          issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          issueTemplate: formValues ? formValues : "",
        };
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else if (
      event.condition &&
      event.answer &&
      event.subType &&
      event.subType.title !== "QuestionAutoIssue"
    ) {
      if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "multiplechoice"
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: [event.answer],
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "singlechoice"
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            // issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype?.toLowerCase() === "numeric"
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },
          answer: Number(event?.answer),
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer}</b>\"  then allow comment."`);
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          // issueTemplate: formValues ? formValues : "",
        };
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else {
      toast.error("All fields are required");
      return;
    }
    dispatch(UploadImgEditQSuccess({ data: { data: [] } }));
  };

  const handleSingleAndMultiChoiceEdit = (e, key, val) => {
    const value = e.target.value;
    let singleChoice = singleandmultichoiceedit;
    singleChoice[key] = e.target.value;
    // setSingleAndMultiChoiceEdit({
    //   ...singleandmultichoiceedit,
    //   [e.target.name]: value,
    // });
    setSingleAndMultiChoiceEdit(singleChoice);
    setUiRender(!uiRender);
  };
  const [objects, setObject] = useState({});
  const handleChangeobj = (event, index, id) => {
    setObject((state) => {
      const newObject = { ...state.objects };
      newObject[`${id}`] = { value: Number(event.target.value) };
      return { objects: newObject };
    });
  };
  useEffect(() => {
    const validations = [
      {
        id: 1,
        key: "required",
        title: "Is Required?",
        type: "radio",
        answer: [...require],
      },
      {
        id: 2,
        key: "audio",
        title: "Record Audio",
        type: "radio",
        answer: [...RecordAudioField],
      },
      {
        id: 3,
        key: "image",
        title: "Take Image",
        type: "radio",
        answer: [...takeImageData],
      },
      {
        id: 4,
        key: "video",
        title: "Take Video",
        type: "radio",
        answer: [...takeVideoData],
      },
      {
        id: 5,
        key: "comment",
        title: "Always allow Comment",
        type: "radio",
        answer: [...allowCommentData],
      },
      {
        id: 6,
        key: "image_optional",
        title: "Is image optional?",
        type: "radio",
        answer: [...imageOptionalData],
      },
      {
        id: 7,
        key: "video_optional",
        title: "Is video optional?",
        type: "radio",
        answer: [...videoOptionalData],
      },
      {
        id: 8,
        key: "comment_required",
        title: "Is comment required?",
        type: "radio",
        answer: [...requiredCommentData],
      },
    ];
    setValidatarr(validations);
  }, [
    require,
    RecordAudio,
    takeImage,
    takeVideo,
    allowComment,
    imageOptional,
    videoOptional,
    requiredComment,
  ]);
  console.log(">>>>>", validatarr);
  useEffect(() => {
    if (
      (editData && editData.type && editData.type.key === "text") ||
      "media" ||
      "file" ||
      "date " ||
      "time" ||
      "datetime" ||
      "timer" ||
      "numeric"
    ) {
      setValue(0);
    }
  }, []);

  const handleReset = () => {
    require[0].value = false;
    require[1].value = false;
    require[2].value = false;
    require[3].value = false;
    require[4].value = false;
    require[5].value = false;
    require[6].value = false;
    takeImageData[0].value = false;
    takeImageData[1].value = false;
    takeVideoData[0].value = false;
    takeVideoData[1].value = false;
    RecordAudioField[0].value = false;
    RecordAudioField[1].value = false;
    allowCommentData[0].value = false;
    allowCommentData[1].value = false;
    imageOptionalData[0].value = false;
    imageOptionalData[1].value = false;
    videoOptionalData[0].value = false;
    videoOptionalData[1].value = false;
    requiredCommentData[0].value = false;
    requiredCommentData[1].value = false;

    setVal("");
    setRecordAudio("");
    setTakeImage("");
    setTakeVideo("");
    setAllowComment("");
    setImageOptional("");
    setVideoOptional("");
    setRequiredComment("");
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          {/* <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link
                  to="/checklist/all-form/edit-forms"
                  className="submenu-item"
                >
                  <Button
                    className="cancle-button cancle-hover"
                    style={{ backgroundColor: "#ffffff" }}
                    sx={{ backgroundColor: "#ffffff" }}
                    onClick={() => {
                      dispatch(UploadImgEditQSuccess({ data: { data: [] } }));
                    }}
                  >
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Button type="submit" className="header-add cancle-hover">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid> */}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Button
                  className="cancle-button cancle-hover"
                  onClick={() => {
                    dispatch(UploadImgEditQSuccess({ data: { data: [] } }));
                    navigate(-1);
                  }}
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>

                <Button type="submit" className="header-add cancle-hover">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
            <Container
              maxWidth="lg"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Title Images</label>
                <Box className="attachment-file">
                  <input
                    name="titleImages"
                    accept="image/png, image/jpeg"
                    className="file-upload"
                    id="contained-button-file"
                    multiple
                    type="file"
                    hidden
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="contained-button-file">
                    <Fab component="span" className="store-file cancle-hover">
                      <AddOutlinedIcon />
                    </Fab>
                  </label>
                  <Box display="flex" flexDirection="row" flexWrap="wrap">
                    {allKey && allKey.length > 0 ? (
                      allKey.map((item, index) => (
                        <div key={index} className="image-container">
                          <div className="image-wrapper">
                            <img
                              className="file-preview"
                              src={item.url || item.location}
                              alt={`attachment-${index}`}
                            />
                            <div className="delete-container">
                              <DeleteIcon
                                className="delete-icon"
                                onClick={() => handleDeleteImage(item.key)}
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <Box>
                        <img
                          className="file-preview"
                          src={Blank}
                          alt="attachment"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </FormControl>

              {console.log("formik.values", formik.values)}
              {pathName == "/checklist/all-form/add-form/edit-question" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormControlLabel
                    className="label-name"
                    control={
                      <Checkbox
                        type="checkbox"
                        name="isQuestionToInspection"
                        onChange={(e) =>
                          setIsQuestionToInspections(e.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ color: "#196773" }}
                        checked={isQuestionToInspections}
                      />
                    }
                    label="Add Question to Inspection"
                  />
                </FormControl>
              )}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Title</label>
                <TextField
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  className="date-input"
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Description</label>
                <TextField
                  name="desc"
                  value={formik.values.desc}
                  onChange={formik.handleChange}
                  error={formik.touched.desc && Boolean(formik.errors.desc)}
                  helperText={formik.touched.desc && formik.errors.desc}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  multiline
                  className="date-input"
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Question Score</label>
                <TextField
                  name="question_score"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  value={formik.values.question_score}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.question_score &&
                    Boolean(formik.errors.question_score)
                  }
                  helperText={
                    formik.touched.question_score &&
                    formik.errors.question_score
                  }
                  id="filled-number"
                  variant="outlined"
                  size="small"
                  className="date-input"
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Question Type</label>
                <Select
                  size="small"
                  disabled
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: "#e2e8f0",
                        "& .MuiMenuItem-root": {
                          padding: 2,
                        },
                        height: "400px",
                      },
                    },
                  }}
                  defaultValue={editdata?.type?.title}
                  name="questiontype"
                  value={formik.values.questiontype}
                  onChange={formik.handleChange}
                  renderValue={(selected) => {
                    var type = typeof selected;
                    return selected;
                  }}
                  error={
                    formik.touched.questiontype &&
                    Boolean(formik.errors.questiontype)
                  }
                  helperText={
                    formik.touched.questiontype && formik.errors.questiontype
                  }
                  sx={{ borderRadius: "8px" }}
                >
                  {/* <MenuItem value={formik.values.questiontype}>
                  <em>{formik.values.questiontype}</em>
                </MenuItem> */}
                  {questiontype &&
                    questiontype?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.title}>
                          {option.title ? option.title : editData?.type?.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              {formik && formik?.values && formik?.values?.questiontype && (
                <Box className="tab-box">
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      background: "#F6F8FC",
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Validation"
                        {...a11yProps(0)}
                        className="tab-item"
                      />
                      {editData && editData?.type?.key === "text" ? (
                        ""
                      ) : editData && editData?.type?.key === "numeric" ? (
                        ""
                      ) : editData && editData?.type?.key === "Numeric" ? (
                        ""
                      ) : editData && editData?.type?.key === "multi_select" ? (
                        ""
                      ) : editData && editData?.type?.key === "media" ? (
                        ""
                      ) : editData && editData?.type?.key === "file" ? (
                        ""
                      ) : // editData && editData?.type?.key === "Date" ? (
                      //   ""
                      // ) : editData && editData?.type?.key === "date" ? (
                      //   ""
                      // ) : editData && editData?.type?.key === "Time" ? (
                      //   ""
                      // ) : editData && editData?.type?.key === "time" ? (
                      //   ""
                      // ) : editData && editData?.type?.key === "DateTime" ? (
                      //   ""
                      // ) : editData && editData?.type?.key === "datetime" ? (
                      //   ""
                      // )
                      editData &&
                        (editData?.type?.key === "Date" ||
                          editData?.type?.key === "date" ||
                          editData?.type?.key === "Time" ||
                          editData?.type?.key === "time" ||
                          editData?.type?.key === "DateTime" ||
                          editData?.type?.key === "datetime") ? (
                        <Tab
                          label="Rules"
                          {...a11yProps(3)}
                          className="tab-item"
                        />
                      ) : editData && editData?.type?.key === "Timer" ? (
                        ""
                      ) : editData && editData?.type?.key === "timer" ? (
                        ""
                      ) : editData && editData?.type?.key === "number" ? (
                        ""
                      ) : (
                        <Tab
                          label="Rules"
                          {...a11yProps(1)}
                          className="tab-item"
                        />
                      )}
                      {editData && editData?.type?.key === "text" ? (
                        ""
                      ) : editData && editData?.type?.key === "numeric" ? (
                        ""
                      ) : editData && editData?.type?.key === "Numeric" ? (
                        ""
                      ) : editData && editData?.type?.key === "multi_select" ? (
                        ""
                      ) : editData && editData?.type?.key === "media" ? (
                        ""
                      ) : editData && editData?.type?.key === "file" ? (
                        ""
                      ) : editData && editData?.type?.key === "Date" ? (
                        ""
                      ) : editData && editData?.type?.key === "date" ? (
                        ""
                      ) : editData && editData?.type?.key === "Time" ? (
                        ""
                      ) : editData && editData?.type?.key === "time" ? (
                        ""
                      ) : editData && editData?.type?.key === "DateTime" ? (
                        ""
                      ) : editData && editData?.type?.key === "datetime" ? (
                        ""
                      ) : editData && editData?.type?.key === "Timer" ? (
                        ""
                      ) : editData && editData?.type?.key === "timer" ? (
                        ""
                      ) : editData && editData?.type?.key === "number" ? (
                        ""
                      ) : (
                        <Tab
                          label="Weightage Score"
                          {...a11yProps(2)}
                          className="tab-item"
                        />
                      )}
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    {validatarr.map((item, i) => {
                      console.log("valval", val);
                      return (
                        <Box key={i}>
                          {item?.key === "required" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                onChange={handleRequire}
                                value={val ? val : ""}
                                // value={require}
                                row
                                name="required"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setRequire("yes");
                                      // }}
                                    />
                                  }
                                  label="Yes"
                                />

                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => setRequire("no")}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}

                          {item?.key === "audio" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                // onChange={formik.handleChange}
                                onChange={handleRecordaudio}
                                value={RecordAudio}
                                row
                                name="audio"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => setRecordAudio("yes")}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => setRecordAudio("no")}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {item?.key === "image" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                onChange={handleTakeImage}
                                value={takeImage}
                                row
                                name="image"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setTakeImage("yes");
                                      // }}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setTakeImage("no");
                                      // }}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {item?.key === "video" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                onChange={handleTakeVideo}
                                value={takeVideo}
                                row
                                name="video"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setTakeImage("yes");
                                      // }}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setTakeImage("no");
                                      // }}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {item?.key === "comment" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                // onChange={formik.handleChange}
                                onChange={handleAllowComment}
                                value={allowComment}
                                row
                                name="comment"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setAllowComment("yes");
                                      // }}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setAllowComment("no");
                                      // }}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {item?.key === "image_optional" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                onChange={handleImageOptional}
                                value={imageOptional}
                                row
                                name="image_optional"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setImageOptional("yes");
                                      // }}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setImageOptional("no");
                                      // }}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {item?.key === "video_optional" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                onChange={handleVideoOptional}
                                value={videoOptional}
                                row
                                name="video_optional"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setImageOptional("yes");
                                      // }}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setImageOptional("no");
                                      // }}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {item?.key === "comment_required" && (
                            <FormControl fullWidth>
                              <label>
                                {item.id}. &nbsp;
                                {item?.title}
                              </label>
                              <RadioGroup
                                onChange={handleRequiredComment}
                                value={requiredComment}
                                row
                                name="comment_required"
                                sx={{ p: 0 }}
                                className="radio-btn"
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setRequiredComment("yes");
                                      // }}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio
                                      size="small"
                                      // onClick={() => {
                                      //   setRequiredComment("no");
                                      // }}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                        </Box>
                      );
                    })}
                    <Button
                      // type="submit"
                      className="header-add cancle-hover"
                      onClick={handleReset}
                    >
                      Reset All
                    </Button>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {editData && editData?.type?.key === "text" ? (
                      ""
                    ) : editData && editData?.type?.key === "numeric" ? (
                      ""
                    ) : editData && editData?.type?.key === "Numeric" ? (
                      ""
                    ) : editData && editData?.type?.key === "multi_select" ? (
                      ""
                    ) : editData && editData?.type?.key === "media" ? (
                      ""
                    ) : editData && editData?.type?.key === "file" ? (
                      ""
                    ) : editData && editData?.type?.key === "Date" ? (
                      ""
                    ) : editData && editData?.type?.key === "date" ? (
                      ""
                    ) : editData && editData?.type?.key === "Time" ? (
                      ""
                    ) : editData && editData?.type?.key === "time" ? (
                      ""
                    ) : editData && editData?.type?.key === "DateTime" ? (
                      ""
                    ) : editData && editData?.type?.key === "datetime" ? (
                      ""
                    ) : editData && editData?.type?.key === "Timer" ? (
                      ""
                    ) : editData && editData?.type?.key === "timer" ? (
                      ""
                    ) : editData && editData?.type?.key === "number" ? (
                      ""
                    ) : (
                      <>
                        <Grid container spacing={1} sx={{ textAlign: "left" }}>
                          <Grid item xs={12}>
                            <Box sx={{ fontWeight: "bold" }}>Rules</Box>
                            <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                              Rules Preset
                            </Box>
                            {ruleList &&
                              ruleList.map((items, i) => {
                                return (
                                  <Card sx={{ p: 3, mb: 2 }} key={i}>
                                    <Grid
                                      container
                                      spacing={1}
                                      sx={{
                                        textAlign: "left",
                                        padding: "20px 0px 20px 0px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={11}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          className="list-btn third-list"
                                        >
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[0]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition" ? (
                                            <FormControl>
                                              {editData &&
                                              editData.type &&
                                              editData.type.key === "number" ? (
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  size="small"
                                                  id="demo-simple-select"
                                                  name="condition"
                                                  defaultValue="select"
                                                  value={
                                                    items && items.condition
                                                      ? items.condition.title
                                                      : "Select Condition"
                                                  }
                                                  renderValue={(selectvalue) =>
                                                    selectvalue
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        fontSize: "12px",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Condition
                                                    </em>
                                                  </MenuItem>
                                                  {numericCondition &&
                                                    numericCondition.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              ) : (
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  size="small"
                                                  id="demo-simple-select"
                                                  name="condition"
                                                  defaultValue="select"
                                                  value={
                                                    items && items.condition
                                                      ? items.condition
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    value="="
                                                    className="select-item"
                                                  >
                                                    =
                                                  </MenuItem>
                                                </Select>
                                              )}
                                            </FormControl>
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[2]}
                                          </Typography>
                                          {/* {items?.template?.split(/[{,}]/)[3] ===
                             "answer" ? (
                               <FormControl>
                                 <Select
                                   size="small"
                                   defaultValue="select"
                                   labelId="demo-simple-select-label"
                                   id="demo-simple-select"
                                   name="answer"
                                   value={
                                     items && items.answer
                                       ? items.answer
                                       : "select"
                                   }
                                   onChange={(e) =>
                                     handleChangeRules(e, items, i)
                                   }
                                   MenuProps={{
                                     disableScrollLock: true,
                                     anchorOrigin: {
                                       vertical: "bottom",
                                       horizontal: "center",
                                     },
                                     PaperProps: {
                                       sx: {
                                         height: "200px",
                                       },
                                     },
                                   }}
                                   sx={{ borderRadius: "8px" }}
                                 >
                                   <MenuItem
                                     disabled
                                     value="select"
                                     className="select-item"
                                   >
                                     <em
                                       style={{
                                         fontStyle: "unset",
                                         color: "darkgrey",
                                       }}
                                     >
                                       Select Answer
                                     </em>
                                   </MenuItem>
                                   {optionsAnswer &&
                                     optionsAnswer.map((select, i) => {
                                       return (
                                         <MenuItem
                                           key={i}
                                           value={select.value}
                                           className="select-item"
                                         >
                                           {select?.title}
                                         </MenuItem>
                                       );
                                     })}
                                 </Select>
                               </FormControl>
                             ) : (
                               ""
                             )} */}
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[3] === "answer" ? (
                                            editData &&
                                            editData.type &&
                                            editData.type.key ===
                                              "single_select" &&
                                            editData &&
                                            editData?.attached_list ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {editData &&
                                                    editData?.attached_list &&
                                                    editData?.attached_list?.items?.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : editData &&
                                              editData.type &&
                                              editData.type.key ===
                                                "multi_select" &&
                                              editData &&
                                              editData?.attached_list ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {editData &&
                                                    editData?.attached_list &&
                                                    editData?.attached_list?.items?.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : editData &&
                                              editData.type &&
                                              editData.type.key === "number" ? (
                                              <FormControl
                                                hiddenLabel
                                                sx={{ mb: 2 }}
                                              >
                                                {/* {items && items.answer ? "" : <InputLabel id="formsid" disableAnimation>Select Item</InputLabel>} */}
                                                <TextField
                                                  name="answer"
                                                  // InputLabelProps={{
                                                  //   shrink: true,
                                                  // }}
                                                  autoComplete="off"
                                                  sx={{
                                                    width: { sm: 80, md: 150 },
                                                    "& .MuiInputBase-root": {
                                                      height: 35,
                                                    },
                                                  }}
                                                  // label={<Input id="formsid"/>}
                                                  variant="outlined"
                                                  disableUnderline={true}
                                                  InputProps={{
                                                    inputProps: { min: 1 },
                                                  }}
                                                  type="number"
                                                  // value={formik?.values?.questionscore}
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : ""
                                                  }
                                                  // onChange={formik.handleChange}
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  error={
                                                    formik.touched
                                                      .questionscore &&
                                                    Boolean(
                                                      formik.errors
                                                        .questionscore
                                                    )
                                                  }
                                                  helperText={
                                                    formik.touched
                                                      .questionscore &&
                                                    formik.errors.questionscore
                                                  }
                                                  id="filled-number"
                                                  size="md"
                                                  className="date-input"
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer.title
                                                      : "select"
                                                  }
                                                  renderValue={(selectvalue) =>
                                                    selectvalue
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {optionsAnswer &&
                                                    optionsAnswer.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            )
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[4]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[5] === "issueTemplate" ? (
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              className="list-btn"
                                              sx={{
                                                alignItems: "center",
                                                margin: "5px 0 0 0",
                                              }}
                                            >
                                              <Button
                                                onClick={handleClickOpen}
                                                className="select-issues cancle-hover"
                                              >
                                                Select issue template
                                              </Button>
                                            </Stack>
                                          ) : (
                                            ""
                                          )}
                                          {items?.template?.split(/[{,}]/)[6]}
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={1}>
                                        <Box
                                          sx={{ float: "right" }}
                                          className="plus-icon"
                                        >
                                          <AddOutlinedIcon
                                            onClick={(e) =>
                                              handleFirst(items, i, formValues)
                                            }
                                            sx={{ mr: 1 }}
                                            className="add-icon"
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Card>
                                );
                              })}
                          </Grid>
                        </Grid>
                        <Card sx={{ p: 3, mb: 1 }} className="card">
                          <h3>Your Rules</h3>
                          {firstData &&
                            firstData.map((items, i) => (
                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  textAlign: "left",
                                  margin: "5px",
                                  padding: "5px",
                                }}
                              >
                                <Grid item xs={11}>
                                  <Box>
                                    <Box sx={{ display: "flex" }}>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[0]}
                                      </Typography>
                                      <Typography>
                                        &nbsp; "
                                        <b>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition"
                                            ? items.data?.condition?.title
                                            : ""}
                                        </b>
                                        " &nbsp;{" "}
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[2]}
                                      </Typography>

                                      {/* {editdata &&
                         editdata?.type?.title === "Multiple Choice" ? (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   Array.isArray(items.data?.answer) &&
                                   items.data?.answer?.map((data) => (
                                     <>{data.title}</>
                                   ))
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         ) : editdata &&
                           editdata?.type?.title === "Single Choice" ? (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   Array.isArray(items.data?.answer) &&
                                   items.data?.answer?.map((data) => (
                                     <>{data.title}</>
                                   ))
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         ) : editdata &&
                         editdata?.type?.key  ===
                         "number" ? (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   items.data?.answer
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         ) : (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   items.data?.answer?.title
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         )} */}
                                      <Typography>
                                        {/* &nbsp; "
                                 <b>
                                   {items?.template?.split(/[{,}]/)[3] ===
                                     "answer"
                                     ? items.data?.answer?.map((data)=>(
                                       <>{data.title}</>
                                     ))
                                     : ""}
                                 </b>
                                 " &nbsp; */}
                                        {
                                          <>
                                            {" "}
                                            &nbsp; "{" "}
                                            <b>
                                              {items &&
                                              items?.template?.split(
                                                /[{,}]/
                                              )[3] === "answer" ? (
                                                <>
                                                  {items &&
                                                    items.data &&
                                                    Array.isArray(
                                                      items?.data?.answer
                                                    ) &&
                                                    items.data?.answer?.map(
                                                      (data) => (
                                                        <>{data.title}</>
                                                      )
                                                    )}

                                                  {Array.isArray(
                                                    items?.data?.answer
                                                  ) === false ? (
                                                    <>
                                                      {" "}
                                                      <b>
                                                        {items &&
                                                        items?.template?.split(
                                                          /[{,}]/
                                                        )[3] === "answer"
                                                          ? items &&
                                                            items.data &&
                                                            items.data
                                                              ?.answer &&
                                                            items.data?.answer
                                                              ?.title
                                                            ? items.data?.answer
                                                                ?.title
                                                            : items.data?.answer
                                                          : ""}
                                                      </b>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </b>
                                            " &nbsp;
                                          </>

                                          // : formik?.values?.questiontype ===
                                          //   "numeric" ? (
                                          //   <>
                                          //     {" "}
                                          //     &nbsp; "{" "}
                                          //     <b>
                                          //       {items &&
                                          //       items?.template?.split(
                                          //         /[{,}]/
                                          //       )[3] === "answer"
                                          //         ? items &&
                                          //           items.data &&
                                          //           items.data?.answer
                                          //         : ""}
                                          //     </b>
                                          //     " &nbsp;
                                          //   </>
                                          // ) : (
                                          //   <>
                                          //     {" "}
                                          //     &nbsp; "{" "}
                                          //     <b>
                                          //       {items &&
                                          //       items?.template?.split(
                                          //         /[{,}]/
                                          //       )[3] === "answer"
                                          //         ? items &&
                                          //           items.data &&
                                          //           items.data?.answer?.title
                                          //         : ""}
                                          //     </b>
                                          //     " &nbsp;
                                          //   </>
                                          // )
                                        }
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[4]}
                                      </Typography>
                                      <Divider />

                                      <Divider />
                                    </Box>
                                    {items?.template?.split(/[{,}]/)[5] ===
                                    "issueTemplate" ? (
                                      <Box>
                                        <h4>
                                          <b>
                                            Observation :{" "}
                                            {
                                              items?.data?.issueTemplate
                                                ?.observation
                                            }
                                          </b>
                                        </h4>
                                        <p>
                                          {items?.data?.issueTemplate?.category}{" "}
                                          {"=>"}{" "}
                                          {
                                            items?.data?.issueTemplate
                                              ?.subCategory
                                          }
                                        </p>
                                        <p>
                                          IssuePriority :{" "}
                                          <b>
                                            {
                                              items?.data?.issueTemplate
                                                ?.severity
                                            }
                                          </b>
                                        </p>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    {/* {items?.template?.split(/[{,}]/)[5] ===
                       "issueTemplate" ? (
                         <Box>
                           <h4>
                             <b>
                               Observation :{" "}
                               {items?.data?.issueTemplate?.issue}
                             </b>
                           </h4>
                           <p>
                             {items?.data?.issueTemplate?.category}{" "}
                             {"=>"}{" "}
                             {items?.data?.issueTemplate?.subcategory}
                           </p>
                           <p>
                             IssuePriority :{" "}
                             <b>
                               {items?.data?.issueTemplate?.severity}
                             </b>
                           </p>
                         </Box>
                       ) : (
                         ""
                       )} */}
                                    <Typography>
                                      {" "}
                                      {items?.template?.split(/[{,}]/)[6]}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    onClick={() => handleDelete(i)}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                    className="iconbtn"
                                  >
                                    <DeleteOutlineIcon
                                      sx={{ color: "#e74a3b" }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                        </Card>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "text" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "numeric" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Numeric" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "number" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "multi_select" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "media" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "file" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Date" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "date" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Time" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "time" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "DateTime" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "datetime" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Timer" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "timer" ? (
                      ""
                    ) : formik?.values?.questiontype?.toLowerCase() ===
                      "numeric" ? (
                      ""
                    ) : (
                      <>
                        {editData &&
                        editData.type &&
                        editData.type.key === "single_select" ? (
                          singlemultichoice &&
                          Object.entries(singlemultichoice)?.map(
                            ([key, val]) => (
                              <Grid
                                container
                                spacing={1}
                                className="marginbottom"
                              >
                                <Grid item md={6} xs={12} sm={12} lg={2}>
                                  <Typography>{key}</Typography>
                                </Grid>
                                <Grid item md={6} xs={12} sm={12} lg={6}>
                                  <TextField
                                    id="outlined-basic"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name={key}
                                    defaultValue={val}
                                    value={singleandmultichoiceedit.value}
                                    onChange={(e) =>
                                      handleSingleAndMultiChoiceEdit(
                                        e,
                                        key,
                                        val
                                      )
                                    }
                                    className="date-input"
                                  />
                                </Grid>
                              </Grid>
                            )
                          )
                        ) : editData &&
                          editData.type &&
                          editData.type.key === "multi_select" ? (
                          singlemultichoice &&
                          Object.entries(singlemultichoice)?.map(
                            ([key, val]) => (
                              <Grid
                                container
                                spacing={1}
                                className="marginbottom"
                              >
                                <Grid item md={6} xs={12} sm={12} lg={2}>
                                  <Typography>{key}</Typography>
                                </Grid>
                                <Grid item md={6} xs={12} sm={12} lg={6}>
                                  <TextField
                                    id="outlined-basic"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name={key}
                                    defaultValue={val}
                                    value={singleandmultichoiceedit.value}
                                    onChange={(e) =>
                                      handleSingleAndMultiChoiceEdit(
                                        e,
                                        key,
                                        val
                                      )
                                    }
                                    className="date-input"
                                  />
                                </Grid>
                              </Grid>
                            )
                          )
                        ) : (
                          <>
                            {" "}
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Yes</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  defaultValue="1"
                                  name="option_score.yes"
                                  value={formik.values?.option_score?.yes}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>No</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  defaultValue="0"
                                  name="option_score.no"
                                  value={formik.values?.option_score?.no}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Acceptable</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  defaultValue="1"
                                  name="option_score.acceptable"
                                  value={
                                    formik.values?.option_score?.acceptable
                                  }
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Not Acceptable</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  defaultValue="0"
                                  name="option_score.notAcceptable"
                                  value={
                                    formik.values?.option_score?.notAcceptable
                                  }
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Discarded</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.discarded"
                                  value={formik.values?.option_score?.discarded}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Returned</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  defaultValue="0"
                                  name="option_score.returned"
                                  value={formik.values?.option_score?.returned}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Not Applicable</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  defaultValue="0"
                                  name="option_score.notApplicable"
                                  value={
                                    formik.values?.option_score?.notApplicable
                                  }
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}

                    {/* {editData && editData.type && editData.type.key?.toLowerCase() !== "single_select" && "multichoice" ? <> <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} xs={12} sm={12} lg={2}>
                      <Typography>Yes</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="option_score.yes"
                        defaultValue="1"
                        value={formik.values?.option_score?.yes}
                        onChange={formik.handleChange}
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                    <Grid container spacing={1} className="marginbottom">
                      <Grid item md={6} xs={12} sm={12} lg={2}>
                        <Typography>No</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12} lg={6}>
                        <TextField
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue="0"
                          name="option_score.no"
                          value={formik.values?.option_score?.no}
                          onChange={formik.handleChange}
                          className="date-input"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="marginbottom">
                      <Grid item md={6} xs={12} sm={12} lg={2}>
                        <Typography>Acceptable</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12} lg={6}>
                        <TextField
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue="1"
                          name="option_score.acceptable"
                          value={formik.values?.option_score?.acceptable}
                          onChange={formik.handleChange}
                          className="date-input"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="marginbottom">
                      <Grid item md={6} xs={12} sm={12} lg={2}>
                        <Typography>Not Acceptable</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12} lg={6}>
                        <TextField
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue="0"
                          name="option_score.notacceptable"
                          value={formik.values?.option_score?.notacceptable}
                          onChange={formik.handleChange}
                          className="date-input"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="marginbottom">
                      <Grid item md={6} xs={12} sm={12} lg={2}>
                        <Typography>Discarded</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12} lg={6}>
                        <TextField
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue="0"
                          name="option_score.discarded"
                          value={formik.values?.option_score?.discarded}
                          onChange={formik.handleChange}
                          className="date-input"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="marginbottom">
                      <Grid item md={6} xs={12} sm={12} lg={2}>
                        <Typography>Returned</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12} lg={6}>
                        <TextField
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue="0"
                          name="option_score.returned"
                          value={formik.values?.option_score?.returned}
                          onChange={formik.handleChange}
                          className="date-input"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="marginbottom">
                      <Grid item md={6} xs={12} sm={12} lg={2}>
                        <Typography>Not Applicable</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12} lg={6}>
                        <TextField
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue="0"
                          name="option_score.notapplicable"
                          value={formik.values?.option_score?.notapplicable}
                          onChange={formik.handleChange}
                          className="date-input"
                        />
                      </Grid>
                    </Grid></> : ""} */}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {editData && editData?.type?.key === "text" ? (
                      ""
                    ) : editData && editData?.type?.key === "numeric" ? (
                      ""
                    ) : editData && editData?.type?.key === "Numeric" ? (
                      ""
                    ) : editData && editData?.type?.key === "multi_select" ? (
                      ""
                    ) : editData && editData?.type?.key === "media" ? (
                      ""
                    ) : editData && editData?.type?.key === "file" ? (
                      ""
                    ) : editData && editData?.type?.key === "Boolean" ? (
                      ""
                    ) : editData && editData?.type?.key === "boolean" ? (
                      ""
                    ) : editData && editData?.type?.key === "Timer" ? (
                      ""
                    ) : editData && editData?.type?.key === "timer" ? (
                      ""
                    ) : editData && editData?.type?.key === "number" ? (
                      ""
                    ) : (
                      <>
                        <Grid container spacing={1} sx={{ textAlign: "left" }}>
                          <Grid item xs={12}>
                            <Box sx={{ fontWeight: "bold" }}>Rules</Box>
                            <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                              Rules Preset
                            </Box>
                            {ruleList &&
                              ruleList.map((items, i) => {
                                if (i !== 1) return null;
                                return (
                                  <Card sx={{ p: 3, mb: 2 }} key={i}>
                                    <Grid
                                      container
                                      spacing={1}
                                      sx={{
                                        textAlign: "left",
                                        padding: "20px 0px 20px 0px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={11}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          className="list-btn third-list"
                                        >
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[0]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition" ? (
                                            <FormControl>
                                              {editData &&
                                              editData.type &&
                                              editData.type.key === "number" ? (
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  size="small"
                                                  id="demo-simple-select"
                                                  name="condition"
                                                  defaultValue="select"
                                                  value={
                                                    items && items.condition
                                                      ? items.condition.title
                                                      : "Select Condition"
                                                  }
                                                  renderValue={(selectvalue) =>
                                                    selectvalue
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        fontSize: "12px",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Condition
                                                    </em>
                                                  </MenuItem>
                                                  {numericCondition &&
                                                    numericCondition.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              ) : (
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  size="small"
                                                  id="demo-simple-select"
                                                  name="condition"
                                                  defaultValue="select"
                                                  value={
                                                    items && items.condition
                                                      ? items.condition
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    value="="
                                                    className="select-item"
                                                  >
                                                    =
                                                  </MenuItem>
                                                </Select>
                                              )}
                                            </FormControl>
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[2]}
                                          </Typography>
                                          {/* {items?.template?.split(/[{,}]/)[3] ===
                             "answer" ? (
                               <FormControl>
                                 <Select
                                   size="small"
                                   defaultValue="select"
                                   labelId="demo-simple-select-label"
                                   id="demo-simple-select"
                                   name="answer"
                                   value={
                                     items && items.answer
                                       ? items.answer
                                       : "select"
                                   }
                                   onChange={(e) =>
                                     handleChangeRules(e, items, i)
                                   }
                                   MenuProps={{
                                     disableScrollLock: true,
                                     anchorOrigin: {
                                       vertical: "bottom",
                                       horizontal: "center",
                                     },
                                     PaperProps: {
                                       sx: {
                                         height: "200px",
                                       },
                                     },
                                   }}
                                   sx={{ borderRadius: "8px" }}
                                 >
                                   <MenuItem
                                     disabled
                                     value="select"
                                     className="select-item"
                                   >
                                     <em
                                       style={{
                                         fontStyle: "unset",
                                         color: "darkgrey",
                                       }}
                                     >
                                       Select Answer
                                     </em>
                                   </MenuItem>
                                   {optionsAnswer &&
                                     optionsAnswer.map((select, i) => {
                                       return (
                                         <MenuItem
                                           key={i}
                                           value={select.value}
                                           className="select-item"
                                         >
                                           {select?.title}
                                         </MenuItem>
                                       );
                                     })}
                                 </Select>
                               </FormControl>
                             ) : (
                               ""
                             )} */}
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[3] === "answer" ? (
                                            editData &&
                                            editData.type &&
                                            editData.type.key ===
                                              "single_select" &&
                                            editData &&
                                            editData?.attached_list ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {editData &&
                                                    editData?.attached_list &&
                                                    editData?.attached_list?.items?.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : editData &&
                                              editData.type &&
                                              editData.type.key ===
                                                "multi_select" &&
                                              editData &&
                                              editData?.attached_list ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {editData &&
                                                    editData?.attached_list &&
                                                    editData?.attached_list?.items?.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : editData &&
                                              editData.type &&
                                              editData.type.key === "number" ? (
                                              <FormControl
                                                hiddenLabel
                                                sx={{ mb: 2 }}
                                              >
                                                {/* {items && items.answer ? "" : <InputLabel id="formsid" disableAnimation>Select Item</InputLabel>} */}
                                                <TextField
                                                  name="answer"
                                                  // InputLabelProps={{
                                                  //   shrink: true,
                                                  // }}
                                                  autoComplete="off"
                                                  sx={{
                                                    width: { sm: 80, md: 150 },
                                                    "& .MuiInputBase-root": {
                                                      height: 35,
                                                    },
                                                  }}
                                                  // label={<Input id="formsid"/>}
                                                  variant="outlined"
                                                  disableUnderline={true}
                                                  InputProps={{
                                                    inputProps: { min: 1 },
                                                  }}
                                                  type="number"
                                                  // value={formik?.values?.questionscore}
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : ""
                                                  }
                                                  // onChange={formik.handleChange}
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  error={
                                                    formik.touched
                                                      .questionscore &&
                                                    Boolean(
                                                      formik.errors
                                                        .questionscore
                                                    )
                                                  }
                                                  helperText={
                                                    formik.touched
                                                      .questionscore &&
                                                    formik.errors.questionscore
                                                  }
                                                  id="filled-number"
                                                  size="md"
                                                  className="date-input"
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer.title
                                                      : "select"
                                                  }
                                                  renderValue={(selectvalue) =>
                                                    selectvalue
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {optionsAnswer &&
                                                    optionsAnswer.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            )
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[4]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[5] === "issueTemplate" ? (
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              className="list-btn"
                                              sx={{
                                                alignItems: "center",
                                                margin: "5px 0 0 0",
                                              }}
                                            >
                                              <Button
                                                onClick={handleClickOpen}
                                                className="select-issues cancle-hover"
                                              >
                                                Select issue template
                                              </Button>
                                            </Stack>
                                          ) : (
                                            ""
                                          )}
                                          {items?.template?.split(/[{,}]/)[6]}
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={1}>
                                        <Box
                                          sx={{ float: "right" }}
                                          className="plus-icon"
                                        >
                                          <AddOutlinedIcon
                                            onClick={(e) =>
                                              handleFirst(items, i, formValues)
                                            }
                                            sx={{ mr: 1 }}
                                            className="add-icon"
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Card>
                                );
                              })}
                          </Grid>
                        </Grid>
                        <Card sx={{ p: 3, mb: 1 }} className="card">
                          <h3>Your Rules</h3>
                          {firstData &&
                            firstData.map((items, i) => (
                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  textAlign: "left",
                                  margin: "5px",
                                  padding: "5px",
                                }}
                              >
                                <Grid item xs={11}>
                                  <Box>
                                    <Box sx={{ display: "flex" }}>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[0]}
                                      </Typography>
                                      <Typography>
                                        &nbsp; "
                                        <b>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition"
                                            ? items.data?.condition?.title
                                            : ""}
                                        </b>
                                        " &nbsp;{" "}
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[2]}
                                      </Typography>

                                      {/* {editdata &&
                         editdata?.type?.title === "Multiple Choice" ? (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   Array.isArray(items.data?.answer) &&
                                   items.data?.answer?.map((data) => (
                                     <>{data.title}</>
                                   ))
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         ) : editdata &&
                           editdata?.type?.title === "Single Choice" ? (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   Array.isArray(items.data?.answer) &&
                                   items.data?.answer?.map((data) => (
                                     <>{data.title}</>
                                   ))
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         ) : editdata &&
                         editdata?.type?.key  ===
                         "number" ? (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   items.data?.answer
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         ) : (
                           <>
                             {" "}
                             &nbsp; "{" "}
                             <b>
                               {items &&
                               items?.template?.split(/[{,}]/)[3] ===
                                 "answer"
                                 ? items &&
                                   items.data &&
                                   items.data?.answer?.title
                                 : ""}
                             </b>
                             " &nbsp;
                           </>
                         )} */}
                                      <Typography>
                                        {/* &nbsp; "
                                 <b>
                                   {items?.template?.split(/[{,}]/)[3] ===
                                     "answer"
                                     ? items.data?.answer?.map((data)=>(
                                       <>{data.title}</>
                                     ))
                                     : ""}
                                 </b>
                                 " &nbsp; */}
                                        {
                                          <>
                                            {" "}
                                            &nbsp; "{" "}
                                            <b>
                                              {items &&
                                              items?.template?.split(
                                                /[{,}]/
                                              )[3] === "answer" ? (
                                                <>
                                                  {items &&
                                                    items.data &&
                                                    Array.isArray(
                                                      items?.data?.answer
                                                    ) &&
                                                    items.data?.answer?.map(
                                                      (data) => (
                                                        <>{data.title}</>
                                                      )
                                                    )}

                                                  {Array.isArray(
                                                    items?.data?.answer
                                                  ) === false ? (
                                                    <>
                                                      {" "}
                                                      <b>
                                                        {items &&
                                                        items?.template?.split(
                                                          /[{,}]/
                                                        )[3] === "answer"
                                                          ? items &&
                                                            items.data &&
                                                            items.data
                                                              ?.answer &&
                                                            items.data?.answer
                                                              ?.title
                                                            ? items.data?.answer
                                                                ?.title
                                                            : items.data?.answer
                                                          : ""}
                                                      </b>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </b>
                                            " &nbsp;
                                          </>

                                          // : formik?.values?.questiontype ===
                                          //   "numeric" ? (
                                          //   <>
                                          //     {" "}
                                          //     &nbsp; "{" "}
                                          //     <b>
                                          //       {items &&
                                          //       items?.template?.split(
                                          //         /[{,}]/
                                          //       )[3] === "answer"
                                          //         ? items &&
                                          //           items.data &&
                                          //           items.data?.answer
                                          //         : ""}
                                          //     </b>
                                          //     " &nbsp;
                                          //   </>
                                          // ) : (
                                          //   <>
                                          //     {" "}
                                          //     &nbsp; "{" "}
                                          //     <b>
                                          //       {items &&
                                          //       items?.template?.split(
                                          //         /[{,}]/
                                          //       )[3] === "answer"
                                          //         ? items &&
                                          //           items.data &&
                                          //           items.data?.answer?.title
                                          //         : ""}
                                          //     </b>
                                          //     " &nbsp;
                                          //   </>
                                          // )
                                        }
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[4]}
                                      </Typography>
                                      <Divider />

                                      <Divider />
                                    </Box>
                                    {items?.template?.split(/[{,}]/)[5] ===
                                    "issueTemplate" ? (
                                      <Box>
                                        <h4>
                                          <b>
                                            Observation :{" "}
                                            {
                                              items?.data?.issueTemplate
                                                ?.observation
                                            }
                                          </b>
                                        </h4>
                                        <p>
                                          {items?.data?.issueTemplate?.category}{" "}
                                          {"=>"}{" "}
                                          {
                                            items?.data?.issueTemplate
                                              ?.subCategory
                                          }
                                        </p>
                                        <p>
                                          IssuePriority :{" "}
                                          <b>
                                            {
                                              items?.data?.issueTemplate
                                                ?.severity
                                            }
                                          </b>
                                        </p>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    {/* {items?.template?.split(/[{,}]/)[5] ===
                       "issueTemplate" ? (
                         <Box>
                           <h4>
                             <b>
                               Observation :{" "}
                               {items?.data?.issueTemplate?.issue}
                             </b>
                           </h4>
                           <p>
                             {items?.data?.issueTemplate?.category}{" "}
                             {"=>"}{" "}
                             {items?.data?.issueTemplate?.subcategory}
                           </p>
                           <p>
                             IssuePriority :{" "}
                             <b>
                               {items?.data?.issueTemplate?.severity}
                             </b>
                           </p>
                         </Box>
                       ) : (
                         ""
                       )} */}
                                    <Typography>
                                      {" "}
                                      {items?.template?.split(/[{,}]/)[6]}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    onClick={() => handleDelete(i)}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                    className="iconbtn"
                                  >
                                    <DeleteOutlineIcon
                                      sx={{ color: "#e74a3b" }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                        </Card>
                      </>
                    )}
                  </TabPanel>
                </Box>
              )}
            </Container>
          </Box>
        )}
      </form>
      <SelectRuleIssueModal
        open={open}
        handleClose={handleClose}
        listData={listData}
        setFormValues={setFormValues}
      />
    </>
  );
};

export default EditQuestionAllform;
